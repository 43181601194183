import React, { useState, useEffect } from 'react';
import { schools as localities } from '../schools';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import './Home.css';
import Categories from './Categories';
import CustomError from './common/CustomError';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';

const Home = (props) => {

  const defaultSelectedCity = '-1';
  const defaultSelectedState = '-1';
  const subject = encodeURIComponent("Intrebare Scoala Soferi");
  const body = encodeURIComponent(
    "Buna,\n\n"
    + "Vi-am gasit adresa pe www.viraj.ro\n"
    + "M-ar interesa sa fac scoala de soferi (categoria B) cu Dvs.\n"
    + "Imi puteti spune va rog care este pretul?.\n\n"
    + "Multumesc,\n"
    + "Numele Tau"
  );

  const [selectedState, setSelectedState] = useState(defaultSelectedState);
  const [selectedCity, setSelectedCity] = useState(defaultSelectedCity);
  const [stateData, setStateData] = useState([]);
  const [drivingSchoolsList, setDrivingSchoolsList] = useState([]);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  useEffect(() => {
    const state = query.get("judet");
    const city = query.get("oras");

    getDrivingSchools(state, city);

  }, []);

  const getDrivingSchools = async (state, city) => {
    if (!state || state === defaultSelectedState || !city || city === defaultSelectedCity) {
      return;
    }

    setDocumentTitle(city);
    setSelectedState(state);
    const data = localities.find(school => school.state === state.toString());
    if (data) {
      setStateData(data.schools);
    }
    setSelectedCity(city);
    setIsLoading(true);

    try {
      const data = await API.get('driving-schools', `/schools?state=${state}&city=${city}`);
      setDrivingSchoolsList(data);
    }
    catch (err) {
      setShowError(true);
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }

  const onSelectedStateChange = (event) => {
    setIsSearchDisabled(true);
    const newSelectedState = event.target.value;

    setSelectedState(newSelectedState);
    setDrivingSchoolsList([]);

    const data = localities.find(school => school.state === newSelectedState.toString());
    if (data) {
      setStateData(data.schools);
    }

    setSelectedCity(defaultSelectedCity);
  }

  const onSelectedCityChange = (event) => {
    const newSelectedCity = event.target.value;

    setIsSearchDisabled(newSelectedCity === defaultSelectedCity)
    setDrivingSchoolsList([]);
    setSelectedCity(newSelectedCity);
  }

  const handleSearchClick = async () => {
    props.history.push(`?judet=${selectedState}&oras=${selectedCity}`);
    setDocumentTitle(selectedCity);
    setDrivingSchoolsList([]);
    getDrivingSchools(selectedState, selectedCity);
  }

  const setDocumentTitle = (city) => document.title = `Scoala Soferi In ${city}`;

  const renderTitle = () => {
    let text = "Lista Scoli Soferi";
    if (selectedCity !== defaultSelectedCity) {
      text = `Scoala Soferi In ${selectedCity}`;
    }

    return (
      <div className="text-center">
        <h1 className="font-weight-normal mb-5">{text}</h1>
      </div>
    )
  }

  const cardStyle = {
    backgroundImage: 'url(https://commonlook.com/wp-content/uploads/2019/05/placeholder-300.jpg)'
  }

  const renderSchoolList = () => {
    const drivingSchools = drivingSchoolsList.map(school =>
      <div className="card shadow mb-4 mh-10">
        <div className="card-body">
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              <img className="card-img image-cover" style={cardStyle} />
            </div>
            <div className="col-md-7 mt-3">
              <p>Nume: {school.name}</p>
              {Categories(school.categories)}
              <p>Email:<a href={`mailto:${school.email}?subject=${subject}&body=${body}`} target="_blank" rel="noopener noreferrer"> {school.email}</a></p>
              {
                props.isAuthenticated &&
                <CopyToClipboard text={school.school_id}>
                  <button className="btn btn-primary">Revendica</button>
                </CopyToClipboard>
              }
            </div>
          </div>
        </div>
      </div>
    );

    return drivingSchools;
  }

  return (
    <div className="container">
      <Helmet>
        <title>Gaseste Scoala De Soferi Perfecta</title>
      </Helmet>
      <CustomError message="Am intampinat o eroare in timpul cautarii. Te rugam sa incerci dinnou." show={showError} onHide={() => setShowError(false)} />
      {renderTitle()}
      <div className="row justify-content-center">
        <div className="col-lg-8">

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" for="state">Judet</label>
            </div>
            <select className="custom-select" id="state" value={selectedState} onChange={onSelectedStateChange}>
              <option value={defaultSelectedState}>Selecteaza</option>
              {localities.map(school => <option value={school.state}>{school.state}</option>)}
            </select>
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" for="city">Oras</label>
            </div>
            <select className="custom-select" id="city" value={selectedCity} onChange={onSelectedCityChange}>
              <option value={defaultSelectedCity}>Selecteaza</option>
              {stateData.map(data => <option value={data.city}>{data.city}</option>)}
            </select>
          </div>

          <button onClick={handleSearchClick} disabled={isSearchDisabled} className="btn btn-primary mb-5">Cauta</button>

          {
            isLoading &&
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          }

          {renderSchoolList()}
        </div>
      </div>

    </div>
  );
}

export default Home;
