import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      event.persist();

      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    },
    (newFields) => setValues(newFields),
  ];
}

export function useCategories(initialCategories) {
  const [categories, setCategories] = useState(initialCategories);

  const includesCategory = (category) => categories.includes(category)
  const handleCategoryChange = (event) => {
    event.persist();

    const isChecked = event.target.checked;
    const category = event.target.id;

    if (isChecked === false) {
      const newCateogires = categories.filter(c => c !== category);
      setCategories(newCateogires);
    } else if (isChecked === true && !categories.includes(category)) {
      setCategories([...categories, category]);
    }
  }

  return [
    categories,
    setCategories,
    includesCategory,
    handleCategoryChange,
  ];
}
