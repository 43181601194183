import React, { useState, useEffect } from 'react';
import './DrivingSchoolAdmin.css'
import { API } from 'aws-amplify';
import Categories from './Categories';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import CustomError from './common/CustomError';

const DrivingSchoolAdmin = () => {

  const subject = encodeURIComponent("Intrebare Scoala Soferi");
  const body = encodeURIComponent(
    "Buna,\n\n"
    + "Vi-am gasit adresa pe www.viraj.ro\n"
    + "M-ar interesa sa fac scoala de soferi (categoria B) cu Dvs.\n"
    + "Imi puteti spune va rog care este pretul?.\n\n"
    + "Multumesc,\n"
    + "Numele Tau"
  );

  const [drivingSchoolsList, setDrivingSchoolsList] = useState([]);
  const [drivingSchoolToDelete, setDrivingSchoolToDelete] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDrivingSchools();
  }, []);

  const renderDeleteConfirmation = () => {
    return (
      <Modal show={showDeleteConfirmation} onHide={hideModal} centered onExited={handleModaLExit}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Te rugam sa confirmi faptul ca doresti sa stergi "{drivingSchoolToDelete.name}"
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={hideModal} variant="secondary">Anuleaza</Button>
          <Button disabled={isDeleting} onClick={handleDeleteConfirmationClick} variant="primary">
            {isDeleting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>
    )
  };

  const hideError = () => setShowError(false);
  const hideModal = () => setShowDeleteConfirmation(false)
  const handleModaLExit = () => setIsDeleting(false);


  const getDrivingSchools = async () => {
    setIsLoading(true);
    try {
      const data = await API.get('driving-schools', '/schools/admin');
      // const data = [
      //   {
      //     school_id: 'id',
      //     telephone: '123',
      //     categories: ["A", "B"],
      //     city: "RESITA",
      //     state: "CS",
      //     name: "Samepl Schoopl",
      //     userId: "superadmin",
      //     address: "test addr",
      //     email: "cool@mail.com",
      //   }
      // ];

      setDrivingSchoolsList(data);
    }
    catch (err) {
      showErrorModal('Am intampinat o eroare in timpul afisarii. Te rugam sa re-incarci pagina.');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteClick = (drivingSchool) => {
    setDrivingSchoolToDelete(drivingSchool);
    setShowDeleteConfirmation(true);
  }

  const showErrorModal = (message) => {
    setErrorMessage(message);
    setShowError(true);
  }

  const handleDeleteConfirmationClick = async () => {
    try {
      setIsDeleting(true);
      await API.del('driving-schools', `/schools/${drivingSchoolToDelete.school_id}`);
      await getDrivingSchools();
      setShowDeleteConfirmation(false);
    }
    catch (err) {
      setShowDeleteConfirmation(false);
      showErrorModal('Am intampinat o eroare in timpul stergerii. Te rugam sa incerci din nou.');
    }
  }

  const renderDrivingSchoolCards = () => {
    const items = [];

    // items.push(
    //   <div className="text-center mb-5">
    //     <Link to={{
    //       pathname: "/driving-school-admin/add",
    //       state: {
    //         title: 'Adauga',
    //         drivingSchool: {
    //           name: '',
    //           state: '',
    //           city: '',
    //           address: '',
    //           email: '',
    //           telephone: '',
    //           categories: [],
    //         },
    //         isEditMode: false,
    //       }
    //     }} className="navbar-brand">
    //       <button className="btn btn-primary">Adauga</button>
    //     </Link>
    //   </div>
    // );

    for (const drivingSchool of drivingSchoolsList) {

      items.push(
        <>
          <div className="card shadow mb-3">
            <div className="card-body">
              <p>Nume: {drivingSchool.name}</p>
              {Categories(drivingSchool.categories)}
              <p>Email:<a href={`mailto:${drivingSchool.email}?subject=${subject}&body=${body}`} target="_blank" rel="noopener noreferrer"> {drivingSchool.email}</a></p>
              <Link to={{
                pathname: "/driving-school-admin/edit",
                state: {
                  title: 'Editare',
                  drivingSchool,
                  isEditMode: true,
                }
              }} className="navbar-brand">
                <button className="btn btn-primary"><span className="material-icons">edit</span></button>
              </Link>
              {/* <button onClick={e => handleDeleteClick(drivingSchool)} className="btn btn-danger"><i className="material-icons">delete</i></button> */}
            </div>
          </div>
        </>
      );
    }
    return items;
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <CustomError message={errorMessage} show={showError} onHide={hideError} />
        <div className="col-lg-6">
          {renderDrivingSchoolCards()}

          {
            isLoading &&
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          }

          <p>Pentru a revendica o scoala de soferi avem nevoie de 3 date trimise la adresa de email revendica@viraj.ro</p>
          <p>1. Adresa de email folosita pentru a creea contrul tau pe viraj.ro</p>
          <p>2. ID-ul scolii pe care doresti sa o revendici. Pentru a obtine ID-ul scolii pe care doresti sa o revendici, te rugam sa mergi pe pagina principala si sa cauti scoala dorita, apoi sa dai click pe "Copiaza ID" si sa ii dai Paste in email</p>
          <p>3. Pentru a verifica faptul ca detii scoala de soferi cu ID-ul respectiv, te rugam sa atasezi in acest email o copie dupa certificatul de înregistrare eliberat de O.N.R.C</p>
          <p>Dupa ce verificam actul, iti vom asigna scoala de soferi cu ID-ul pe care l-ai specificat</p>

          {renderDeleteConfirmation()}
        </div>
      </div>
    </div >
  )
}

export default DrivingSchoolAdmin;