import React, { useState } from 'react';
import './SignIn.css';
import { Auth } from 'aws-amplify';
import CustomError from './common/CustomError';
import { Link } from "react-router-dom";

const SignIn = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    setIsLoading(true);

    try {
      await Auth.signIn(email, password);
      props.userHasAuthenticated(true);
      props.history.push("/driving-school-admin");
    } catch (e) {
      console.log(e);
      
      if (e.message === "Incorrect username or password.") {
        setErrorMessage('Nume utilizator sau parola sunt incorecte. Te rugam sa incerci dinnou');
      } else {
        setErrorMessage('Am intampinat o eroare in timpul logarii. Te rugam sa incerci dinnou');
      }
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function isFormValid() {
    return email.length > 0 && password.length > 0;
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <div className="container text-center">
      <CustomError message={errorMessage} show={showError} onHide={() => setShowError(false)} />
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-4">
          <form onSubmit={handleOnSubmit}>
            <h1 className="font-weight-normal mb-5">Logare Admin Scoala</h1>
            <input value={email} onChange={e => setEmail(e.target.value)} className="form-control mb-3" type="email" placeholder="Adresa Email" />
            <input value={password} onChange={e => setPassword(e.target.value)} className="form-control mb-5" type="password" placeholder="Parola" />
            {
              isLoading
                ? <button disabled={isLoading} onClick={handleLogin} className="btn btn-primary">
                  <div class="spinner-grow text-light" role="status"></div>
                </button>
                : <button onClick={handleLogin} className="btn btn-lg btn-primary btn-block mb-5" disabled={!isFormValid()}>Logare</button>
            }
          </form>
          <div>
            <div className="mb-5">
              <Link to="/sign-in/reset">Ai Uitat Parola?</Link>
            </div>
            <p className="font-weight-normal h5">Nu ai cont?</p>
            <a className="btn btn-primary" href="/sign-up">Creeaza Cont</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn;