export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://so6iskxcy4.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_2Xy5M4YPG",
    APP_CLIENT_ID: "6v4r5eql71c217mmfg7n552v90",
    IDENTITY_POOL_ID: "us-east-1:3ad9c640-0f62-4d03-93a6-957c1111c398"
  }
};