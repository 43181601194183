import React from 'react';
import { Modal } from 'react-bootstrap';

const CustomError = (props) => {

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Eroare</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.message}
      </Modal.Body>
    </Modal>
  )
}

export default CustomError;