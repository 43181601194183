import React, { useState, useEffect } from 'react';
import { useCategories, useFormFields } from '../libs/hooksLib';
import { schools as localities } from '../schools';
import { API } from 'aws-amplify';
import CustomError from './common/CustomError';

const DrivingSchoolForm = (props) => {

  const { title, drivingSchool, isEditMode } = props.location.state;

  const catA = ['AM', 'A1', 'A2', 'A'];
  const catB = ['B1', 'B', 'BE'];
  const catC = ['C1', 'C1E', 'C', 'CE'];
  const catD = ['D1', 'D1E', 'D', 'DE',]
  const catT = ['Tr', 'Tb', 'Tv'];

  const defaultSelectedCity = '-1';
  const defaultSelectedState = '-1';

  const [citiesList, setCitiesList] = useState([]);
  const [editFormData, handleEditFormDataChange, setEditFormData] = useFormFields(drivingSchool);
  const [categories, setCategories, isCategoryIncluded, handleCategoryChange] = useCategories(drivingSchool.categories);
  const [selectedState, setSelectedState] = useState(defaultSelectedState);
  const [selectedCity, setSelectedCity] = useState(defaultSelectedCity);
  const [isSavingData, setIsSavingData] = useState(false);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setSelectedState(drivingSchool.state);
    updateCitiesList(drivingSchool.state);
    setSelectedCity(drivingSchool.city);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSavingData(true);

    const finalData = {
      ...editFormData,
      categories,
      state: selectedState,
      city: selectedCity,
    }

    try {
      if (isEditMode) {
        await updateDrivingSchool(finalData);
      } else {
        await addDrivingSchool(finalData);
      }
      //TODO go to list page
    } catch (err) {
      setShowError(true);
    } finally {
      setIsSavingData(false);
    }
  };

  const updateDrivingSchool = async (drivingSchool) => {
    try {
      await API.put("driving-schools", `/schools/${drivingSchool.school_id}`, {
        body: drivingSchool,
      });
      props.history.push('/driving-school-admin');
    } catch (err) {
      setIsSavingData(false);
      // Show pop-up or smth?
      alert(err);
    }
  }

  const addDrivingSchool = async (drivingSchool) => {
    try {
      await API.post("driving-schools", `/schools`, {
        body: drivingSchool,
      });
      props.history.push('/driving-school-admin');
    } catch (err) {
      setIsSavingData(false);
      alert(err);
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    props.history.push('/driving-school-admin');
  };

  const updateCitiesList = (newSelectedState) => {
    const data = localities.find(school => school.state === newSelectedState.toString());
    if (data) {
      setCitiesList(data.schools);
    }
  }


  const renderPremiumBadge = () => {
    return (<span class="badge badge-warning">Premium</span>)
  }

  const renderBoolCategories = (categories) => {
    const categoriesToRender = [];
    for (const category of categories) {
      categoriesToRender.push(
        <td>
          <div className="custom-control custom-checkbox">
            <input checked={isCategoryIncluded(category)} onChange={handleCategoryChange} type="checkbox" className="custom-control-input" id={category} />
            <label className="custom-control-label" for={category}>{category}</label>
          </div>
        </td>
      );
    }

    return categoriesToRender;
  };

  const hideError = () => setShowError(false);


  const isFormValid = () => {
    return editFormData.name.length > 0
      && editFormData.address.length > 0
      && editFormData.email.length > 0
      && editFormData.telephone.length > 0
      && categories.length > 0
      && selectedState !== defaultSelectedState
      && selectedCity !== defaultSelectedCity
  }

  const renderLocationDropdowns = () => {

    const onSelectedStateChange = (event) => {
      const newSelectedState = event.target.value;
      setSelectedState(newSelectedState);

      updateCitiesList(newSelectedState);

      setSelectedCity(defaultSelectedCity);
    }

    const onSelectedCityChange = (event) => {
      const newSelectedCity = event.target.value;

      setSelectedCity(newSelectedCity);
    }

    return (
      <>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <label className="input-group-text" for="state">Judet</label>
          </div>
          <select className="custom-select" id="state" value={selectedState} onChange={onSelectedStateChange}>
            <option value={defaultSelectedState}>Selecteaza</option>
            {localities.map(school => <option value={school.state}>{school.state}</option>)}
          </select>
        </div>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <label className="input-group-text" for="city">Oras</label>
          </div>
          <select className="custom-select" id="city" value={selectedCity} onChange={onSelectedCityChange}>
            <option value={defaultSelectedCity}>Selecteaza</option>
            {citiesList.map(data => <option value={data.city}>{data.city}</option>)}
          </select>
        </div>
      </>
    )
  };

  return (
    <div className="container">
      <CustomError message="Am intampinat o eroare in timpul salvarii. Te rugam sa incerci dinnou." show={showError} onHide={hideError} />
      <h1 className="text-center mb-4">{title}</h1>
      <form>
        <div className="form-group">
          <label for="name">Nume Scoala Soferi</label>
          <input value={editFormData.name} onChange={handleEditFormDataChange} type="text" className="form-control" id="name" />
        </div>

        {/* <div className="mb-4 mt-1">
          <label for="profileImage">Imagine profil {renderPremiumBadge()}</label>
          <input type="file" className="form-control-file" id="profileImage" />
        </div> */}

        {renderLocationDropdowns()}

        {/* <div className="form-group">
          <label for="email">Adresa {renderPremiumBadge()}</label>
          <input value={editFormData.address} onChange={handleEditFormDataChange} type="text" className="form-control" id="address" aria-describedby="emailHelp" />
        </div> */}

        <div class="form-group">
          <label for="email">Adresa Email</label>
          <input value={editFormData.email} onChange={handleEditFormDataChange} type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="scoala@adresa.com" />
        </div>

        {/* <div class="form-group">
          <label for="telephone">Numar telefon {renderPremiumBadge()}</label>
          <input value={editFormData.telephone} onChange={handleEditFormDataChange} type="tel" className="form-control" id="telephone" aria-describedby="telHelp" />
        </div> */}

        <div>
          <label for="cat-container">Categorii</label>
          <table className="table table-borderless" id="cat-container">
            <tbody>

            </tbody>
            <tr>
              {renderBoolCategories(catA)}
            </tr>
            <tr>
              {renderBoolCategories(catB)}
            </tr>
            <tr>
              {renderBoolCategories(catC)}
            </tr>
            <tr>
              {renderBoolCategories(catD)}
            </tr>
            <tr>
              {renderBoolCategories(catT)}
            </tr>
          </table>

        </div>
        <div className="row mt-4 mb-3 justify-content-center">
          <button onClick={handleSubmit} className="btn btn-primary mx-3" disabled={isSavingData || !isFormValid()}>Salveaza
              {isSavingData && <div class="spinner-border spinner-border-sm ml-2 align-middle" role="status"></div>}
          </button>
          <button onClick={handleCancel} className="btn btn-danger mx-3" disabled={isSavingData}>Anuleaza</button>
        </div>
      </form>
    </div>
  )
};

export default DrivingSchoolForm;