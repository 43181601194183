import React from "react";
import { Switch } from "react-router-dom";
import Home from "./components/Home";
import AppliedRoute from './components/AppliedRoute';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import DrivingSchoolAdmin from "./components/DrivingSchoolAdmin";
import DrivingSchoolForm from "./components/DrivingSchoolForm";
import AccountVerification from './components/AccountVerification';
import ResetPassword from './components/pages/ResetPassword';
import PrivacyPolicy from "./components/pages/legal/PrivacyPolicy";
import { URLs } from './constants';
import TermsAndConditions from "./components/pages/legal/TermsAndConditions";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute exact path="/" component={Home} appProps={appProps} />
      <AppliedRoute exact path="/sign-in" component={SignIn} appProps={appProps} />
      <AppliedRoute exact path="/sign-in/reset" component={ResetPassword} appProps={appProps} />
      <AppliedRoute path="/sign-up" exact component={SignUp} appProps={appProps} />
      <AppliedRoute path="/driving-school-admin" exact component={DrivingSchoolAdmin} appProps={appProps} />
      <AppliedRoute path="/driving-school-admin/edit" exact component={DrivingSchoolForm} appProps={appProps} />
      <AppliedRoute path="/driving-school-admin/add" exact component={DrivingSchoolForm} appProps={appProps} />
      <AppliedRoute path="/verify-account/:username" exact component={AccountVerification} appProps={appProps} />
      <AppliedRoute path={URLs.privacyPolicy} exact component={PrivacyPolicy} appProps={appProps} />
    </Switch>
  );
}