import React, { useState } from 'react';
import './SignIn.css';
import { Auth } from 'aws-amplify';
import CustomError from './common/CustomError';

const SignUp = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);

    try {
      await Auth.signUp({
        username: email,
        password: password
      });
      props.history.push(`/verify-account/${email}`);
    } catch (e) {
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function isFormValid() {
    return (
      email.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <div className="container text-center">
      <CustomError message="Am intampinat o eroare in timpul creeari contului. Te rugam sa incerci dinnou." show={showError} onHide={() => setShowError(false)} />
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-4">
          <form onSubmit={handleOnSubmit}>
            <h1 className="font-weight-normal mb-5">Cont Nou</h1>
            <input className="form-control mb-2" value={email} onChange={v => setEmail(v.target.value)} type="email" placeholder="Adresa Email" />
            <input className="form-control mb-2" value={password} onChange={v => setPassword(v.target.value)} type="password" placeholder="Parola" />
            <input className="form-control mb-5" value={confirmPassword} onChange={v => setConfirmPassword(v.target.value)} type="password" placeholder="Repeta parola" />
            <button onClick={handleSubmit} className="btn btn-lg btn-primary btn-block" disabled={isLoading || !isFormValid()} >
              Creeaza Cont
            {isLoading && <div class="spinner-border spinner-border-sm ml-3 align-middle" role="status"></div>}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUp;