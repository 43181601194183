import React from 'react';

const Categories = (categories) => {
  let customBadgeClass = '';

  return (
    <p> Categoria:
      {
        categories.map(category => {
          if (category.startsWith('A')) {
            customBadgeClass = 'badge-secondary';
          } else if (category.startsWith('B')) {
            customBadgeClass = 'badge-primary';
          } else if (category.startsWith('C')) {
            customBadgeClass = 'badge-success';
          } else if (category.startsWith('D')) {
            customBadgeClass = 'badge-info';
          } else if (category.startsWith('T')) {
            customBadgeClass = 'badge-dark';
          }

          const badgeClass = `badge ${customBadgeClass} ml-2`;
          return <span className={badgeClass}>{category}</span>
        })}
    </p>
  )
}

export default Categories;