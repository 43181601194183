export const schools = [
  {
    "state": "AB",
    "schools": [
      {
        "city": "SEBES",
        "schools": [
          {
            "name": "AUTODIA SRL",
            "address": "STR.SIMION BARNUTIU NR.14",
            "category": "B",
            "telephone": "0745624621",
            "email": "bratean@yahoo.com"
          },
          {
            "name": "AUTO DRAGOI NIC SRL",
            "address": "STR.DORIN PAVEL NR.95",
            "category": "B",
            "telephone": "0258733976",
            "email": "avdragoi2006@yahoo.com"
          },
          {
            "name": "AUTO NOVA SOF SRL",
            "address": "STR.AVRAM IANCU NR.21",
            "category": "B",
            "telephone": "0721352429",
            "email": "autonovasof@yahoo.com"
          },
          {
            "name": "CATELINA SOF SRL",
            "address": "STR.SURIANU NR.30",
            "category": "B,C,CE",
            "telephone": "0744848760",
            "email": "c_simonel@yahoo.com"
          },
          {
            "name": "DELOREAN SRL",
            "address": "STR.PARINGULUI NR.10",
            "category": "B,C,CE",
            "telephone": "0258733595",
            "email": "scoala_delorean@yahoo.com"
          },
          {
            "name": "STAS CIUCUR SNC SEBES",
            "address": "STR.OCCIDENTULUI NR.2",
            "category": "B,C,D,CE",
            "telephone": "",
            "email": "ciucur_oana@yahoo.com"
          },
          {
            "name": "AUTO DRAGOI NIC",
            "address": "STR.DORIN PAVEL NR.30",
            "category": "B",
            "telephone": "0788185469",
            "email": "avdragoi2006@yahoo.com"
          }
        ]
      },
      {
        "city": "BLAJ",
        "schools": [
          {
            "name": "PLESA SOFERUL DANTE SRL",
            "address": "STR.ASTRA NR.4",
            "category": "B,C",
            "telephone": "0258714889",
            "email": "plesasoferul@gmail.com"
          },
          {
            "name": "ROTARIA IMPEX SRL",
            "address": "STR.IZVORULUI NR.7",
            "category": "B",
            "telephone": "0258710145",
            "email": "rotaria_impex@yahoo.com"
          },
          {
            "name": "PILOT TEST AUTO SRL",
            "address": "P-TA 1848 NR.3",
            "category": "B",
            "telephone": "0755238148",
            "email": "pilottestauto@yahoo.com"
          },
          {
            "name": "MARCU DRIVING",
            "address": "STR.TIMOTEI CIPARIU NR.21",
            "category": "B",
            "telephone": "0743141887",
            "email": "marcuadrian14@icloud.com"
          },
          {
            "name": "GILU FAHRER SRL",
            "address": "PIATA 1848 NR.3",
            "category": "B",
            "telephone": "0764199922",
            "email": "virgil.marginean@yahoo.com"
          }
        ]
      },
      {
        "city": "AIUD",
        "schools": [
          {
            "name": "BOC COMERCIAL SRL",
            "address": "STR.VALEA AIUDULUI NR.76",
            "category": "B,C,D,CE",
            "telephone": "",
            "email": "BOCCOMERIAL@YAHOO.COM"
          },
          {
            "name": "POJAR TEST SRL",
            "address": "STR.GHEORGJHE SINCAI NR.1",
            "category": "B",
            "telephone": "0747067797",
            "email": "pojar62@yahoo.com"
          },
          {
            "name": "CSEGEZI SRL",
            "address": "STR.MIHAI EMINESCU NR.7",
            "category": "B,C,CE",
            "telephone": "863132",
            "email": "Csegezi.tunde@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC AIUD",
            "address": "STR.ION CREANGA NR.14",
            "category": "B",
            "telephone": "861937",
            "email": "ai.aiud@mail.albanet.ro"
          },
          {
            "name": "MUNTEAN EUGEN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.UNIRII,BL.53,SC.J,ET.3,AP.9,LOC.AIU\nD,JUD.ALBA",
            "category": "B",
            "telephone": "0745398112",
            "email": "ioanmircea_leahu@yahoo.com"
          },
          {
            "name": "ORIAN AUGUSTIN  P.F.A.",
            "address": "STR.SG.HATEGAN,BL.B10,ET.2,AP.7,LOC.",
            "category": "B",
            "telephone": "0721371283",
            "email": "ioanmircea_leahu@yahoo.com"
          },
          {
            "name": "CHINDEA VIOREL PFA",
            "address": "STR.MIHAI VITEAZU BL.2 SC.J AP.13",
            "category": "B",
            "telephone": "0745581847",
            "email": "ioanmircea_leahu@yahoo.com"
          },
          {
            "name": "KERESZTESI STEFAN PFA",
            "address": "STR.AUREL VLAICU,NR.24,LOC.AIUD",
            "category": "B",
            "telephone": "0744766704",
            "email": "ioanmircea_leahu@yahoo.com"
          }
        ]
      },
      {
        "city": "ALBA IULA",
        "schools": [
          {
            "name": "P.F.A. MICLEA MIRCEA",
            "address": "STR.REPUBLICII NR.32",
            "category": "B",
            "telephone": "0740071306",
            "email": "mirceamicleapfa@yahoo.com"
          },
          {
            "name": "PFA SALISTEAN  ION DOMNICA",
            "address": "STR.REPUBLICII NR.32",
            "category": "B",
            "telephone": "0758709395",
            "email": "andrei_cfr.cluj@yahoo.com"
          },
          {
            "name": "P.F.A. RADU LIVIUS   OLIMPIA   ",
            "address": "STR.REPUBLICII NR.32",
            "category": "B",
            "telephone": "0722358131",
            "email": "radu.livius@yahoo.com"
          }
        ]
      },
      {
        "city": "OCNA MURES",
        "schools": [
          {
            "name": "ORIAN TIUCA SRL",
            "address": "STR.STEFAN AUGUSTIN NR.36",
            "category": "B",
            "telephone": "0766326321",
            "email": "mihaelatiuca@yahoo.com"
          },
          {
            "name": "GAD AUTO DRIVE SRL",
            "address": "STR.COLONIA PESTE MURES NR.9",
            "category": "B",
            "telephone": "0721/286306",
            "email": "ioanmircea_leahu@yahoo.com"
          },
          {
            "name": "TEST GAD AUTO",
            "address": "STR.NICOLAE IORGA NR.18A",
            "category": "B",
            "telephone": "0736193557",
            "email": "b200gad@gmail.com"
          }
        ]
      },
      {
        "city": "ALBA IULIA",
        "schools": [
          {
            "name": "EURO-DELI AUTO SRL",
            "address": "STR.TEIULOR NR.2",
            "category": "B,B1",
            "telephone": "",
            "email": "gligorion@yahoo.com"
          },
          {
            "name": "AUTO SOF ALBA SRL",
            "address": "STR.REPUBLICII NR.2,ET.2",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0742938655",
            "email": "aaaautosof@yahoo.com"
          },
          {
            "name": "TOTAL DRIVE SRL",
            "address": "STR.STEFAN CEL MARE NR.2 ET.1",
            "category": "B,C,CE",
            "telephone": "0744289551",
            "email": "totaldrive_ab@yahoo.com"
          },
          {
            "name": "AUTO COMPANY ALBA SRL",
            "address": "STR.EMIL RACOVITA NR 29 A,ET.2",
            "category": "B",
            "telephone": "0744709790",
            "email": "nicoleta@autocompany.ro"
          },
          {
            "name": "TRIFAN AUTO SRL",
            "address": "B-DUL REPUBLICII NR.33A",
            "category": "B",
            "telephone": "0745659070",
            "email": "trifanauto@yahoo.com"
          },
          {
            "name": "VOCRYSCO ALBA SRL",
            "address": "STR.ALESSANDRIA NR.7 A",
            "category": "B",
            "telephone": "0744535599",
            "email": "vocrys2000@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ALEXANDRU \nDOMSA",
            "address": "STR.TUDOR VLADIMIRESCU;NR.143",
            "category": "B",
            "telephone": "0258816065",
            "email": "david_carmen_daniela@yahoo.com"
          },
          {
            "name": "TUDOR SRL",
            "address": "STR.DECEBAL NR.27 ET.1",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0258810832",
            "email": "dragusmrs@yahoo.com"
          },
          {
            "name": "PFA CROSAN GHEORGHE",
            "address": "STR.REPUBLICII NR.33 A",
            "category": "B",
            "telephone": "0724206506",
            "email": "iosifedaniel@yahoo.com"
          },
          {
            "name": "AUTO SOF LAZAR SRL",
            "address": "B-DUL FERDINAND I NR.66",
            "category": "B,C,D,CE,D1",
            "telephone": "0745587511",
            "email": "vi61la@yahoo.com"
          },
          {
            "name": "ROYAL SOF SRL-D",
            "address": "STR.EMIL RACOVITA NR.29A",
            "category": "B,C,BE,CE",
            "telephone": "0746212282",
            "email": "royalsof_alba@yahoo.com"
          },
          {
            "name": "SULARIA D IOSIF VALENTIN",
            "address": "STR VIOLETELOR NR.17",
            "category": "B",
            "telephone": "0755828545",
            "email": "sulariavalentin@yahoo.com"
          },
          {
            "name": "CIOARA N GIANI SEPTIMIU",
            "address": "STR VIOLETELOR NR.17",
            "category": "B",
            "telephone": "0744779428",
            "email": "gya.therapy@yahoo.com"
          },
          {
            "name": "ONET SORIN",
            "address": "STR VIOLETELOR NR.17",
            "category": "B",
            "telephone": "0745587507",
            "email": "onets@Yahoo.com"
          },
          {
            "name": "IOSIF EUGEN DANIEL. DAVID",
            "address": "STR PETRESTI NR.21",
            "category": "B",
            "telephone": "",
            "email": "iosifedaniel@yahoo.com"
          },
          {
            "name": "IGNEA NICOLAE",
            "address": "STR VIOLETELOR NR.17",
            "category": "B",
            "telephone": "0744579324",
            "email": "igneanicolae.in@gmail.com"
          },
          {
            "name": "PASCU PARTENIE P.F.A.",
            "address": "STR.VANATORILOR,NR.32,BL.B1B,AP.13",
            "category": "B,BE",
            "telephone": "0744976081",
            "email": "danpascu18@yahoo.ro"
          },
          {
            "name": "PASCU NICOLAE PFA",
            "address": "STR.BUCURESTI,NR.81",
            "category": "B",
            "telephone": "0720046884",
            "email": "iosifedaniel@gmail.com"
          },
          {
            "name": "OLALAU VASILE P.F.A.",
            "address": "STR.TOPORASILOR BL.CT4 AP.12",
            "category": "B",
            "telephone": "",
            "email": "vasile.olalau@yahoo.com"
          },
          {
            "name": "DANILA VASILE PFA",
            "address": "STR.CORNA NR.23.A",
            "category": "B",
            "telephone": "",
            "email": "totaldrive_ab@yahoo.com"
          },
          {
            "name": "OPTIM DRIVE SRL",
            "address": "STRADA FANTANELE,NR.16A",
            "category": "B,C,CE",
            "telephone": "",
            "email": "optimdrive@gmail.com"
          },
          {
            "name": "ADAM IONEL \"ADAM\" PFA",
            "address": "STR.VIOLETELOR,NR.16A",
            "category": "B",
            "telephone": "",
            "email": "totaldrive_ab@yahoo.com"
          },
          {
            "name": "ZAHEU RADU AUREL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STRADA OCTAVIAN GOGA,NR.36,AP1",
            "category": "B",
            "telephone": "",
            "email": "totaldrive_ab@yahoo.com"
          },
          {
            "name": "PFA BUCUR GHE.VIOREL",
            "address": "STR.FANTANELE NR.24 A",
            "category": "B",
            "telephone": "",
            "email": "oneaedy@yahoo.com"
          },
          {
            "name": "PFA ADAM PETRU",
            "address": "STR.STEFAN CEL MARE NR.14 BL.CM2 ",
            "category": "B",
            "telephone": "0740026009",
            "email": "adam_petru@ymail.com"
          },
          {
            "name": "PFA ONEA ION AUTOSTART",
            "address": "B-DUL.TRANSILVANIEI NR.25 BL.3CD  ",
            "category": "B",
            "telephone": "0723406463",
            "email": "oneaedy@yahoo.com"
          }
        ]
      },
      {
        "city": "BISTRA",
        "schools": [
          {
            "name": "APUSENI AUTOTOTAL SRL",
            "address": "Calea Turzii Nr. 146",
            "category": "A,B,C,BE,CE",
            "telephone": "0751951090",
            "email": "apuseniautototal.bistra@yahoo.com"
          }
        ]
      },
      {
        "city": "CUGIR",
        "schools": [
          {
            "name": "TUDOR CUGIR SRL",
            "address": "STR.VICTORIEI NR.9 ET 2 ",
            "category": "B",
            "telephone": "0751213069",
            "email": "dragusmrs@yahoo.com"
          },
          {
            "name": "S.C. PRUDENT S.R.L.",
            "address": "STR. VICTORIEI, NR.8B/III",
            "category": "B",
            "telephone": "0745356056",
            "email": "luben.2010@yahoo.com"
          }
        ]
      },
      {
        "city": "ZLATNA",
        "schools": [
          {
            "name": "P.F.A.VESA EUSEBIU GELU",
            "address": "STR.ION CREANGA NR.3",
            "category": "B",
            "telephone": "0740780342",
            "email": "vesa_lucica@yahoo.com"
          },
          {
            "name": "P.F.A BIHIC SILVIU ",
            "address": "STR.ION CREANGA NR.3",
            "category": "B",
            "telephone": "0742149139",
            "email": "bihic_silviu@yahoo.com"
          }
        ]
      },
      {
        "city": "ABRUD",
        "schools": [
          {
            "name": "SCOALA GLIGOR SRL-D",
            "address": "STR.PIATA EROILOR NR.10",
            "category": "B",
            "telephone": "0740026700",
            "email": "meghe_88@yahoo.com"
          }
        ]
      },
      {
        "city": "LUNCA MURESULUI",
        "schools": [
          {
            "name": "ADI AUTO MOBIL SRL",
            "address": "STR.LIVADEA NR.83",
            "category": "B,C,CE",
            "telephone": "0757425646",
            "email": "diade97@yahoo.com"
          }
        ]
      },
      {
        "city": "TEIUS",
        "schools": [
          {
            "name": "TRAFIC VIAL SRL",
            "address": "STR.DECEBAL NR.127",
            "category": "B",
            "telephone": "0736159168",
            "email": "traficvial@yahoo.com"
          }
        ]
      },
      {
        "city": "BAIA DE ARIES",
        "schools": [
          {
            "name": "EUROSOF AUTO SRL",
            "address": "Str.IOAN PATCAS,Nr.34",
            "category": "B,C,CE",
            "telephone": "0747522422",
            "email": "lveurosofaries@yahoo.com"
          }
        ]
      },
      {
        "city": "OARDA",
        "schools": [
          {
            "name": "DEFENSIVE AUTOSIM ",
            "address": "STR.CARABUSULUI,NR.8",
            "category": "B",
            "telephone": "0744978888",
            "email": "ohoi_alex@yahoo.com"
          }
        ]
      },
      {
        "city": "CAMPENI",
        "schools": [
          {
            "name": "VLAD DRAI SOF SRL",
            "address": "STR.MOTILOR NR.14",
            "category": "B",
            "telephone": "0740517677",
            "email": "Vladdraisof@yahoo.com"
          }
        ]
      },
      {
        "city": "MICESTI",
        "schools": [
          {
            "name": "BORDEA BENIAMIN ANDREI PFA",
            "address": "STR.PADIS NR.24",
            "category": "B",
            "telephone": "",
            "email": "andbenjy@yahoo.com"
          }
        ]
      },
      {
        "city": "METES",
        "schools": [
          {
            "name": "GIURA NICOLAE GRIGORE \"AUTO\" \nPFA",
            "address": "SAT TAUTI,COMUNA METES,NR.129",
            "category": "B",
            "telephone": "",
            "email": "totaldrive_ab@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "AG",
    "schools": [
      {
        "city": "PITESTI",
        "schools": [
          {
            "name": "COMEF AUTO PROMPT SRL",
            "address": "STR. MIRCEA VODA, NR. 42, CAM. 05",
            "category": "B",
            "telephone": "0721544486",
            "email": "comefautoprompt@yahoo.com"
          },
          {
            "name": "B&B TEAM PLUS 2003",
            "address": "STR. NICOLAE DOBRIN NR.3",
            "category": "B",
            "telephone": "0723282150",
            "email": "bb.teamplus2003@yahoo.ro"
          },
          {
            "name": "CRI-MAR AUTO COMPANY SRL",
            "address": "STR. CALEA CRAIOVEI, NR. 113",
            "category": "B,C,D,CE",
            "telephone": "0722524367",
            "email": "magdau@yahoo.ro"
          },
          {
            "name": "MAEDA AUTO MOTO FAVORIT",
            "address": "STR. NICOLAE DOBRIN, NR. 83, ETAJ 2",
            "category": "A,B,C,D,BE,CE,A1,B1,A2",
            "telephone": "0744913160",
            "email": "maeda_srl@yahoo.com"
          },
          {
            "name": "ALEX MIHAEL CAM SRL",
            "address": "STR. CONSTANTIN BRANNCOVEANU, BL. ",
            "category": "B",
            "telephone": "0755492768",
            "email": "www.alex_mihael_cam@yahoo.com"
          },
          {
            "name": "EMC AUTO DRIVER 2006 SRL",
            "address": "STR.NEGRU VODA NR.38 ET.1 INTRAREA ",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0747405717",
            "email": "emc@scoalasoferiemc.ro"
          },
          {
            "name": "DAMIAN AUTO DRIVE",
            "address": "B-DUL PETROCHIMISTILOR, NR. 23, ETAJ ",
            "category": "B",
            "telephone": "0726267382",
            "email": "auto.damian@yahoo.com"
          },
          {
            "name": "BNC AUTO DRIVE SRL",
            "address": "STR.PESCARILOR, NR.20, CORP B, ETAJ 3, \nCOLEGIUL AL.ODOBESCU",
            "category": "B",
            "telephone": "0741010429",
            "email": "bnc_auto1974@yahoo.ro"
          },
          {
            "name": "AUTOGENERAL ND EXCLUSIV SRL",
            "address": "STR. PATRASCU VODA, NR. 2, CORP C",
            "category": "B",
            "telephone": "0784044355",
            "email": "roxana.tudose@ymail.com"
          },
          {
            "name": "AUTO GABRIEL OPREA",
            "address": "STR. TUDOR VLADIMIRESCU, NR. 111, ",
            "category": "B",
            "telephone": "0722700148",
            "email": "autogabrieloprea@gmail.com"
          },
          {
            "name": "IMPEX STAR COM SRL",
            "address": "FUNDATURA TEILOR, NR. 11",
            "category": "B",
            "telephone": "0744925806",
            "email": "bdanutiu@yahoo.com"
          },
          {
            "name": "AUTO KINDER AYAN SRL",
            "address": "STR. DACIA, NR. 2A, ET. 2, ",
            "category": "B",
            "telephone": "0741991131",
            "email": "world.kinder@yahoo.com"
          },
          {
            "name": "MARYO AUTO ASTRAL VYP",
            "address": "STR. MIRCEA VODA, NR. 42",
            "category": "B",
            "telephone": "0745024557",
            "email": "maryoautoastralvyp@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DACIA",
            "address": "STR. HENRI COANDA, NR. 4",
            "category": "B",
            "telephone": "0248212334",
            "email": "dacialiceutehnologic@yahoo.ro"
          },
          {
            "name": "LEO SCHOOL BEST SRL",
            "address": "STR. PRUNDU MIC, NR.9",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0744.194.152",
            "email": "Leoschoolbest@gmail.com"
          },
          {
            "name": "BASARAB I",
            "address": "STR. TRIVALE, NR. 16",
            "category": "B,C",
            "telephone": "0248215900",
            "email": "relatii.publice01225@forter.ro"
          },
          {
            "name": "PFA OPREA GABRIEL ION",
            "address": "STR. TUDOR VLADIMIRESCU, NR. 111, ",
            "category": "B",
            "telephone": "0722700148",
            "email": "autogabrieloprea@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC MIHAI \nVITEAZUL",
            "address": "STR. NICOLAE DOBRIN, NR. 20",
            "category": "B",
            "telephone": "0741014433",
            "email": "irinastroescu@yahoo.com"
          },
          {
            "name": "S.C. PROFESIONISTII ANDI SI ARI SRL",
            "address": "B-DUL NICOLAE BALCESCU, NR. 92, ",
            "category": "B,C,CE",
            "telephone": "0348457095",
            "email": "magdau@yahoo.ro"
          },
          {
            "name": "S.C.AUTO DARION ABC SRL",
            "address": "STR. MIHAIL EMINESCU, NR. 11, CAM. ",
            "category": "B",
            "telephone": "0723651046",
            "email": "dragut_i@yahoo.com"
          },
          {
            "name": "MONARCH SERVICES SRL",
            "address": "STR. RAZBOIENI, NR. 35",
            "category": "A,B,A1,A2",
            "telephone": "0744352624",
            "email": "cmarius05@yahoo.com"
          },
          {
            "name": "MASTER AUTO RLG SRL",
            "address": "STR. TARGU DIN VALE, NR. 1",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0744181129",
            "email": "masterautorlg@yahoo.com"
          },
          {
            "name": "PFA-DRAGOI ILIE VALERIU",
            "address": "ION MINULESCU,NR.6, BL. B12, SC.D, ",
            "category": "A,B,A2",
            "telephone": "0766494787",
            "email": "dragoilie@yahoo.com"
          },
          {
            "name": "P.F.A. CONSTANTIN M. FLORINEL",
            "address": "CALEA CRAIOVEI, NR. 113, COMPLEX ",
            "category": "B",
            "telephone": "0769166719",
            "email": "scoaladesoferi201709@yahoo.com"
          },
          {
            "name": "VIO TOP DRIVE SRL",
            "address": "STR. OITUZ, NR. 3A,",
            "category": "B",
            "telephone": "0748994422",
            "email": "viotopdrive@yahoo.com"
          },
          {
            "name": "P.F.A. BADOI P. FLORIN-SORINEL",
            "address": "B-DUL REPUBLICII, NR. 148, CLADIREA ",
            "category": "B",
            "telephone": "0720065091",
            "email": "florin_badoi75@yahoo.com"
          },
          {
            "name": "P.F.A.RACEALA TUDOR-DANUT",
            "address": "B-DUL REPUBLICII, NR. 148, CLADIREA ",
            "category": "B",
            "telephone": "0722210788",
            "email": "racealadan@yahoo.com"
          },
          {
            "name": "P.F.A. DUMITRU OVIDIU GABRIEL",
            "address": "B-DUL REPUBLICII, NR. 148, CLADIREA ",
            "category": "B",
            "telephone": "0724577855",
            "email": "dumitruogabriel@yahoo.com"
          },
          {
            "name": "P.F.A. TUDOR E. FLORIAN",
            "address": "STR. RAZBOIENI, NR. 35",
            "category": "B",
            "telephone": "0740070112",
            "email": "floriantudor115@yahoo.com"
          },
          {
            "name": "P.F.A. SANDU GEORGE DACIAN",
            "address": "STR. RAZBOIENI, NR. 35",
            "category": "B",
            "telephone": "0724038084",
            "email": "sandu_george22@yahoo.com"
          },
          {
            "name": "P.F.A. FOTESCU MARIN",
            "address": "STR. MIRCEA VODA, NR. 42",
            "category": "B",
            "telephone": "0745784018",
            "email": "catalinfotescu@gmail.com"
          },
          {
            "name": "P.F.A.MALDAIANU ANTON MARIUS",
            "address": "STR. RAZBOIENI, NR. 35",
            "category": "B",
            "telephone": "0765252966",
            "email": "marius78anton@yahoo.com"
          },
          {
            "name": "P.F.A. MINU ADRIAN",
            "address": "STR. RAZBOIENI, NR. 35",
            "category": "B",
            "telephone": "0744506621",
            "email": "adi92mhi@yahoo.com"
          },
          {
            "name": "P.F.A. NICU GEORGEL",
            "address": "B-DUL REPUBLICII, NR. 148, CLADIREA ",
            "category": "B",
            "telephone": "0741010413",
            "email": "nnn.georgel@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC COSTIN D. \nNENITESCU",
            "address": "B-DUL PETROCHIMISTILOR, NR. 23",
            "category": "B",
            "telephone": "0741014433",
            "email": "irinastroescu@yahoo.com"
          },
          {
            "name": "CRI-MAR AUTO ACC SRL",
            "address": "CALEA CRAIOVEI NR.113 CAMERELE13 SI ",
            "category": "B,C,CE",
            "telephone": "0722524367",
            "email": "magdau@yahoo.ro"
          }
        ]
      },
      {
        "city": "CAMPULUNG",
        "schools": [
          {
            "name": "VALAH HERMES SRL",
            "address": "STR.MATEI BASARAB NR.64",
            "category": "B",
            "telephone": "0728878180",
            "email": "oncescunicolae@yahoo.com"
          },
          {
            "name": "MUNTENIA TRANS KLM SRL",
            "address": "STR. CPT. ION BECLEANU, NR. 7, ETAJ",
            "category": "A,B,C,CE,A2",
            "telephone": "0745030500",
            "email": "munteniatransklm@yahoo.com"
          },
          {
            "name": "PILOT AUTOVIL DRIVE SRL",
            "address": "STR. ISTRATE RIZEANU, NR. 4, ET. 1",
            "category": "B,C,CE",
            "telephone": "0744496721",
            "email": "pilotautovildrive@gmail.com"
          },
          {
            "name": "MAU AUTO MASTER SPEED SRL",
            "address": "STR. MATEI BASARAB, NR. 131, ETAJ 2",
            "category": "B",
            "telephone": "0745077090",
            "email": "mau_auto_master_speed@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC AUTO \nCAMPULUNG",
            "address": "SOS. NATIONALA, NR. 108",
            "category": "B,C,CE",
            "telephone": "0248529150",
            "email": "gabriel.alexandru1@gmail.com"
          },
          {
            "name": "COLEGIUL TEHNIC CAMPULUNG",
            "address": "SOS. BRASOVULUI, NR.1",
            "category": "B",
            "telephone": "0744377434",
            "email": "gabriel_zabava@yahoo.com"
          },
          {
            "name": "PERFORMER AUTO CEAPRAZ SRL-D",
            "address": "STR. SF. ILIE NR. 29, ETAJ PARTER",
            "category": "B",
            "telephone": "0744377434",
            "email": "ceaprazadina@yahoo.com"
          },
          {
            "name": "KLM MUNTENIA AUTO SRL",
            "address": "STR. CPT. ION BECLEANU, NR. 7, ETAJ, ",
            "category": "B",
            "telephone": "0745485971",
            "email": "klm.auto2017@yahoo.com"
          },
          {
            "name": "S.C. PROFESSIONAL PILOT DRIVER \nS.R.L.",
            "address": "Str. Republicii NR 35  Et. 3  Mun. \nCampulung Jud. Arges",
            "category": "B",
            "telephone": "0753369520",
            "email": "ppdriver20017@gmail.com"
          },
          {
            "name": "VALAH AUTOLINE SRL",
            "address": "STR. NEGRU VODA, NR. 150",
            "category": "B",
            "telephone": "0746250998",
            "email": "trimbaciu_cosmin@yahoo.com"
          }
        ]
      },
      {
        "city": "CURTEA DE ARGES",
        "schools": [
          {
            "name": "FOX PRESTARI SERVICII SRL",
            "address": "STR. BASARABILOR, INTRAREA C, NR. 92",
            "category": "B",
            "telephone": "0744314451",
            "email": "foxprestariservicii@gmail.com"
          },
          {
            "name": "MARNIC AUTOPROFESIONAL SRL",
            "address": "STR. 1 MAI NR.23 mail ",
            "category": "B",
            "telephone": "0744995353",
            "email": "nicu_marnic@yahoo.com"
          },
          {
            "name": "CINCI INSTRUCTORI SRL",
            "address": "STR. MIHAI BRAVU, NR. 2, ET. 1",
            "category": "B",
            "telephone": "0721446444",
            "email": "cinciinstructori@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC AUTO CURTEA \nDE ARGES",
            "address": "STR. RM. VALCEA, NR. 41",
            "category": "B,C,CE",
            "telephone": "0248721720",
            "email": "gsacag@yahoo.com"
          },
          {
            "name": "F.A.I. DRIVE ELECTRONIQUE",
            "address": "STR.1 DECEMBRIE 1918 NR.1 ETAJ 1",
            "category": "B,C,D,CE",
            "telephone": "0722240525",
            "email": "office@faidrive.ro"
          },
          {
            "name": "ELECTROCASNICA SERVICE CD",
            "address": "STR. CUZA VODA, NR. 15A, ",
            "category": "B",
            "telephone": "0754792415",
            "email": "andreixp2004@yahoo.com"
          },
          {
            "name": "KRL NIKAR GYA SRL",
            "address": "STR. 1 DECEMBRIE 1918, SALA 6, ",
            "category": "B",
            "telephone": "0723568113",
            "email": "martinvasile@yahoo.com"
          },
          {
            "name": "LEO SCHOOL BEST SRL",
            "address": "MIHAI EMINESCU",
            "category": "B,C,CE",
            "telephone": "0744194152",
            "email": "leoschoolbest@gmail.com"
          },
          {
            "name": "P.F.A. VILCELEANU VASILE",
            "address": "STR. VICTORIEI, NR. 32",
            "category": "B",
            "telephone": "0742868662",
            "email": "vasivilceleanu@yahoo.com"
          },
          {
            "name": "MIRADIN AUTO SRL",
            "address": "STR. CUZA VODA, NR. 69, C1, PARTER",
            "category": "B",
            "telephone": "0743115933",
            "email": "nicolae_ciurea@ymail.com"
          }
        ]
      },
      {
        "city": "MOSOAIA",
        "schools": [
          {
            "name": "DRIVE CLUB PROFESIONAL",
            "address": "SAT SMEURA, NR. 604A",
            "category": "B",
            "telephone": "0744765588",
            "email": "dorelnitulescu@yahoo.com"
          },
          {
            "name": "DRIVE CLUB NSM PROFESIONAL SRL",
            "address": "COMUNA MOSOAIA, SAT SMEURA, STR. \nFUNDATURA CAMPULUI, NR. 12",
            "category": "B",
            "telephone": "0726765588",
            "email": "doinanitulescu@yahoo.com"
          }
        ]
      },
      {
        "city": "MIOVENI",
        "schools": [
          {
            "name": "AUTO SORINACHE SRL",
            "address": "C.C.S. DACIA, SALA FEMINA, ETAJ 2",
            "category": "B",
            "telephone": "0726635524",
            "email": "auto_sorinache@yahoo.com"
          },
          {
            "name": "RHEA MUSAE",
            "address": "STR. PARASTI, NR. 61",
            "category": "B,C,CE",
            "telephone": "0741207495",
            "email": "rhea.musae@ymail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC CONSTRUCTII \nDE MASINI MIOVENI",
            "address": "STR. UZINEI, NR. 1-3",
            "category": "B,C",
            "telephone": "0732921635",
            "email": "sandu_dragoi@yohoo.com"
          },
          {
            "name": "ALRO AUTO CAR SRL",
            "address": "TRONSON A, BL. P22, PARTER",
            "category": "B",
            "telephone": "0743020037",
            "email": "alroautocar@yahoo.ro"
          },
          {
            "name": "START AUTO XO SRL",
            "address": "STR. RADU CEL FRUMOS NR.1 CASA DE \nCULTURA DACIA SALA FEMINA ET.2",
            "category": "B",
            "telephone": "0740384611",
            "email": "auto_sorinache@yahoo.com"
          }
        ]
      },
      {
        "city": "COSTESTI",
        "schools": [
          {
            "name": "GRIG TRANS SRL",
            "address": "STR. VICTORIEI, NR. 56",
            "category": "B",
            "telephone": "0766551549",
            "email": "grigtrans93@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC COSTESTI",
            "address": "STR. PIETII, NR. 8",
            "category": "B",
            "telephone": "0248672538",
            "email": "stoian.tatiana@yahoo.com"
          }
        ]
      },
      {
        "city": "STEFANESTI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC DINU \nBRATIANU",
            "address": "STEFANESTI",
            "category": "B",
            "telephone": "0744862700",
            "email": "dobrescu_leo@yahoo.com"
          },
          {
            "name": "SC AAA TOP DRIVE CMG SRL",
            "address": "Sat Valea Mare Podgoria, Str. Drumul ",
            "category": "B",
            "telephone": "0744352624",
            "email": "cmarius05@yahoo.com"
          }
        ]
      },
      {
        "city": "MOZACENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC LIVIU \nREBREANU",
            "address": "MOZACENI",
            "category": "B",
            "telephone": "0723597648",
            "email": "mozaceni1@yahoo.com"
          }
        ]
      },
      {
        "city": "DOMNESTI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC PETRE \nIONESCU MUSCEL",
            "address": "STR. VLAICU VODA, NR. 2",
            "category": "B",
            "telephone": "0747640012",
            "email": "gsdomnesti@yahoo.com"
          }
        ]
      },
      {
        "city": "SUSENI",
        "schools": [
          {
            "name": "AUTO KRISS&FLY SRL-D",
            "address": "SAT BURDESTI",
            "category": "B",
            "telephone": "0766297401",
            "email": "autokriss.fly@gmail.com"
          }
        ]
      },
      {
        "city": "Pitesti",
        "schools": [
          {
            "name": "INA AUTO EXPERT SRL",
            "address": "B-dul Fratii Golesti, Nr. 84A, Cam. 1",
            "category": "B",
            "telephone": "0727473723",
            "email": "ina_auto@yahoo.com"
          }
        ]
      },
      {
        "city": "ALBOTA",
        "schools": [
          {
            "name": "P.F.A. CRACIUN EMILIAN",
            "address": "SAT ALBOTA, NR. 438C",
            "category": "B",
            "telephone": "0741337100",
            "email": "emil75craciun@yahoo.com"
          }
        ]
      },
      {
        "city": "BASCOV",
        "schools": [
          {
            "name": "CLUB AUTO DOI DAN SRL",
            "address": "STR. SCHIAU NR.105",
            "category": "B",
            "telephone": "0742091355",
            "email": "clubautodoidan@yahoo.com"
          }
        ]
      },
      {
        "city": "TOPOLOVENI",
        "schools": [
          {
            "name": "TUDOROIU N. STEFAN P.F.A.",
            "address": "CALEA BUCURESTI NR. 246",
            "category": "B",
            "telephone": "0724349137",
            "email": "stefantudoroiu222@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "AR",
    "schools": [
      {
        "city": "ARAD",
        "schools": [
          {
            "name": "S.C. CARMEN XL 99 S.R.L.",
            "address": "STR.EPISCOPIEI NR.28 AP.6A",
            "category": "B,C,CE",
            "telephone": "0257255445",
            "email": "l1l1ana2000@yahoo.fr"
          },
          {
            "name": "RODVAS",
            "address": "STR.EMIL GARLEANU NR.1 ",
            "category": "B",
            "telephone": " 0257233020",
            "email": "scrodvas@yahoo.com"
          },
          {
            "name": "PODOABA",
            "address": "CL.VICTORIEI NR. 34",
            "category": "B",
            "telephone": "0257/256050",
            "email": "doris.farcas@yahoo.com"
          },
          {
            "name": "S.C. OPTIM AUTO ADYSCHOOL ",
            "address": "STR.MIHAI EMINESCU NR.15/1 ",
            "category": "B",
            "telephone": "0257/275629",
            "email": "scoalanicola@gmail.com"
          },
          {
            "name": "ALBU DYNAMIC",
            "address": "STR.UNIRII NR.19 AP.9",
            "category": "A,B,C,CE",
            "telephone": "0742121478",
            "email": "alcobra04@yahoo.com"
          },
          {
            "name": "S.C. AUTOBEST SEBA S.R.L.",
            "address": "B-DUL GENERAL DRAGALINA NR.2 AP.5B",
            "category": "B",
            "telephone": "0740032371",
            "email": "autobestseba@yahoo.ro"
          },
          {
            "name": "S.C. FLOR AUTOEXPERT S.R.L.",
            "address": "PIATA EROILOR NR.7",
            "category": "B,C,CE",
            "telephone": "0728877130",
            "email": "florinaien@yahoo.com"
          },
          {
            "name": "S.C. INSTRUCTAJ AUTO BUNIOV \nS.R.L.",
            "address": "STR.ZARANDULUI NR.22",
            "category": "B",
            "telephone": "0747116022",
            "email": "calin75@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"AUREL \nVLAICU\" ",
            "address": "BV.DECEBAL NR.31A",
            "category": "B",
            "telephone": "0257281437",
            "email": "licvlaicu@yahoo.com"
          },
          {
            "name": "S.C. CUSTOM S.R.L.",
            "address": "STR CORNELIU COPOSU NR.3-4 AP.19",
            "category": "A,B,BE,A1,A2",
            "telephone": "0257254196",
            "email": "scoala.custom@yahoo.com"
          },
          {
            "name": "S.C. AUTO WEST CHIRCA S.R.L.",
            "address": "B-DUL REVOLUTIEI NR.23/20",
            "category": "A,B,C,Tr,BE,CE,A1,B1,AM",
            "telephone": "0357802499",
            "email": "mgratiana@yahoo.com"
          },
          {
            "name": "AS TRANS SPEDITION",
            "address": "STR. IULIU MANIU NR. 52-54 PARTER",
            "category": "A,B,C,CE,A2",
            "telephone": "0724255554",
            "email": "office@scoala-soferi-as.ro"
          },
          {
            "name": "MINI COMPANY IRIMIA",
            "address": "STR.STAN DRAGU NR.42",
            "category": "A,B,Tr",
            "telephone": "0744183885",
            "email": "aurelirimia0@yahoo.com"
          },
          {
            "name": "S.C. VERBITA S.R.L.",
            "address": "STR. CALEA AUREL VLAICU NR. 282/1 ",
            "category": "B,C,D,BE,CE",
            "telephone": "0257/275960",
            "email": "scoala@verbita.ro"
          },
          {
            "name": "S.C. ROSTEMA S.R.L.",
            "address": "STR.HORIA NR.7",
            "category": "A,B,C,CE,A1,AM",
            "telephone": "0257232402",
            "email": "office@scoalarostema.ro"
          },
          {
            "name": "S.C. AUTO TODERICI & MORAR ",
            "address": "STR.GLADIATOR NR.36",
            "category": "B",
            "telephone": "0257214223",
            "email": "soferiautotm@gmail.com"
          },
          {
            "name": "S.C. GHETEO TRANS S.R.L.",
            "address": "CL.6 VANATORI NR.2-4 ET.II",
            "category": "A,B,C,CE,A1,B1,A2",
            "telephone": "0731346705",
            "email": "cgdascau@gmail.com"
          },
          {
            "name": "S.C. COMPANIA DE TRANSPORT \nPUBLIC S.A.",
            "address": "CL.VICTORIEI NR.35B-37",
            "category": "B,Tv",
            "telephone": "0257338590",
            "email": "danastefanas@yahoo.com"
          },
          {
            "name": "S.C. NICOLA & NICOLA DRIVING \nS.R.L.",
            "address": "B-DUL. REVOLUTIEI NR.71 AP.2 ",
            "category": "B",
            "telephone": "0729489074",
            "email": "ovidiu.nicola@gmail.com"
          },
          {
            "name": "S.C. BELC INTERNATIONAL S.R.L.",
            "address": "STR. BUMBACULUI NR.1 ETAJ 1 AP.4",
            "category": "A,B,C,D,BE,CE,A1",
            "telephone": "0744938335",
            "email": "belcinternational@yahoo.com"
          },
          {
            "name": "S.C. FOCUS RALI VEST S.R.L.",
            "address": "PIATA HEIM DOMOKOS NR.19 AP.1A",
            "category": "B",
            "telephone": "0757313298",
            "email": "focus.rali.vest@gmail.com"
          },
          {
            "name": "S.C. AUTO MADUTA S.R.L.",
            "address": "STR. ELENA GHIBA BIRTA NR. 12A AP. 1 ",
            "category": "B",
            "telephone": "0740306148",
            "email": "automaduta@gmail.com"
          }
        ]
      },
      {
        "city": "NADAB",
        "schools": [
          {
            "name": "S.C. LELEK AUTO G.T. S.R.L.",
            "address": "STR.VASILE GOLDIS NR.18",
            "category": "B",
            "telephone": "0257/352886",
            "email": "lelekautogt83@yahoo.com"
          }
        ]
      },
      {
        "city": "PAIUSENI",
        "schools": [
          {
            "name": "S.C. AUTO DRAGAN RO S.R.L.",
            "address": " NR 166",
            "category": "B",
            "telephone": "0742499243",
            "email": "AUTODRGAN@YAHOO.COM"
          }
        ]
      },
      {
        "city": "SANTANA",
        "schools": [
          {
            "name": "S.C. DAN MANDRIC S.R.L.",
            "address": "STR.GHIOCEILOR NR.6",
            "category": "B",
            "telephone": "0724530221",
            "email": "danmandric@yahoo.com"
          }
        ]
      },
      {
        "city": "AGRISU MIC",
        "schools": [
          {
            "name": "S.C. AUTO SEBA MARIS S.R.L.",
            "address": "NR.15",
            "category": "B",
            "telephone": "0755410003",
            "email": "autosebamaris@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "B",
    "schools": [
      {
        "city": "BUCURESTI",
        "schools": [
          {
            "name": "AUTOGENERAL SA",
            "address": "STR.DAMBOVNICULUI SECTOR4 NR.34",
            "category": "A,B,A1,AM,A2",
            "telephone": "0213304242",
            "email": "office@autogeneral.ro"
          },
          {
            "name": "IMPERIAL SERVICII SRL",
            "address": "STR.ALEEA TOMESTI NR.2 SECTOR 4",
            "category": "B",
            "telephone": "0216857669",
            "email": "imperial_servicii@yahoo.com"
          },
          {
            "name": "S.C. LOTUS TEAM S.R.L.",
            "address": "STR.GRANITULUI NR.24A SECT.6",
            "category": "B",
            "telephone": "0213231197",
            "email": "teodora.segarceanu@yahoo.com"
          },
          {
            "name": "ADMIN. PATRIM. PROTOC. DE STAT \nR.A.BUC. S.T.B.N.",
            "address": "BV.AVIATORILOR SECTOR1 NR.14",
            "category": "B",
            "telephone": "0213101133",
            "email": "ofice@serviceaviatorilor.ro"
          },
          {
            "name": "S.C. AUTO FILIP TRANS S.R.L.",
            "address": "STR.GOVODARVA SECT.6 NR.27",
            "category": "B",
            "telephone": "0728039379",
            "email": "office@scoalaautofilip.ro"
          },
          {
            "name": "S.C DRIVE 4U TRAINING S.R.L",
            "address": "STR.NICOLAE TECLU NR.5 CAMERA 10 ",
            "category": "B",
            "telephone": "0722707456",
            "email": "office@scoalade-soferi.ro"
          },
          {
            "name": "S.C. ABITRANS DRIVE S.R.L.",
            "address": "MS.B-DUL.IULIU MANIU NR.171A ET.1 ",
            "category": "A,B,A1,A2",
            "telephone": "0729068748",
            "email": "abitrans@ymail.com"
          },
          {
            "name": "S.C. AUTOSPORT DRIVING CENTER \nS.R.L.",
            "address": "SOS.PANTELIMON NR.300 ET.2 CAM.3 \nSECT.2",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0751511775",
            "email": "scoala_autosport@yahoo.com"
          },
          {
            "name": "S.C. ALLSPEED IDEALTRANS S.R.L.",
            "address": "BD.TIMISOARA NR.6 (INCINTA ",
            "category": "B",
            "telephone": "0733584111",
            "email": "allspeedi@yahoo.com"
          },
          {
            "name": "S.C. NEDELCU EDUARD SERVICE \nS.R.L.",
            "address": "STR.ILIOARA NR.3 SECTOR 3",
            "category": "B,C,CE",
            "telephone": "0720317528",
            "email": "a.timisan@yahoo.es"
          },
          {
            "name": "S.C. ANDY AUTOMIXT S.R.L.",
            "address": "STR.SOLD.NICULAE SEBE NR.1A SECT.3 ",
            "category": "B,C,D,CE",
            "telephone": "0763888775",
            "email": "office@automixt.ro"
          },
          {
            "name": "S.C. NELIT EVOLUTION S.R.L.",
            "address": "STR.DRUMUL TABEREI NR.132 SECTOR 6",
            "category": "B",
            "telephone": "0766726252",
            "email": "nely_ilie@yahoo.com"
          },
          {
            "name": "S.C. ADI MOTO TEAM S.R.L.",
            "address": "STR.ILIOARA NR.15 SECTOR 3",
            "category": "A,B,C,BE,CE,A2",
            "telephone": "0723200175",
            "email": "adiautomoto@yahoo.com"
          },
          {
            "name": "S.C. AUTO EMI PROFESIONAL S.R.L.",
            "address": "P.L. STR. ELECTONICII NR.19 SECTOR 2",
            "category": "B",
            "telephone": "0722284523",
            "email": "scoala_autoemi@yahoo.com"
          },
          {
            "name": "S.C. MASTER CAR 4 DRIVER S.R.L.",
            "address": "STR.VESELIEI NR.5A CLADIREA C21 ",
            "category": "B,C,CE",
            "telephone": "0725119279",
            "email": "mastercar4driver@gmail.com"
          },
          {
            "name": "CONTACT AUTO TRANSPORT CO ",
            "address": "SOS.FUNDENI SECTOR 2 NR.235",
            "category": "B",
            "telephone": "0212400045",
            "email": "adi_adisor19@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DIMITRIE \nLEONIDA",
            "address": "BV.BASARABIA SECTOR2 NR.47",
            "category": "B",
            "telephone": "0213243295",
            "email": "dimitrie@leonida.ro"
          },
          {
            "name": "S.C. IANCU STAR S.R.L.",
            "address": "AL.MATELOTILOR SECTOR1 NR.1",
            "category": "A,B,A1,AM,A2",
            "telephone": "0722737160",
            "email": "eugengerry@yahoo.com"
          },
          {
            "name": "GENERAL TRADING 73 SRL",
            "address": "STR.PATINOARULUI SECTOR3 NR.46",
            "category": "B",
            "telephone": "",
            "email": "lauragt73@yahoo.com"
          },
          {
            "name": "S.I.G. I COM IMPEX SRL",
            "address": "STR.MIHAIL SEBASTIAN SECTOR5 NR.47",
            "category": "B",
            "telephone": "0214231075",
            "email": "sig1service@yahoo.com"
          },
          {
            "name": "GABRIEL TRANSCOMEXIM SRL",
            "address": "CL.MOSILOR SECTOR2 NR.235A CORP C ",
            "category": "B",
            "telephone": "0216192304",
            "email": "info@autogabriel.ro"
          },
          {
            "name": "S.C. AUTO DAVID S.R.L.",
            "address": "SOS.OLTENITEI SECTOR4 NR.105A CORP ",
            "category": "B",
            "telephone": "0722605995",
            "email": "AUTODAVIDBUCURESTI@YAHOO.COM"
          },
          {
            "name": "PERFECT AUTO VYO SRL",
            "address": "CL.GRIVITEI SECTOR1 NR.206 PARTER",
            "category": "B",
            "telephone": "0722419199",
            "email": "contact@scoalasoferi.ro"
          },
          {
            "name": "PERFECT AUTO VYO SRL",
            "address": "BV.TIMISOARA SECTOR6 NR.21",
            "category": "B",
            "telephone": "0722824431",
            "email": "contact@scoalasoferi.ro"
          },
          {
            "name": "TRANSCOM SA",
            "address": "STR,BUZESTI NR.38 SECTOR 1",
            "category": "B",
            "telephone": "0213177148",
            "email": "scoala.transcom.bucuresti@gmail.com"
          },
          {
            "name": "SOCIETATEA DE TRANSPORT \nBUCURESTI STB S.A.",
            "address": "STR.THEODOR PALLADY SECTOR3 NR.64",
            "category": "B,D,Tv,Tb",
            "telephone": "0213451750",
            "email": "formare.profesionala@ratb.ro"
          },
          {
            "name": "AUTOSTAR MAST SRL",
            "address": "B-DUL.GHENCEA SECT.6 NR.126-132 ",
            "category": "A,B",
            "telephone": "0213225000",
            "email": "autostar_mast@yahoo.com"
          },
          {
            "name": "DORIO PLUS SRL",
            "address": "SOS.MORARILOR NR.1 SECT.2",
            "category": "B",
            "telephone": "0724192536",
            "email": "autodorio@yahoo.com"
          },
          {
            "name": "OLIMPIC EXPERT SRL",
            "address": "B-DUL.BASARABIA SECT.3 NR.250",
            "category": "A,B,A1,AM,A2",
            "telephone": "0740103403",
            "email": "office@olimpicexpert.ro"
          },
          {
            "name": "GIROMEX IMPEX SRL",
            "address": "STR.ALUNISULUI SECTOR 4 NR.181 ET.1",
            "category": "B",
            "telephone": "0722727030",
            "email": "giromex_impex@yahoo.com"
          },
          {
            "name": "LA VOLAN TRANS SRL",
            "address": "STR.G-RAL ERNEST BROSTEANU NR.29 ",
            "category": "B",
            "telephone": "0314126541",
            "email": "la_volan@yahoo.com"
          },
          {
            "name": "S.C. RADAN TOUR S.R.L.",
            "address": "SOS. MORARILOR, NR.2, RELEVEU C35, ",
            "category": "B",
            "telephone": "0744649230",
            "email": "radantour@gmail.com"
          },
          {
            "name": "S.C. ABC PILOT AUTO S.R.L",
            "address": "P.L. CALEA 13 SEPTEMBRIE NR.168-184 ",
            "category": "A,B",
            "telephone": "0723361214",
            "email": "abcpilotauto@gmail.com"
          },
          {
            "name": "EXPERTINVEST ZONE SRL",
            "address": "STR.ANTIAERIANA NR.35 SECT.5",
            "category": "B",
            "telephone": "0729106178",
            "email": "robert_mihai75@yahoo.com"
          },
          {
            "name": "S.C. MOTORRAD S.R.L.",
            "address": "SOS. VIILOR NR.51 SECTOR 5",
            "category": "A,B,A1,AM,A2",
            "telephone": "0723981197",
            "email": "contact@scoalamotolouis.ro"
          },
          {
            "name": "S.C. IOANA AUTO TRANS S.R.L.",
            "address": "STR.VALEA SADULUI SECTOR3 NR.1E",
            "category": "B,BE,B1",
            "telephone": "0760614845",
            "email": "scorteanumihaela@gmail.com"
          },
          {
            "name": "AUTO INDEPENDENT SRL",
            "address": "STR.VERGULUI SECTOR 2 NR.18",
            "category": "B",
            "telephone": "0720411548",
            "email": "auto.independent@yahoo.com"
          },
          {
            "name": "DANBRO TRANS IMPEX SRL",
            "address": "STR.EREMIA POPESCU SECTOR4 NR.23 ",
            "category": "B",
            "telephone": "0722855746",
            "email": "danbro92@yahoo.com"
          },
          {
            "name": "S.C. AUTO DIANA INVEST S.R.L",
            "address": "STR.SCORUSULUI NR.7 CORP C2 SECT.4",
            "category": "B",
            "telephone": "0744294999",
            "email": "cristiandanandrei@yahoo.com"
          },
          {
            "name": "GNN PLATINUM CAR SRL",
            "address": "STR.NICOLAE TECLU NR.5 CAMERELE 1,2 ",
            "category": "B,C,D,CE",
            "telephone": "0723305807",
            "email": "nelu.prico@yahoo.com"
          },
          {
            "name": "LEADER UNIC IMPEX SRL",
            "address": "BV.BASARABIA SECTOR3 NR.256C ET.1 ",
            "category": "A,B,C,CE",
            "telephone": "0722303130",
            "email": "leaderunic@gmail.com"
          },
          {
            "name": "S.C. AUTO GETY IONUT S.R.L",
            "address": "STR.NICOLAE PASCU SECT.3 NR.61-63 ",
            "category": "A,B,C,D,BE,CE,B1,AM,A2",
            "telephone": "0744855894",
            "email": "crisandum@yahoo.com"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "SOS.OLTENITEI SECTOR4 NR.131A",
            "category": "B",
            "telephone": "0753098574",
            "email": "silvia@vreaupermis.ro"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "BD.PETRE ISPIRESCU NR.115 ET.2 ",
            "category": "A,B,BE,A1,B1,AM",
            "telephone": "0753098500",
            "email": "silvia@vreaupermis.ro"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "P.L. STR.CAMPIA LIBERTATII NR.37A ",
            "category": "A,B,A2",
            "telephone": "0753098500",
            "email": "silvia@vreaupermis.ro"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "B-DUL.AEROGARII SECT.1 NR.38",
            "category": "B",
            "telephone": "0753098500",
            "email": "silvia@vreaupermis.ro"
          },
          {
            "name": "S.C. AUTO DANY DRIVE S.R.L.",
            "address": "STR.POPA STOICA FARCAS NR.72 ",
            "category": "B",
            "telephone": "0785091505",
            "email": "autodanydrive@yahoo.com"
          },
          {
            "name": "DRIVE IMPEX SRL",
            "address": "ALEEA ZANELOR NR.1 SECTOR 4",
            "category": "B",
            "telephone": "0726175172",
            "email": "madairis@yahoo.com"
          },
          {
            "name": "BIDI TRANS SRL",
            "address": "P.L SOS.OLTENITEI SECTOR4 NR.38A",
            "category": "A,B,A1,A2",
            "telephone": "0213323553",
            "email": "scoalabiditrans@yahoo.com"
          },
          {
            "name": "BIDI TRANS SRL",
            "address": "SOS.ALEXANDRIEI SECTOR5 NR.176B",
            "category": "B,C,D,BE,CE",
            "telephone": "0214114612",
            "email": "scoalabiditrans@yahoo.com"
          },
          {
            "name": "BIDI TRANS SRL",
            "address": "BV.IULIU MANIU SECTOR6 NR.8A ",
            "category": "B",
            "telephone": "0214114612",
            "email": "scoalabiditrans@yahoo.com"
          },
          {
            "name": "S&G DIVERTIS IMPORT EXPORT SRL",
            "address": "STR.ROSTOGOLEA GHEORGHE SECTOR6 ",
            "category": "B",
            "telephone": "0217717157",
            "email": "sg.divertis@yahoo.com"
          },
          {
            "name": "TRIUMF SERVICE AUTO SRL",
            "address": "INTR.TRUBADURULUI SECTOR3 NR.3",
            "category": "B",
            "telephone": "0213227088",
            "email": "ifcontab@gmail.com"
          },
          {
            "name": "DICU IMPEX SRL",
            "address": "STR.NICODIM NR.26A SECT.6",
            "category": "B,C,CE",
            "telephone": "0722751483",
            "email": "scoaladicu@yahoo.com"
          },
          {
            "name": "SIMPLY DRIVE SRL",
            "address": "STR.REDEA NR.24 SECTOR 1",
            "category": "B",
            "telephone": "0722234519",
            "email": "silviu.teodorescu@gmail.com"
          },
          {
            "name": "S.C. BBC LUDUS S.R.L.",
            "address": "BV.ENERGETICIENILOR NR.9-11 ET.2 \nCAM.3 ET.1 CAM.15 SECTOR 3",
            "category": "B",
            "telephone": "0722205711",
            "email": "olegsilvia@yahoo.com"
          },
          {
            "name": "DINU AUTO TOTAL S.R.L",
            "address": "BD.ION MIHALACHE SECT.1 NR.265BV.ENERGETICIENILOR NR.9E BL.M1 ",
            "category": "B,C,CE",
            "telephone": "0769555777",
            "email": "dinu2000lux@yahoo.com"
          },
          {
            "name": "ATOMIC DRIVE SRL",
            "address": "PARTER CAM.05 ET.2 CAM.1219 SECTOR \n3",
            "category": "B",
            "telephone": "0721129656",
            "email": "atomic.drive@yahoo.com"
          },
          {
            "name": "S.C. ELEGANT EUROTOTH S.R.L.",
            "address": "P.L. BULEVARDUL BASARABIA NR.256C ",
            "category": "B",
            "telephone": "0723187374",
            "email": "elegant_eurototh@yahoo.com"
          },
          {
            "name": "MANU AUTO SERV SRL",
            "address": "STR.FIZICIENILOR NR.15 SECTOR 3",
            "category": "A,B,C,D,CE",
            "telephone": "0314146232",
            "email": "vyvyana81@yahoo.com"
          },
          {
            "name": "S.C. AUTO MOTO BUJIE S.R.L.",
            "address": "STR.DRUMUL TABEREI NR.4 BL.F SECTOR ",
            "category": "A,B",
            "telephone": "0727035972",
            "email": "automoto.bujie@yahoo.com"
          },
          {
            "name": "S.C. AUTO ANDRONACHE RUTIER \nS.R.L.",
            "address": "SOS.ANDRONACHE NR.6 SECT.2",
            "category": "B",
            "telephone": "0728778428",
            "email": "taficauto@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNOLOGIC VIACESLAV \nHARNAJ",
            "address": "BV.FICUSULUI SECTOR1 NR.20-26",
            "category": "B",
            "telephone": "0722644199",
            "email": "grup_scolar_agricol_harnaj@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ELIE RADU",
            "address": "BD. ENERGETICIENILOR NR.5 SECTOR 3",
            "category": "B",
            "telephone": "0213466040",
            "email": "grup_scolar_elie_radu@yahoo.com"
          },
          {
            "name": "S.C. GTI AUTO CONSULT 2013 S.R.L.",
            "address": "SOS.OLTENITEI NR.105 PARTER SPATIU \n78 MP SECT.4 BUCURESTI",
            "category": "B",
            "telephone": "0735038597",
            "email": "office@scoalagti.ro"
          },
          {
            "name": "COLEGIUL TEHNIC MIRCEA CEL \nBATRAN",
            "address": "STR.FEROVIARILOR NR.37 SECTOR 1",
            "category": "B",
            "telephone": "0212242376",
            "email": "nicu59enescu@yahoo.com"
          },
          {
            "name": "S.C. ACR AUTO TURISM S.R.L.",
            "address": "SOS.COLENTINA NR.1 BL.34 BIS ET.1 ",
            "category": "B",
            "telephone": "0745028292",
            "email": "scoala@acr.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC THEODOR \nPALLADY",
            "address": "STR.THEODOR PALLADY NR.250 SECTOR \n3",
            "category": "B",
            "telephone": "0722645170",
            "email": "georgiana_beatrice@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC IULIU MANIU",
            "address": "BDUL.IULIU MANIU NR.381-391 SECTOR ",
            "category": "B",
            "telephone": "0722613648",
            "email": "daniela.mihalache@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC MECANIC ",
            "address": "CALEA GRIVITEI NR.363 SECTOR 1",
            "category": "B",
            "telephone": "0212240770",
            "email": "scoaladesofericolegiugrivita@yahoo.com"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "P.L. STR.BABA NOVAC NR.9G ET.2 ",
            "category": "A,B,C,D,DE,CE,C1,C1E",
            "telephone": "0753098500",
            "email": "silvia@vreaupermis.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC DACIA",
            "address": "STR.CAP.GRIGORE MARIN SECTOR4 ",
            "category": "B",
            "telephone": "",
            "email": ""
          },
          {
            "name": "S.C. VLAD EXPERT AUTOSERVICE \nS.R.L.",
            "address": "SPLAIUL INDEPENDENTEI NR.313 SALA \nJA 001 JD015 SECT.6",
            "category": "B,C,CE",
            "telephone": "0722211164",
            "email": "voinea.neculai@gmail.com"
          },
          {
            "name": "S.C. AAA VREAU PERMIS S.R.L.",
            "address": "P.L. SOSEAUA MIHAI BRAVU NR.223 ET.1 ",
            "category": "B,C,CE",
            "telephone": "0761912892",
            "email": "aaa.vreaupermis@gmail.com"
          },
          {
            "name": "S.C. ADMIS TOTAL AUTO S.R.L.",
            "address": "STR.SOS.VIILOR, NR.97 A, SECTOR 5",
            "category": "B",
            "telephone": "0769088456",
            "email": "comercialpena@yahoo.com"
          },
          {
            "name": "S.C. APOLODOR AUTO TRANSPORT \nS.R.L.",
            "address": "STR.TEIUL DOAMNEI, NR.96 ET.1, \nSECTOR 2",
            "category": "B",
            "telephone": "0767885152",
            "email": "scoaladesoferi.apolodor@yahoo.ro"
          },
          {
            "name": "S.C. AUTOTRANSPORT DINU S.R.L",
            "address": "SOSEAUA GIURGIULUI NR.206A SECTOR ",
            "category": "B,BE",
            "telephone": "0722407723",
            "email": "dinu2000old@yahoo.com"
          },
          {
            "name": "S.C. AUTOLUKY SPEED S.R.L.",
            "address": "STR.PETRE ISPIRESCU NR.99 SECT.5 ",
            "category": "B",
            "telephone": "0721271255",
            "email": "mitaru.nelus@yahoo.com"
          },
          {
            "name": "S.C. VLC INDEPENDENT S.R.L.",
            "address": "SOS.VERGULUI NR.18, C2, PARTER, ",
            "category": "B",
            "telephone": "0745068848",
            "email": "vlc.independent@yahoo.ro"
          },
          {
            "name": "S.C. ACTIV AUTO CONSULT S.R.L.",
            "address": "B-DUL IULIU MANIU NR.7 CORP 1 ET.1 ",
            "category": "A,B",
            "telephone": "0721327762",
            "email": "activauto@yahoo.com"
          },
          {
            "name": "S.C. VIP AUTO EXPERT S.R.L.",
            "address": "SPLAIUL UNIRII NR.160 CORP C1 ET.1 ",
            "category": "B,C,CE",
            "telephone": "0760861462",
            "email": "scoalasoferivip@gmail.com"
          },
          {
            "name": "S.C. A&G AUTO DRIVE S.R.L.",
            "address": "STR. LUICA NR.1 SECTOR 4 BUCURESTI",
            "category": "B,C,CE",
            "telephone": "0766425311",
            "email": "agautodrive@yahoo.com"
          },
          {
            "name": "S.C. OTTO AUTO DRIVE S.R.L.",
            "address": "B-DUL. BASARABIA NR.96B PARTER \nSPATIUL COMERCIAL NR.2 SECT.2",
            "category": "B",
            "telephone": "0736308997",
            "email": "office@ottoauto.ro"
          },
          {
            "name": "S.C. AUTO SPEED DRIVE S.R.L.",
            "address": "BLD. UVERTURII NR.98 SECT.6",
            "category": "B,C,CE",
            "telephone": "0785125125",
            "email": "autospeeddrive@yahoo.com"
          },
          {
            "name": "S.C. IMPERIAL AUTO DRIVE S.R.L.",
            "address": "STR.GIURGIULUI NR.45 ETAJ SECT.4 ",
            "category": "B",
            "telephone": "0729103575",
            "email": "imperial_servicii@yahoo.com"
          },
          {
            "name": "S.C. FORMAL PROF S.R.L.",
            "address": "STR.GEORGE GEORGESCU NR.8 PARTER ",
            "category": "B",
            "telephone": "0722522777",
            "email": "mircea_manolache@yahoo.com"
          },
          {
            "name": "S.C. DRIVE IMPEX S.R.L.",
            "address": "SOS.OLTENITEI NR.35-37, CORP CLADIRE ",
            "category": "B",
            "telephone": "0722312506",
            "email": "scoaladesoferibucuresti@yahoo.com"
          },
          {
            "name": "S.C. BMD AUTOGO S.R.L.",
            "address": "STR. ALMAS NR.2-8 SECTOR 1 ",
            "category": "B",
            "telephone": "0723128964",
            "email": "dan_bmd@yahoo.com"
          },
          {
            "name": "S.C. MOTOR DRIVE IMPEX S.R.L.",
            "address": "STR. LACUL MARE NR.19 CAMERA 3 ",
            "category": "B",
            "telephone": "0767974492",
            "email": "motordrive40@yahoo.com"
          },
          {
            "name": "S.C. S.V.S. RODELL S.R.L.",
            "address": "PRELUNGIREA GHENCEA NR.83 SECTOR ",
            "category": "B,C,CE",
            "telephone": "0724602021",
            "email": "scoala@rodell.ro"
          },
          {
            "name": "S.C. S.V.S. RODELL DRIVER S.R.L.",
            "address": "PRELUNGIREA GHENCEA NR.83 SECT.6 ",
            "category": "B",
            "telephone": "0724602021",
            "email": "scoala@rodell.ro"
          },
          {
            "name": "S.C. AUTO DANY EMY DRIVE S.R.L.",
            "address": "STR.NICOLAE PASCU NR.9 ETAJ 1 SECT.3",
            "category": "B",
            "telephone": "0762177863",
            "email": "autodanyemydrive@yahoo.com"
          },
          {
            "name": "S.C. COMPANIA AUTO-GENERAL \nGMI S.R.L.",
            "address": "STR. DÂMBOVNICULUI NR.34 SECT.4",
            "category": "B,BE",
            "telephone": "0213174141",
            "email": "OFFICE@AUTOGENERAL.RO"
          },
          {
            "name": "S.C. CD LAUDRUP INVEST S.R.L.",
            "address": "CALEA VACARESTI NR.209 BL.85 PARTER ",
            "category": "B",
            "telephone": "0721959950",
            "email": "dinudanielalexandru@yahoo.com"
          },
          {
            "name": "S.C. MOTO SSD S.R.L.",
            "address": "STR.SEBASTIAN NR.130 PARTER ",
            "category": "A,B,C,CE,A2",
            "telephone": "0768673583",
            "email": "dinu2000old@yahoo.com"
          },
          {
            "name": "S.C. DAN AUTOSPORT S.R.L",
            "address": "SOS.PANTELIMON NR.300 CAMERE 11 SI ",
            "category": "B,C,D,BE,CE",
            "telephone": "0729511775",
            "email": "dan_autosport@yahoo.com"
          },
          {
            "name": "S.C. MIHNEA TOP S.R.L.",
            "address": "STR.GHEORGHE TITEICA NR.85-87 ",
            "category": "B,BE,B1",
            "telephone": "0723530812",
            "email": "costibrd@yahoo.com"
          },
          {
            "name": "S.C. AUTO-BEST DRIVE S.R.L.",
            "address": "P.L. STR.MEDIC CONSTANTIN ZLATESCU \nNR.35 SECT.2 BUCURESTI",
            "category": "B",
            "telephone": "0760712663",
            "email": "scoalaautobest@gmail.com"
          },
          {
            "name": "S.C. DRIVE IMPEX SERV S.R.L.",
            "address": "STR.G-RAL PETRE POPOVAT NR.72 ",
            "category": "B",
            "telephone": "0726175172",
            "email": "madairis@yahoo.com"
          },
          {
            "name": "S.C. ACTIV AUTO CONFORT S.R.L.",
            "address": "B-DUL IULIU MANIU NR.138-142 SECT.6",
            "category": "B",
            "telephone": "0736932372",
            "email": "activautoconfort@yahoo.com"
          },
          {
            "name": "S.C. MOTOR SPORT CENTER S.R.L.",
            "address": "SOS.CHITILEI NR.88 SPATIUL IN \nSUPRAFATA DE 100 MP ET.4 SECT.1",
            "category": "B,C,D,CE",
            "telephone": "0746482952",
            "email": "motorsportcenter@yahoo.com"
          },
          {
            "name": "S.C. AUTO LYN DRIVE S.R.L.",
            "address": "B-DUL.CONSTANTIN BRANCOVEANU ",
            "category": "B",
            "telephone": "0724170003",
            "email": "dincaallex@gmail.com"
          },
          {
            "name": "S.C. INITIAL AUTO S.R.L.",
            "address": "MS SOS.OLTENITEI NR.103, 42mp CAM. ",
            "category": "B",
            "telephone": "0761616272",
            "email": "initialauto2@gmail.com"
          },
          {
            "name": "COLEGIUL TEHNIC CAROL I",
            "address": "STR. PORUMBACU NR.52 SECTOR 6 ",
            "category": "B",
            "telephone": "0722673097",
            "email": "colegiul.tehnic.carol@gmail.com"
          },
          {
            "name": "S.C. DETASAT AUTO S.R.L.",
            "address": "SOS.BERCENI NR.104 INCAPEREA 3D-",
            "category": "B",
            "telephone": "0728049238",
            "email": "conducidetasat@gmail.com"
          },
          {
            "name": "P.F.A. BIRGOVEANU DUMITRU",
            "address": "SOSEAUA VIILOR NR.51 CAM.7 SECTOR 5",
            "category": "B",
            "telephone": "0734412366",
            "email": "dumitrubirgoveanu@yahoo.com"
          },
          {
            "name": "S.C. LUCKY AUTO DRIVE S.R.L.",
            "address": "SOS.BERCENI NR.104 CAMERELE 2A SI ",
            "category": "B,C",
            "telephone": "0723670423",
            "email": "gheorgheplaiasu55@gmail.com"
          },
          {
            "name": "S.C. AUTO ALAZET S.R.L.",
            "address": "STR.VIRTUTII NR.48 ET.5 SECTOR 6",
            "category": "B",
            "telephone": "0722396561",
            "email": "alindimancescu@yahoo.com"
          },
          {
            "name": "S.C. VIO AUTOCLASS S.R.L.",
            "address": "DRUMUL SARII NR.115 SECTOR 6",
            "category": "B",
            "telephone": "0737275883",
            "email": "bunea_mirel@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DINICU GOLESCU",
            "address": "CALEA GIULESTI NR.10 SECTOR 1",
            "category": "B",
            "telephone": "0216370484",
            "email": "georgiana_beatrice@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ION I.C. \nBRATIANU",
            "address": "STR.POPA LAZAR NR.8A SECTOR 2",
            "category": "B",
            "telephone": "0212528130",
            "email": "marin.barosan@gmail.com"
          },
          {
            "name": "S.C. GEORGY GRUP VEHICUL S.R.L.",
            "address": "SOS. PIPERA NR.46F CAM.113 PAV.AD-",
            "category": "B,C,BE,CE,A1",
            "telephone": "0761531308",
            "email": "scoala.catalin@gmail.com"
          },
          {
            "name": "S.C. TOP INSTRUCTOR AMI S.R.L.",
            "address": "SOS.ELECTRONICII NR.48 CAM.11 SI 14 STR.LITOVOI VOIEVOD NR.59 ",
            "category": "A,B,A2",
            "telephone": "0721749908",
            "email": "icojanu@gmail.com"
          },
          {
            "name": "S.C. ENERGIA MOTO S.R.L.",
            "address": "CONSTRUCTIE C1 S+P , CONSTRUCTIE C2 \nPARTER  , CONSTRUCTIE C3 GARAJ ",
            "category": "A,B,A1,A2",
            "telephone": "0723644114",
            "email": "scoalaheymoto@gmail.com"
          },
          {
            "name": "S.C. AUTO BEST DRIVE CONCEPT \nS.R.L.",
            "address": "CALEA RAHOVEI NR.228 SECTOR 5B-DUL. RAMNICU SARAT NR.41 ",
            "category": "B",
            "telephone": "0760712663",
            "email": "scoalaautobest@gmail.com"
          },
          {
            "name": "S.C. IMOB P.M.E.G. S.R.L.",
            "address": "COMPLEX COM.BOBOCICA NOUA ET.2 \nSECT.3",
            "category": "A,B,C,D,CE,A2",
            "telephone": "0761531308",
            "email": "scoala.imob@gmail.com"
          },
          {
            "name": "S.C. ONIX AUTO DRIVE S.R.L.",
            "address": "Str. Tufisului, Nr.14, Sectorul 5",
            "category": "B",
            "telephone": "0767358220",
            "email": "neluenache40@yahoo.com"
          },
          {
            "name": "S.C. PRO HARA AUTO S.R.L.",
            "address": "B-DUL. BASARABIA NR.250 SECTOR 3",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0723644672",
            "email": "harabagiu_george@yahoo.com"
          },
          {
            "name": "S.C. Q TEHNIC & PROFESIONAL \nTRAINING S.R.L.",
            "address": "BLD. BASARABIA NR.14 SECT.2",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0736900001",
            "email": "qtehnic01@gmail.com"
          },
          {
            "name": "S.C. AG MIHBRAD S.R.L.",
            "address": "STR.GHEORGHE TITEICA NR.132 SECTOR ",
            "category": "B",
            "telephone": "0735852872",
            "email": "helenabrd@yahoo.com"
          },
          {
            "name": "S.C. V.I.S. DRIVING AUTO S.R.L.",
            "address": "STR.CIMPOIERILOR NR.7-9 PARTER AP.2 ",
            "category": "B",
            "telephone": "0724088379",
            "email": "gabi_vatafu@yahoo.com"
          },
          {
            "name": "S.C. AUTO REC PREST S.R.L.",
            "address": "SOSEAUA CHITILEI NR.223 CAMERA 1,2 ",
            "category": "B",
            "telephone": "0721271651",
            "email": "scoalarec@gmail.com"
          },
          {
            "name": "S.C. SMART DRIVER SSAD S.R.L.",
            "address": "CALEA VITAN NR.109B-109D SC.1 ET.1 ",
            "category": "B",
            "telephone": "0726850075",
            "email": "smartdriverssad@gmail.com"
          },
          {
            "name": "S.C. SOSO AUTO ERP S.R.L.",
            "address": "STR.CHIRISTIGIILOR NR.4 PARTER \nMAGAZIN 3 BLOC P36C SECTOR 2",
            "category": "B",
            "telephone": "0767838932",
            "email": "paul@sosoauto.ro"
          },
          {
            "name": "P.F.A. ALEXANDRESCU GH. ",
            "address": "STR.BRAZILIEI NR.18 SECT.1 BUCURESTI",
            "category": "B",
            "telephone": "0745555695",
            "email": "cristianalexandrescu@gmail.com"
          },
          {
            "name": "P.F.A. CROITORU ILIE",
            "address": "STR.BRAZILIEI NR.18 SECT.1 BUCURESTI",
            "category": "B",
            "telephone": "077590702",
            "email": "iliecroitoru54@gmail.com"
          },
          {
            "name": "P.F.A. ADAM N. MARICEL",
            "address": "STR.BRAZILIEI NR.18 PARTER SECT.1 ",
            "category": "B",
            "telephone": "0726451890",
            "email": "maricel.adam@yahoo.com"
          },
          {
            "name": "S.C. DRIVE LILI-BRO S.R.L.",
            "address": "STR.ION IRICEANU NR.35 SECTOR 4 ",
            "category": "B,BE",
            "telephone": "0722855746",
            "email": "lili_bro@yahoo.com"
          },
          {
            "name": "S.C. CATA GRUP VEHICUL S.R.L.",
            "address": "STR. LCT.CAMPEANU NR.9 MODUL ",
            "category": "A,B",
            "telephone": "0761531308",
            "email": "scoala.catagrup@gmail.com"
          },
          {
            "name": "S.C. HAPPY DRIVE LUCKY S.R.L.",
            "address": "STR.HOREI NR.20 PARTER SECTOR 2",
            "category": "B",
            "telephone": "0763480403",
            "email": "georgecondurache21@gmail.com"
          },
          {
            "name": "S.C. FORMULA 1 DRIVING CENTER \nS.R.L",
            "address": "SOS.PANTELIMON NR.300 CAMERA 9,10 \nETAJ 4 SECTOR 2",
            "category": "B,C,CE",
            "telephone": "0729511775",
            "email": "f1drivingcenter@yahoo.com"
          },
          {
            "name": "S.C. AUTO COLT DRIVE S.R.L",
            "address": "STR.OLANESTI NR.2 PARTER CAM.1,2,3 B-DUL.IULIU MANIU NR.381-391 IN ",
            "category": "B",
            "telephone": "0767955202",
            "email": "autocoltdrive@yahoo.com"
          },
          {
            "name": "S.C.HARA ACTIV S.R.L.",
            "address": "INCINTA COLEGIULUI TEHNIC IULIU \nMANIU SECT.6 BUCURESTI",
            "category": "B,C",
            "telephone": "0723644672",
            "email": "harabagiu_george@yahoo.com"
          },
          {
            "name": "S.C.UTIL TOUR TEHNOLOGY S.R.L.",
            "address": "B-DUL.BUCURESTII NOI NR.16 PARTER ",
            "category": "B,D",
            "telephone": "0761912892",
            "email": "scoala.util@gmail.com"
          },
          {
            "name": "S.C. EURO DRIVING CENTER S.R.L.",
            "address": "STR.JEAN STERIADI NR.17 PARTER \nCAM.1 SI 2 SUPRAFATA 58.93 MP SECT.3",
            "category": "B,C,CE",
            "telephone": "0729511775",
            "email": "eurodrivingcenter@yahoo.com"
          },
          {
            "name": "S.C. AUTO RECONSTAFF S.R.L.",
            "address": "B-DUL BUCURESTII NOI, NR.169, CAM.1, ",
            "category": "B",
            "telephone": "0721271651",
            "email": "reconstaff@gmail.com"
          },
          {
            "name": "S.C. AUTOVEHICUL PROFESIONAL \nTEHNOLOGIC S.R.L.",
            "address": "STR.PROGRESULUI NR.134-138 CAM.B1-\n8 SI B1-9 SC.B ET.1 SECT.5B-DUL.BASARABIA NR.256C PAVILION ",
            "category": "B",
            "telephone": "0787405132",
            "email": "autovehiculprofesional@gmail.com"
          },
          {
            "name": "S.C. SERVICII AUTO NEDELCU S.R.L.",
            "address": "ADMINISTRATIV CAM.101 ET.1 SECT.3 \nBUCURESTI",
            "category": "B",
            "telephone": "0722654055",
            "email": "camyiftime@gmail.com"
          },
          {
            "name": "S.C.MOTOR RIDER CENTER S.R.L.",
            "address": "SOS.OLTENITEI NR.103 CAM.A105,A106 \nSI A107,PAV.ADMIN.ET.1 SECT.4B-DUL. T. VLADIMIRESCU NR.57 ",
            "category": "A,B,C",
            "telephone": "0769402525",
            "email": "motoridercenter@yahoo.com"
          },
          {
            "name": "S.C. MAYA DRIVE S.R.L.",
            "address": "MEZANIN CAM.9,10 SI 11 BL.T4 SC.2 \nSECT.5",
            "category": "B",
            "telephone": "0723460276",
            "email": "mayadrive2012@yahoo.com"
          },
          {
            "name": "S.C. AUTOVEHICUL PROFESIONAL \nTEHNOLOGIC S.R.L",
            "address": "SOSEAUA ELECTRONICII NR.48 SECT.2",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0787405132",
            "email": "imobpmeg@yahoo.com"
          },
          {
            "name": "S.C.CDI GRUP VEHICUL S.R.L.",
            "address": "STR.STRADUINTEI NR.2 SECT.4 ",
            "category": "B",
            "telephone": "0787405132",
            "email": "imobpmeg@yahoo.com"
          },
          {
            "name": "S.C. CIUDI PILOT S.R.L.",
            "address": "B-DUL. ENERGETICIENILOR NR.13-15 \nPAV.ADM.CAM.404 ET.4 SECTOR 3",
            "category": "B",
            "telephone": "0764449139",
            "email": "aciu2u@yahoo.com"
          },
          {
            "name": "P.F.A. IONESCU EMIL",
            "address": "SOSEAUA OLTENITEI NR.105A CORP C1 \nCAMERA A3 80MP ETAJ 2 SECT.4",
            "category": "B",
            "telephone": "0754558202",
            "email": "calin.stefan.ionescu@gmail.com"
          },
          {
            "name": "S.C. HAPPY DRIVE LUCKY S.R.L.",
            "address": "STR.INGINERILOR TEI NR.9 CAMERA \nNR.6 PARTEA STANGA SECT.2",
            "category": "B",
            "telephone": "0763780403",
            "email": "georgecondurache21@gmail.com"
          },
          {
            "name": "S.C. ABC DRIVEPERFECT S.R.L.",
            "address": "STR.PREVEDERII NR.2F SECTOR 3B-DUL.TIMISOARA NR.103-111 SP.P+1 ",
            "category": "B",
            "telephone": "0729468665",
            "email": "robo.necsulescu@gmail.com"
          },
          {
            "name": "S.C. ADI AUTO MOTO TEAM S.R.L.",
            "address": "ST.A6 COMPLEX BUC.-CONSTRUCT \nSECT.6 BUCURESTI",
            "category": "A,B,C,A2",
            "telephone": "0722705842",
            "email": "adiautomoto@yahoo.com"
          },
          {
            "name": "S.C. ABC PERFECT AUTO MOTO \nS.R.L.",
            "address": "BULEVARDUL GHENCEA NR.79 CAM.10A \nET.2 SECT.6",
            "category": "A,B,A1,A2",
            "telephone": "0723361214",
            "email": "dragosbar@yahoo.com"
          },
          {
            "name": "S.C. AUTOGENERAL LIDER S.R.L.",
            "address": "STR.DÂMBOVNICULUI NR.34 SALA 02 ",
            "category": "B,C,CE",
            "telephone": "0744378608",
            "email": "office@autogeneral.ro"
          },
          {
            "name": "S.C. AUTO ALAZET START S.R.L.",
            "address": "B-DUL. GHENCEA NR.43B BIROUL 54 \nGHENCEA BUSINESS CENTER ET.4 SECT.6STR.RAMNICU SARAT NR.41 CAM.E2 ",
            "category": "B",
            "telephone": "0722396561",
            "email": "alindimancescu@yahoo.com"
          },
          {
            "name": "S.C. MARY AUTO TOUR S.R.L.",
            "address": "COMPLEX COMERCIAL BOBOCICA NOUA \nET.1 SECT.3",
            "category": "B,D",
            "telephone": "0761912892",
            "email": "imobpmeg@yahoo.com"
          },
          {
            "name": "S.C. AUTOGENERAL TEST S.R.L.",
            "address": "STR.DAMBOVNICULUI NR.34 SALA 1 ET.2 ",
            "category": "B,D",
            "telephone": "0213174141",
            "email": "office@autogeneral.ro"
          },
          {
            "name": "S.C. AUTO SOFT DRIVE S.R.L.",
            "address": "CALEA VACARESTI NR.304 PARTER BLOC STR. DAMBOVNICULUI NR.34 SALA 2 ",
            "category": "A,B,C,D,BE,CE",
            "telephone": "0726699110",
            "email": "marius_softdrive@yahoo.com"
          },
          {
            "name": "S.C. AUTOGENERAL CENTRU \nBUCURESTI S.R.L.",
            "address": "PARTER SI ET.2 SALA 2A SECT.4 \nBUCURESTI",
            "category": "B",
            "telephone": "0724252252",
            "email": "office@autogeneral.ro"
          },
          {
            "name": "S.C. AUTO LYN DRIVE BUSINESS \nS.R.L.",
            "address": "B-DUL. TIMISOARA NR.60D BIROURILE \n9,10 SI 11 ETAJ 2 SECTOR 6",
            "category": "B,C,CE",
            "telephone": "0726376133",
            "email": "militari@autolyn.ro"
          },
          {
            "name": "S.C. SECOND FAMILY TEAM S.R.L.",
            "address": "STR.ILIOARA NR.14H IN SUPRAFATA DE ",
            "category": "B",
            "telephone": "0764409070",
            "email": "tatiana.stroe@gmail.com"
          },
          {
            "name": "S.C. DRIVE AUTO FOCUS S.R.L.",
            "address": "STR.GHEORGHE TITEICA NR.178 ETAJ 2 ",
            "category": "B",
            "telephone": "0764421141",
            "email": "contact.focusdrive@gmail.com"
          },
          {
            "name": "S.C. TEO CONSULT AUTO 2003 ",
            "address": "B-DUL.CONSTRUCTORILOR NR.26 SECT.6 ",
            "category": "B",
            "telephone": "0722324073",
            "email": "silvia@vreaupermis.ro"
          }
        ]
      }
    ]
  },
  {
    "state": "BC",
    "schools": [
      {
        "city": "ONESTI",
        "schools": [
          {
            "name": "AUTOSERA SRL",
            "address": "STR. C. D. GHEREA BL.6.SC.A PARTER",
            "category": "A,B,A1,AM",
            "telephone": "0745627362",
            "email": "adriansergentu@yahoo.com"
          },
          {
            "name": "GELD INC SRL",
            "address": "B-DUL REPUBLICII NR.74",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0740951176",
            "email": "office@scoaladesoferionesti.ro"
          },
          {
            "name": "LIA STAR SRL",
            "address": "B-DUL REPUBLICII NR.68,A.4",
            "category": "B",
            "telephone": "0742058014",
            "email": "sc.liastar@yahoo.com"
          },
          {
            "name": "AUTOKITI VGB SRL",
            "address": "CALEA MARASESTI,NR.151",
            "category": "B",
            "telephone": "0770684798",
            "email": "gabriela.chitic@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ONESTI",
            "address": "STR.AURORA NR.2",
            "category": "B",
            "telephone": "0234313010",
            "email": "s_elais@yahoo.com"
          },
          {
            "name": "MIHOC SRL",
            "address": "STR .REDULUI NR.135",
            "category": "B,C,D,BE,CE",
            "telephone": "0234318157",
            "email": "mihoc.grup@yahoo.com"
          },
          {
            "name": "ACATRINI M. PUIU P. F. A.",
            "address": "Strada ALEXANDRU IOAN CUZA NR. 17 ",
            "category": "B",
            "telephone": "0744578558",
            "email": "pacatrini@yahoo.com"
          },
          {
            "name": "LIA 1 CL SRL",
            "address": "Str. Spicului Nr.111",
            "category": "B",
            "telephone": "0742058013",
            "email": "sc.liastar@yahoo.com"
          },
          {
            "name": "GAMAN I. CRISTINEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "Aleea Parcului, Bl.3, Ap.44",
            "category": "B",
            "telephone": "0745309350",
            "email": "gamc2007@yahoo.com"
          },
          {
            "name": "P.F.A. SERBAN V. NICU",
            "address": "BULEVARDUL REPUBLICII NR. 58",
            "category": "B",
            "telephone": "0748147838",
            "email": "cica_serban@yahoo.com"
          },
          {
            "name": "P.F.A. ROTILA MIHAI",
            "address": "BULEVARDUL REPUBLICII NR 58",
            "category": "B",
            "telephone": "0743229837",
            "email": "rotila_mihai31@yahoo.com"
          },
          {
            "name": "P.F.A. SRAER ROBERT-CAROL",
            "address": "BULEVARDUL REPUBLICII NR 58",
            "category": "B",
            "telephone": "0742141954",
            "email": "s_elais@yahoo.com"
          },
          {
            "name": "P.F.A. BURDUSEL NICU",
            "address": "BULEVARDUL REPUBLICII NR 58",
            "category": "B",
            "telephone": "0744474559",
            "email": "burdusel.nicu@yahoo.com"
          },
          {
            "name": "P.F.A. DIACONITA EUGEN",
            "address": "STR. CONSTANTIN DOBROGEANU ",
            "category": "B",
            "telephone": "0749966479",
            "email": "eugendiaconita@yahoo.com"
          }
        ]
      },
      {
        "city": "MOINESTI",
        "schools": [
          {
            "name": "COSNIST SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.D5",
            "category": "B",
            "telephone": "0740761896",
            "email": "cosnist@yahoo.com"
          },
          {
            "name": "EL-ROXPAN SRL",
            "address": "STR. TUDOR VLADIMIRESCU COMPLEX ",
            "category": "B",
            "telephone": "0745319377",
            "email": "panaite_lenuta@yahoo.com"
          },
          {
            "name": "AVRAM PREST SRL",
            "address": "STR. TUDOR VLADIMIRESCU BLOC 10 ",
            "category": "B",
            "telephone": "0740995741",
            "email": "AVRAMPREST@YAHOO.COM"
          },
          {
            "name": "LUCAIL AUTO SRL",
            "address": "STR.CPT.PAUL ZAGANESCU,NR.35",
            "category": "B,C,CE",
            "telephone": "0740154794",
            "email": "lucail_auto@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC  GRIGORE \nCOBALCESCU ",
            "address": "STR. GEORGE ENESCU NR. 2 LOC. \nMOINESTI",
            "category": "B",
            "telephone": "0745266198",
            "email": "colegiulcobalcescu@yahoo.com"
          }
        ]
      },
      {
        "city": "TARGU OCNA",
        "schools": [
          {
            "name": "GIULYEM SRL",
            "address": "B-DUL REPUBLICII NR.1",
            "category": "B",
            "telephone": "0740265444",
            "email": "GIULYEM@YMAIL.COM"
          },
          {
            "name": "JANRAD SRL",
            "address": "STR.COSTACHE NEGRII NR.78",
            "category": "B",
            "telephone": "0742088540",
            "email": "Carmenpreda@yahoo.com"
          },
          {
            "name": "DRIVER DAY",
            "address": "STR.C.NEGRI NR.72",
            "category": "B",
            "telephone": "0745276602",
            "email": "IONASCUDRGS@YAHOO.COM"
          },
          {
            "name": "AUTO FAB 54 SRL",
            "address": "Strada ION SLATINEANU NR. 2",
            "category": "B",
            "telephone": "0744122876",
            "email": "autofab54@yahoo.ro"
          },
          {
            "name": "TEOSEB AUTOTRANS SRL",
            "address": "B-dul Republicii Nr.3",
            "category": "B,C,CE",
            "telephone": "0740824781",
            "email": "olaru_emanuel@yahoo.com"
          }
        ]
      },
      {
        "city": "BACAU",
        "schools": [
          {
            "name": "FELALEX SRL",
            "address": "CALEA ROMANULUI NR.25",
            "category": "B",
            "telephone": "0745537897",
            "email": "badita.constantin@yahoo.com"
          },
          {
            "name": "MAR-TRANS-GRUP SRL",
            "address": "STR.VADU BISTRITEI NR.48",
            "category": "B,C,CE",
            "telephone": "0744575775",
            "email": "martransgrup@yahoo.com"
          },
          {
            "name": "EURO DRIVE SRL",
            "address": "STR MIHAI VITEAZU,NR.8,CAMERA 1-",
            "category": "B",
            "telephone": "0745419279",
            "email": "euro_drive@ymail.com"
          },
          {
            "name": "TECRO COMPANY SRL",
            "address": "STR. PASAJUL REVOLUTIEI NR.5 ET.1",
            "category": "B,C,D,CE",
            "telephone": "0742507421",
            "email": "tecrocompany@yahoo.com"
          },
          {
            "name": "SMART DRIVING SRL",
            "address": "STR.ALEXANDRU CEL BUN NR.43",
            "category": "B",
            "telephone": "0746220166",
            "email": "office@smartdriving.ro"
          },
          {
            "name": "DIADRIVE SRL",
            "address": "BACAU, CALEA MOLDOVEI NR. 112",
            "category": "A,B,C,D,BE,CE,B1,A2",
            "telephone": "0746594104",
            "email": "diadrive@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC PETRU RARES ",
            "address": "STR.TECUCIULUI NR.17",
            "category": "B",
            "telephone": "0741400456",
            "email": "gsprbacau@yahoo.com"
          },
          {
            "name": "AUTO DUNAVA SRL",
            "address": "STR.MARASESTI NR.5",
            "category": "B",
            "telephone": "0745621731",
            "email": "autodunava@yahoo.com"
          },
          {
            "name": "PROVOIAJ AUTO SRL ",
            "address": "STR. RAZBOIENI NR. 8",
            "category": "B",
            "telephone": "0757667755",
            "email": "scoala.provoiaj@yahoo.ro"
          },
          {
            "name": "AUTO CARLA SRL",
            "address": "Str. I.L.Caragiale, Nr. 1 B",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0740548784",
            "email": "florinfocsaneanu@yahoo.com"
          },
          {
            "name": "ACSIDRIVE SRL",
            "address": "STR ALEXANDRU CEL BUN NR.13",
            "category": "B",
            "telephone": "0756130199",
            "email": "racsinte@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ''ANGHEL \nSALIGNY''",
            "address": "STR.VASILE ALECSANDRI NR.18",
            "category": "B",
            "telephone": "0234515254",
            "email": "adrian_ungureanu_bacau@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"DUMITRU \nMANGERON''",
            "address": "STR.AEROPORTULUI NR.5",
            "category": "B",
            "telephone": "0234575365",
            "email": "mihai_guramulta@yahoo.com"
          },
          {
            "name": "LIV-COMAL SRL",
            "address": "STR MILCOV NR.98",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0745207767",
            "email": "liv_comal@yahoo.com"
          },
          {
            "name": "ADERENT SRL",
            "address": "STR.AEROPORTULUI NR.5",
            "category": "B",
            "telephone": "0765241729",
            "email": "aderent.srl@gmail.com"
          },
          {
            "name": "MUV SRL",
            "address": "STR.GARII.NR.12",
            "category": "B,C,D,CE",
            "telephone": "0752241369",
            "email": "muvsrl@yahoo.com"
          },
          {
            "name": "LIVPROD SRL",
            "address": "STR.MARASESTI,NR.5,PARTER,CAM.22",
            "category": "A,B,A1,AM,A2",
            "telephone": "0744884960",
            "email": "livprod@yahoo.com"
          },
          {
            "name": "S.C. PERMIS EXPERT S.R.L.",
            "address": "STR.MILCOV,NR.1 ",
            "category": "B",
            "telephone": "0749515287",
            "email": "alina.mihalachi@centralclinic.ro"
          },
          {
            "name": "AUTO BIMBO SRL",
            "address": "STR.AEROPORTULUI NR. 4",
            "category": "B",
            "telephone": "0744603394",
            "email": "bimbomic@yahoo,com"
          },
          {
            "name": "ASK SMART ABC ",
            "address": "Str. Mihai Viteazu , Nr. 1",
            "category": "A,B,B1",
            "telephone": "0740305070",
            "email": "asksmartABC@yahoo.com"
          },
          {
            "name": "ANCAUTORIZARE SRL",
            "address": "Strada ARINILOR Nr.43",
            "category": "B,C,D,CE",
            "telephone": "0745537897",
            "email": "badita.constantin@yahoo.com"
          },
          {
            "name": "PROFESIONAL DESIGN SRL",
            "address": "STRADA PIETII NR.6",
            "category": "A,B",
            "telephone": "0745070979",
            "email": "profesionalgrup@yahoo.com"
          },
          {
            "name": "P.F.A.MIHAI T.GEORGE",
            "address": "STRADA TECUCIULUI NR.17",
            "category": "B",
            "telephone": "",
            "email": "mihaigeorge58@yahoo.com"
          },
          {
            "name": "P.F.A.BENEA A.ADRIAN",
            "address": "STRADA TECUCIULUI NR.17",
            "category": "B",
            "telephone": "",
            "email": "beneaadrian53@gmail.com"
          },
          {
            "name": "P.F.A. DUDECI N DINU",
            "address": "STRADA TECUCIULUI NR.17",
            "category": "B",
            "telephone": "",
            "email": "dudecidinu1950@yahoo.com"
          },
          {
            "name": "P.F.A. OLARU I.EMIL",
            "address": "STR.TECUCIULUI NR 17",
            "category": "B",
            "telephone": "",
            "email": "olaruemil1954@gmail.com"
          },
          {
            "name": "P.F.A.ILIE C.NECULAI",
            "address": "STR.GHEORGHE DONICI NR.19",
            "category": "B",
            "telephone": "0745408180",
            "email": "racsinte@gmail.com"
          },
          {
            "name": "TECRO TRANS S.R.L.",
            "address": "Strada PASAJUL REVOLUTIEI, Nr.6, Etaj 2",
            "category": "A,B,BE",
            "telephone": "0742507421",
            "email": "tecrocompany@yahoo.com"
          },
          {
            "name": "P.F.A. SPANU CONSTANTIN ",
            "address": "STR.MIHAI VITEAZU NR. 8 ET.3 CAM.4",
            "category": "B",
            "telephone": "0745419269",
            "email": "spanuconstantin@yahoo.com"
          },
          {
            "name": "P.F.A. BALAN D. HARALAMBIE ",
            "address": "STR. MIHAI VITEAZU, NR.8, ET 3, CAM.4 ",
            "category": "B",
            "telephone": "0745374556",
            "email": "balan.haralambie@gmail.com"
          },
          {
            "name": "P.F.A. SERBAN A. MIHAI SORINEL",
            "address": "STR. MIHAI VITEAZU, NR.8, CAM.4, ETAJ ",
            "category": "B",
            "telephone": "0742909947",
            "email": "pfaserbansorin@gmail.com"
          },
          {
            "name": "P.F.A. ARITON I. FLORIN",
            "address": "STR. MIHAI VITEAZU,NR.8, CAM. 8 ETAJ ",
            "category": "B",
            "telephone": "0742703667",
            "email": "violeta_ariton@yahoo.ro"
          },
          {
            "name": "AUTOREGAL ABCDE S.R.L.",
            "address": "Strada 9 MAI, Nr. 78, mezanin, biroul 2",
            "category": "B,C,D,CE",
            "telephone": "0742188638",
            "email": "autoregalabcde@gmail.com"
          },
          {
            "name": "DAVNIC AUTO SRL",
            "address": "STR. MIRON COSTIN NR. 75 bisSTR. BUCIUMULUI 2A, COMPLEX ",
            "category": "B",
            "telephone": "0745538234",
            "email": "davnicauto@yahoo.com"
          },
          {
            "name": "RODANI DRIVE S.R.L.",
            "address": "AGROALIMENTAR NORD, MODULUL 11-\n12-13",
            "category": "B",
            "telephone": "0744509447",
            "email": "bej3dan@yahoo.com"
          },
          {
            "name": "R & R PERMIS AUTO S.R.L.",
            "address": "Strada STEFAN CEL MARE , Nr.25, ",
            "category": "B",
            "telephone": "0745775091",
            "email": "irina_ramona76@yahoo.com"
          },
          {
            "name": "CONNECT DRIVE 68 SRL",
            "address": "Str. Lucretiu Patrascanu, Nr. 2, Scara C, ",
            "category": "B",
            "telephone": "",
            "email": "shuidici@yahoo.ro"
          }
        ]
      },
      {
        "city": "Onesti",
        "schools": [
          {
            "name": "P.F.A. POPA GH. VASILE",
            "address": "Str. Capitan Busila nr. 5 bl A5 Ap. 5",
            "category": "B",
            "telephone": "0751636647",
            "email": "vpopa2003@yahoo.ro"
          },
          {
            "name": "P.F.A. Lungu S. Valentin",
            "address": "Strada Alexandru Ioan Cuza, Nr. 25. Etaj ",
            "category": "B",
            "telephone": "0742108695",
            "email": "vlungu2003@yahoo.com"
          }
        ]
      },
      {
        "city": "DARMANESTI",
        "schools": [
          {
            "name": "IMBOLD TRANS SRL",
            "address": "DARMANESTI STR. RAFINARIEI NR. 27 ",
            "category": "B,C,D,CE",
            "telephone": "0742068296",
            "email": "imbold.trans@gmail.com"
          },
          {
            "name": "IMBOLD SCHOOL SRL",
            "address": "Str. Rafinariei, Nr.27 A",
            "category": "B,C,CE",
            "telephone": "0742068296",
            "email": "imbold.school@gmail.com"
          }
        ]
      },
      {
        "city": "Moinesti",
        "schools": [
          {
            "name": "MARDARE AUTO-TEST SRL",
            "address": "Str.G-ral Nicolaie Sova,Nr.15",
            "category": "B,C,CE",
            "telephone": "0740767895",
            "email": "mardareserv@yahoo.com"
          }
        ]
      },
      {
        "city": "RACOVA",
        "schools": [
          {
            "name": "ROMEXPRES SRL",
            "address": "COM. RACOVA STR.RACOVA SES NR.613",
            "category": "B,C,Tr,CE",
            "telephone": "0745450459",
            "email": "romexpres.racova@yahoo.com"
          }
        ]
      },
      {
        "city": "BERESTI BISTRITA",
        "schools": [
          {
            "name": "P.F.A. BORDEIANU GH. PETRU",
            "address": "COM. BERESTI BISTRITA",
            "category": "B",
            "telephone": "",
            "email": "bordeianu13@yahoo.com"
          }
        ]
      },
      {
        "city": "COMANESTI",
        "schools": [
          {
            "name": "P.F.A.HUSU I.TRAIAN",
            "address": "STR GHEORGHE DONICI NR.19",
            "category": "B",
            "telephone": "0756053793",
            "email": "HUSUTRAIAN@YAHOO.COM"
          },
          {
            "name": "ANCA FEL TRANS SRL",
            "address": "Strada REPUBLICII, Bloc 11, Scara B",
            "category": "B",
            "telephone": "0745537897",
            "email": "badita.constantin@yahoo.com"
          }
        ]
      },
      {
        "city": "AGAS",
        "schools": [
          {
            "name": "P.F.A. TONCU MARINEL",
            "address": "SAT CAMENCA, COM. BRUSTUROASA, ",
            "category": "B",
            "telephone": "0752077422",
            "email": "marineltoncu@gmail.com"
          }
        ]
      },
      {
        "city": "SAT RACOVA, NR 72",
        "schools": [
          {
            "name": "P.F.A.FUIOGA CONSTANTIN",
            "address": "COM. RACOVA",
            "category": "B",
            "telephone": "0744357042",
            "email": "drutu_nicoleta@yahoo.com"
          }
        ]
      },
      {
        "city": "Stanisesti",
        "schools": [
          {
            "name": "P.F.A.LUNGU V.D.GHEORGHE",
            "address": "Sat Balotesti",
            "category": "B",
            "telephone": "0748923047",
            "email": "glungu255@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "BH",
    "schools": [
      {
        "city": "SINTANDREI",
        "schools": [
          {
            "name": "BACRISAN SRL",
            "address": "NR.274/A",
            "category": "B,C,CE,AM",
            "telephone": "0744548220",
            "email": "contactautocristian@yahoo.com"
          }
        ]
      },
      {
        "city": "MARGHITA",
        "schools": [
          {
            "name": "ZECE IONUTZ SRL",
            "address": "STR.REPUBLICII NR.70",
            "category": "B",
            "telephone": "0724636566",
            "email": "DECEBALSCOALA@YAHOO.COM"
          },
          {
            "name": "AUTOLIV SRL",
            "address": "STR. REPUBLICII NR.52",
            "category": "B,C,CE",
            "telephone": "0259362513",
            "email": "valeria_benea@yahoo.com"
          }
        ]
      },
      {
        "city": "BEIUS",
        "schools": [
          {
            "name": "AUTO RAPID",
            "address": "STR. C. BOLIAC NR.17",
            "category": "B",
            "telephone": "0259322002",
            "email": "cerneaalex@yahoo.com"
          },
          {
            "name": "NIKO DRIVER",
            "address": "STR.IOAN CIORDAS NR.18",
            "category": "B",
            "telephone": "0744774920",
            "email": "ovidiu_groza@yahoo.com"
          },
          {
            "name": "PERTE DRIVE TEST SRL",
            "address": "STR. PETRILENI NR.15",
            "category": "B,C,CE",
            "telephone": "0723282931",
            "email": "perte_drivetest@yahoo.com"
          },
          {
            "name": "AUTO TIG SRL",
            "address": "STR.BURGUNDIA MARE NR.25",
            "category": "B",
            "telephone": "0744782751",
            "email": "auto_tig@yahoo.com"
          }
        ]
      },
      {
        "city": "ORADEA",
        "schools": [
          {
            "name": "AUTO DORINA",
            "address": "STR.IULIU MANIU NR.17A",
            "category": "A,B1,B,C,D,BE,CE,A1,AM,",
            "telephone": "414698",
            "email": "auto.dorina@yahoo.ro"
          },
          {
            "name": "AUTO SANTA SRL",
            "address": "STR.MIHAI EMINESCU NR.44 ET.1 SALA ",
            "category": "A,B,BE,A1,AM,A2",
            "telephone": "0259436566",
            "email": "contact@autosanta.ro"
          },
          {
            "name": "ZECENUT SRL",
            "address": "STR.SIMION BARNUTIU NR.32 SPATIU ",
            "category": "A,B,C,D,CE,A1,B1,AM,A2",
            "telephone": "0359450279",
            "email": "decebalscoala@yahoo.com"
          },
          {
            "name": "AUTOLIV SRL",
            "address": "P.L. PIATA EMANUIL GOJDU NR.49",
            "category": "A,B,A1,AM",
            "telephone": "0745524433",
            "email": "valeria_benea@yahoo.com"
          },
          {
            "name": "AUTO COCIUBEI SRL",
            "address": "STR. CAZABAN NR.13, PCT. L. ",
            "category": "B,BE",
            "telephone": "0359809333",
            "email": "autocociubei@yahoo.com"
          },
          {
            "name": "ZACH & CO SRL",
            "address": "STR.ROZMARINULUI NR.11",
            "category": "B,C,BE,CE",
            "telephone": "0741276801",
            "email": "scoalaautozach@gmail.com"
          },
          {
            "name": "RADIDOS",
            "address": "STR.SELEUSULUI NR.40",
            "category": "B",
            "telephone": "0751885863",
            "email": "petrica.iosif@yahoo.com"
          },
          {
            "name": "AUTOTRIOFAVORIT",
            "address": "STR.PRIMARIEI NR.22 AP.2",
            "category": "B,C,D,CE",
            "telephone": "0259226226",
            "email": "jurcut_gheorghe@yahoo.com"
          },
          {
            "name": "SCOALA DE PREGATIRE A A.P.F. \nAVRAM IANCU ORADEA",
            "address": "CALEA ARADULUI NR.2",
            "category": "B",
            "telephone": "0259419520",
            "email": "biosagheorghe@yahoo.com"
          },
          {
            "name": "AUTO GOLDIS SRL",
            "address": "B-DUL COPOSU NR.27",
            "category": "A,B,C,D,BE,CE,AM",
            "telephone": "0359171322",
            "email": "permis@autogoldis.ro"
          },
          {
            "name": "S.A.O. SA",
            "address": "STR.OVIDIU DENSUSEANU NR.4",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0259454731",
            "email": "saooradea@gmail.com"
          },
          {
            "name": "AVRONA",
            "address": "B-DUL GENERAL GHE.MAGHERU NR.22",
            "category": "B",
            "telephone": "0722273185",
            "email": "avrona@rdsor.ro"
          },
          {
            "name": "SAGETATORUL COMIMPEX",
            "address": "STR. MIHAI EMINESCU NR.44 PAERTER, ",
            "category": "B,C,D,CE,D1",
            "telephone": "0259136566",
            "email": "contact@autosanta.ro"
          },
          {
            "name": "GES IMPEX SRL",
            "address": "STR. OVIDIU DENSUSIANU NR.1",
            "category": "A,B,C,D,Tr,BE,CE,A1,B1,A",
            "telephone": "0259422141",
            "email": "office@autoges.ro"
          },
          {
            "name": "DREIVERCAR SRL",
            "address": "STR.PIATA 1 DECEMBRIE NR.5 AP.4",
            "category": "B",
            "telephone": "0745628944",
            "email": "baropisti@yahoo.com"
          },
          {
            "name": "AUTO SANTA JUNIOR S.R.L.",
            "address": "STR. MIHAI EMINESCU NR.44 SALA NR.4 ",
            "category": "B,C,Tr,CE",
            "telephone": "0259436566",
            "email": "IONUTSANTA@YAHOO.COM"
          },
          {
            "name": "DORIMIA DRIVER SRL",
            "address": "STR. STEFAN ZWEIG NR.10/A, PARTER",
            "category": "B",
            "telephone": "0773322329",
            "email": "dorinagall@yahoo.com"
          },
          {
            "name": "TAKO JANOS PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.PIATA 1 DECEMBRIE NR.5 AP.4",
            "category": "B",
            "telephone": "0744300536",
            "email": "janitako@yahoo.com"
          },
          {
            "name": "BIOSA GHEORGHE PERSOANA \nFIZICA AUTORIZATA",
            "address": "ALEEA PROF. ONISIFOR GHIBU NR.8 \nBL.QB6 SCARA C ETAJ 1 AP.23",
            "category": "B",
            "telephone": "0740103054",
            "email": "biosagheorghe@yahoo.com"
          },
          {
            "name": "RISTEA CATALIN VIOREL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR, GRADINARILOR NR.35 BL. PB9 \nAP.15",
            "category": "B",
            "telephone": "0770757906",
            "email": "ristea9@gmail.com"
          },
          {
            "name": "AUTO TEOOSAX POPA SRL",
            "address": "STR. CUZA VODA NR.73 AP.11",
            "category": "B,C,CE",
            "telephone": "0744978995",
            "email": "dorelpopasax@yahoo.com"
          },
          {
            "name": "AUTOCUTEZATORII SRL",
            "address": "STR. CUZA VODA NR.41 CORP A AP.1",
            "category": "B",
            "telephone": "0742018735",
            "email": "autocutezatoriioradea@gmail.com"
          },
          {
            "name": "AUTO STAR DRIVER SRL",
            "address": "STR. PARTENIE COSMA NR.17",
            "category": "B",
            "telephone": "0771630952",
            "email": "autostardriver@yahoo.com"
          },
          {
            "name": "DRAGOMIR IMPEX SRL",
            "address": "STR. MESTESUGARILOR NR.71, SPATIU \nCOMERCIAL, PARTER, BL.R119",
            "category": "B",
            "telephone": "0770116930",
            "email": "vio_pintea57@yahoo.ro"
          },
          {
            "name": "ZECENUT DOI SRL",
            "address": "SALONTA, STR. ARADULUI NR.18",
            "category": "B",
            "telephone": "0730231313",
            "email": "decebalscoala@yahoo.com"
          }
        ]
      },
      {
        "city": "STEI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC UNIREA  STEI",
            "address": "STR.INDEPENDENTEI NR.19",
            "category": "B,C,CE",
            "telephone": "",
            "email": "griunirea@yahoo.com"
          },
          {
            "name": "COR - TIN FAVORIT S.R.L.",
            "address": "STR. MIRON POMPILIU NR.107/A",
            "category": "B,C,BE",
            "telephone": "0740912292",
            "email": "cortinfavorit@yahoo.com"
          }
        ]
      },
      {
        "city": "ALESD",
        "schools": [
          {
            "name": "BACRISAN SRL",
            "address": "STR.BOBALNA NR.41",
            "category": "B",
            "telephone": "0744548220",
            "email": "contactautocristian@yahoo.com"
          },
          {
            "name": "SCOALA AUTO GES SRL",
            "address": "STR. BOBALNA NR.45",
            "category": "B",
            "telephone": "0742682618",
            "email": "office@autoges.ro"
          }
        ]
      },
      {
        "city": "SACUENI",
        "schools": [
          {
            "name": "ZECEIONUT SRL",
            "address": "STR. MORII NR.42",
            "category": "B,BE",
            "telephone": "0730231313",
            "email": "decebalscoala@yahoo.com"
          }
        ]
      },
      {
        "city": "SALONTA",
        "schools": [
          {
            "name": "AUTO BORA DRIVER SRL",
            "address": "STR. REPUBLICII PARTER NR.37 BL.AN1 ",
            "category": "B,C,CE",
            "telephone": "0745833212",
            "email": "boramarian1977@yahoo.com"
          },
          {
            "name": "MOZA DAN MARIUS PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR. SARCADULUI NR.14",
            "category": "B",
            "telephone": "0770257175",
            "email": "moza.dan@yahoo.com"
          }
        ]
      },
      {
        "city": "MAGESTI",
        "schools": [
          {
            "name": "MOISIN SEBASTIAN IOAN \nPERSOANA FIZICA AUTORIZATA",
            "address": "SAT CACUCIU NOU NR.29",
            "category": "B",
            "telephone": "0745185592",
            "email": "moisin_m@yahoo.com"
          },
          {
            "name": "MOISIN MONICA MARIANA \nPERSOANA FIZICA AUTORIZATA",
            "address": "SAT CACUCIU NOU NR.29",
            "category": "B",
            "telephone": "0745847518",
            "email": "monicamoisin@yahoo.com"
          }
        ]
      },
      {
        "city": "NOJORID",
        "schools": [
          {
            "name": "BARCUI ROMAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "SAT NOJORID NR.128A",
            "category": "B",
            "telephone": "0740208620",
            "email": "barcuiroman@yahoo.com"
          }
        ]
      },
      {
        "city": "HAIEU",
        "schools": [
          {
            "name": "AUTO PERTE JUNIOR SRL",
            "address": "CARTIERUL TINERETULUI NR.493",
            "category": "B,BE",
            "telephone": "0737898276",
            "email": "auto.pertejr@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "BN",
    "schools": [
      {
        "city": "BISTRITA",
        "schools": [
          {
            "name": "AUTO RASCA SRL",
            "address": "STR.ZEFIRULUI NR.9",
            "category": "B",
            "telephone": "0263 238437",
            "email": "auto_rasca@yahoo.com"
          },
          {
            "name": "ALFA KATY DRIVE SRL",
            "address": "STR.SUCEVEI NR.6A SPATIU COMERCIAL ",
            "category": "B",
            "telephone": "0753034487",
            "email": "alfakatydrive@yahoo.com"
          },
          {
            "name": "MARTURA EXPRES SRL",
            "address": "STR.VIILOR NR.1",
            "category": "B,C,D,BE,CE",
            "telephone": "0740155103",
            "email": "martura_rox@yahoo.com"
          },
          {
            "name": "AUTO MOTO RASCA SRL",
            "address": "STR.ZEFIRULUI NR.9",
            "category": "B,C,D,CE",
            "telephone": "0263238437",
            "email": "automoto_rasca@yahoo.com"
          },
          {
            "name": "ANA-KATY DRIVE SRL",
            "address": "STR.URSULUI,NR.12-14,ET.1",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0756030818",
            "email": "anakatydrive@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  GRIGORE \nMOISIL ",
            "address": "STR.RODNEI NR.23",
            "category": "B",
            "telephone": "0263233259",
            "email": "mihai_sporis@yahoo.com"
          },
          {
            "name": "SUPER OK SRL",
            "address": "BDUL REPUBLICII NR.41",
            "category": "A,B,BE,A1,A2",
            "telephone": "0263238101",
            "email": "adi.vaida@yahoo.com"
          },
          {
            "name": "AUTOVIP EXPERT SRL",
            "address": "STR.1 DECEMBRIE NR.27-29",
            "category": "B",
            "telephone": "0745605030",
            "email": "autovip.bistrita@gmail.com"
          },
          {
            "name": "HENIU SA",
            "address": "STR. RODNEI NR.1A",
            "category": "B,C,D,CE",
            "telephone": "0263-234243",
            "email": "contact@heniu.ro"
          },
          {
            "name": "PROFESIONAL AUTO SRL",
            "address": "STR.IMPARATUL TRAIAN NR.58D",
            "category": "B,C,CE",
            "telephone": "0771447731",
            "email": "olivia_olimpiu@yahoo.com"
          },
          {
            "name": "NEGRUSA SNC",
            "address": "STR.EC.TEODOROIU NR.15 SC.B AP.8",
            "category": "B",
            "telephone": "0263 218238",
            "email": "larisa_vivi@yahoo.com"
          },
          {
            "name": "ARTR EXPRES SRL",
            "address": "STR. STEFAN CEL MARE NR.24",
            "category": "B,C,CE",
            "telephone": "0263-233021",
            "email": "artrexpres@yahoo.com"
          },
          {
            "name": "ROBY STAR DRIVE SRL",
            "address": "STR.STEFAN CEL MARE BL.22 PARTER",
            "category": "B",
            "telephone": "0745115996",
            "email": "robystardrive@yahoo.com"
          },
          {
            "name": "ALFA FLA DRIVE SRL",
            "address": "STR.LIVIU REBREANU NR.22 Etaj 1",
            "category": "B",
            "telephone": "07530344487",
            "email": "alfafladrive@yahoo.com"
          },
          {
            "name": "PETRINA EUGEN IOAN PFA",
            "address": "VIISOARA NR.209/2",
            "category": "B",
            "telephone": "0747597746",
            "email": "eugen.petrina@yahoo.com"
          },
          {
            "name": "P.F.A. SOMESAN NICOLAE",
            "address": "STR. NASAUDULUI , NR.6, SC.A, AP.12",
            "category": "B",
            "telephone": "0740466667",
            "email": "somesannicolae1960@gmail.com"
          },
          {
            "name": "P.F.A. HOMOREAN NICOLAI",
            "address": "STR. CERBULUI, NR.15",
            "category": "B",
            "telephone": "0740154903",
            "email": "homorean.nicolai@gmail.com"
          },
          {
            "name": "P.F.A.MURESAN G. DORIN",
            "address": "STR,BARGAULUI NR.22 SC.A AP.8",
            "category": "B",
            "telephone": "0740987516",
            "email": "dorinmuresan_22@yahoo.com"
          },
          {
            "name": "SUPER OK SIGURANTA RUTIERA SRL",
            "address": "SIEUT NR.84 C JUD.BISTRITA-NASAUD",
            "category": "B",
            "telephone": "0743111720",
            "email": "florina.vaida@yahoo.com"
          },
          {
            "name": "DRIVE EXPRES SRL",
            "address": "STR.STEFAN CEL MARE NR.24 PARTER ",
            "category": "B,C,CE",
            "telephone": "",
            "email": "artrexpres@yahoo.com"
          },
          {
            "name": "PROPERMIS AUTO SRL",
            "address": "STR.RODNEI NR.1A CAMERA 8",
            "category": "B,C,D,CE",
            "telephone": "0747276616",
            "email": "alex_iliesi@yahoo.com"
          }
        ]
      },
      {
        "city": "DUMITRA",
        "schools": [
          {
            "name": "PANTELIMON TRANS SRL",
            "address": "STR.PRINCIPALA NR.134",
            "category": "A,B,C,Tr,CE,A1,AM,A2",
            "telephone": "0744 179470",
            "email": "paul19martita@yahoo.com"
          }
        ]
      },
      {
        "city": "CAIANU MIC",
        "schools": [
          {
            "name": "PFA PUGNA GHEORGHE",
            "address": "STR. PRINCIPALA,NR.71",
            "category": "B",
            "telephone": "0742657558",
            "email": "pugna.gheorghe@gmail.com"
          }
        ]
      },
      {
        "city": "NIMIGEA",
        "schools": [
          {
            "name": "PFA PIRTIU IOAN TUDOR",
            "address": "NR.147",
            "category": "B",
            "telephone": "0766209355",
            "email": "PirtiuIoanTudor@Yahoo.com"
          }
        ]
      },
      {
        "city": "NASAUD",
        "schools": [
          {
            "name": "UBI NASAUD SRL",
            "address": "STR.IULIAN MARTIAN NR.12A",
            "category": "B",
            "telephone": "0745055577",
            "email": "Cremeneubiauto@yahoo.com"
          },
          {
            "name": "LARISA SRL",
            "address": "STR.BISTRITEI NR.7/E",
            "category": "B,C,CE",
            "telephone": "0740870447",
            "email": "georgelgm2005@yahoo.com"
          },
          {
            "name": "LICEUL SILVIC \"TRANSILVANIA\"",
            "address": "STR.GARII NR.1",
            "category": "B",
            "telephone": "0263360481",
            "email": "lsilvic@hotmail.com"
          },
          {
            "name": "PRO TRAINING TRANSILVANIA SRL",
            "address": "STR.GEORGE COSBUC NR.150A",
            "category": "B",
            "telephone": "0761159375",
            "email": "scoalaidrive@gmail.com"
          },
          {
            "name": "DRIVE EXPRES ARTR SRL",
            "address": "FUNDATURA TANASE TUDORAN",
            "category": "B,C",
            "telephone": "0747227608",
            "email": "moldovanflaviu@yahoo.com"
          }
        ]
      },
      {
        "city": "PRUNDU \nBARGAULUI",
        "schools": [
          {
            "name": "PFA VLAD T. TRAIAN",
            "address": "NR.1177",
            "category": "B",
            "telephone": "0740805772",
            "email": "vladsgi@yahoo.com"
          },
          {
            "name": "NATH GRIGORE PFA",
            "address": "STR.PRINCIPALA NR.1293",
            "category": "B",
            "telephone": "0747077442",
            "email": "nath_grigore@yahoo.com"
          },
          {
            "name": "GALBEN IULIAN MIRCEA PFA",
            "address": "STR.PRINCIPALA NR.1293",
            "category": "B",
            "telephone": "0740774263",
            "email": "eugenia_moisan@yahoo.com"
          }
        ]
      },
      {
        "city": "BECLEAN",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC AGRICOL \nBECLEAN",
            "address": "PIATA LIBERTATII NR.2",
            "category": "B",
            "telephone": "0263 343454",
            "email": "raulfodor@yahoo.com"
          },
          {
            "name": "MIHUT SRL",
            "address": "STR.BELLA BARTOK NR.4",
            "category": "B,C,D,CE",
            "telephone": "0745248597",
            "email": "gavril.mihut@yahoo.com"
          },
          {
            "name": "ANDREEAS & UCU SRL",
            "address": "PIATA LIBERTATII NR.1",
            "category": "B",
            "telephone": "0740182082",
            "email": "andreeasucu16@yahoo.com"
          },
          {
            "name": "SAN AUTO DRIVE SRL",
            "address": "STR.1 DECEMBRIE 1918 NR.16 BLOC A32 ",
            "category": "B",
            "telephone": "0751659411",
            "email": "sanautodrive@yahoo.ro"
          },
          {
            "name": "P.F.A.AVRAM IOAN",
            "address": "ALEEA GHIOCELULUI NR.7 AP.6 BECLEAN",
            "category": "B",
            "telephone": "0744304380",
            "email": "jeanvaljean1204@gmail.com"
          },
          {
            "name": "P.F.A.BUTA VASILE",
            "address": "STR.CODRULUI NR.28 BECLEAN",
            "category": "B",
            "telephone": "0744603663",
            "email": "jeanvaljean1204@gmail.com"
          }
        ]
      },
      {
        "city": "SANGEORZ-BAI",
        "schools": [
          {
            "name": "VIOHEBMAX SRL",
            "address": "STR.CARPATILOR NR.41B",
            "category": "B,C,CE",
            "telephone": "0263370795",
            "email": "viohebmax2004@yahoo.com"
          }
        ]
      },
      {
        "city": "SANGEORZ BAI",
        "schools": [
          {
            "name": "RELUX ON DUTY SRL",
            "address": "STR.SOMESULUI  NR.4",
            "category": "B",
            "telephone": "0263370366",
            "email": "aurel_carbune@yahoo.com"
          },
          {
            "name": "VIOHEBMAX DRIVE SRL",
            "address": "STR.CARPATILOR NR.41B",
            "category": "B",
            "telephone": "0744224948",
            "email": "viohebmax2004@yahoo.com"
          }
        ]
      },
      {
        "city": "COSBUC",
        "schools": [
          {
            "name": "P.F.A. MOLDOVAN ERNEST MARIUS",
            "address": "CALEA MOLDOVEI NR.15",
            "category": "B",
            "telephone": "0763016688",
            "email": "emoldovanmarius@yahoo.com"
          }
        ]
      },
      {
        "city": "REBRISOARA",
        "schools": [
          {
            "name": "P.F.A. BACHIS STEFAN",
            "address": "Sat Rebrisoara, Com. Rebrisoara Nr.187",
            "category": "B",
            "telephone": "0744165931",
            "email": "stefanbachis75@gmail.com"
          }
        ]
      },
      {
        "city": "DOBRIC",
        "schools": [
          {
            "name": "PROAUTO-NELU SRL",
            "address": "COM.CAIANU MIC SAT DOBRIC NR.1A",
            "category": "B",
            "telephone": "0744164836",
            "email": "scoalanelubot@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "BR",
    "schools": [
      {
        "city": "BRAILA",
        "schools": [
          {
            "name": "LECRISTRANS SRL",
            "address": "B-DUL.DOROBANTILOR BL.A40 PARTER",
            "category": "B",
            "telephone": "0742079203",
            "email": "nik.nicoleta_elegant@yahoo.com"
          },
          {
            "name": "AUTO TEST 2005 SRL",
            "address": "STR GRIVITEI NR.151",
            "category": "B,C,D,CE,AM",
            "telephone": "0741660611",
            "email": "autotest2005@yahoo.com"
          },
          {
            "name": "AUTO LUZ SRL",
            "address": "STR.INDEPENDENTEI BL.5 PARTER",
            "category": "B",
            "telephone": "0721372425",
            "email": "www.ionflorin1968@yahoo.com"
          },
          {
            "name": "DUMITIN TRANS SRL",
            "address": "STR.G-RAL.EREMIA GRIGORESCU NR.59 ",
            "category": "B",
            "telephone": "0745846975",
            "email": "cristianmusat72@yahoo.com"
          },
          {
            "name": "AUTO MOBILCRI SRL",
            "address": "BV.INDEPENDENTEI NR.16 SPATIUL ",
            "category": "B,AM",
            "telephone": "0745516782",
            "email": "cristianmusat72@yahoo.com"
          },
          {
            "name": "SAM PERMIS SRL",
            "address": "STR.PETRU MAIOR NR.29",
            "category": "B",
            "telephone": "0723521590",
            "email": "robert_isim@yahoo.com"
          },
          {
            "name": "AUTO RADONU SRL",
            "address": "STR.MIRCEA VODA NR.52A",
            "category": "A,B,BE,A1,A2",
            "telephone": "0722687420",
            "email": "scoaladesoferiautoradonu@yahoo.com"
          },
          {
            "name": "VALRAZ AUTO SRL",
            "address": "STR.1 DECEMBRIE 1918 BL.F3 PARTER",
            "category": "B,C,D,CE",
            "telephone": "0726182080",
            "email": "Valraz_auto@yahoo.com"
          },
          {
            "name": "AUTO SOFERILA SRL",
            "address": "B-DUL. AL.I.CUZA NR.162 CAM.1-4 ",
            "category": "B",
            "telephone": "0745506928",
            "email": "autosoferila@yahoo.com"
          },
          {
            "name": "COJANABIL SRL",
            "address": "Soseaua Ramnicu Sarat Nr.37 Lot 2",
            "category": "B",
            "telephone": "0758631487",
            "email": "cojandumitru48@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ANGHEL \nSALIGNY",
            "address": "STR.RUBINELOR NR.2",
            "category": "B",
            "telephone": "0239619779",
            "email": "dobrear@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC PANAIT \nISTRATI",
            "address": "STR.PLANTELOR NR.13",
            "category": "B",
            "telephone": "0239619321",
            "email": "marianamonamarcu@yahoo.com"
          },
          {
            "name": "PROLOADS TRANS SRL",
            "address": "B-DUL DOROBANTILOR   NR.604A  ET.1 ",
            "category": "B,C,D,CE",
            "telephone": "0339732947",
            "email": "scoala@proactivauto.ro"
          },
          {
            "name": "KELES SRL",
            "address": "STR.DOROBANTILOR NR.12",
            "category": "A,B,C,D,BE,CE,AM",
            "telephone": "0722306754",
            "email": "keles.nicu@yahoo.com"
          },
          {
            "name": "FRATII COM",
            "address": "STR.CALEA GALATI NR.315",
            "category": "B",
            "telephone": "0239610601",
            "email": "cristianmusat72@yahoo.com"
          },
          {
            "name": "GERUM COM SRL",
            "address": "STR.ROSIORILOR NR.431",
            "category": "A,B,C,CE",
            "telephone": "0723498276",
            "email": "scoalagerum@yahoo.com"
          },
          {
            "name": "AUTO PRUDENT",
            "address": "STR.GRIVITEI Nr.398",
            "category": "B,C,CE",
            "telephone": "0239623200",
            "email": "auto_prudent@yahoo.com"
          },
          {
            "name": "STAPAN PE VOLAN COMPANY SRL",
            "address": "STR.STEFAN CEL MARE NR.576",
            "category": "B,C,D,CE",
            "telephone": "0239618199",
            "email": "sc_asii_volanului@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC COSTIN D. \nNENITESCU",
            "address": "STR BUZAULUI NR.15 ",
            "category": "B",
            "telephone": "0735189119",
            "email": "carmen_dana_sandru@yahoo.com"
          },
          {
            "name": "ALIMI DRIVING SRL",
            "address": "ALEEA CUTEZATORILOR NR.4 CARTIER \nHIPODROM LOT 2 SP.COM.2",
            "category": "A,B,BE",
            "telephone": "0744766061",
            "email": "sc_asii_volanului@yahoo.com"
          },
          {
            "name": "ELYAMA DRIVE SRL",
            "address": "B-DUL DOROBANTILOR NR.513,LOT ",
            "category": "B",
            "telephone": "0767563835",
            "email": "elyamadrive@yahoo.com"
          },
          {
            "name": "P.F.A. NEGRAIA CONSTANTIN",
            "address": "CALEA GALATI NR. 346 CORP CAMERA 8",
            "category": "B",
            "telephone": "0745388139",
            "email": "negraia_mimi@yahoo.com"
          },
          {
            "name": "P.F.A. SITARU GEORGE",
            "address": "MIRCEA VODASTR.INDUSTRIEI NR.196 CARTIER ",
            "category": "B",
            "telephone": "0723318952",
            "email": "negraia_mimi@yahoo.com"
          },
          {
            "name": "VALY&RIANA AUTO SRL",
            "address": "PROGRESUL SPATIU COMERCIAL, \nBL.33A, AP.77",
            "category": "B",
            "telephone": "0757576554",
            "email": "valraz_auto@yahoo.ro"
          },
          {
            "name": "P.F.A. FRATILA DUMITRU LUCIAN",
            "address": "STR.ALBINEI NR.35 BL.A3 AP.8",
            "category": "B",
            "telephone": "0751253232",
            "email": "rbh.fratila@gmail.com"
          },
          {
            "name": "ROLAN DRIVE SRL",
            "address": "B-DUL.DOROBANTILOR NR.512 P+1",
            "category": "B",
            "telephone": "0743046664",
            "email": "rolandrive.braila@gmail.com"
          },
          {
            "name": "P.F.A.NASTASE O.VASILE",
            "address": "STR.SEBESULUI NR.71",
            "category": "A,B,A1,A2",
            "telephone": "0745375568",
            "email": "Ricanastsse@yahoo.com"
          },
          {
            "name": "ROVALRAZ PREST SRL",
            "address": "STR.1 DECEMBRIE 1918 NR.65A ",
            "category": "B,C,D",
            "telephone": "",
            "email": "valrazauto@yahoo.com"
          }
        ]
      },
      {
        "city": "IANCA",
        "schools": [
          {
            "name": "AUTO MAESTRII VOLANULUI SRL",
            "address": "STR.SARATENI NR.105",
            "category": "B",
            "telephone": "0743046664",
            "email": "maestriiv@yahoo.com"
          }
        ]
      },
      {
        "city": "INSURATEI",
        "schools": [
          {
            "name": "VALRAZ AUTO SRL",
            "address": "SOSEAUA BRAILEI NR.31 CONSTRUCTIA ",
            "category": "B",
            "telephone": "0726182080",
            "email": "valraz_auto@yahoo.ro"
          }
        ]
      }
    ]
  },
  {
    "state": "BT",
    "schools": [
      {
        "city": "DOROHOI",
        "schools": [
          {
            "name": "AUTOEXAM S.R.L.",
            "address": "STR.GEORGE ENESCU,NR.57",
            "category": "B",
            "telephone": "",
            "email": "gsulugiuc@yahoo.ro"
          },
          {
            "name": "DOMOSTAR SRL",
            "address": "STR.VASILE TELEGA NR.22",
            "category": "B",
            "telephone": "0740883218",
            "email": "domoncoioan@yahoo.com"
          },
          {
            "name": "BAXON IMPEX S.R.L.",
            "address": "STR.C.D.GHEREA,NR.64",
            "category": "B",
            "telephone": "0745670699",
            "email": "baxonsccoala@yahoo.com"
          },
          {
            "name": "FAHR.SCHULE HOR SRL",
            "address": "STR.LILIACULUI,NR.2",
            "category": "B",
            "telephone": "0744436885",
            "email": "scoaladesoferifs@yahoo.com"
          },
          {
            "name": "SIGUR LA VOLAN SRL",
            "address": "P. L. Strada CONSTANTIN DOBROGEANU ",
            "category": "B,C,Tr,CE",
            "telephone": "0330101051",
            "email": "scoaladesoferisv@yahoo.com"
          },
          {
            "name": "AUTOPIN-CATALIN SRL",
            "address": "Str. Grigore Ghica, Nr. 7, Etaj 2",
            "category": "B,BE",
            "telephone": "0745923606",
            "email": "catalinpintrijal@yahoo.com"
          },
          {
            "name": "WOLFVAL S.R.L.",
            "address": "STRADA GRIGORE GHICA,NR.7,ETAJ \n2,LOC DOROHOI,JUDET BOTOSANI",
            "category": "B",
            "telephone": "0747070668",
            "email": "monicos2103@yahoo.com"
          },
          {
            "name": "AUTO EXPRESS CIPRIAN S.R.L.",
            "address": "B-DUL VICTORIEI,NR.3-5,BIROUL ",
            "category": "B",
            "telephone": "0744436885",
            "email": "autoexpressciprian@yahoo.com"
          },
          {
            "name": "VEP AUTOVEP CORMIR S.R.L.",
            "address": "STR.GRIGORE GHICA,NR.7,BLADIREA CEC ",
            "category": "B",
            "telephone": "0744294343",
            "email": "vepautovep2018@gmail.com"
          }
        ]
      },
      {
        "city": "BOTOSANI",
        "schools": [
          {
            "name": "SEMAFORUL SRL",
            "address": "STR.PACEA,NR.47A",
            "category": "B",
            "telephone": "0740938779",
            "email": "scoalasemaforul@yahoo.com"
          },
          {
            "name": "URSA MARE S.R.L.",
            "address": "STR.PACEA,NR.49",
            "category": "B",
            "telephone": "0745600763",
            "email": "ursuleanufg@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC PETRU RARES",
            "address": "STR.CALUGARENI, NR.9",
            "category": "B,Tr",
            "telephone": "0231513686",
            "email": "nickk_mar@yahoo.com"
          },
          {
            "name": "PILOT SRL",
            "address": "STR.OCTAV ONICESCU NR.62B ",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0744808700",
            "email": "ernestchitu@yahoo.com"
          },
          {
            "name": "TRANSPORTURI AUTO SA",
            "address": "STR.I.I.C. BRATIANU NR.114",
            "category": "B",
            "telephone": "0231517036",
            "email": "tasabt@gmail.com"
          },
          {
            "name": "UNIVERSUM SRL",
            "address": "STR.CUZA VODA NR.8A",
            "category": "A,B,C,D,CE",
            "telephone": "0231533300",
            "email": "scoalauniversum@yahoo.com"
          },
          {
            "name": "AUTOVULCANIZARE S.R.L.",
            "address": "B-DUL MIHAI EMINESCU NR.15B",
            "category": "A,B,C,D,CE",
            "telephone": "0788656499",
            "email": "autovulcanizare@yahoo.com"
          },
          {
            "name": "TOTEX S.R.L.",
            "address": "STR.I.C. BRATIANU,NR.104",
            "category": "B,C,D,CE",
            "telephone": "0231533777",
            "email": "totex@totex.ro"
          },
          {
            "name": "BOGDAN PREST SRL",
            "address": "STR.CALEA NATIONALA,NR.58B",
            "category": "B,C,D,CE",
            "telephone": "0740999412",
            "email": "ciprianbbc@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC GHEORGHE \nASACHI",
            "address": "STR.PRIETENIEI,NR.7",
            "category": "B",
            "telephone": "0231584006",
            "email": "luminitafunduianu@yahoo.com"
          },
          {
            "name": "APETRANS S.R.L",
            "address": "STR.GARII.NR4,BLOC B5,ETAJ PARTER",
            "category": "A,B,C,D,BE,CE,A1",
            "telephone": "0741288363",
            "email": "scoala@apetrans.ro"
          },
          {
            "name": "MT SRL",
            "address": "STR.PENES CURCANUL,NR.1",
            "category": "B",
            "telephone": "0744857860",
            "email": "scmtsrl@yahoo.com"
          },
          {
            "name": "AUTOALFA LORILEX S.R.L",
            "address": "STR POD DE PIATRA,NR.29A.",
            "category": "B",
            "telephone": "0748233681",
            "email": "autoalfabotosani@yahoo.com"
          },
          {
            "name": "BEST AUTOMOTO DRIVING SRL",
            "address": "STR. IMPARAT TRAIAN,NR.27",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM,",
            "telephone": "0756041117",
            "email": "ernestchitu@yahoo.com"
          },
          {
            "name": "P.F.A. BALAN D. IONUT",
            "address": "ALEEA CALUGARENI, NR.13",
            "category": "B",
            "telephone": "0743599381",
            "email": "balanpfa@yahoo.com"
          },
          {
            "name": "P.F.A. MACOVEI FLORIN ",
            "address": "ALEEA CALUGARENI, NR.13",
            "category": "B",
            "telephone": "0740187457",
            "email": "florin29t@yahoo.com"
          },
          {
            "name": "AUTOBETA MARILEX SRL",
            "address": "STR. I.C. BRATIANU, NR.104, ET.1",
            "category": "B,C,CE",
            "telephone": "0748233681",
            "email": "cristian_simionica@yahoo.com"
          },
          {
            "name": "P.F.A. NATARASU GHEORGHE-\nCRISTINEL",
            "address": "DOAMNEI,COM.CURTESTI,STR. \nPRINCIPALA,NR.479 A",
            "category": "A,B,BE,A2",
            "telephone": "0740015425",
            "email": "cristi_natarasu@yahoo.com"
          },
          {
            "name": "P.F.A. SUSCIUC BACIU IONEL",
            "address": "ALEEA CALUGARENI, NR.13",
            "category": "B",
            "telephone": "0740563833",
            "email": "ionelsusciuc@gmail.com"
          }
        ]
      },
      {
        "city": "SENDRICENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC ALEXANDRU \nVLAHUTA",
            "address": "COM.SENDRICENI",
            "category": "B,Tr",
            "telephone": "0231613748",
            "email": "tudorbunduc@yahoo.com"
          }
        ]
      },
      {
        "city": "DARABANI",
        "schools": [
          {
            "name": "S.C.COM.AUTO.H. S.R.L.",
            "address": "STR. MUNCITORULUI, NR.2, ",
            "category": "B,C,D,CE",
            "telephone": "0740015352",
            "email": "horodincudorina@yahoo.com"
          },
          {
            "name": "AIRAHAZ S.R.L.",
            "address": "STR.CONSTANTIN STERE, NR.4",
            "category": "B",
            "telephone": "0745306038",
            "email": "airahaz1@yahoo.com"
          }
        ]
      },
      {
        "city": "COMUNA  MIHAI \nEMINESCU",
        "schools": [
          {
            "name": "P.F.A.PETRESCU CEZAR DUMITRU",
            "address": "SAT CATAMARASTI-DEAL,COMUNA \nMIHAI EMINESCU,",
            "category": "B",
            "telephone": "0756688939",
            "email": "pfa.petrescucezar@yahoo.com"
          }
        ]
      },
      {
        "city": "DACIA",
        "schools": [
          {
            "name": "STEL-COR AUTO DRIVING SRL-D",
            "address": "STR.TUDOR ",
            "category": "B",
            "telephone": "0746294261",
            "email": "steliandan2000@yahoo.com"
          }
        ]
      },
      {
        "city": "FLAMANZI",
        "schools": [
          {
            "name": "P.F.A. BALTUTA VASILE",
            "address": "STR.LANULUI,NR.17SAT MANASTIREA ",
            "category": "B",
            "telephone": "0740418815",
            "email": "baltuta_vasy@yahoo.com"
          },
          {
            "name": "SELUDAP TRANS SRL",
            "address": "CALEA NATIONALA,NR.28A",
            "category": "B",
            "telephone": "0752015112",
            "email": "seludaptrans@gmail.com"
          }
        ]
      },
      {
        "city": "VLASINESTI",
        "schools": [
          {
            "name": "P.F.A. COJOCARIU F. MIHAI",
            "address": "B-DUL MIHAI EMINESCU,NR.15B,ET.1",
            "category": "B",
            "telephone": "",
            "email": "cristi_natarasu@yahoo.com"
          }
        ]
      },
      {
        "city": "MANASTIREA \nDOAMNEI",
        "schools": [
          {
            "name": "P.F.A. ALEXA I.ONORICA ",
            "address": "ALEEA CALUGARENI, NR.13",
            "category": "B",
            "telephone": "0747705188",
            "email": "alexaono67@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "BV",
    "schools": [
      {
        "city": "BRASOV",
        "schools": [
          {
            "name": "BOSS ABC SRL",
            "address": "STR ALEXANDRU IOAN CUZA NR 37 ",
            "category": "B",
            "telephone": "0368008009",
            "email": "mihaela_adi17@yahoo.com"
          },
          {
            "name": "EXCEL AUTO O.E.D. S.R.L.",
            "address": "STR.TRAIAN NR.111 AP.1A",
            "category": "B,Tr",
            "telephone": "0722752418",
            "email": "exceloedauto@yahoo.com"
          },
          {
            "name": "LIGNA SERV SRL",
            "address": "STR.PRAHOVA , NR. 22",
            "category": "B",
            "telephone": "0745421483",
            "email": "laiutaignat@yahoo.com"
          },
          {
            "name": "P.F.A. GAVRILA IOAN",
            "address": "STR.BRANDUSELOR,NR.5,BL.2,SC.B,AP.1",
            "category": "B",
            "telephone": "0722656644",
            "email": "elgavrila@yahoo.com"
          },
          {
            "name": "PREMIAT AUTO SRL",
            "address": "STR MIRCEA CEL BATRAN NR 15 CLADIRE ",
            "category": "B",
            "telephone": "0742980755",
            "email": "nelu.prelipcianu@yahoo.com"
          },
          {
            "name": "P.F.A. NEGULICI VASILE",
            "address": "STR.PARAULUI NR.46,BL.F10,SC.A,AP.1,",
            "category": "B",
            "telephone": "0727756098",
            "email": "autoneva@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC TRANSILVANIA \nBRASOV",
            "address": "STR PANSELELOR NR 23",
            "category": "B,C1",
            "telephone": "0368408544",
            "email": "diaconugheorghe10@yahoo.com"
          },
          {
            "name": " OK SCHOOL SRL",
            "address": "STR. AVRAM IANCU NR. 9",
            "category": "B",
            "telephone": "0740143518",
            "email": "ovidiucostin@yahoo.com"
          },
          {
            "name": "NYM TRAVEL SRL",
            "address": "STR.APULUM NR.10",
            "category": "B",
            "telephone": "0743384479",
            "email": "scoaladesoferinoias@yahoo.com"
          },
          {
            "name": "LELIOARA PREST SRL",
            "address": "STR CASTELULUI NR 81",
            "category": "B",
            "telephone": "0740031308",
            "email": "mirica.mirica@yahoo.com"
          },
          {
            "name": "DATINFO IND SRL",
            "address": "STR. ZIZINULUI NR. 110",
            "category": "B",
            "telephone": "0727619170",
            "email": "office@tu-conduci.ro"
          },
          {
            "name": "AUTOSTART SA",
            "address": "STR.LUNGA NR.261",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0268441113",
            "email": "autostart91@yahoo.com"
          },
          {
            "name": "CASADI SRL",
            "address": "STR.MARGARITARELOR NR.16",
            "category": "B,C,D,CE",
            "telephone": "0268330662",
            "email": "casadibrasov1@yahoo.com"
          },
          {
            "name": "LA CODREANU SERVICII PUBLICE ",
            "address": "STR.AVRAM IANCU NR.114",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0268318099",
            "email": "centru_cod@yahoo.com"
          },
          {
            "name": "HEINZTEC SRL",
            "address": "STR.DR.DAVILA NR.1 AP.2",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0268420202",
            "email": "george.avram@scoaladesoferigeo.ro"
          },
          {
            "name": "PERFECT CLEAN SRL",
            "address": "STR.VULCAN NR.41",
            "category": "B",
            "telephone": "0724377871",
            "email": "florin.zdraila@yahoo.com"
          },
          {
            "name": "CONVEST COMPANY SRL",
            "address": "STR.ZIZINULUI NR.119 BL.OBIECTIV 5 ",
            "category": "A,B,C,D,Tr,BE,CE,A1,B1,A",
            "telephone": "0268332094",
            "email": "convestcompany@yahoo.com"
          },
          {
            "name": "ACR AUTO TURISM S.R.L.",
            "address": "STR.CALEA BUCURESTI , NR.68 , BL.A10 , ",
            "category": "B",
            "telephone": "0745328738",
            "email": "acr_bv@acr.ro"
          },
          {
            "name": "ACADEMIA FORTELOR AERIENE \n`HENRI COANDA` UM 1932",
            "address": "STR MIHAI VITEAZUL NR 160",
            "category": "B",
            "telephone": "0268423421",
            "email": "scoalasoferiafahc@yahoo.com"
          },
          {
            "name": "BOSS TRANS ABC SRL",
            "address": "STR 13 DECEMBRIE NR 94 CAMERA ",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0730496606",
            "email": "contact@scoalabossbrasov.ro"
          },
          {
            "name": "COLEGIUL TEHNIC DE \nTRANSPORTURI BRASOV",
            "address": "STR FUNDATURA HARMANULUI NR 12",
            "category": "B,C,CE",
            "telephone": "0268335700",
            "email": "ctf.brasov@gmail.com"
          },
          {
            "name": "S.D.S. AUTOMOBILE SRL",
            "address": "STR ALEXANDRU IOAN CUZA NR 37 ET 1",
            "category": "B",
            "telephone": "0729969993",
            "email": "dan@sdsautomobile.ro"
          },
          {
            "name": "GBX EDUCATION SRL",
            "address": "B-DUL GRIVITEI NR.33 PARTER",
            "category": "B",
            "telephone": "0722221768",
            "email": "gabixeducation@gmail.com"
          },
          {
            "name": "ALIART CONSULT SRL",
            "address": "B-DUL. 15 NOIEMBRIE, NR.92, SC. D, AP. ",
            "category": "B",
            "telephone": "0744900485",
            "email": "aliartconsult@gmail.com"
          },
          {
            "name": "FOCUS DEFENSIVE SRL-D",
            "address": "STR BISERICII ROMANE NR 86 ",
            "category": "B",
            "telephone": "0743771545",
            "email": "lechea.alex@yahoo.com"
          },
          {
            "name": "PROFANTAL S.R.L.",
            "address": "STR LUNGA NR 92CALEA FAGARASULUI NR 29 SPATIUL E6 ",
            "category": "B",
            "telephone": "0727387311",
            "email": "scoalaautoantal@gmail.com"
          },
          {
            "name": "DC AUTOROM GLOBAL SRL",
            "address": "COMPLEX COMERCIAL COME&GO \nSTATIA TERMINUS DE CALATORI ",
            "category": "B",
            "telephone": "0747170789",
            "email": "dcautorom@gmail.com"
          },
          {
            "name": " P.F.A. MIHAI (NICULAE) GABRIEL",
            "address": "STR. CARPATILOR NR.89 ET.1 CAM.9",
            "category": "B",
            "telephone": "0722221768",
            "email": "instructorautobrasov@gmail.com"
          },
          {
            "name": "PFA RAILEANU MARIUS DAN",
            "address": "STR. G-RAL MOCIULSCHI NR.26 BL.G9 ",
            "category": "B",
            "telephone": "0722785758",
            "email": "mariusraileanu999@yahoo.com"
          },
          {
            "name": "P.F.A. BANCIU FLORIN CRISTIAN",
            "address": "STR CARAMIDARIEI NR 3 CAMERA 4",
            "category": "B",
            "telephone": "0744877717",
            "email": "bimbialiflo@yahoo.com"
          },
          {
            "name": "P.F.A. PANDRA VASILE",
            "address": "STR.CALEA FAGARASULUI NR.29 SPATIUL \nP9 COMPLEX COMERCIAL COME&GO ",
            "category": "B",
            "telephone": "0744343894",
            "email": "vasilepandra@yahoo.com"
          },
          {
            "name": "P.F.A. MATIFALEAN LIVIU FLORIN",
            "address": "STR CARAMIDARIEI NR 3 BIROUL 4",
            "category": "B",
            "telephone": "0745087079",
            "email": "matifalean.liviu@yahoo.com"
          },
          {
            "name": "P.F.A. AURSULESEI DANUT",
            "address": "STR FANTANII NR 20 ",
            "category": "B",
            "telephone": "0745160552",
            "email": "aursulesei_danut@yahoo.com"
          },
          {
            "name": "BOSS TRANS ABC SRL",
            "address": "STR.CALEA FAGARASULUI NR.29 SPATIUL \nE20 COMPLEX COMERCIAL COME&GO",
            "category": "B",
            "telephone": "0721327162",
            "email": "contact@scoalabossbrasov.ro"
          },
          {
            "name": "P.F.A. DIACONU P. GHEORGHE",
            "address": "STR. FREZIEI, NR. 11, BL. 11, SC. A, ET. 1, ",
            "category": "B",
            "telephone": "0745251131",
            "email": "diaconugheorghe10@yahoo.com"
          },
          {
            "name": "P.F.A. COTEANU (PETRU) CRISTIAN",
            "address": "STR CARPENULUI NR 1C SP 1 BL A3C ET ",
            "category": "B",
            "telephone": "0745134737",
            "email": "matiz73yna@yahoo.com"
          },
          {
            "name": "PILOT ABC BRASOV SRL",
            "address": "STR IZVORUL RECE NR 7 AP 2D",
            "category": "B",
            "telephone": "0744309484",
            "email": "fedrabv@yahoo.com"
          },
          {
            "name": "AUTO ECODRIVE S.R.L.",
            "address": "STR DOBROGEA NR 38 BIROU 3",
            "category": "B",
            "telephone": "0746046966",
            "email": "monica.prelipcianu@yahoo.com"
          },
          {
            "name": "P.F.A. HANDRA F. FRANCISC",
            "address": "CALEA FAGARASULUI, NR. 29B, SPATIUL \nE4, COMPLEXUL COMERCIAL COME&GO",
            "category": "A,B,A1,A2",
            "telephone": "0742660651",
            "email": "contact@instructorultau.ro"
          },
          {
            "name": "L.S. DRIVING SOLUTION S.R.L.",
            "address": "STR MOLDOVEI NR 37 ETAJ PARTER",
            "category": "A,B,A2",
            "telephone": "0742938297",
            "email": "liviu@lsacademy.ro"
          },
          {
            "name": "MOTO INSTRUCTORUL TAU S.R.L.",
            "address": "CAL. FAGARASULUI NR 29 SP. E9 \nCOMPLEX COME&GO ST. TERMINUS ",
            "category": "B,BE",
            "telephone": "0742660651",
            "email": "contact@instructorultau.ro"
          }
        ]
      },
      {
        "city": "RUPEA",
        "schools": [
          {
            "name": "HAIDUC-AUTO SRL",
            "address": "STR REPUBLICII NR 107 CAM 4",
            "category": "B,BE,B1",
            "telephone": "0740190143",
            "email": "haiduc_gabriel@yahoo.com"
          }
        ]
      },
      {
        "city": "FAGARAS",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC  DR. IOAN \nSENCHEA",
            "address": "STR.VLAD TEPES NR.11B",
            "category": "B",
            "telephone": "0268211933",
            "email": "gr_sc_senchea@yahoo.com"
          },
          {
            "name": "NOVITA COM SRL",
            "address": "STR.NICOVALEI NR.19",
            "category": "B,C,CE",
            "telephone": "0268218828",
            "email": "ciprian_beches@yahoo.com"
          },
          {
            "name": "SIDIOREX SRL",
            "address": "STR.D-NA STANCANR.62",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0744563464",
            "email": "carmenpopescu60@yahoo.com"
          },
          {
            "name": "STIRBU SGM SRL",
            "address": "SOSEAUA COMBINATULUI NR 7",
            "category": "B",
            "telephone": "0765345736",
            "email": "cosma_sgm@yahoo.com"
          },
          {
            "name": "P.F.A. GRECU GHEORGHE",
            "address": "STR.DOAMNA STANCA NR.62",
            "category": "B",
            "telephone": "0744536665",
            "email": "grecu_skif@yahoo.ro"
          },
          {
            "name": "P.F.A. PANDREA VIOREL",
            "address": "STR.DOAMNA STANCA NR.62",
            "category": "B",
            "telephone": "0744299528",
            "email": "viopandrea59@gmail.com"
          },
          {
            "name": "P.F.A. COVALIU I. MIRCEA",
            "address": "STR. D-NA STANCA, NR.62",
            "category": "B",
            "telephone": "0744563464",
            "email": "mircea.cov@yahoo.com"
          },
          {
            "name": "P.F.A. COVALIU IULIAN",
            "address": "STR. D-NA STANCA, NR.62",
            "category": "B",
            "telephone": "0767864116",
            "email": "iuliancovaliu72@gmail.com"
          },
          {
            "name": "P.F.A. SASU DANIEL GHEORGHE",
            "address": "STR. DOAMNA STANCA, NR. 62",
            "category": "B",
            "telephone": "0722998505",
            "email": "danysasu@gmail.com"
          },
          {
            "name": "P.F.A. TAROPA CORNEL ROMEO",
            "address": "STR DOAMNA STANCA NR 62",
            "category": "B",
            "telephone": "0747767045",
            "email": "andrada.marin@gmail.com"
          },
          {
            "name": "ROSCRIS IMPEX SRL",
            "address": "STR DOAMNA STANCA NR 8-10",
            "category": "B",
            "telephone": "0751399853",
            "email": "scoaladesoferi.roscris@yahoo.ro"
          }
        ]
      },
      {
        "city": "PREJMER",
        "schools": [
          {
            "name": "COLEGIUL AIA`TARA BARSEI` ",
            "address": "STR.BRASOVULUI NR.160A",
            "category": "B",
            "telephone": "0735532570",
            "email": "colegiul_prejmer@yahoo.com"
          },
          {
            "name": "ABC DRIVE SCHOOL S.R.L.",
            "address": "STR MARE NR 647A CAMERA 1 ",
            "category": "A,B,A2",
            "telephone": "0721327162",
            "email": "contact@scoalabossbrasov.ro"
          }
        ]
      },
      {
        "city": "CODLEA",
        "schools": [
          {
            "name": "IONLAZ COM SRL",
            "address": "STR.MUNTISOR (SPATIUL COMERCIAL ",
            "category": "B",
            "telephone": "0746416782",
            "email": "ionlazcom@yahoo.com"
          },
          {
            "name": "ROBETO COM SRL",
            "address": "STR HALCHIULUI NR 6",
            "category": "B,C,CE",
            "telephone": "0268254498",
            "email": "robetocom@yahoo.com"
          }
        ]
      },
      {
        "city": "ZARNESTI",
        "schools": [
          {
            "name": "LUTEA AUTO SRL",
            "address": "STR.13 DECEMBRIE NR.18",
            "category": "B",
            "telephone": "0746815839",
            "email": "lutea_alin@yahoo.com"
          }
        ]
      },
      {
        "city": "SACELE",
        "schools": [
          {
            "name": "BELL BIT SRL",
            "address": "STR AL.I. LAPEDATU NR 7 ",
            "category": "B",
            "telephone": "0744565899",
            "email": "bell.bit2014@gmail.com"
          },
          {
            "name": "BOGDY FYTYPALDY SRL",
            "address": "BLD. BRASOVULUI NR 9 CAMERELE 1-3",
            "category": "B",
            "telephone": "0756673577",
            "email": "bogdyfytypaldy@gmail.com"
          },
          {
            "name": "T.S.O. AUTOMOBILE SRL",
            "address": "STR ARMATA ROMANA NR 11",
            "category": "B",
            "telephone": "0742186153",
            "email": "e_tabarcea@yahoo.com"
          }
        ]
      },
      {
        "city": "CRISTIAN",
        "schools": [
          {
            "name": "DUVENBECK LOGISTIK SRL",
            "address": "ALEEA DUVENBECK NR.1",
            "category": "B,C,CE",
            "telephone": "0268507800",
            "email": "atbratu@duvenbeck.de"
          }
        ]
      },
      {
        "city": "RASNOV",
        "schools": [
          {
            "name": "AUTO MALINA SRL",
            "address": "STR.CARAIMAN NR.28",
            "category": "B",
            "telephone": "0268231748",
            "email": "automalina05@gmail.com"
          },
          {
            "name": "P.F.A. NICOLAE STELIAN",
            "address": "DN 1 KM 175+250M SHOWROOM TESS, \nLOC. GHIMBAV, JUD. BRASOV",
            "category": "B",
            "telephone": "0722355401",
            "email": "ale.popa@yahoo.com"
          }
        ]
      },
      {
        "city": "GHIMBAV",
        "schools": [
          {
            "name": "P.F.A. VINTILA IOAN",
            "address": "DN 1 KM 175+250, F.N.",
            "category": "B",
            "telephone": "0721167857",
            "email": "vintilaioan@yahoo.com"
          },
          {
            "name": "P.F.A. BORDEA IOAN",
            "address": "DN 1 KM 175+250, F.N.",
            "category": "B",
            "telephone": "0722777249",
            "email": "bordeaiancu@gmail.com"
          },
          {
            "name": "P.F.A. DARIE VASILE",
            "address": "DN 1, KM 175 + 250, F.N.",
            "category": "B",
            "telephone": "0745045427",
            "email": "INSRUCTOR.DARIE.@YAHO.COM."
          }
        ]
      },
      {
        "city": "HOMOROD",
        "schools": [
          {
            "name": "P.F.A. SIMU ALIN NICOLAE",
            "address": "STR PRINCIPALA NR 22",
            "category": "B",
            "telephone": "0721611561",
            "email": "simualin@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "BZ",
    "schools": [
      {
        "city": "BUZAU",
        "schools": [
          {
            "name": "BRATILA SRL",
            "address": "STR.VICTORIEI NR.65",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0238726789",
            "email": "scoalabratila@yahoo.com"
          },
          {
            "name": "PILOTAJ PROF S.R.L.",
            "address": "STR. ALEXANDRU MARGHILOMAN NR.28",
            "category": "B,C,D,CE",
            "telephone": "0723665570",
            "email": "pilotajprof@yahoo.com"
          },
          {
            "name": "DNL GRUP SRL",
            "address": "STR. BUCEGI, NR. 18A",
            "category": "B",
            "telephone": "0765098945",
            "email": "dnlgrup@yahoo.com"
          },
          {
            "name": "ALEXFLO 2005 SRL",
            "address": "STR. COL. ION BUZOIANU NR.48",
            "category": "B,C,CE",
            "telephone": "0722731991",
            "email": "asiivolanului@gmail.com"
          },
          {
            "name": "CENTRUL DE FORMARE SI \nCONSULTANTA PRIMA SCOOL SRL",
            "address": "STR. PLEVNEI NR.2A",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM",
            "telephone": "0238721779",
            "email": "centruldeformaresiconsultanta@yahoo.com"
          },
          {
            "name": "ANDRUTU S.R.L.",
            "address": "STR.VICTORIEI, NR.73, INCINTA SCOLII, ",
            "category": "B",
            "telephone": "0726856063",
            "email": "instructorautobuzau@yahoo.com"
          },
          {
            "name": "DRIVE PROF S.R.L.",
            "address": "BULEVARDUL REPUBLICII, NR.4D",
            "category": "B,C,CE",
            "telephone": "0735175833",
            "email": "scdryveprof@yahoo.com"
          },
          {
            "name": "FAVORIT DRIVER SRL",
            "address": "STR. DIMINETII NR.6",
            "category": "B",
            "telephone": "0762219466",
            "email": "favorit.driver@gmail.com"
          },
          {
            "name": "AMAT UTIL TRANSPORT S.R.L.",
            "address": "STR. ALEEA SCOLILOR, NR.3, INCINTA ",
            "category": "B",
            "telephone": "0744875065",
            "email": "catrinelghita@yahoo.ro"
          },
          {
            "name": "MASTER COM SRL",
            "address": "B-dul UNIRII, INCINTA NR. 11, UNITATEA \nELECTROSERVICE, BL.P8, PARTER",
            "category": "B",
            "telephone": "0744680942",
            "email": "scoalamstercom@yahoo.com"
          },
          {
            "name": "ENACHE AUTO SERV SRL",
            "address": "ALEEA SCOLILOR, INCINTA SCOLII CU ",
            "category": "B",
            "telephone": "0722727626",
            "email": "enache_gef@yahoo.com"
          },
          {
            "name": "UFO TRANS SRL",
            "address": "STR.LUPENI NR.88",
            "category": "B",
            "telephone": "0744157861",
            "email": "ufotrans@yahoo.com"
          },
          {
            "name": "STANCIU ASCF SRL",
            "address": "ALEEA TEILOR NR.10",
            "category": "B",
            "telephone": "0745802251",
            "email": "stanciu.ascf@gmail.com"
          },
          {
            "name": "LICEUL AGRICOL DR. CONSTANTIN \nANGELESCU",
            "address": "STR.CRIZANTEMELOR NR.17",
            "category": "B",
            "telephone": "0238717519",
            "email": "georgeta_op@yahoo.com"
          },
          {
            "name": "PFA IRIMIA GHEORGHE",
            "address": "STR. VICTORIEI, INCINTA SCOLII \nGIMNAZIALE \"ION CREANGA\" MICRO 5",
            "category": "B",
            "telephone": "0741024783",
            "email": "irimiadrive@.com"
          },
          {
            "name": "PFA FLOREA C. GABRIEL",
            "address": "STR. SIMILA ,NR. 46",
            "category": "B",
            "telephone": "0724311628",
            "email": ""
          },
          {
            "name": "LICEUL TEHNIC BUZAU",
            "address": "ALEEA INDUSTRIILOR NR.5",
            "category": "B",
            "telephone": "0238723982",
            "email": "coltehbz@yahoo.com"
          },
          {
            "name": "BRATILA S.R.L.",
            "address": "STR. VICTORIEI NR. 63",
            "category": "B",
            "telephone": "0238726789",
            "email": "scoalabratila@yahoo.com"
          },
          {
            "name": "KING DRIVE BC SRL",
            "address": "STR. HORTICOLEI NR. 50",
            "category": "B,C,D,CE",
            "telephone": "0748216058",
            "email": "kingdrive2014@yahoo.com"
          },
          {
            "name": "PUBLIC CREATION S.R.L.",
            "address": "STR. BRAILEI, NR. 6",
            "category": "B,Tr,BE",
            "telephone": "0740301150",
            "email": "publicsolar@yahoo.com"
          },
          {
            "name": "PFA GROSARU NECULAI ",
            "address": "STR. VICTORIEI NR.63",
            "category": "B",
            "telephone": "0723436311",
            "email": "valentina2007bz@yahoo.com"
          },
          {
            "name": "PFA NEGOITA NECULAI",
            "address": "STR. VICTORIEI NR.63",
            "category": "B",
            "telephone": "0741201099",
            "email": "aura_munteanu@yahoo.com"
          },
          {
            "name": "PFA PASCU VALENTIN",
            "address": "STR. VICTORIEI NR.63",
            "category": "A,B,A2",
            "telephone": "0767328350",
            "email": "pascu.valentin77@yahoo.com"
          },
          {
            "name": "PFA CIOCAN M NICOLAE",
            "address": "STR. VICTORIEI NR.63",
            "category": "B",
            "telephone": "0766861818",
            "email": "valentina2007bz@yahoo.com"
          },
          {
            "name": "PFA BURLACU GHEORGHE",
            "address": "STRADA TURDEI, NR.15",
            "category": "B",
            "telephone": "0745086804",
            "email": "gheorgheburlacu423@yahoo.com"
          },
          {
            "name": "PFA PANAIT GHE SORIN-DAN",
            "address": "STR. TURDEI NR.15",
            "category": "B",
            "telephone": "0732400177",
            "email": "panait1977@gmail.com"
          },
          {
            "name": "PFA POPA A GABRIEL",
            "address": "STR. TURDEI NR.15",
            "category": "B",
            "telephone": "",
            "email": "gabrielpopa1791@gmail.com"
          },
          {
            "name": "PFA ROMAN GHE DANIEL",
            "address": "CARTIERUL BROSTENI, BLOC K5, SCARA ",
            "category": "B",
            "telephone": "0767873726",
            "email": "danroman76@yahoo.com"
          },
          {
            "name": "C.P.P. RED CAR S.R.L.",
            "address": "B-DUL STADIONULUI NR.58",
            "category": "B,C,CE",
            "telephone": "0743174947",
            "email": "florin_wmw17@yahoo.com"
          },
          {
            "name": "GABI & TAVI TRANS LUX SRL",
            "address": "STR. 1 DECEMBRIE 1918, NR. 12, CORP ",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0722214887",
            "email": "dimagabriel61@yahoo.com"
          },
          {
            "name": "ALIS AUTO SCHOOL S.R.L.",
            "address": "STR. TRANSILVANIEI, NR. 98, ETAJ",
            "category": "B",
            "telephone": "0760972268",
            "email": "Alisautoschool@yahoo.com"
          },
          {
            "name": "DRIVING SCHOOL ELERON S.R.L.",
            "address": "STRADA COMETEI, NR.63, CONSTRUCTIA ",
            "category": "B",
            "telephone": "0762370008",
            "email": "stroe_valentin88@yahoo.com"
          }
        ]
      },
      {
        "city": "RAMNICU SARAT",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC VICTOR \nFRUNZA",
            "address": "STR.TOAMNEI NR.4",
            "category": "B",
            "telephone": "0723689881",
            "email": "ifrim_victoria@yahoo.ro"
          },
          {
            "name": "AUTO MYH SERV 07 SRL",
            "address": "STR. TOAMNEI NR. 4",
            "category": "B",
            "telephone": "0745999216",
            "email": "auto_myh@yahoo.com"
          },
          {
            "name": "IMBUS COMPROD SRL",
            "address": "STR.CONSTANTIN BRANCOVEANU NR.40",
            "category": "B",
            "telephone": "0722652929",
            "email": "toma_adina_2007@yahoo.com"
          },
          {
            "name": "VARIABIL CONTUR S.R.L.",
            "address": "STRADA CONSTANTIN BRÂNCOVEANU, ",
            "category": "B",
            "telephone": "0764364397",
            "email": "plopeanup@yahoo.com"
          },
          {
            "name": "IONALIS S.R.L.",
            "address": "STR. TUDOR VLADIMIRESCU, NR.23",
            "category": "B",
            "telephone": "0766862988",
            "email": "dunutaionut@gmail.com"
          },
          {
            "name": "SYDERAL SEEN DRIVE SRL",
            "address": "STR. MIHAIL KOGALNICEANU BL.B3 ",
            "category": "B",
            "telephone": "0764462523",
            "email": "syderalseendrive@gmail.com"
          },
          {
            "name": "STR DEEA DRIVER S.R.L.",
            "address": "RAMNICU SARAT STR. PRINCIPELE ",
            "category": "B,C,BE,CE",
            "telephone": "0721667955",
            "email": "babei_ramona@yahoo.com"
          },
          {
            "name": "TOPP ANDURANT DRIVER S.R.L.",
            "address": "STRADA CONSTANTIN BRANCOVEANU, ",
            "category": "B",
            "telephone": "0764364397",
            "email": "plopeanup@yahoo.com"
          }
        ]
      },
      {
        "city": "MARACINENI",
        "schools": [
          {
            "name": "YUL PILOTAJ SRL",
            "address": "STR.FOCSANEI NR.18",
            "category": "B",
            "telephone": "0744175185",
            "email": "yulpilotajsrl@yahoo.com"
          },
          {
            "name": "PFA MOCANU GABI CONSTANTIN",
            "address": "SAT MARACINENI, STR. SOARELUI ,NR. ",
            "category": "B",
            "telephone": "0764021255",
            "email": "gabiabc97@yahoo.com"
          },
          {
            "name": "PFA ANDREI VASILE",
            "address": "STR. SOARELUI NR.13",
            "category": "B",
            "telephone": "0745160735",
            "email": "andreivasilepfa@gmail.com"
          }
        ]
      },
      {
        "city": "MEREI",
        "schools": [
          {
            "name": "AHA DRIVE S.R.L.",
            "address": "SAT LIPIA NR.336",
            "category": "B",
            "telephone": "0765635125",
            "email": "andreihorjea@yahoo.com"
          },
          {
            "name": "PFA DINU SERAFIM MIHAELA",
            "address": "SAT LIPIA, NR. 205B",
            "category": "B",
            "telephone": "0766222265",
            "email": "dinu1971mihaela@yahoo.com"
          }
        ]
      },
      {
        "city": "VERNESTI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC VERNESTI",
            "address": "VERNESTI",
            "category": "B",
            "telephone": "0238700370",
            "email": "iraducea@yahoo.com"
          },
          {
            "name": "MILLENIUM TRANS S.R.L.",
            "address": "SAT. VERNESTI NR. CADASTRAL 1041",
            "category": "B,C,D,CE",
            "telephone": "0743753015",
            "email": "camibulai@yahoo.com"
          }
        ]
      },
      {
        "city": "SIBICIU DE JOS",
        "schools": [
          {
            "name": "PFA GHINEA CONSTANTIN",
            "address": "SAT SIBICIU DE JOS, NR.14",
            "category": "B",
            "telephone": "0767084558",
            "email": "pfa_ghineaconstantin@yahoo.com"
          }
        ]
      },
      {
        "city": "NEHOIU",
        "schools": [
          {
            "name": "PFA PETROIU I. FLORIN",
            "address": "STR. ION ANDREESCU, NR. 3",
            "category": "B",
            "telephone": "0740097295",
            "email": "petroiu.florin71@yahoo.com"
          }
        ]
      },
      {
        "city": "Nehoiu",
        "schools": [
          {
            "name": "PFA GAVRILOIU CONSTANTIN",
            "address": "Str. 1 Decembrie 1918, Zona Centrala",
            "category": "B",
            "telephone": "0744820965",
            "email": ""
          }
        ]
      },
      {
        "city": "SMEENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC AGRICOL \nSMEENI",
            "address": "STR.PRINCIPALA NR.677",
            "category": "B",
            "telephone": "0238532507",
            "email": "grupscsmeeni@yahoo.com"
          }
        ]
      },
      {
        "city": "BOBOC",
        "schools": [
          {
            "name": "SCOALA MILITARA A FORTELOR \nAERIENE TRAIAN VUIA",
            "address": "BOBOC",
            "category": "B",
            "telephone": "0238727674",
            "email": "amariusgabi@yahoo.com"
          }
        ]
      },
      {
        "city": "GLODEANU \nSILISTEA",
        "schools": [
          {
            "name": "PFA GHITA ADRIAN",
            "address": "SAT CIRLIGU MIC NR.44",
            "category": "B",
            "telephone": "0768815523",
            "email": "adrianghita08@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "CJ",
    "schools": [
      {
        "city": "GHERLA",
        "schools": [
          {
            "name": "AMD AUTO SRL",
            "address": "STR. 1 DECEMBRIE 1918 NR. 20, LICEUL ",
            "category": "B",
            "telephone": "0745314835",
            "email": "amd.auto@yahoo.com"
          },
          {
            "name": "MOTO GHIRAN SRL",
            "address": "STR. 1 DECEMBRIE 1918, NR. 4, Corp C, ",
            "category": "B",
            "telephone": "0764477455",
            "email": "motoghiran@gmail.com"
          },
          {
            "name": "DORAND & A SRL",
            "address": "STR. 1 DECEMBRIE 1918, NR.18",
            "category": "A,B,C,D,BE,CE,B1",
            "telephone": "0746257611",
            "email": "farcasdorin@yahoo.com"
          },
          {
            "name": "ELVEST AUTO DRIVE SRL",
            "address": "STR. PRIMAVERII NR. 6",
            "category": "B",
            "telephone": "0745114942",
            "email": "elvestauto@yahoo.com"
          },
          {
            "name": "PROMOAUTO INVEST SRL",
            "address": "STR. ALEXANDRU VLAHUTA NR. 17A",
            "category": "B",
            "telephone": "0747348281",
            "email": "promoautoinvest@gmail.com"
          },
          {
            "name": "ROYAL AUTO DRIVE SRL",
            "address": "STR.CLUJULUI NR. 2A, BL. D3, AP.65/1/V",
            "category": "B",
            "telephone": "0756592899",
            "email": "eduardfarcas21@yahoo.com"
          }
        ]
      },
      {
        "city": "TURDA",
        "schools": [
          {
            "name": "ART COND AUTO SRL",
            "address": "STR. RAPSODIEI NR. 1, ET. 1",
            "category": "B",
            "telephone": "0755999595",
            "email": "artcondauto@gmail.com"
          },
          {
            "name": "MISIANA AUTO SRL",
            "address": "STR. ALEXANDRU IOAN CUZA NR. 17",
            "category": "B",
            "telephone": "0741245769",
            "email": "misiana2001@yahoo.com"
          },
          {
            "name": "TRIF TRANS SRL",
            "address": "STR. STADIONULUI NR. 14/I/2",
            "category": "A,B,C,D,CE,A1",
            "telephone": "0744404943",
            "email": "triftrans_turda@yahoo.com"
          },
          {
            "name": "CABRIOLET SRL",
            "address": "STR. GHEORGHE LAZAR NR. 2",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0264/314136",
            "email": "turda_cabriolet@yahoo.com"
          },
          {
            "name": "HOREA TURDA SRL",
            "address": "STR. STADIONULUI NR. 14",
            "category": "B",
            "telephone": "0744404943",
            "email": "triftrans_turda@yahoo.com"
          },
          {
            "name": "CATALINA DRIVE SCHOOL SRL",
            "address": "STR. GEORGE COSBUC NR. 2, CAMERA 5, ",
            "category": "B",
            "telephone": "0748977383",
            "email": "catalinadriveschool@yahoo.com"
          },
          {
            "name": "P.F.A. CANILA GHEORGHE CEZAR ",
            "address": "STR. LOTUS NR. 6, BL. A9, SC. D, AP. 35",
            "category": "B",
            "telephone": "0761640120",
            "email": "kanighe@yahoo.com"
          },
          {
            "name": "P.F.A. TODEA CIPRIAN MIHAI ",
            "address": "STR. LOTUS NR. 40, SC. B, ET. IV, AP. 39",
            "category": "B",
            "telephone": "0742093997",
            "email": "todeaciprianmihai@yahoo.com"
          },
          {
            "name": "PEGASUS TEAM SRL",
            "address": "STR.REPUBLICII, NR.8, AP.10 ",
            "category": "A,B,A1,A2",
            "telephone": "0744479212",
            "email": "pegasusturda@gmail.com"
          },
          {
            "name": "TRIF AUTO INTER SRL",
            "address": "STR. STADIONULUI NR. 14, CORP ",
            "category": "B",
            "telephone": "0743464926",
            "email": ""
          }
        ]
      },
      {
        "city": "CLUJ-NAPOCA",
        "schools": [
          {
            "name": "AUTO FORMULA D SOFERI SRL",
            "address": "STR. TRAIAN NR.73",
            "category": "B",
            "telephone": "0747495345",
            "email": "tigau.gheoeghe@yahoo.com"
          },
          {
            "name": "AUTO CADET SRL",
            "address": "STR. BUCURESTI NR. 77",
            "category": "B",
            "telephone": "0744129908",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "SAROM IMPEX SRL",
            "address": "STR ODOBESTI NR 2",
            "category": "B",
            "telephone": "0744134225",
            "email": "sarom_impex@yahoo.com"
          },
          {
            "name": "P.F.A. STOICA S OLIVIU",
            "address": "STR. EMIL A. DANDEA NR. 5",
            "category": "B",
            "telephone": "0744251380",
            "email": "oliviu_stoica@yahoo.com"
          },
          {
            "name": "P.F.A. NAGY M LADISLAU",
            "address": "STR. I. L. CARAGIALE NR. 2-8, SC. 2, ET. P, ",
            "category": "B",
            "telephone": "0751626957",
            "email": ""
          },
          {
            "name": "P.F.A. BAIAS GRIGORE",
            "address": "STR. ALEEA PEANA NR. 7, SC. 2, ET. 4, ",
            "category": "B",
            "telephone": "0747017234",
            "email": "mariusgcioca@live.com"
          },
          {
            "name": "P.F.A. MEZEI VALERIU",
            "address": "STR. FANTANELE NR. 55, AP. 59",
            "category": "B",
            "telephone": "0740863693",
            "email": ""
          },
          {
            "name": "P.F.A. SUARASAN IOAN CALIN",
            "address": "STR. FAGULUI NR. 32, ET. 1, AP. 7",
            "category": "B",
            "telephone": "0745311722",
            "email": "calin.suarasan@yahoo.com"
          }
        ]
      },
      {
        "city": "CLUJ NAPOCA",
        "schools": [
          {
            "name": "RODNA TRANS SRL",
            "address": "STR. BARBU LAUTARU NR. 1",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0744795466",
            "email": "scoalasoferi@rodna-trans.ro"
          },
          {
            "name": "NIKO AUTOSTAR SRL",
            "address": "STR. REPUBLICII NR. 7, BL.CORPUL II, ",
            "category": "B",
            "telephone": "0742930412",
            "email": "scoalaniko@yahoo.com"
          },
          {
            "name": "CRINDEL SRL",
            "address": "STR. SANATORULUI NR. 33",
            "category": "B",
            "telephone": "0745520989,",
            "email": "delia_romanti@yahoo.com"
          },
          {
            "name": "SCOALA DE AGENTI DE POLITIE \nSEPTIMIU MURESAN",
            "address": "STR. CORNELIU COPOSU NR. 89-91",
            "category": "B",
            "telephone": "0744774237",
            "email": "scoala.cluj@cj.politiatomana.ro"
          },
          {
            "name": "CIO CLEO SRL",
            "address": "STR.BUCURESTI, NR.74",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0740532400",
            "email": "claudiu_cio@yahoo.com"
          },
          {
            "name": "P.F.A. FRATEAN T IOAN",
            "address": "STR. SEPTIMIU ALBINI NR. 139-141, AP. 8",
            "category": "B",
            "telephone": "0741642970",
            "email": "mariusgcioca@live.com"
          },
          {
            "name": "P.F.A. BOBOC MARIN GHEORGHE",
            "address": "STR. POET ANDREI MURESANU NR. 14, ",
            "category": "B",
            "telephone": "0740148210",
            "email": "pop.antonia@yahoo.com"
          },
          {
            "name": "P.F.A. FABIAN IOAN ",
            "address": "ALEEA TAZLAU NR. 9, AP. 66",
            "category": "B",
            "telephone": "0744629459",
            "email": "tigau.gheorghe@yahoo.com"
          },
          {
            "name": "P.F.A. CRACIUN VASILE",
            "address": "STR. ALVERNA NR. 61, BL. E3, AP. 22",
            "category": "B",
            "telephone": "0745640260",
            "email": "nvirgil41@yahoo.com"
          },
          {
            "name": "P.F.A. TIMIS STEFAN",
            "address": "STR. CIUCAS NR. 9, BL. R1, SC. 1, ET. 1, ",
            "category": "B",
            "telephone": "0744750750",
            "email": "tigau.gheorghe@yahoo.com"
          },
          {
            "name": "P.F.A. GRIGORE ORESTE ",
            "address": "B-DUL 21 DECEMBRIE, NR. 137, AP. 150",
            "category": "B",
            "telephone": "0762661403",
            "email": "grigoreoreste@yahoo.com"
          },
          {
            "name": "P.F.A. SELEJAN MARIUS ADRIAN",
            "address": "STR. TRANSILVANIEI NR. 32, AP. 3",
            "category": "B",
            "telephone": "0740322799",
            "email": "adi_sele@yahoo.com"
          },
          {
            "name": "UNIVERSITATEA STIINTE AGRICOLE \nSI MED.VETERINARA",
            "address": "CALEA MANASTUR NR. 3-5",
            "category": "B,C,CE",
            "telephone": "0744557633",
            "email": "crisan_valentin_dan@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DE \nTRANSPORTURI TRANSILVANIA",
            "address": "STR BISTRITEI NR 21 ",
            "category": "B,C,CE,AM",
            "telephone": "0729614600",
            "email": "daciana.macaria@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ALEXANDRU \nBORZA",
            "address": "STR.  ALEXANDRU VAIDA VOIEVOD NR.  \n57",
            "category": "B,Tr",
            "telephone": "0720005856",
            "email": "gs.alexborza@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NR. 1",
            "address": "STR MARAMURESULUI  NR 165A",
            "category": "B,C1",
            "telephone": "0725221780",
            "email": "tehnofrig_scoala@yahoo.com"
          },
          {
            "name": "TONI AUTO SRL",
            "address": "ALEEA BIBLIOTECII NR. 10, BL. 13,(FOSTA \nPIATA MARASTI), SALA 2 SI SALA 3",
            "category": "A,B,C,D,BE,CE,A1,B1,C1,C\n1E,AM,A2",
            "telephone": "0745990749",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "ALFA INSTRUIRE A \nCONDUCATORILOR AUTO AMATORI \nSA",
            "address": "STR. ALEXANDRU VAIDA VOIEVOD, \nNR.63",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0264/442589",
            "email": "alfarusu@yahoo.com"
          },
          {
            "name": "P.F.A. DUMA IOAN",
            "address": "STR. TULCEA NR. 6, AP. 69",
            "category": "B",
            "telephone": "0766617420",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. SZEKELY MIHAI",
            "address": "STR. TULCEA NR. 12, BL. M2, AP. 68",
            "category": "B",
            "telephone": "0745322758",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. GHERMAN TOADER",
            "address": "STR. BECAS NR. 13",
            "category": "B",
            "telephone": "0721857123",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "TONI AUTO CENTER SRL",
            "address": "STR. BIBLIOTECII NR. 10, AP. 43",
            "category": "B",
            "telephone": "0745990749",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "LUCUTA & ASOCIATII SRL ",
            "address": "STR. IULIU MANIU NR. 18",
            "category": "A,B,A2",
            "telephone": "0799666555",
            "email": "ionutgiurgea@yahoo.com"
          },
          {
            "name": "AUTO TUDOR SPEED SRL",
            "address": "BULEVARDUL 21 DECEMBRIE 1989, ",
            "category": "B",
            "telephone": "0765888888",
            "email": "office@auto-tudor.ro"
          },
          {
            "name": "SILAGHI AUTO SRL-D",
            "address": "STR. AUREL VLAICU NR. F.N.",
            "category": "B",
            "telephone": "0773846052",
            "email": "silaghiauto@yahoo.com"
          },
          {
            "name": "AUTO MOTO START SRL ",
            "address": "STR.RAVASULUI, NR.12, ETAJ SUBSOL, ",
            "category": "A,B,BE,A1,B1,A2",
            "telephone": "0758995961",
            "email": "scautostartsrl@yahoo.com"
          },
          {
            "name": "P.F.A. UNGUREANU IOAN LUMINIU ",
            "address": "STR. ALEXANDRU VLAHUTA, BLOC LAMA ",
            "category": "B",
            "telephone": "0740302938",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. ABRUDAN MIHAI ",
            "address": "STR. PLOPILOR NR. 58, AP. 24",
            "category": "B",
            "telephone": "0745369579",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. RUS Z. IOAN",
            "address": "STR. HAMEIULUI NR. 3, SC. B, ET. 2, AP. ",
            "category": "B",
            "telephone": "0751040991",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. NANDREA DAN",
            "address": "CALEA DOROBANTILOR NR. 109, BL. 16, ",
            "category": "B",
            "telephone": "0745286954",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. CODOREAN CALIN ",
            "address": "STR. TULCEA NR. 29, AP. 27",
            "category": "B",
            "telephone": "0744538597",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. MARCHIS MARCEL VASILE",
            "address": "STR. ANTON PANN NR. 32-34",
            "category": "B",
            "telephone": "0742591816",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. ANTON ALEXANDRU",
            "address": "STR. BUREBISTA NR. 1, AP. 2",
            "category": "B",
            "telephone": "0742687255",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. SILOSI VALER",
            "address": "STR. TASNAD NR. 20, BL. E3, SC. 5, ET. 2, ",
            "category": "B",
            "telephone": "0752930212",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. TOADER PETRU VIOREL ",
            "address": "STR. ARINILOR NR. 15, BL. B5, SC. 1, ET. ",
            "category": "B",
            "telephone": "0744869223",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. CENAN RADU OLIMPIU  ",
            "address": "STR. HOREA NR. 32, ET. 2, AP. 6",
            "category": "B",
            "telephone": "0742225772",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. PETRISOR GEORGEL \nFLORENTIN",
            "address": "STR. OBSERVATORULUI NR. 17, AP. 24",
            "category": "B",
            "telephone": "0740833650",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. CRISAN ADRIAN EMIL",
            "address": "STR. POET GRIGORE ALEXANDRESCU NR. ",
            "category": "B",
            "telephone": "0742540543",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. SCHULLER ROBERT ",
            "address": "STR. MEMORANDULUI NR. 3, ET. II, AP. ",
            "category": "B",
            "telephone": "0744620034",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "P.F.A. CENGHER MIHAIL ",
            "address": "STR. GRUIA, NR. 58, BL. A, SC. 3, AP. 7",
            "category": "B",
            "telephone": "0742591927",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. LUCA L. IOAN",
            "address": "STR. PADURII NR. 16, BL. M4, AP. 22",
            "category": "B",
            "telephone": "0746105763",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. POP M. VALENTIN",
            "address": "STR. IZLAZULUI NR. 18, ET. 7, AP. 252",
            "category": "B",
            "telephone": "0749516977",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. SERGHIAC IOAN",
            "address": "STR. TULCEA NR. 16. AP. 15",
            "category": "B",
            "telephone": "0747218080",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. STANCIU I V GHEORGHE ",
            "address": "STR. GHEORGHE DIMA NR. 25, BL. IV.B, ",
            "category": "B",
            "telephone": "0758299205",
            "email": "ghitystanciu@yahoo.com"
          },
          {
            "name": "P.F.A. ARTIUDEAN VASILE ",
            "address": "STR. RODNEI NR. 28-30",
            "category": "B",
            "telephone": "0722635872",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. CIOBANU B VASILE",
            "address": "STR. MEHEDINTI NR. 74, BL. G15, SC. 4, ",
            "category": "B",
            "telephone": "0742388235",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. DEMIAN I IOAN CRISTIAN ",
            "address": "STR. POET GRIGORE ALEXANDRESCU NR. ",
            "category": "B",
            "telephone": "0744623454",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. MOLDOVAN CRISTIAN \nOVIDIU ",
            "address": "STR. RAPSODIEI NR. 21, SC. II, ET. II, AP. \n21",
            "category": "B",
            "telephone": "0744393227",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. SOMESFALEAN EMIL MIRCEA",
            "address": "ALEEA SCARISOARA NR. 1, AP.36",
            "category": "B",
            "telephone": "0722977368",
            "email": "mariusgcioca@live.com"
          },
          {
            "name": "P.F.A. UIFALEAN LUCIAN DAN",
            "address": "STR. LOCOMOTIVEI NR. 1, ET. 1, AP. 3",
            "category": "B",
            "telephone": "0744575688",
            "email": "mariusgcioca@live.com"
          },
          {
            "name": "P.F.A. KUNCIK RUDOLF LASZLO ",
            "address": "STR. COLONIA SOPORULUI NR. 50",
            "category": "B",
            "telephone": "0744563152",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. BUTUR A IOAN ",
            "address": "STR. TARNITA NR. 2, BLOC C1, AP. 98",
            "category": "B",
            "telephone": "0747479254",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. GOIA V MARIA ",
            "address": "STR. EUGEN IONESCO NR. 10",
            "category": "B",
            "telephone": "0742454956",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. RAZMAN ISTVAN",
            "address": "STR. ARANY JANOS NR. 19, AP. 5",
            "category": "B",
            "telephone": "0746087338",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "P.F.A. NITISOARA NICOLITA",
            "address": "STR. BISTRITEI NR. 21",
            "category": "B",
            "telephone": "0744872855",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "MUNTEANU I DRAGOS MIHAI \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR. ALEXANDRU VAIDA VOEVOD NR. \n70, ET. 3, AP. 22",
            "category": "B",
            "telephone": "0745015105",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "P.F.A. RUS M. ION",
            "address": "STR. DAMBOVITEI, NR. 87, AP. 105, AP. ",
            "category": "B",
            "telephone": "0741101602",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "CONDUCERE AUTO ACR SRL",
            "address": "BLD. 21 DECEMBRIE 1989 NR. 131, ET. P ",
            "category": "B",
            "telephone": "0721257133",
            "email": "acr_cj@acr.ro"
          },
          {
            "name": "LET`S GOO DRIVE SRL",
            "address": "STR.ZAHARIA BARSAN, NR.1",
            "category": "B",
            "telephone": "0756336110",
            "email": "xag71paul@gmail.com"
          }
        ]
      },
      {
        "city": "CAMPIA TURZII",
        "schools": [
          {
            "name": "BIA BLANCA SRL",
            "address": "STR. POET ANDREI MURESANU NR. 11",
            "category": "B,C,BE,CE",
            "telephone": "0744599718",
            "email": "bia.blanca45@yahoo.com"
          },
          {
            "name": "CRIM TRANS AUTO SRL",
            "address": "STR. 1 DECEMBRIE 1918 NR. 14",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0747647911",
            "email": "crimtrans@yahoo.com"
          },
          {
            "name": "BIA BLANCA AUTO SRL",
            "address": "STR. POET ANDREI MURESANU NR. 11",
            "category": "B",
            "telephone": "0744599718",
            "email": "biablancaauto@yahoo.com"
          },
          {
            "name": "DANDAN TUR AUTO SRL",
            "address": "STR. LAMINORISTILOR NR. 94",
            "category": "B,C,CE",
            "telephone": "0744273876",
            "email": "dandantur78@yahoo.com"
          }
        ]
      },
      {
        "city": "DEJ",
        "schools": [
          {
            "name": "BAMSE SRL",
            "address": "STR. PARCULUI NR. 16, AP. 1",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0761632023",
            "email": "bamseee@yahoo.com"
          },
          {
            "name": "GHIRAN AUTO MOTO SRL",
            "address": "STR . MIHAIL KOGALNICEANU NR 10",
            "category": "B,C,CE",
            "telephone": "0764448887",
            "email": "ghiran.dej@gmail.com"
          },
          {
            "name": "GMC PILOTAJ SRL",
            "address": "STR. MIHAIL KOGALNICEANU NR. 10, AP. ",
            "category": "B",
            "telephone": "0264220030",
            "email": "gmcpilotaj@gmail.com"
          },
          {
            "name": "DRIVE AUTO TIM SRL",
            "address": "STR. MIORITEI NR. 4-6",
            "category": "B",
            "telephone": "0762668288",
            "email": "drive_autotim@yahoo.com"
          },
          {
            "name": "LUCKYLORD SRL",
            "address": "STR. AVRAM IANCU NR. 2-4, INCINTA \nSCOALA MIHAI EMINESCU",
            "category": "B, C, CE",
            "telephone": "0763616506",
            "email": "luckylord999@yahoo.com"
          },
          {
            "name": "SCOALA DE SOFERI NR. 2 DEJ",
            "address": "STR. TUDOR VLADIMIRESCU NR. 1",
            "category": "B,C",
            "telephone": "0724020672",
            "email": ""
          },
          {
            "name": "RH ELVEST AUTO SRL",
            "address": "STR. PETRU RARES, NR. 4, AP. 16",
            "category": "B",
            "telephone": "0745114942",
            "email": "elvestauto@yahoo.com"
          },
          {
            "name": "VASROM MOTO SPORT SRL",
            "address": "STR. BISTRITEI NR. 2, CAMERELE 1,2,4 SI ",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0745695018",
            "email": "SCOALA_MOTOSPORT@VASROM.RO"
          },
          {
            "name": "TODEA AUTO MOTO SRL",
            "address": "STR. 1 MAI NR. 6",
            "category": "A,B,BE,A2",
            "telephone": "0744147997",
            "email": "todea_automoto@yahoo.com"
          },
          {
            "name": "VASROM DRIVE TEAM SRL ",
            "address": "STR. BISTRITEI NR. 2, SALA 4 SI SALA 5, ",
            "category": "B",
            "telephone": "",
            "email": "scoala@vasrom.ro"
          }
        ]
      },
      {
        "city": "GILAU",
        "schools": [
          {
            "name": "P.F.A. NICA NICOLAE VIRGIL",
            "address": "STR. REPUBLICII NR. 768",
            "category": "B",
            "telephone": "0744782242",
            "email": "nvirgil41@yahoo.com"
          },
          {
            "name": "P.F.A. SPIRIDON GHEORGHE \nADRIAN",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA B",
            "category": "B",
            "telephone": "0745387053",
            "email": "adi.spiridon@gmail.com"
          },
          {
            "name": "P.F.A. KOPENETZ ATTILA SANDOR",
            "address": "SAT GILAU NR. 1286",
            "category": "B",
            "telephone": "0744129908",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. VINCZE ZOLTAN",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA C",
            "category": "B",
            "telephone": "0744814461",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. DEAK TIBERIU",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA B",
            "category": "B",
            "telephone": "0746043278",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. POP SILVIU LUCIAN",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA C",
            "category": "B",
            "telephone": "0740839827",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. CSOREGI ISTVAN IANOS",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA C",
            "category": "B",
            "telephone": "0742765461",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. LOSONTI VICTOR VIOREL",
            "address": "NR.1085, CAMERA A",
            "category": "B",
            "telephone": "0746682864",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. PINTILIE VASILE OVIDIU",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA C",
            "category": "B",
            "telephone": "0745317001",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. JAKAB A MIHALY",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA B",
            "category": "B",
            "telephone": "0745804238",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. LAPUSTE NICOLAE",
            "address": "STR. BRANISTE NR. 27A",
            "category": "B",
            "telephone": "0742010322",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. CRISTEA TOADER",
            "address": "STR. PRINCIPALA NR. 1286, CAMERA B",
            "category": "B",
            "telephone": "0747929635",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. CIOBAN TARCEA SIMION",
            "address": "NR.1300, CAMERA A, ETAJ 1",
            "category": "B",
            "telephone": "0744489815",
            "email": "autocadet@yahoo.com"
          },
          {
            "name": "P.F.A. ANDREICA IONEL MELINTE",
            "address": "STR. PRINCIPALA NR. 1286",
            "category": "B",
            "telephone": "0744516601",
            "email": "kopmeli@yahoo.com"
          },
          {
            "name": "P.F.A. KORPOS F. FRANCISC",
            "address": "STR. PRINCIPALA NR. 1286",
            "category": "B",
            "telephone": "0746215176",
            "email": "autocadet@yahoo.com"
          }
        ]
      },
      {
        "city": "HUEDIN",
        "schools": [
          {
            "name": "C&P INTRANS SRL ",
            "address": "PIATA REPUBLICII NR. 8, AP. 105/14",
            "category": "A,B,C,CE,A2",
            "telephone": "0755035015",
            "email": "nirolimob@gmail.com"
          },
          {
            "name": "P.F.A. KUDOR LEVENTE FRANCISC",
            "address": "STR.FILDULUI, NR.16",
            "category": "B",
            "telephone": "0744604410",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. KALMAN IOAN",
            "address": "STR.VIOLETELOR, NR.1",
            "category": "B",
            "telephone": "0744251116",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "P.F.A. SIMIONAS LIVIU ARDELEAN",
            "address": "STR.HOREA, NR.3A, SCARA 2, ETAJ I, ",
            "category": "B",
            "telephone": "0746675319",
            "email": "kunciklaszlo@yahoo.com"
          },
          {
            "name": "BLUE AUTO DRIVE SRL",
            "address": "STR. VLADEASA NR. 4B, PARTER, AP. ",
            "category": "B,BE",
            "telephone": "0751382711",
            "email": "blueautodrive@yahoo.com"
          },
          {
            "name": "CIOTA MARIUS IOSIF PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR. ECATERINA VARGA NR. 38",
            "category": "B",
            "telephone": "0725522250",
            "email": "mariusciota@gmail.com"
          }
        ]
      },
      {
        "city": "POIENI",
        "schools": [
          {
            "name": "P.F.A. NEGRU CALIN IOAN",
            "address": "SAT VALEA DRAGANULUI NR. 299",
            "category": "B",
            "telephone": "0745386620",
            "email": "kopmeli@yahoo.com"
          }
        ]
      },
      {
        "city": "COM. FLORESTI",
        "schools": [
          {
            "name": "P.F.A. SZATMARI MARTIN",
            "address": "SAT LUNA DE SUS NR. 540",
            "category": "B",
            "telephone": "0744856628",
            "email": "kopmeli@yahoo.com"
          }
        ]
      },
      {
        "city": "COM. CEANU MARE",
        "schools": [
          {
            "name": "P.F.A. COMAN E IOAN",
            "address": "SAT CEANU MARE NR. 69",
            "category": "B",
            "telephone": "0748654796",
            "email": ""
          }
        ]
      },
      {
        "city": "BAGARA",
        "schools": [
          {
            "name": "P.F.A. JANKO STEFAN",
            "address": "NR. 18, COM. AGHIRESU",
            "category": "B",
            "telephone": "0742023744",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "FLORESTI",
        "schools": [
          {
            "name": "P.F.A. SECASIU VICTOR",
            "address": "STR. AVRAM IANCU NR. 355",
            "category": "B",
            "telephone": "0743733816",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. TIPLEA LUCIAN",
            "address": "STR. FLORILOR NR. 192, BL. G, SC. 1, ET. ",
            "category": "B",
            "telephone": "0748321178",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. POPA RAZVAN SILVIU ",
            "address": "STR. AVRAM IANCU NR. 154",
            "category": "A,B",
            "telephone": "0744398317",
            "email": "scautostartsrl@yahoo.com"
          },
          {
            "name": "SERADOR SRL",
            "address": "STR.EROILOR, NR.21, SPATIUL \nCOMERCIAL NR.10(birou 1 si birou 2)",
            "category": "B",
            "telephone": "0746257611",
            "email": "farcasdorin@yahoo.com"
          }
        ]
      },
      {
        "city": "SANMARTIN",
        "schools": [
          {
            "name": "P.F.A. CIORA AUREL",
            "address": "NR. 3, COM. CHINTENI",
            "category": "B",
            "telephone": "0743684092",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "SANNICOARA",
        "schools": [
          {
            "name": "P.F.A. TODOR OCTAVIAN",
            "address": "STR. HOREA NR. 20, COM. APAHIDA",
            "category": "B",
            "telephone": "0745792594",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "PODENI",
        "schools": [
          {
            "name": "P.F.A. TIPLEA RARES",
            "address": "NR. 43, COM. MOLDOVENESTI",
            "category": "B",
            "telephone": "0748670328",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "TAGA",
        "schools": [
          {
            "name": "P.F.A. TIRNOVAN RAUL SILVIU",
            "address": "NR. 27, AP. 3",
            "category": "B",
            "telephone": "0744536518",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "CRISTOREL",
        "schools": [
          {
            "name": "P.F.A. OROIAN TUDOR IOAN",
            "address": "JUD. ALBA, SAT LUPSA, COM. LUPSA NR. ",
            "category": "B",
            "telephone": "0740162110",
            "email": "scoala@toniauto.ro"
          }
        ]
      },
      {
        "city": "ASCHILEU",
        "schools": [
          {
            "name": "P.F.A. RIGLEA SILVIU",
            "address": "SAT CRISTOREL NR. 17, SALA NR. I",
            "category": "B",
            "telephone": "0758686979",
            "email": "silviuriglea@yahoo.com"
          }
        ]
      },
      {
        "city": "GHEORGHIENI",
        "schools": [
          {
            "name": "P.F.A. MUZSI A. LASZLO",
            "address": "NR. 400, COM. FELEACU",
            "category": "B",
            "telephone": "0746055898",
            "email": "autocadet@yahoo.com"
          }
        ]
      },
      {
        "city": "Turda",
        "schools": [
          {
            "name": "P.F.A. TRUTA VASILE ",
            "address": "str. Poiana nr. 17",
            "category": "B",
            "telephone": "0766397627",
            "email": "vasile.truta59@gmail.com"
          }
        ]
      },
      {
        "city": "APAHIDA",
        "schools": [
          {
            "name": "SERADOR SRL",
            "address": "STR. PARCULUI NR. 2, MANSARDA",
            "category": "B",
            "telephone": "0746257611",
            "email": "farcasdorin@yahoo.com"
          }
        ]
      },
      {
        "city": "ICLOD",
        "schools": [
          {
            "name": "P.F.A. VERES SILVIU GAVRIL",
            "address": "SAT LIVADA NR. 104",
            "category": "B",
            "telephone": "0745565800",
            "email": "VERES.SILVIU.PFA@GMAIL.COM."
          }
        ]
      },
      {
        "city": "SANPAUL",
        "schools": [
          {
            "name": "P.F.A  HANGANU M. CONSTANTIN",
            "address": "NR.240, SALA NR.1",
            "category": "B",
            "telephone": "0744951703",
            "email": "scoala@toniauto.ro"
          },
          {
            "name": "P.F.A. SALAJAN MARIUS CIPRIAN",
            "address": "NR.240, SALA NR.1",
            "category": "B",
            "telephone": "0740250821",
            "email": "scoala@toniauto.ro"
          }
        ]
      }
    ]
  },
  {
    "state": "CL",
    "schools": [
      {
        "city": "CALARASI",
        "schools": [
          {
            "name": "ALYNA SRL",
            "address": "ALEEA TRANDAFIRILOR, NR. 1 BL.D25, ",
            "category": "A,B,A1,AM,A2",
            "telephone": "0242311766",
            "email": "info@scoalasoferialyna.ro"
          },
          {
            "name": "BADEA CONSTANTIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.SF. NICOLAE NR. 19, BL. A25, SC. 1, \nPARTER",
            "category": "B",
            "telephone": "",
            "email": "daniela.badea@allianztiriac.ro"
          },
          {
            "name": "BURCA IULIAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR. PROGRESUL NR. A16, PARTER",
            "category": "B",
            "telephone": "",
            "email": "iulianburca20@yahoo.ro"
          },
          {
            "name": "ION MARIAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.SF. NICOLAE NR. 19, BL. A25, SC. 1, \nPARTER",
            "category": "B",
            "telephone": "",
            "email": "mandelas68@gmail.com"
          },
          {
            "name": "VALMAR AUTOTRANS SRL",
            "address": "B-DUL REPUBLICII NR. 160",
            "category": "B,C,D,CE",
            "telephone": "0728828098",
            "email": "valmarautotrans@yahoo.com"
          },
          {
            "name": " MOLDOVEANU I MARIAN \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.SF. NICOLAE NR. 19, BL. A25, SC. 1, \nPARTER",
            "category": "B",
            "telephone": "",
            "email": "mandelas68@GMAIL.COM"
          },
          {
            "name": "DUMITRACHE C.E. MARIN \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.GENERAL CONSTANTIN PANTAZI \nNR.4",
            "category": "B",
            "telephone": "0729442843",
            "email": "gheorghe.dobre@sidertrans.ro"
          },
          {
            "name": "P.F.A. FLOREA GEORGICA",
            "address": "STR. INDEPENDENTEI NR. 22",
            "category": "B",
            "telephone": "0724213197",
            "email": "florea.george@yahoo.com"
          },
          {
            "name": "P.F.A. MIREA GEORGE",
            "address": "STR. INDEPENDENTEI NR. 22",
            "category": "B",
            "telephone": "0729219606",
            "email": "florea.george2006@gmail.com"
          },
          {
            "name": "TUNSU NICOLAE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.GENERAL CONSTANTIN PANTAZI \nNR.4",
            "category": "B",
            "telephone": "0744242837",
            "email": "gheorghe.dobre@sidertrans.ro"
          },
          {
            "name": "DIATUR AUTO PLUS SRL",
            "address": "STR.BUCURESTI NR.206",
            "category": "B",
            "telephone": "0731438843",
            "email": "diaturautoplus@yahoo.com"
          },
          {
            "name": "SMART CHOICE UMN SRL",
            "address": "STR.CORNISEI NR.50 BL.D1 ET.1",
            "category": "A,B,A2",
            "telephone": "0741034632",
            "email": "mandelas68@yahoo.com"
          },
          {
            "name": "AUTOVICTORIA MAN SRL",
            "address": "STR.PREL.BUCURESTI NR.8-12",
            "category": "B",
            "telephone": "0722642453",
            "email": "autovictoria_man@yahoo.com"
          },
          {
            "name": "P.F.A. VASILACHE GEORGIAN",
            "address": "STR. INDEPENDENTEI NR. 22",
            "category": "B",
            "telephone": "0724836151",
            "email": "vasilache.georgian@gmail.com"
          },
          {
            "name": "AUTO BBB SRL",
            "address": "STR.LOCOMOTIVEI NR.2",
            "category": "B,C,D,CE",
            "telephone": "0242332261",
            "email": "autobbbsrl@yahoo.com"
          },
          {
            "name": "MARINACHE NICULAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.GENERAL CONSTANTIN PANTAZI \nNR.4",
            "category": "B",
            "telephone": "0744366457",
            "email": "gheorghe.dobre@sidertrans.ro"
          },
          {
            "name": "BARBU I. CONSTANTIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.GENERAL CONSTANTIN PANTAZI \nNR.4",
            "category": "B",
            "telephone": "0723329225",
            "email": "gheorghe.dobre@sidertrans.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC \nTRANSPORTURI AUTO",
            "address": "STR.PRELUNGIREA SLOBOZIEI NR.2",
            "category": "B",
            "telephone": "0242314874",
            "email": "liceul_auto@yahoo.com"
          },
          {
            "name": "ISTRATE AUTO SRL",
            "address": "SOSEAUA PORTULUI NR. 3",
            "category": "B",
            "telephone": "0727356853",
            "email": "dan69istrate@yahoo.com"
          },
          {
            "name": "MANCIU DRIVING SCHOOL SRL",
            "address": "STR. PRELUNGIREA BUCURESTI NR. 230",
            "category": "B",
            "telephone": "0728729951",
            "email": "manciuds2017@gmail.com"
          },
          {
            "name": "MOGA MARIAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR. INDEPENDENTEI NR.22",
            "category": "B",
            "telephone": "0728074643",
            "email": "special.auto2002@yahoo.com"
          },
          {
            "name": "ANGHEL I.S. COSTEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.PRELUNGIREA BUCURESTI NR.8-12",
            "category": "B",
            "telephone": "0720571600",
            "email": "o.zainea@yahoo.com"
          },
          {
            "name": "CHIRITA MISU PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.PRELUNGIREA BUCURESTI NR.8-12",
            "category": "B",
            "telephone": "0727844576",
            "email": "o.zainea@yahoo.com"
          },
          {
            "name": "NASTASE V. DANIEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.PRELUNGIREA BUCURESTI NR.8-12",
            "category": "B",
            "telephone": "0722310647",
            "email": "o.zainea@yahoo.com"
          },
          {
            "name": "ZAINEA OVIDIU PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.PRELUNGIREA BUCURESTI NR.315, \nPRAVALIA II",
            "category": "B",
            "telephone": "0731935707",
            "email": "emtry74@yahoo.com"
          },
          {
            "name": "VASILE V.M. SORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.PRELUNGIREA BUCURESTI NR.8-12",
            "category": "B",
            "telephone": "0728282197",
            "email": "o.zainea@yahoo.com"
          },
          {
            "name": "CHIRU GH. VASILE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.PROGRESUL, NR.A16,PARTER",
            "category": "B",
            "telephone": "0726393637",
            "email": "ulianburca@yahoo.com"
          },
          {
            "name": "MOTOI SANDU GHEORGHE \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.INDEPENDENTEI NR.22STR. SFANTUL NICOLAE NR. 19 SPATIUL ",
            "category": "B",
            "telephone": "0721747885",
            "email": "special.auto2002@yahoo.com"
          },
          {
            "name": "SERBAN I. ADRIAN DANIEL \nPERSOANA FIZICA AUTORIZATA",
            "address": "COMERCIAN NR. 58 MUZICA, BL. A25 SC. \n1 PARTER",
            "category": "B",
            "telephone": "0726213157",
            "email": "ionmarianumn@yahoo.com"
          }
        ]
      },
      {
        "city": "OLTENITA",
        "schools": [
          {
            "name": "TUDOR ADRI SRL",
            "address": "B-DUL 1 DECEMBRIE NR.26 ",
            "category": "B",
            "telephone": "0729517855",
            "email": "tatiana.bold@gmail.com"
          },
          {
            "name": "P.F.A. LEMANDROI MARIANA",
            "address": "B-DUL 1 DECEMBRIE BL. A1, SC. A ET. 4 ",
            "category": "B",
            "telephone": "0722302488",
            "email": "mariana.lemandroi@yahoo.ro"
          },
          {
            "name": "AUTOIULIUS SRL",
            "address": "STR.23 APRILIE NR.17",
            "category": "B",
            "telephone": "0724257272",
            "email": "getyrusu@yahoo.com"
          },
          {
            "name": "ELA AUTO SRL",
            "address": "STR.CUZA VODA NR.36",
            "category": "B",
            "telephone": "0723184940",
            "email": "neluboboc69@gmail.com"
          },
          {
            "name": "AUTOPROFESIONAL SRL",
            "address": "B-DUL MARASESTI NR. 26A BL. Z12, SC. ",
            "category": "B",
            "telephone": "0723518170",
            "email": "hummerul@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NICOLAE \nBALCESCU",
            "address": "SOS PORTULUI NR.3",
            "category": "B",
            "telephone": "0242515792",
            "email": "ilienelu66@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ION GHICA",
            "address": "STR. ARGESULUI NR. 100",
            "category": "B",
            "telephone": "0242515729",
            "email": "angela.dobre@artesimeserii.ro"
          },
          {
            "name": "TRANSDANUBIUS SA",
            "address": "B-DUL 1 DECEMBRIE NR.35",
            "category": "B",
            "telephone": "0242515909",
            "email": "transdanubius@gmail.com"
          },
          {
            "name": "GRASU SNC",
            "address": "STR.TRAIAN NR.23",
            "category": "B",
            "telephone": "0723518172",
            "email": "grasusnc@yahoo.com"
          },
          {
            "name": "VALMAR AUTOTRANS SRL",
            "address": "B-DUL REPUBLICII  NR.48-50",
            "category": "B,C,D,CE",
            "telephone": "0722342103",
            "email": "valmarautotrans@yahoo.com"
          },
          {
            "name": "POPA ION MIRCEA PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0728828239",
            "email": "nelypim@yahoo.com"
          },
          {
            "name": "MIHAI & CRISTINA AUTO SRL ",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0720798419",
            "email": "raducristina205@gmail.com"
          },
          {
            "name": "AUTODAV MARIUS SRL",
            "address": "SOSEAUA PORTULUI NR. 3",
            "category": "B",
            "telephone": "0728562257",
            "email": "autodavmarius@yahoo.com"
          },
          {
            "name": "ILIE C. NELU PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0722546351",
            "email": "ilienelu66@yahoo.com"
          },
          {
            "name": "DINU MARIN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0723376765",
            "email": "dobre90@yahoo.com"
          },
          {
            "name": "MURARU D. MARIUS CRISTINEL \nP.F.A. ",
            "address": "STR. ARGESULUI NR. 100",
            "category": "B",
            "telephone": "0723465532",
            "email": "dobre90@yahoo.com"
          },
          {
            "name": "P.F.A. DRAGAN MIHAIL",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0722274070",
            "email": "raducristina205@gmail.com"
          },
          {
            "name": "P.F.A. DOBRE C-TIN NICOLAE ",
            "address": "STR.ARGESULUI NR.100",
            "category": "B",
            "telephone": "0766518323",
            "email": "dobre90@yahoo.com"
          }
        ]
      },
      {
        "city": "CURCANI",
        "schools": [
          {
            "name": "P.F.A. DRAGAN M. MIHAIL",
            "address": "CURCANI",
            "category": "B",
            "telephone": "0723",
            "email": "draganmihail48@gmail.com"
          }
        ]
      },
      {
        "city": "FUNDULEA",
        "schools": [
          {
            "name": "WSO DRIVE SRL",
            "address": "BLD. 22 DECEMBRIE NR. 38BIS ETAJ 1",
            "category": "B",
            "telephone": "0734950000",
            "email": "wso.drive@gmail.com"
          }
        ]
      },
      {
        "city": "LEHLIU GARA",
        "schools": [
          {
            "name": "UDESKOM AUTO SRL",
            "address": "STR. NICOLAE TITULESCU NR. 7A ET. 1",
            "category": "B",
            "telephone": "0768498364",
            "email": "udeskom@yahoo.com"
          },
          {
            "name": "THEO DRIVING SCHOOL S.R.L.",
            "address": "STR.VIITORULUI NR.9",
            "category": "B",
            "telephone": "0745995607",
            "email": "theodrivingschool@gmail.com"
          },
          {
            "name": "UDESKOM PROFESIONAL S.R.L.",
            "address": "STR.NICOLAE TITULESCU, ",
            "category": "B,C,CE",
            "telephone": "0774021013",
            "email": "udescu_ramona@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "CS",
    "schools": [
      {
        "city": "CARANSEBES",
        "schools": [
          {
            "name": "X - STREAM SRL",
            "address": "ST.ARDEALULUI NR.166",
            "category": "B",
            "telephone": "0788700400",
            "email": "adipanfilescu@yahoo.com"
          },
          {
            "name": "PYT JURJA GRUP SRL",
            "address": "ST.NICOLAE CORNEANU NR.1/1",
            "category": "B",
            "telephone": "0744624402",
            "email": "scoala.soferi@rocketmail.com"
          },
          {
            "name": "INSTRUCTORI AUTO BALAN \nMIHAND SRL",
            "address": "ST.AL.RAPSODIEI BL.4 SC.A AP.9",
            "category": "B",
            "telephone": "0741771670",
            "email": "costin_corbu@yahoo.co.uk"
          },
          {
            "name": "BANDASILA DUMITRU SRL",
            "address": "ST.TRIBUNALULUI NR.5",
            "category": "B",
            "telephone": "0741219066",
            "email": "sc_bandasiladumitru_srl@yahoo.com"
          },
          {
            "name": "ADE PROFI AUTO SRL",
            "address": "ST.ARDEALULUI NR.166",
            "category": "B",
            "telephone": "0743588793",
            "email": "stefan_jucos@yahoo.com"
          },
          {
            "name": "KOSTAR RODMAR SRL",
            "address": "ST.LIBERTATII. NR.21",
            "category": "B,C,D,CE",
            "telephone": "0761281334",
            "email": "marin_gresa@yahoo.com"
          },
          {
            "name": "ANOCA SRL",
            "address": "ST.TRIBUNALULUI NR.6",
            "category": "B,C,CE",
            "telephone": "0255514558",
            "email": "marius_cnm19786@yahoo.fr"
          },
          {
            "name": "LICEUL TEHNOLOGIC TRANDAFIR \nCOCARLA",
            "address": "ST.TRIBUNALULUI NR.2",
            "category": "B",
            "telephone": "0255512131",
            "email": "grup_scolar_auto_caransebes@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DACIA",
            "address": "ST.LIBERTATII NR.21",
            "category": "B,Tr",
            "telephone": "0255512705",
            "email": "marin_gresea@yahoo.com"
          },
          {
            "name": "AUTOCIUREL N&L SRL",
            "address": "ST.TUDOR VLADIMIRESCU NR.32",
            "category": "A,B,A1,AM",
            "telephone": "0740205103",
            "email": "autociurel@gmail.com"
          },
          {
            "name": "I & R AUTO ANAELI SRL",
            "address": "ST. MIHAI VITEAZU NR.9",
            "category": "B",
            "telephone": "0786602825",
            "email": "autoanaeli@gmail.com"
          },
          {
            "name": "OCB GHERGA SRL-D",
            "address": "ST.CONSTANTIN DAICOVICI NR.23",
            "category": "B",
            "telephone": "0722494182",
            "email": "oana.gherga@yahoo.com"
          },
          {
            "name": "AUTO CIUCUR PETRICA SRL",
            "address": "ST.NICOLAE BALCESCU NR.7",
            "category": "B",
            "telephone": "0743274271",
            "email": "pciucur@yahoo.com"
          },
          {
            "name": "AUTORIAL TEST SRL",
            "address": "ST.NICOLAE BALCESCU NR.25",
            "category": "B",
            "telephone": "0744545808",
            "email": "autorialzlc@yahoo.com"
          },
          {
            "name": "GRESEA AUTO SRL",
            "address": "STR.RAUL ALB NR.5 CAMERA 2",
            "category": "B",
            "telephone": "0761281334",
            "email": "marin_gresea@yahoo.com"
          }
        ]
      },
      {
        "city": "RESITA",
        "schools": [
          {
            "name": "NLC GRATI SRL",
            "address": "ST.LIBERTATII. NR.40",
            "category": "B",
            "telephone": "0742904542",
            "email": "nlc.grati@yahoo.ro"
          },
          {
            "name": "BANDA 1 CONTACT SRL",
            "address": "ST.PIATA 1 DECEMBRIE 1918 NR.7 ET.7",
            "category": "B,C,D,CE",
            "telephone": "0722509614",
            "email": "banda_unu@yahoo.com"
          },
          {
            "name": "PRIMII PASI INSTRUCTAJ SRL",
            "address": "ST.BD.ALEXANDRU IOAN CUZA NR.5A ",
            "category": "B",
            "telephone": "0723457590",
            "email": "starternetsrl@yahoo.com"
          },
          {
            "name": "FORZA EXPROD SRL",
            "address": "ST.BD.REPUBLICII NR.19 SC.1 PARTER",
            "category": "B",
            "telephone": "0740356419",
            "email": "kevin21leo31@yahoo.com"
          },
          {
            "name": "AUTOSTAR-PILOT SRL",
            "address": "ST.BD.MUNCII NR.1BIS",
            "category": "B",
            "telephone": "0761697204",
            "email": "autostarpilot@yahoo.com"
          },
          {
            "name": "EURODYNAMIC DRIVE SRL",
            "address": "ST.TRAIAN LALESCU NR.9",
            "category": "A,B,A1",
            "telephone": "0740065002",
            "email": "eurodynamic.2008@yahoo.com"
          },
          {
            "name": "ACR AUTOTURISM SRL",
            "address": "ST.PIATA 1 DECEMBRIE 1918 NR.24 ",
            "category": "B",
            "telephone": "",
            "email": "acr_cs@acr.ro"
          },
          {
            "name": "SEGARCEANU LIVIU PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.HORIA NR.2 BL.A2 SC.1 ET.10 AP.37",
            "category": "B",
            "telephone": "0744765198",
            "email": "liviusegarceanu@yahoo.com"
          },
          {
            "name": "ENASESCU ADRIAN OVIDIU \nPERSOANA FIZICA AUTORIZATA",
            "address": "ST.PETRU MAIOR NR.71 SC.1 ET.2 AP.10",
            "category": "A,B,A1,AM,A2",
            "telephone": "0744655686",
            "email": "adienasescu@yahoo.com"
          },
          {
            "name": "GAVINVEST SRL",
            "address": "ST.CASTANILOR NR.30 AP.1",
            "category": "B",
            "telephone": "0771088032",
            "email": "stelicacare@gmail.com"
          },
          {
            "name": "PROFI AUTO ATESTAT \nPROFESIONAL SRL",
            "address": "AGOINDUSTRIAL RESITA NORD ET.2 \nAP.5,8,9",
            "category": "B,C,CE",
            "telephone": "0744669257",
            "email": "frant_mihaela@yahoo.com"
          },
          {
            "name": "AUTORIAL TEST SRL",
            "address": "ST.LIBERTATII BL.A4 PARTER",
            "category": "B,C,D,BE,CE",
            "telephone": "0744545808",
            "email": "autorialzlc@yahoo.com"
          },
          {
            "name": "PROFI MIHA & NONU SRL",
            "address": "B-DUL MUNCII NR.10A COMPL RESITA ",
            "category": "A,B,A1,AM,A2",
            "telephone": "0745255988",
            "email": "frant_mihaela@yahoo.com"
          },
          {
            "name": "FUNDUIANU PETRU-VALERIU \nPERSOANA FIZICA AUTORIZATA",
            "address": "ST.ATENEULUI NR.2",
            "category": "B",
            "telephone": "0743851661",
            "email": "funduianu.vali@yahoo.com"
          },
          {
            "name": "ANDRITOI NICOLAIE PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.ATENEULUI NR.2",
            "category": "B",
            "telephone": "0733807006",
            "email": "nicuandritoi@yahoo.com"
          },
          {
            "name": "ROSCA N.M. NICOLAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.BD.REVOLUTIA DIN DECEMBRIE \nNR.1A ET.2",
            "category": "B",
            "telephone": "0721468574",
            "email": "nycu_darius2000@yahoo.com"
          },
          {
            "name": "MUNTEANU N. NICOLAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.ATENEULUI NR.2",
            "category": "B",
            "telephone": "0764943221",
            "email": "nicolae.muntean@yahoo.com"
          },
          {
            "name": "MARILA IOSIF PERSOANA FIZICA \nAUTORIZATA",
            "address": "ST.ATENEULUI NR.2 CAM.11",
            "category": "B",
            "telephone": "0744286463",
            "email": "titimarila@yahoo.com"
          },
          {
            "name": "VERDES ALEXANDRU PERSOANA \nFIZICA",
            "address": "ST.ATENEULUI NR.2 CAM.11",
            "category": "B",
            "telephone": "0740985707",
            "email": "alexvrds@yahoo.com"
          },
          {
            "name": "EURODYNAMIC CLASS SRL",
            "address": "ST.TRAIAN LALESCU NR.9",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0726661654",
            "email": "eurodynamic.2008@yahoo.com"
          },
          {
            "name": "CABLEA NICUSOR FABIU PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.ATENEULUI NR.2 CAMERA 11",
            "category": "B",
            "telephone": "0744120717",
            "email": "cableanicusor@gmail.com"
          },
          {
            "name": "BANDA 1 ALEGI PERFECT MGA SRL",
            "address": "ST.PIATA 1 DECEMBRIE 1918 NR.7 ET.7 ",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0722509614",
            "email": "banda_unu@yahoo.com"
          },
          {
            "name": "BANDA 1 SUCCES SIGUR MGA SRL",
            "address": "ST.PIATA 1 DECEMBRIE 1918 NR.7 ET.7 ",
            "category": "B",
            "telephone": "0722509614",
            "email": "banda_unu@yahoo.com"
          },
          {
            "name": "AUTORIAL ZLC SRL",
            "address": "ST.LIBERTATII BL.A4 PARTER",
            "category": "A,B,A1,AM,A2",
            "telephone": "0744545808",
            "email": "autorialzlc@yahoo.com"
          },
          {
            "name": "CLAULORY START SRL",
            "address": "ST.ALEXANDRU IOAN CUZA NR.34 SC.1 ST.BD.MUNCII NR.10A COMPLEX ",
            "category": "B",
            "telephone": "0723767970",
            "email": "claulorystart@yahoo.com"
          },
          {
            "name": "PROFI DRIVERS AUTO ATESTAT \nPROFESIONAL SRL",
            "address": "AGROINDUSTRIAL RESITA NORD ET.2 \nAP.2 8 12",
            "category": "B,C,CE",
            "telephone": "0744669257",
            "email": "frant_mihaela@yahoo.com"
          },
          {
            "name": "AUTO AMYGOS NONU S.R.L",
            "address": "B-DUL MUNCII NR.10 ETAJ PARTER",
            "category": "B",
            "telephone": "0745255988",
            "email": "autoamygos@gmail.com"
          },
          {
            "name": "AUTO AMYGOS PROFI SRL",
            "address": "B-DUL MUNCII NR.10 SALA 1,2,3 ",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0745255988",
            "email": "autoamygos@gmail.com"
          }
        ]
      },
      {
        "city": "MOLDOVA NOUA",
        "schools": [
          {
            "name": "VASY PILOT SRL",
            "address": "ST.MIHAI VITEAZU NR.36A",
            "category": "A,B,C,D,CE",
            "telephone": "0762777772",
            "email": "vasy_pilot@yahoo.com"
          }
        ]
      },
      {
        "city": "OTELU ROSU",
        "schools": [
          {
            "name": "CRISSFIL SRL",
            "address": "ST.MIHAI EMINESCU NR.15",
            "category": "B",
            "telephone": "0744642763",
            "email": "ionitamoica@yahoo.com"
          },
          {
            "name": "FLOREA MIHAI - GHEORGHE \nPERSOANA FIZICA AUTORIZATA",
            "address": "ST.REVOLUTIEI NR.190A",
            "category": "A,B,A1,AM,A2",
            "telephone": "0721238020",
            "email": "florea.mihai73@gmail.com"
          }
        ]
      },
      {
        "city": "BOCSA",
        "schools": [
          {
            "name": "30 FIRST RIDES CS SRL",
            "address": "ST.MIRCEA CEL BATRIN NR.34",
            "category": "B,BE",
            "telephone": "0757020127",
            "email": "trifu_catalin@yahoo.com"
          },
          {
            "name": "MARUSCA LIVIU-ION PERSOANA \nFIZICA AUTORIZATA",
            "address": "ST.GEORGE COSBUC NR.83",
            "category": "B",
            "telephone": "0740827501",
            "email": "maruscaliviu15@yahoo.com"
          },
          {
            "name": "BALEA IOAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "ST.GEORGE COSBUC NR.83",
            "category": "B",
            "telephone": "0763276390",
            "email": "balea.prof.ioan@gmail.com"
          }
        ]
      },
      {
        "city": "BANIA",
        "schools": [
          {
            "name": "DERLEAN COM S.R.L",
            "address": "NR.464",
            "category": "A,B,C,CE,A1",
            "telephone": "0255204988",
            "email": "derleancom@yahoo.com"
          }
        ]
      },
      {
        "city": "ORAVITA",
        "schools": [
          {
            "name": "CLAULORY START SRL",
            "address": "ST.RACHITOVEI NR.11ST. BD.MUNCII NR.10A COMPLEX ",
            "category": "B,C,CE",
            "telephone": "0768315090",
            "email": "claulorystart@yahoo.com"
          }
        ]
      },
      {
        "city": "ZAVOI",
        "schools": [
          {
            "name": "FLOREI IOAN-PETRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.79",
            "category": "B",
            "telephone": "0722408717",
            "email": "floreielena@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "CT",
    "schools": [
      {
        "city": "CONSTANTA",
        "schools": [
          {
            "name": "AUTO G.M.D. PRO S.R.L.",
            "address": "Bd. Al Lapusneanu nr. 1, INC. CASA DE ",
            "category": "B",
            "telephone": "0744517670",
            "email": "paraschivescugabriela@yahoo.com"
          },
          {
            "name": "AUTO MOTO ALEXE SRL",
            "address": "STR OLTENIEI  NR. 30",
            "category": "A,B,C,D,CE",
            "telephone": "0722976020",
            "email": "auto_moto_alexe@yahoo.com"
          },
          {
            "name": "LEADER AUTO S.R.L.",
            "address": "STR. INDUSTRIALA , NR.8 , CORP  C5",
            "category": "B,C,D,CE",
            "telephone": "0241551933",
            "email": "office@ytc.ro"
          },
          {
            "name": "LIA PILOT S.R.L.",
            "address": "STR. SALVIEI NR.13",
            "category": "A,B,C,D,BE,CE,A1,B1,AM,",
            "telephone": "0723605573",
            "email": "lipan_ion@yahoo.com"
          },
          {
            "name": "A.B.C. COND FAIR-PLAY",
            "address": "B-DUL IC BRATIANU NR. 45, C6",
            "category": "B",
            "telephone": "0752127744",
            "email": "abc_cond_fairplay@yahoo.com"
          },
          {
            "name": " SMART AUTO TAB SRL",
            "address": "STR. BABA NOVAC nr. 28, etaj-subsol si ",
            "category": "B,C,CE",
            "telephone": "0723738177",
            "email": "smart_auto_tab@yahoo.com"
          },
          {
            "name": "EUROCOND AUTO S.R.L.",
            "address": "STR. STEFAN MIHAILEANU , NR.89",
            "category": "B",
            "telephone": "0722320965",
            "email": "crotalclub@yahoo.com"
          },
          {
            "name": "STAR DRIVE S.R.L.",
            "address": "STR. MEDEEA NR.55",
            "category": "B",
            "telephone": "0721712692",
            "email": "scoalastardrive@yahoo.com"
          },
          {
            "name": "L.P.S. TRAINING FORTE S.R.L.",
            "address": "STR. MIHAIL JORA , NR.3 , PARTER ",
            "category": "B",
            "telephone": "0723622590",
            "email": "office.spguard@gmail.com"
          },
          {
            "name": " LION SPEED TRANS SRL",
            "address": "SOSEAUA DIN VII, NR. 60A",
            "category": "B",
            "telephone": "0723834019",
            "email": "office@lionspeed.ro"
          },
          {
            "name": "DRIVE TEST S.R.L.",
            "address": "STR. MIHAI VITEAZU NR. 25 C1 PARTER",
            "category": "B,C,D,BE,CE,B1",
            "telephone": "0765234209",
            "email": "office@scoaladrivetest.ro"
          },
          {
            "name": "RACAREANU S.R.L.",
            "address": "STR.LT.GHEORGHE BURICESCU NR37 ",
            "category": "B",
            "telephone": "0723227753",
            "email": "gheorghe.racareanu@yahoo.com"
          },
          {
            "name": "CLUB AUTO TOMIS S.R.L.",
            "address": "STR.DEZROBIRII 93",
            "category": "B",
            "telephone": "0723621378",
            "email": "clubautotomis@yahoo.com"
          },
          {
            "name": "LITORAL AUTO SRL",
            "address": "STR. CUZA VODA, NR. 43B-45 , ET. 2",
            "category": "B",
            "telephone": "0720965134",
            "email": "nico_s82@yahoo.com"
          },
          {
            "name": "AUTO STAR PREVENT SRL",
            "address": "STR. MERCUR, NR. 13, AP. 5",
            "category": "B",
            "telephone": "0723974633",
            "email": "AUTO_STAR_PREVENT@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC „DIMITRIE \nLEONIDA”",
            "address": "ALEEA PELICANULUI NR.2",
            "category": "B,C",
            "telephone": "0241600802",
            "email": "mirceadragoi1969@yahoo.com"
          },
          {
            "name": "SETRAN S.R.L.",
            "address": "STR. MUNCEL , NR.62",
            "category": "B",
            "telephone": "0727371702",
            "email": "rodica_1270@yahoo.com"
          },
          {
            "name": "GALCON SRL",
            "address": "STR. STRAJERULUI NR. 8",
            "category": "B",
            "telephone": "0241636458",
            "email": "andrei_fasole@yahoo.com"
          },
          {
            "name": "PORSENA",
            "address": "STR BANU MIHALCEA NR 165",
            "category": "B",
            "telephone": "0241639153",
            "email": "psi_porsena@yahoo.com"
          },
          {
            "name": "TRANSEVREN",
            "address": "STR. CARAIMAN NR. 4, (SECTOR ",
            "category": "A, B, C, D",
            "telephone": "0241 584410",
            "email": "Transevren1@yahoo.com"
          },
          {
            "name": "S.C. DIA AUTO S.R.L.",
            "address": "STR. OLTENIEI, NR 25",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "",
            "email": "dia_auto_srl@yahoo.com"
          },
          {
            "name": "AUTOEUXIN SRL",
            "address": "CASA DE CULTURA, INTRARE STR. I.L. ",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0241624659",
            "email": "autoeuxin@yahoo.com"
          },
          {
            "name": "ABCDE EDUCATIONAL SRL",
            "address": "CONSTRUCTIE C1, PARTER, SPATIU \nCOMERCIAL-INCAPERE 1",
            "category": "B",
            "telephone": "0723296969",
            "email": "rovasgroup@yahoo.com"
          },
          {
            "name": "BASIS COMPLET SRL",
            "address": "BD. AUREL VLAICU NR. 60  CONSTR. C1 ",
            "category": "B",
            "telephone": "0723296969",
            "email": "rovasgrup@yahoo.com"
          },
          {
            "name": "DELUXE AUTO CLUB SRL",
            "address": "STR. POPORULUI NR. 147, PARTER",
            "category": "B",
            "telephone": "0723137323",
            "email": "t.ancaraluca@yahoo.com"
          },
          {
            "name": "MDC PILOT SRL",
            "address": "STR. COSTACHE NEGRUZZI NR. 2",
            "category": "B",
            "telephone": "0727764300",
            "email": "mdcpilot@yahoo.com"
          },
          {
            "name": "AUTOCOZ PILOT FRC SRL",
            "address": "Str. Lt. Dumitru Lemnea nr. 4, spatiu ",
            "category": "B",
            "telephone": "",
            "email": ""
          },
          {
            "name": "SMART AUTO ADMIS SRL",
            "address": "STR. BABA NOVAC nr. 28, subsol si ",
            "category": "B,C,D,CE",
            "telephone": "0724218239",
            "email": "smartautoadmis@yahoo.com"
          },
          {
            "name": "BULGARU VIRGIL-SORIN P.F.A.",
            "address": "STR. PATRIEI NR. 13 (STR. CARAIMAN ",
            "category": "B",
            "telephone": "0723649024",
            "email": "sorinvirgilbubu73@gmail.com"
          },
          {
            "name": "GRAJDEANU C-TIN FLORIN (PFA)",
            "address": "STR. CARAIMAN NR. 1, CAMERA 1, \nPARTER, INCINTA GR. SC. COOPERATIST",
            "category": "B",
            "telephone": "0723577793",
            "email": "grajdeanuflorinctin@gmail.com"
          },
          {
            "name": "AUTO VALCOND 2018 S.R.L.",
            "address": "B-DUL AUREL VLAICU NR. 140 BIS, ",
            "category": "B,C,D,CE",
            "telephone": "0727314017",
            "email": "office.autovalcond2018@yahoo.com"
          },
          {
            "name": "AUTO ASICOND SRL",
            "address": "B-DUL 1 MAI NR. 128 1 camera",
            "category": "B",
            "telephone": "0724114162",
            "email": "autoasicond@gmail.com"
          },
          {
            "name": "AUTO DRIVE RACAREANU",
            "address": "STR. LT. GHEORGHE BURICESCU NR. 37, ",
            "category": "B",
            "telephone": "0760316073",
            "email": "gabi.racareanu@yahoo.com"
          }
        ]
      },
      {
        "city": "MEDGIDIA",
        "schools": [
          {
            "name": "AUTO COM-BODEANU SRL",
            "address": "STR ION CREANGA NR.2",
            "category": "B,C,D,CE",
            "telephone": "0721285676",
            "email": "autocombodeanu@yahoo.com"
          },
          {
            "name": "AUTO GIMD IMPEX S.R.L.",
            "address": "STR. INDEPENDENTEI BL. C2 SP. ",
            "category": "B,C,D,CE",
            "telephone": "0722516679",
            "email": "autogimd@yahoo.com"
          },
          {
            "name": "IS PRO AUTO MED ",
            "address": "STR. REPUBLICII NR. 67A",
            "category": "A,B,BE",
            "telephone": "0769178347",
            "email": "ISPROAUTOMED@GMAIL.COM"
          },
          {
            "name": "GIN AUTO LIN",
            "address": "STR. DECEBAL NR.33, CASA ",
            "category": "B,C,D,CE",
            "telephone": "0761618228",
            "email": "ginautolin@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC „DRAGOMIR \nHURMUZESCU”",
            "address": "STR. POPORULUI NR. 1",
            "category": "B",
            "telephone": "0722789969",
            "email": "carmenb969@yahoo.com"
          }
        ]
      },
      {
        "city": "MANGALIA",
        "schools": [
          {
            "name": "DAV & FAN AUTO SRL",
            "address": "STR CONSTANTEI NR 7-9",
            "category": "B",
            "telephone": "0747338326",
            "email": "dav_fan_auto@yahoo.com"
          },
          {
            "name": "AS AUTO BEST SRL",
            "address": "Sos. CONSTANTEI NR. 43, SALA 1, \nFOAIER SI SAL 1 Etaj 1, Aripa dreaqpta",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0743334985",
            "email": "ct05aud@yahoo.com"
          },
          {
            "name": "DORIAMI D&D",
            "address": "STR. OITUZ NR.73",
            "category": "B",
            "telephone": "0722173390",
            "email": "doriamidnd@yahoo.com"
          },
          {
            "name": "S.C. CLEDD S.R.L.",
            "address": "STR. OITUZ, NR. 5-7, ETAJ 2",
            "category": "A,B,C,BE,CE,A1,AM,A2",
            "telephone": "0241751444",
            "email": "cledd.cledd@yahoo.com"
          },
          {
            "name": "DAKRY AUTO-MOTO SRL",
            "address": "str. AP. ANDREI NR. 6B-dul AUREL VLAICU NR. 62, ",
            "category": "B",
            "telephone": "0747338333",
            "email": "dakry.automoto@yahoo.com"
          },
          {
            "name": "AUTO BROKAROL S.R.L.",
            "address": "B-DUL CALLATIS NR. 3, in suprafata de 78 \nmp2, BLOC MG 19, PARTER",
            "category": "B",
            "telephone": "0747338391",
            "email": "karolici@yahoo.com"
          },
          {
            "name": "P.F.A. SAVIOLI E. ADRIANO",
            "address": "STR. ROZELOR 18 BL.R10 AP.18",
            "category": "B",
            "telephone": "0743335409",
            "email": "scoala.autosavioli@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC „ION \nBANESCU”",
            "address": "STR. NEGRU VODA NR. 19",
            "category": "B",
            "telephone": "0241753205",
            "email": "lt.ibanescu@yahoo.com"
          },
          {
            "name": "DONARD COOL S.R.L.",
            "address": "STR. STEFAN CEL MARE NR. 26, SPATIU ",
            "category": "B,C",
            "telephone": "0747338389",
            "email": "donardkul@gmail.com"
          }
        ]
      },
      {
        "city": "NAVODARI",
        "schools": [
          {
            "name": "D.A.D. AUTO LEADER SRL",
            "address": "STR. ALBINELOR NR.4, Bloc B2, Scara C ",
            "category": "B",
            "telephone": "0762664507",
            "email": "dollo_doru2007@yahoo.com"
          },
          {
            "name": "JON AUTO S.R.L.",
            "address": "Str. MIDIEI, Bl. H2B. Sc. A. Ap. 1  JUD. ",
            "category": "B",
            "telephone": "0740673876",
            "email": "scoalajonauto@yahoo.com"
          },
          {
            "name": "COSTAND-CAR S.R.L.",
            "address": "Str. TINERETULUI  Nr. 2  JUD. ",
            "category": "B",
            "telephone": "0762754500",
            "email": "costandcar@gmail.com"
          },
          {
            "name": "AUTO PRIMO ESK S.R.L.",
            "address": "STR . NUFERILOR , NR.3 , CAMERA 2",
            "category": "B",
            "telephone": "0723336000",
            "email": "auto_primo_esk@yahoo.com"
          },
          {
            "name": "BOGDAN AUTO MAT SRL",
            "address": "STR. FRUNZELOR SP. COMERCIAL BL. R8  ",
            "category": "B",
            "telephone": "0723585114",
            "email": "mateisavabogdan@yahoo.com"
          }
        ]
      },
      {
        "city": "COBADIN",
        "schools": [
          {
            "name": "AUTOCOZ PILOT FRC S.R.L.",
            "address": "SOSEAUA OSTROV, NR. 8",
            "category": "B,C,BE,CE",
            "telephone": "0761614170",
            "email": "autocozpilot40@yahoo.com"
          }
        ]
      },
      {
        "city": "CERNAVODA",
        "schools": [
          {
            "name": "D.C.M. PRO-ELECTRONIC S.R.L.",
            "address": "STR. MIRCEA CEL BATRAN, NR. 17, Etaj 1 ",
            "category": "A,B,BE,A1,B1,AM,A2",
            "telephone": "0723008887",
            "email": "cristidraghici2007@yahoo.com"
          },
          {
            "name": "DAN AUTOCOND SRL",
            "address": "STR. 24 IANUARIE NR.9",
            "category": "B,C,Tr,BE,CE",
            "telephone": "0788486519",
            "email": "OFFICE@DANAUTOCOND.RO"
          },
          {
            "name": "MIMIAUTO PRO S.R.L.",
            "address": "STR. DACIA NR. 4",
            "category": "B",
            "telephone": "0722954736",
            "email": "tm_mimi@yahoo.com"
          }
        ]
      },
      {
        "city": "BANEASA",
        "schools": [
          {
            "name": "S.C. MSM AUTO S.R.L.",
            "address": "STR. TRANDAFIRILOR NR. 123",
            "category": "B,C,D,Tr,CE",
            "telephone": "0766470312",
            "email": "manolemarian72@yahoo.com"
          }
        ]
      },
      {
        "city": "COGEALAC",
        "schools": [
          {
            "name": "ADE COND ALEXE SRL",
            "address": "STR. MARE NR. 204A CONSTRUCTIA NR. ",
            "category": "B",
            "telephone": "0723002218",
            "email": "ade_auto_alexe@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "CV",
    "schools": [
      {
        "city": "TARGU SECUIESC",
        "schools": [
          {
            "name": "PILOTAJ KOTER SRL",
            "address": "STR.INDEPENDENTEI NR.13",
            "category": "B,BE",
            "telephone": "0744379416",
            "email": "pilotaj.koter@yahoo.com"
          },
          {
            "name": "MISAMAX SRL",
            "address": "STR.MOLNAR JOZSIAS NR.43",
            "category": "B",
            "telephone": "0720542320",
            "email": "deakmisa@freemail.hu"
          },
          {
            "name": "RUNSCHUL SRL",
            "address": "STR. ADY ENDRE NR.46",
            "category": "A,B,C,D,CE,C1,AM",
            "telephone": "0267361209",
            "email": "scoalatransec@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC APOR PETER",
            "address": "STR.SCOLII NR.11",
            "category": "B,C,Tr,CE",
            "telephone": "0267361332",
            "email": "peter.apor@gmail.com"
          },
          {
            "name": "BARABAS G. SCOBAR SNC",
            "address": "STR.CURTEA 39 NR.3 AP.1",
            "category": "B",
            "telephone": "0724527584",
            "email": "bgscobar@gmail.com"
          },
          {
            "name": "DRZ-COM SRL",
            "address": "STR.CURTEA 16 NR.1",
            "category": "B",
            "telephone": "0745616077",
            "email": "illesbalinth@yahoo.com"
          },
          {
            "name": "ZEBRA DRIVE SRL",
            "address": "STR.FABRICII NR.8",
            "category": "B",
            "telephone": "0742598456",
            "email": "zebradrive@yahoo.com"
          },
          {
            "name": "ANDREI & ENIKO SRL",
            "address": "STR.CURTEA 12 NR.2",
            "category": "B,BE",
            "telephone": "0742117433",
            "email": "autorefllex@gmail.com"
          }
        ]
      },
      {
        "city": "SFANTU GHEORGHE",
        "schools": [
          {
            "name": "DOMA DRIVE SRL",
            "address": "STR.PRESEI NR.4  BIROUL 16 SI 23",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0755220044",
            "email": "domadrive@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  PUSKAS \nTIVADAR",
            "address": "STR.PESCARILOR NR.41",
            "category": "B,C1",
            "telephone": "0784205320",
            "email": "joco@puskas.educv.ro"
          },
          {
            "name": "TRANSPORT SERVICII PARTISA SRL",
            "address": "PIATA MIHAI VITEAZUL,UNITATE \nCOMERCIALA,BL.43,SC.C,AP.17",
            "category": "B,C,D,BE,CE,AM",
            "telephone": "0267315474",
            "email": "partisa_sfgheorghe@yahoo.com"
          },
          {
            "name": "LA CODREANU SERVICII PUBLICE ",
            "address": "STR.GEN.GRIGORE BALAN NR.4 ",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0267310165",
            "email": ""
          },
          {
            "name": "PENTACAR SERVICII SRL",
            "address": "STR.OLTULUI NR.30",
            "category": "B",
            "telephone": "0723286868",
            "email": "imredeak@yahoo.com"
          },
          {
            "name": "WILLY DRIVE SRL",
            "address": "STR.GARII NR.1 INCINTA STATIE C.F.",
            "category": "B",
            "telephone": "0745030625",
            "email": "willy.drive@yahoo.com"
          },
          {
            "name": "MMY GORSPEED SRL",
            "address": "STR.JOZSEF ATTILA NR.4",
            "category": "B",
            "telephone": "0720520429",
            "email": "Arpadgor@gmail.com"
          }
        ]
      },
      {
        "city": "BARAOLT",
        "schools": [
          {
            "name": "GABY DRIVE SRL",
            "address": "STR.PETOFI SANDOR NR. 25",
            "category": "B",
            "telephone": "0751313613",
            "email": "gabydrivesrl@gmail.com"
          },
          {
            "name": "PRODUCTIE COMERT MARCUS SRL",
            "address": "STR. KOSSUTH LAJOS NR. 149 BIROUL ",
            "category": "B,Tr",
            "telephone": "0726397399",
            "email": "biropeter@ymail.com"
          },
          {
            "name": "ERDOVIDEK SRL",
            "address": "STR.KOSSUTH LAJOS NR.149 BIROU 2 ",
            "category": "B",
            "telephone": "0729142209",
            "email": "biropeter@ymail.com"
          },
          {
            "name": "SUN DRIVE 2018 SRL",
            "address": "STR.MINERILOR NR.11/A SPATIUL E2-04 ",
            "category": "B",
            "telephone": "0740349226",
            "email": "deaksandor19@gmail.com"
          }
        ]
      },
      {
        "city": "INTORSURA \nBUZAULUI",
        "schools": [
          {
            "name": "TRASCOTUR SRL",
            "address": "STR.BRADET NR.192",
            "category": "B",
            "telephone": "0267371614",
            "email": "florin43m@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "DB",
    "schools": [
      {
        "city": "TITU",
        "schools": [
          {
            "name": "CATAL SRL",
            "address": "STR.GARII NR.37",
            "category": "B,C,CE",
            "telephone": "0722464392",
            "email": "aura_iulia_popa@yahoo.com"
          }
        ]
      },
      {
        "city": "COM.PUCHENI",
        "schools": [
          {
            "name": "P.F.A.DINCA P.PETRE",
            "address": "SAT PUCHENI COM.PUCHENI NR.3",
            "category": "B",
            "telephone": "0744822444",
            "email": "dincappetre@yahoo.com"
          }
        ]
      },
      {
        "city": "TARGOVISTE",
        "schools": [
          {
            "name": "P.F.A.ION D V VICTOR",
            "address": "BV.I.C.BRATIANU NR.46 BL.D6 SC.C ET.4 ",
            "category": "B",
            "telephone": "0722746246",
            "email": "iondvvictor@yahoo.com"
          },
          {
            "name": "DARINA SRL",
            "address": "STR.TINERETULUI NR.1 ET.1 CASA ",
            "category": "B",
            "telephone": "0721766599",
            "email": "eduard_jj@yahoo.com"
          },
          {
            "name": "MICLANA TRANS SRL",
            "address": "STR.TINERETULUI CASA TINERETULUI ",
            "category": "B",
            "telephone": "0723563659",
            "email": "nitumelania@yahoo.com"
          },
          {
            "name": "ROSU GHEORGHE SRL",
            "address": "STR.UNIRII NR.18",
            "category": "B",
            "telephone": "0727370009",
            "email": "rosugheorghe1956@yahoo.com"
          },
          {
            "name": "P.F.A.MIHAI V S VASILE",
            "address": "STR.MOLDOVEI NR.24 BL.65 SC.B ET.P ",
            "category": "B",
            "telephone": "0723123222",
            "email": "scoala.mihai_vasile@yahoo.com"
          },
          {
            "name": "P.F.A.LAMBRU I MARIUS-VALENTIN",
            "address": "STR.LEGUMELOR NR.91",
            "category": "B",
            "telephone": "0722464172",
            "email": "lambru_scoala@yahoo.com"
          },
          {
            "name": "RADION AMUZA SRL-D",
            "address": "STR.CALEA DOMNEASCA NR.334 ET.1 ",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM,",
            "telephone": "0723558047",
            "email": "raduamuza@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DE \nTRANSPORTURI AUTO",
            "address": "STR.G-RAL ION EMANOIL FLORESCU \nNR.18",
            "category": "B",
            "telephone": "0245211092",
            "email": "lumynytza_42@yahoo.com"
          },
          {
            "name": "CLEPSIDRA SRL",
            "address": "STR.PROF.ALEXANDRU VASILESCU NR.1A",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0722737325",
            "email": "alinanicoleta8586@yahoo.co.uk"
          },
          {
            "name": "CVORUM TRANS SRL",
            "address": "STR.TUDOR VLADIMIRESCU BL.3 SC.B ",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM,",
            "telephone": "0722706632",
            "email": "cvorumtrans@yahoo.com"
          },
          {
            "name": "CHINDIA AUTO GRUP SRL",
            "address": "Str. Calea Campulung, nr. 51A  ",
            "category": "B",
            "telephone": "0744321870",
            "email": "office@chindia.ro"
          },
          {
            "name": "AUTO-COM SRL",
            "address": "STR.MR.ION ALEXANDRESCU NR.2A ",
            "category": "B,C,CE",
            "telephone": "0722258161",
            "email": "gigi.tataru@yahoo.com"
          },
          {
            "name": "ROTARU NECULAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.MIRON COSTIN NR.1A",
            "category": "B",
            "telephone": "0721874451",
            "email": "alinanicoleta8586@yahoo.co.uk"
          },
          {
            "name": "PARASCHIVESCU ADRIAN-BOGDAN \nP.F.A",
            "address": "STR.HOTIN NR.1 BL.C1D ET.1 AP.2",
            "category": "B",
            "telephone": "0720787282",
            "email": "alinanicoleta8586@yahoo.co.uk"
          },
          {
            "name": "TUDOSE NICUSOR PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.DIACONU CORESI NR.7 BL.10 SC.B \nET.4 AP.40",
            "category": "B",
            "telephone": "0723575773",
            "email": "nicutudose41@yahoo.com"
          },
          {
            "name": "P.F.A.GORE M ION",
            "address": "STR.RADU POPESCU NR.4A BL.32C ET.4 ",
            "category": "B",
            "telephone": "0724209911",
            "email": "anamariatoader2012@gmail.com"
          },
          {
            "name": "P.F.A.NEAGOE  V MARIN",
            "address": "CALEA CAMPULUNG BL.33 SC.B ET.4 ",
            "category": "B",
            "telephone": "",
            "email": "marian_neagoe56@yahoo.com"
          },
          {
            "name": "PFA CIOTAU PETRE DAN",
            "address": "STR.TUDOR VLADIMIRESCU NR.40",
            "category": "B",
            "telephone": "0744497005",
            "email": "danpetreciotau@gmail.com"
          },
          {
            "name": "PFA MEGA BEBE-NICOLAE",
            "address": "STR.GABRIEL POPESCU NR.13 BL.1 SC.C ",
            "category": "B",
            "telephone": "0722508032",
            "email": "megabebe1967@yahoo.com"
          },
          {
            "name": "P.F.A.CALOMFIRESCU MARIUS",
            "address": "STR.MIHAI POPESCU NR.4A",
            "category": "B",
            "telephone": "0723580370",
            "email": "marius0200@yahoo.com"
          },
          {
            "name": "P.F.A. SAVU N C ADRIAN",
            "address": "Str.Madrid Nr.9 Bl.M1 Et.2 Ap.12",
            "category": "B",
            "telephone": "0728255466",
            "email": "adrian_savu22@yahoo.com"
          },
          {
            "name": "P.F.A. BANITA V ADRIAN",
            "address": "Str.Moldovei Nr.22 Bl.66 Sc.A Et.1 Ap.5",
            "category": "B",
            "telephone": "0721771317",
            "email": "adrian.banita72@yahoo.com"
          }
        ]
      },
      {
        "city": "MORENI",
        "schools": [
          {
            "name": "MABISO SRL",
            "address": "STR.22DECEMBRIE1918 NR.16",
            "category": "B",
            "telephone": "0722622080",
            "email": "mabisosrl@yahoo.com"
          },
          {
            "name": "P.F.A.CHIRITA MARIUS",
            "address": "STR.HORIA,CLOSCA SI CRISAN NR.115E",
            "category": "B",
            "telephone": "0726680420",
            "email": "anamariatoader2012@gmail.com"
          },
          {
            "name": "NICULESCU C MIHAIL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.GHIOCEILOR NR.1",
            "category": "B",
            "telephone": "0722229285",
            "email": "alinanicoleta8586@yahoo.co.uk"
          },
          {
            "name": "MARINESCU V CONSTANTIN \nPERSOANA FIZICA AUTORIZATA",
            "address": "B-DUL PANDURI NR.5 BL.D7A AP.2",
            "category": "B",
            "telephone": "0723290815",
            "email": "constantin_marinescu243@yahoo.com"
          }
        ]
      },
      {
        "city": "GAESTI",
        "schools": [
          {
            "name": "LUXCON SRL",
            "address": "STR.13DECEMBRIE NR.206",
            "category": "B,C,CE",
            "telephone": "0723344226",
            "email": "virgillux@yahoo.com"
          },
          {
            "name": "GETLUC PILOTAJ SRL",
            "address": "STR.1DECEMBRIE NR.47",
            "category": "B",
            "telephone": "0763582236",
            "email": "autotrans99@yahoo.com"
          },
          {
            "name": "VIMACON DRIVE SRL",
            "address": "STR.NICOLAE TITULESCU NR.24-26",
            "category": "B",
            "telephone": "0722551923",
            "email": "vimacon.drive@gmail.com"
          },
          {
            "name": "PFA LEPADATU SORIN NOROCEL",
            "address": "STR.13 DECEMBRIE BL.46 SC.E ET.4 ",
            "category": "B",
            "telephone": "07663447784",
            "email": "norocels@yahoo.com"
          },
          {
            "name": "PFA OPREA ALEXANDRU V MARIAN",
            "address": "STR.ARGESULUI NR.19",
            "category": "B",
            "telephone": "0722271153",
            "email": "marianopreagef@yahoo.com"
          }
        ]
      },
      {
        "city": "TIRGOVISTE",
        "schools": [
          {
            "name": "GIGALO PREST COM SRL",
            "address": "STR.AL.I.CUZA NR.52",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0723341262",
            "email": "elena17_serban@yahoo.com"
          }
        ]
      },
      {
        "city": "PUCIOASA",
        "schools": [
          {
            "name": "NORD TRANS SA",
            "address": "STR.INDEPENDENTEI, NR.4",
            "category": "B",
            "telephone": "0245232312",
            "email": "nordtranspucioasa@yahoo.com"
          }
        ]
      },
      {
        "city": "VALENI \nDAMBOVITA",
        "schools": [
          {
            "name": "CIRSTIAN DANIEL PERSOANA FIZICA \nAUTORIZATA",
            "address": "SAT VALENI DAMBOVITA NR.825",
            "category": "B",
            "telephone": "0727302380",
            "email": "d_cirstian@yahoo.com"
          }
        ]
      },
      {
        "city": "ODOBESTI",
        "schools": [
          {
            "name": "TUDOR SILVIU ION PERSOANA \nFIZICA AUTORIZATA",
            "address": "SAT BRANCOVEANU NR.73",
            "category": "B",
            "telephone": "0723663245",
            "email": "alinanicoleta8586@yahoo.co.uk"
          }
        ]
      },
      {
        "city": "RAZVAD",
        "schools": [
          {
            "name": "P.F.A PARASCHIVA I. GHEORGHE",
            "address": "STR.PREOT GOGLEA NR.179 SAT VALEA ",
            "category": "B",
            "telephone": "0726672825",
            "email": "anamariatoader2012@gmail.com"
          }
        ]
      },
      {
        "city": "SOTANGA",
        "schools": [
          {
            "name": "P.F.A. DUMITRU GH.EUGEN",
            "address": "CALEA TARGOVISTEI NR.220 SAT TEIS",
            "category": "B",
            "telephone": "0722395585",
            "email": "krambert@yahoo.com"
          }
        ]
      },
      {
        "city": "GURA FOII",
        "schools": [
          {
            "name": "PFA CONSTANTIN VICTOR",
            "address": "SAT GURA FOII",
            "category": "B",
            "telephone": "0766702849",
            "email": "alinanicoleta8586@yahoo.co.uk"
          }
        ]
      },
      {
        "city": "DRAGODANA",
        "schools": [
          {
            "name": "PFA GHEORGHE GR.PETRICA",
            "address": "SAT DRAGODANA",
            "category": "B",
            "telephone": "0722517379",
            "email": "alinanicoleta8586@yahoo.co.uk"
          }
        ]
      },
      {
        "city": "PIETROSITA",
        "schools": [
          {
            "name": "P.F.A DUMITRACHE D.IONICA",
            "address": "SAT DEALU FRUMOS COM.PIETROSITA",
            "category": "B",
            "telephone": "0766244482",
            "email": "autotrans99@yahoo.com"
          }
        ]
      },
      {
        "city": "CRANGURILE",
        "schools": [
          {
            "name": "AUTO ERIDAM GAM SRL",
            "address": "SAT CRANGURILE DE JOS NR.33",
            "category": "B",
            "telephone": "",
            "email": "daniela_elamea86@yahoo.com"
          }
        ]
      },
      {
        "city": "BRANISTEA",
        "schools": [
          {
            "name": "P.F.A ILIE ION F.GHEORGHE",
            "address": "",
            "category": "B",
            "telephone": "0726303919",
            "email": "alinanicoleta8586@yahoo.co.uk"
          }
        ]
      },
      {
        "city": "BALENI",
        "schools": [
          {
            "name": "P.F.A. APOSTOL GHE D VASILE",
            "address": "SAT.BALENI-ROMANI SOS.BUCURESTI-",
            "category": "B",
            "telephone": "0724446862",
            "email": "ufotgv@gmail.com"
          }
        ]
      },
      {
        "city": "I. L. CARAGIALE",
        "schools": [
          {
            "name": "P.F.A.SOARE-GRIGORAS VASILE",
            "address": "SAT.GHIRDOVENI COM.I.L.CARAGIALE ",
            "category": "B",
            "telephone": "0722622080",
            "email": "soaretamara@yahoo.com"
          }
        ]
      },
      {
        "city": "TARTASESTI",
        "schools": [
          {
            "name": "P.F.A.CONSTANTIN S DANIEL ",
            "address": "SAT TARTASESTI NR.294",
            "category": "B",
            "telephone": "0723387032",
            "email": "danielconstantin44@yahoo.com"
          }
        ]
      },
      {
        "city": "IEDERA",
        "schools": [
          {
            "name": "P.F.A. COCA MIHAI STELIAN",
            "address": "Sat Iedera de Sus Nr.146",
            "category": "B",
            "telephone": "0723546062",
            "email": "mihaicoca73@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "DJ",
    "schools": [
      {
        "city": "CRAIOVA",
        "schools": [
          {
            "name": "ELESTON SRL",
            "address": "STR.BRESTEI NR.35",
            "category": "B",
            "telephone": "0761000025",
            "email": "llorenzo25@gmail.com"
          },
          {
            "name": "IULCOST COM SRL",
            "address": "STR.RIULUI NR.185A",
            "category": "B,C,D,CE",
            "telephone": "0745772700",
            "email": "iulcost@yahoo.com"
          },
          {
            "name": "ROBERT AUTO SRL",
            "address": "STR.CAROL NR.51",
            "category": "B",
            "telephone": "0744991268",
            "email": "rrrrobertauto@yahoo.com"
          },
          {
            "name": "OZUNU SRL",
            "address": "STR.LALELELOR NR.42",
            "category": "B",
            "telephone": "0765390408",
            "email": "SCOALA_OZUNU@YAHOO.COM"
          },
          {
            "name": "DRIVESTEST LIVIU BANA SRL",
            "address": "STR.UNIRII NR.50",
            "category": "B,C,D,BE,CE",
            "telephone": "0723552363",
            "email": "drivestestliviubana@yahoo.com"
          },
          {
            "name": "ALFA LFC AUTO SRL",
            "address": "STR.BIHORULUI NR.14",
            "category": "B,C,CE",
            "telephone": "0745577355",
            "email": "calinsalceanu@yahoo.com"
          },
          {
            "name": "ALL AUTO DRIVE SRL",
            "address": "STR.BRESTEI NR.121",
            "category": "A,B,BE,AM,A2",
            "telephone": "0351185863",
            "email": "scoalaallautodrive@yahoo.com"
          },
          {
            "name": "ELAN AUTO INS SRL",
            "address": "STR.DEZROBIRII NR.118",
            "category": "B",
            "telephone": "0744476097",
            "email": "dj93bmb@gmail.com"
          },
          {
            "name": "PAPUC AUTO SRL",
            "address": "STR.TOPORASI NR.38",
            "category": "B",
            "telephone": "0765234611",
            "email": "scoalapapuc@yahoo.ro"
          },
          {
            "name": "VM IRIZA AUTO SRL",
            "address": "STR.NICOLAE ROMANESCU NR.84",
            "category": "B,C,D,CE",
            "telephone": "0745626318",
            "email": "irizamarius@yahoo.com"
          },
          {
            "name": "AUTO FRATIA SRL",
            "address": "STR.FULGER NR.235P",
            "category": "B",
            "telephone": "0764471487",
            "email": "anory70@yahoo.com"
          },
          {
            "name": "ZIM TIM AUTO SRL",
            "address": "STR.GH.DOJA NR.66",
            "category": "B",
            "telephone": "0766640830",
            "email": "jeanzim65@yahoo.com"
          },
          {
            "name": "SSI AUTO SRL",
            "address": "CIMPIA ISLAZ NR.40",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0251588700",
            "email": "scoalaignat@yahoo.com"
          },
          {
            "name": "LUPU AUTO TRADING SRL",
            "address": "STR.BRESTEI NR.417",
            "category": "B,C,D,BE,CE",
            "telephone": "0745772672",
            "email": "alice.mirabela.lupu@gmail.com"
          },
          {
            "name": "NEW GENERATION ALL AUTO SRL",
            "address": "CRAIOVA STR.BRESTEI NR.121",
            "category": "B,C,CE",
            "telephone": "0768427123",
            "email": "scoalaallautodrive@yahoo.com"
          },
          {
            "name": "AUTOTEST SORIN SRL",
            "address": "STR.UNIRII NR.50",
            "category": "A,B,C,D,CE",
            "telephone": "0766587076",
            "email": "sorinautoro@yahoo.com"
          },
          {
            "name": "IRIZA AMI AUTO SRL",
            "address": "STR.NICOLAE ROMANESCU NR.84",
            "category": "A,B,A1,AM,A2",
            "telephone": "0731008242",
            "email": "irizamarius@yahoo.com"
          },
          {
            "name": "ARADI DRIVE SRL",
            "address": "STR.NICOLAE ROMANESCU NR.119",
            "category": "B,C,CE",
            "telephone": "0766305858",
            "email": "aradrive@yahoo.com"
          },
          {
            "name": "COLEGIUL STEFAN ODOBLEJA",
            "address": "STR.ELECTROPUTERE NR.21",
            "category": "B",
            "telephone": "0723986270",
            "email": "pascumarian58@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC GEORGE \nBIBESCU",
            "address": "STR.CONSTANTIN BRANCOVEANU \nNR.101",
            "category": "B,C",
            "telephone": "0251423902",
            "email": "cristi_cojy@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DE \nTRANSPORTURI AUTO",
            "address": "B-DUL NICOLAE ROMANESCU, NR.99",
            "category": "B,C,CE,C1",
            "telephone": "0251427636",
            "email": "ivcristina2007@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  CONSTANTIN \nBRINCUSI",
            "address": "STR.C-TIN BRINCUSI NR.17",
            "category": "B",
            "telephone": "0251599509",
            "email": "craiovactcbrancusi@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC AUTO",
            "address": "STR.DECEBAL NR.105",
            "category": "B,C",
            "telephone": "0745605253",
            "email": "catalinraicea@yahoo.com"
          },
          {
            "name": "FIRA PAUL",
            "address": "STR.BARIERA VILCII NR.76",
            "category": "B",
            "telephone": "0761998686",
            "email": "fira_paul@ymail.com"
          },
          {
            "name": "MALIOVSKI DAN",
            "address": "STR.BARIERA VILCII NR.76",
            "category": "B",
            "telephone": "0722751516",
            "email": "dancdna@k.ro"
          },
          {
            "name": "NEX DRIVE SRL",
            "address": "AL.SFINTII APOSTOLI NR.160",
            "category": "A,B,BE,AM,A2",
            "telephone": "0745885368",
            "email": "scoalaallautodrive@yahoo.com"
          },
          {
            "name": "S.K.PINK PANTHER SRL",
            "address": "STR.GHORGHE DOJA NR.30",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0768427123",
            "email": "scoalapinkpanther@yahoo.com"
          },
          {
            "name": "AUTODUC SRL",
            "address": "STR.SFINTII APOSTOLI NR.160",
            "category": "B",
            "telephone": "0744532174",
            "email": "autoduc2016@gmail.com"
          },
          {
            "name": "AUTO CIRSTOIU SRL",
            "address": "STR.RIULUI NR.277",
            "category": "B,C,CE",
            "telephone": "0766291715",
            "email": "autocirstoiu@gmail.com"
          },
          {
            "name": "CILI DRIVE SRL",
            "address": "AL.MATEI BASARAB NR.4 PARTER ",
            "category": "B",
            "telephone": "0766814391",
            "email": "andy_marinescu95@yahoo.ro"
          },
          {
            "name": "AUTO ROIBU SRL",
            "address": "STR.CONSTANTIN BRANCUSI NR.21A",
            "category": "B,C,BE,CE",
            "telephone": "0764471487",
            "email": "cristinutza_aurelia@yahoo.com"
          },
          {
            "name": "ALE SI FEL SRL",
            "address": "STR.DIMITRIE GEROTA NR.103 PARTER",
            "category": "B,C,D,CE",
            "telephone": "0767680313",
            "email": "scoalaalexmotor@gmail.com"
          },
          {
            "name": "BELEA CONSTANTIN",
            "address": "STR.DACIA NR.111A BL.6A",
            "category": "B",
            "telephone": "0749983046",
            "email": "beleaconstantin@yahoo.com"
          },
          {
            "name": "CELEA MARIN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.NARCISELOR, Nr.1, Bl.16bis, Sc.1, \nAp.10",
            "category": "B",
            "telephone": "0745305302",
            "email": "marinelfir@gmail.com"
          },
          {
            "name": "FIRULESCU MARINEL CORNELIU PFA",
            "address": "STR. TOMIS NR.25D",
            "category": "B",
            "telephone": "0722524764",
            "email": "marinelfir@yahoo.com"
          },
          {
            "name": "TALPA GHE. IONEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.PUTNEI NR.11 BLOC CAMIN4 SC.1 \nAP.2",
            "category": "B",
            "telephone": "0741193161",
            "email": "irina.mihaela93@gmail.com"
          },
          {
            "name": "BADICA IULIAN MARIUS PERSOANA \nFIZICA AUTORIZATA",
            "address": "BDUL OLTENIA NR.46 BL.27 SC.7 AP.14",
            "category": "B",
            "telephone": "0740464237",
            "email": "instructoru_2008@yahoo.com"
          },
          {
            "name": "ILEANA MARIUS CRISTIAN \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.NICOLAE COCULESCU BL.88B1 SC.1 \nAP.14",
            "category": "B",
            "telephone": "0768857476",
            "email": "marius_inst@yahoo.com"
          },
          {
            "name": "P.F.A.BASARAB DANIEL LEONARD ",
            "address": "STR.DACIA NR.111A BL.6A",
            "category": "B",
            "telephone": "0766447609",
            "email": "leonardbasarab22@gmail.com"
          },
          {
            "name": "P.F.A. IGNAT ALEXANDRU",
            "address": "STR.DACIA NR.111 BL.6A",
            "category": "B",
            "telephone": "0767880021",
            "email": "sandy.san81@yahoo.com"
          },
          {
            "name": "OZUNU AUTO SRL",
            "address": "STR.BANUL STEPAN NR.1A CAM.3 SI 9",
            "category": "A,B,C,D,BE,CE",
            "telephone": "0765390408",
            "email": "mely_amy@yahoo.com"
          },
          {
            "name": "SCHOOL DRIVE UDRISTE SRL",
            "address": "STR.RIULUI NR.331A ET.1",
            "category": "B,C,D,CE",
            "telephone": "0766271190",
            "email": "schooludriste@yahoo.com"
          },
          {
            "name": "P.F.A. GERA STEFAN",
            "address": "BV.DACIA NR.111A BL.6A CAM.2",
            "category": "B",
            "telephone": "0720595692",
            "email": "scoalaignat@yahoo.com"
          },
          {
            "name": "P.F.A. GRIGORE I. IULIAN",
            "address": "STR.SOIMULUI NR.20",
            "category": "B",
            "telephone": "0752328359",
            "email": "grygore89@gmail.com"
          },
          {
            "name": "P.F.A.PLOAE NICOLAE",
            "address": "STR.SOIMULUI NR.20",
            "category": "B",
            "telephone": "0723129170",
            "email": "grygore27@gmail.com"
          },
          {
            "name": "P.F.A. SANDOI VIRGIL",
            "address": "STR.SOIMULUI NR.20",
            "category": "A,B,A1",
            "telephone": "0768668158",
            "email": "virgilsandoi@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \nTRANSPORTURI CAI FERATE ",
            "address": "STR.CONSTANTIN BRANCUSI NR,15",
            "category": "B",
            "telephone": "0744934250",
            "email": "caiferatecraiova@yahoo.com"
          },
          {
            "name": "AUTOTEST UDRISTE SRL",
            "address": "STR.MUNTENIA NR.114A",
            "category": "B,B1",
            "telephone": "0766271190",
            "email": "AUTOTESTUDRISTE@YAHOO.COM"
          },
          {
            "name": "PROFI INSTRUCT SRL",
            "address": "STR.DEZROBIRII, NR.59",
            "category": "B,C,CE",
            "telephone": "0765856469",
            "email": "profiinstruct@yahoo.com"
          },
          {
            "name": "PERMIS AUTO ABCDE SRL",
            "address": "STR.DACIA NR.111A BLOC 6A",
            "category": "B",
            "telephone": "0251588700",
            "email": "scoalaignat@yahoo.com"
          },
          {
            "name": "IDRIVE ACCELEREAZA SRL",
            "address": "STR.VIITORULUI NR.50",
            "category": "B",
            "telephone": "0721227221",
            "email": "adrianaandrei888@gmail.com"
          },
          {
            "name": "PAPUC DRIVE SRL",
            "address": "STR.TOPORASI NR.38 ,corp C2/2",
            "category": "B",
            "telephone": "0765234611",
            "email": "scoalapapuc@yahoo.ro"
          }
        ]
      },
      {
        "city": "CRAIOVA ",
        "schools": [
          {
            "name": "COSMALEX TRANS SRL",
            "address": "STR.RIULUI NR.185A",
            "category": "A,B,BE,A1,AM,A2",
            "telephone": "0251417438",
            "email": "iulcost@yahoo.com"
          },
          {
            "name": "PUCA DUMITRU",
            "address": "STR.BARIERA VILCII NR.76",
            "category": "B",
            "telephone": "0744237751",
            "email": "dancdna@k.ro"
          },
          {
            "name": "CIRSTEA MARIAN",
            "address": "STR.BARIERA VILCII NR.76",
            "category": "B",
            "telephone": "0766717248",
            "email": "dancdna@k.ro"
          }
        ]
      },
      {
        "city": "CALAFAT",
        "schools": [
          {
            "name": "DOMYON SRL",
            "address": "STR.TEILOR NR.50",
            "category": "B",
            "telephone": "0766686363",
            "email": "ionescu_gratiel@yahoo.com"
          },
          {
            "name": "TOTAL AUTO BIS SRL",
            "address": "STR.T.VLADIMIRESCU NR.21 ",
            "category": "B",
            "telephone": "0769323168",
            "email": "sorin_instructorauto@yaho.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC STEFAN ",
            "address": "STR.TRAIAN NR.2",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      },
      {
        "city": "BAILESTI",
        "schools": [
          {
            "name": "ALEX & BIA SRL",
            "address": "STR.OITUZ NR.39",
            "category": "B",
            "telephone": "0767984680",
            "email": "vbotescu@yahoo.com"
          },
          {
            "name": "FLOR SIN SRL",
            "address": "STR.VICTORIEI NR.152",
            "category": "B",
            "telephone": "0724061041",
            "email": "adrian_eugen_florescu@yahoo.com"
          },
          {
            "name": "JANE PETRISOR AUTO SRL",
            "address": "STR.INDEPENDENTEI NR.7",
            "category": "B",
            "telephone": "0762982399",
            "email": "petrisorjane@yahoo.com"
          },
          {
            "name": "SGA DRIVING SRL",
            "address": "STR.VICTORIEI NR.86 BL.A3 PARTER",
            "category": "B,C,CE",
            "telephone": "0768014770",
            "email": "SGADRIVINGSRL@gmail.com"
          },
          {
            "name": "AUTO DRIVE FLORESCU SRL",
            "address": "STR.VICTORIEI NR.152",
            "category": "B",
            "telephone": "0766376655",
            "email": "adrian_eugen_florescu@yahoo.co"
          },
          {
            "name": "LICEUL TEHNOLOGIC STEFAN \nANGHEL BAILESTI",
            "address": "STR.REVOLUTIEI NR.4",
            "category": "B",
            "telephone": " 0721214210",
            "email": "gsabailesticv2004@yahoo.com"
          },
          {
            "name": "ALNITI COSTEL GHEORGHE \nPERSOANA FIZICA AUTORIZATA",
            "address": "PRELUNGIREA MESERIASI NR.128",
            "category": "B",
            "telephone": "0765588554",
            "email": "roxana.roxana4687@yahoo.com"
          }
        ]
      },
      {
        "city": "COM.MAGLAVIT",
        "schools": [
          {
            "name": "STAM M.A.M. SRL",
            "address": "STR.GARII NR.32",
            "category": "B",
            "telephone": "0762881305",
            "email": "marian.stamin@yahoo.com"
          }
        ]
      },
      {
        "city": "POIANA MARE",
        "schools": [
          {
            "name": "AUTO APOLODOR SRL",
            "address": "STR.MIHAIL KOGALNICEANU NR.7",
            "category": "B",
            "telephone": "0769727566",
            "email": "laviniapatruca@yahoo.com"
          }
        ]
      },
      {
        "city": "COTOFENII DIN \nFATA",
        "schools": [
          {
            "name": "COGITO SRL",
            "address": "STR.BUJORULUI NR.190",
            "category": "B",
            "telephone": "0745355722",
            "email": "marcel_musa@yahoo.com"
          }
        ]
      },
      {
        "city": "SEGARCEA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC HORIA ",
            "address": "STR.UNIRII NR.33",
            "category": "B,Tr",
            "telephone": "0251210507",
            "email": "ciocoiuconstantin@gmail.com"
          }
        ]
      },
      {
        "city": "BRANISTE",
        "schools": [
          {
            "name": "FLORITA SCOALA DE SOFERI SRL",
            "address": "STR.CALAFATULUI NR.57A",
            "category": "B",
            "telephone": "0722708366",
            "email": "scoalaflorita@yahoo.com"
          }
        ]
      },
      {
        "city": "VALEA STANCIULUI",
        "schools": [
          {
            "name": "SCOALA PROFESIONALA VALEA \nSTANCIULUI",
            "address": "STR.ARHITECT CEZAR LAZARESCU NR.57",
            "category": "B",
            "telephone": "0745527501",
            "email": "samvaleastanciului@yahoo.com"
          }
        ]
      },
      {
        "city": "PODARI",
        "schools": [
          {
            "name": "AUTO K&K SRL",
            "address": "STR.DUNARII NR.144B",
            "category": "B",
            "telephone": "0726922256",
            "email": "karinaflorita@yahoo.com"
          }
        ]
      },
      {
        "city": "ISALNITA",
        "schools": [
          {
            "name": "AUTO TEST GICA SRL",
            "address": "STR.UNIRII NR.9",
            "category": "B",
            "telephone": "O766320450",
            "email": "gilone_80@yahoo.com"
          },
          {
            "name": "AUTOZUN DRIVE SRL",
            "address": "STR.UNIRII NR.9",
            "category": "B",
            "telephone": "0770880659",
            "email": "mely_amy@yahoo.com"
          }
        ]
      },
      {
        "city": "CALARASI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC PETRE BANITA",
            "address": "STR.PETRE BANITA NR.219",
            "category": "B,Tr",
            "telephone": "0745016301",
            "email": "alindinu2007@yahoo>com"
          }
        ]
      },
      {
        "city": "FILIASI",
        "schools": [
          {
            "name": "TOMA C. NICOLAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "SAT BALTA, STR.PANDURILOR, NR.201",
            "category": "B",
            "telephone": "0723650665",
            "email": "Toma_nicolae543@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DIMITRIE \nFILISANU",
            "address": "BV.RACOTEANU NR.194",
            "category": "B",
            "telephone": "0745788006",
            "email": "nenumarian@gmail.com"
          }
        ]
      },
      {
        "city": "AMARASTII DE JOS",
        "schools": [
          {
            "name": "P.F.A.PENA F. MARIAN",
            "address": "SAT.PRAPOR NR.418",
            "category": "B",
            "telephone": "0766614580",
            "email": "marianpena05@gmail.com"
          }
        ]
      },
      {
        "city": "CARCEA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC CONSTANTIN \nIANCULESCU",
            "address": "STR.AEROPORTULUI NR.118",
            "category": "B",
            "telephone": "0766472964",
            "email": "gsa_carcea@yahoo.com"
          },
          {
            "name": "GRUP SCOLAR AGRICOL CIRCEA",
            "address": "CIRCEA",
            "category": "B",
            "telephone": "0251458007",
            "email": ""
          }
        ]
      },
      {
        "city": "VELA",
        "schools": [
          {
            "name": "P.F.A.STOIAN S.FLORIN",
            "address": "SAT.VELA COMUNA VELA NR.300",
            "category": "B",
            "telephone": "0765555629",
            "email": "florin.stoian2018@yahoo.com"
          }
        ]
      },
      {
        "city": "CASTRANOVA",
        "schools": [
          {
            "name": "KVKMIKI AUTO DRIVE SRL",
            "address": "SAT.PUTURI STR.GOGOSANILOR NR.18",
            "category": "B",
            "telephone": "0763696199",
            "email": "constantinciovica@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "GJ",
    "schools": [
      {
        "city": "TARGU JIU",
        "schools": [
          {
            "name": "ATOMIC SRL",
            "address": "STR.CONSTANTIN DOBROGEANU ",
            "category": "B",
            "telephone": "0723520171",
            "email": "atomicpaul2000@yahoo.com"
          },
          {
            "name": "DARISER SERV SRL",
            "address": "STR.UNIRII NR.18-20 COMPLEX ",
            "category": "B,BE",
            "telephone": "0722409423",
            "email": "SPIDER_COM_SRL@YAHOO.COM"
          },
          {
            "name": "SETIFRAM SRL",
            "address": "STR.LT.COL.DUMITRU PETRESCU NR.8 ",
            "category": "B",
            "telephone": "0724169741",
            "email": "scoalasetifram@yahoo.com"
          },
          {
            "name": "GEORAL PROFFI SRL",
            "address": "STR.1 DECEMBRIE 1918 NR.35",
            "category": "B,C,CE",
            "telephone": "0762244624",
            "email": "georalproffi@yahoo.com"
          },
          {
            "name": "AUTORADIX SRL",
            "address": "STR.22 DCEMBRIE 1989 NR.49",
            "category": "B,C,CE",
            "telephone": "0767796432",
            "email": "georalproffi@yahoo.com"
          },
          {
            "name": "COPILOT AUTO SRL",
            "address": "STR.PROGRESULUI NR.30",
            "category": "A,B,C,D,BE,CE,A1,B1,D1,",
            "telephone": "0768897646",
            "email": "copilot_auto@yahoo.com"
          },
          {
            "name": "COLEGIUL AUTO TRAIAN VUIA",
            "address": "BULEVARDUL ECATERINA TEODOROIU ",
            "category": "B",
            "telephone": "0353401701",
            "email": "ceraselabaltaretu@yahoo.com"
          },
          {
            "name": "AUTO LUCALEX GROUP SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.25 ",
            "category": "B",
            "telephone": "0721237408",
            "email": "autolucalexgroup@yahoo.com"
          },
          {
            "name": "CRISMALEX AUTOTEST SRL",
            "address": "STR.9 MAI NR.50 ",
            "category": "B",
            "telephone": "0765247977",
            "email": "scoala@crismalex.ro"
          },
          {
            "name": "PERFECT AUTODRIVERLEX SRL",
            "address": "STR.ECATERINA TEODOROIU NR.105C ",
            "category": "B",
            "telephone": "0745647426",
            "email": "perfectaautodriver@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC BIRSESTI",
            "address": "LOC.BIRSESTI NR.8",
            "category": "B,Tr",
            "telephone": "0727766850",
            "email": "mariadabelea@yahoo.com"
          },
          {
            "name": "CARTEST SRL",
            "address": "STR.DUMITRU PLENICEANU NR.7",
            "category": "B,C,BE,CE",
            "telephone": "0765243823",
            "email": "cartest2005@yahoo.com"
          },
          {
            "name": "SUCCES NIC COM SRL",
            "address": "STR.ECATERINA TEODOROIU NR.99",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0253214080",
            "email": "scoalasucces@yahoo.com"
          },
          {
            "name": "SPIDER COM SRL",
            "address": "STR.ECATERINA TEODOROIU NR.7 ET.2",
            "category": "A,B,C,D,Tr,CE,A1,AM,A2",
            "telephone": "0253212314",
            "email": "SPIDER_COM_SRL@YAHOO.COM"
          },
          {
            "name": "S.C. VASIGEN AUTO S.R.L.",
            "address": "STR.11 IUNIE 1848,NR.54.",
            "category": "B",
            "telephone": "0744773990",
            "email": "vasigenauto@yahoo.com"
          },
          {
            "name": "SERBAN COMPANY SRL",
            "address": "STR.ISLAZ NR.11 CLADIRE C1",
            "category": "B",
            "telephone": "0722409423",
            "email": "SPIDER_COM_SRL@YAHOO.COM"
          },
          {
            "name": "ILMA CORALO SRL",
            "address": "STR.VICTORIEI NR.137 ET.2 BIROU 1",
            "category": "B",
            "telephone": "0744646491",
            "email": "ilmacoralo@yahoo.com"
          },
          {
            "name": "ACR AUTO TURISM SRL",
            "address": "STR.ZONA UNIRII SIRET BL.1 PARTER",
            "category": "B",
            "telephone": "0744382720",
            "email": "acr_gj@acr.ro"
          },
          {
            "name": "COLEGIUL TEHNIC NR.2",
            "address": "STR.VICTORIEI NR.132-138",
            "category": "B",
            "telephone": "0253228006",
            "email": "botanciu_ion@yahoo.com"
          },
          {
            "name": "GORJAUTO SRL",
            "address": "STR.ALEEA MINERILOR NR.3",
            "category": "B,C,CE",
            "telephone": "0766422144",
            "email": "gorjauto@yahoo.com"
          },
          {
            "name": "RALY LUCALEX PROFI SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.25 ",
            "category": "B",
            "telephone": "0766356069",
            "email": "ralylucalexprofi@yahoo.com"
          },
          {
            "name": "DRIVE SCHOOL 2017 SRL",
            "address": "BULEVARDUL ECATERINA TEODOROIU ",
            "category": "B,C,CE",
            "telephone": "769278430",
            "email": "driverschool2017@gmail.com"
          },
          {
            "name": "TSC 19B SRL",
            "address": "B-DUL CONSTANTIN BRANCUSI NR.10",
            "category": "A,B,BE,A1,AM",
            "telephone": "0769876953",
            "email": "turbatu.robert@yahoo.com"
          },
          {
            "name": "AUTOALEXDOR SRL",
            "address": "STR.STEFAN CEL MARE NR.34 CAMERA ",
            "category": "B",
            "telephone": "0725094118",
            "email": "enoiuandra19@gmail.com"
          },
          {
            "name": "AUTO PROFFI DRIVER SRL",
            "address": "STR.1 DECEMBRIE 1918 NR.35 PARTER SI ",
            "category": "B,C,CE",
            "telephone": "0762244624",
            "email": "georalproffi@yahoo.com"
          },
          {
            "name": "MARIO-RACE-TEAM SRL",
            "address": "ALEEA DIGULUI NR.22 ET.1",
            "category": "B",
            "telephone": "0771074264",
            "email": "anacalugarita81@gmail.com"
          },
          {
            "name": "PSIAUTOMAK SRL",
            "address": "STR.VASILE ALECSANDRI NR.66 ANEXA ",
            "category": "B",
            "telephone": "0766411433",
            "email": "psiautomak@yahoo.com"
          }
        ]
      },
      {
        "city": "BALTENI",
        "schools": [
          {
            "name": "TSC 19B SRL",
            "address": "SAT BILTENI NR.493",
            "category": "C,CE",
            "telephone": "0765050111",
            "email": "tsc19b@yahoo.com"
          },
          {
            "name": "AUTOTNC PILOT SRL",
            "address": "SAT.BALTENI NR.439",
            "category": "B,C,D,CE",
            "telephone": "0769876953",
            "email": "turbatu.robert@yahoo.com"
          }
        ]
      },
      {
        "city": "MOTRU",
        "schools": [
          {
            "name": "BZV LENPILOT SRL",
            "address": "STR.TINERETULUI NR.8",
            "category": "B",
            "telephone": "0727506527",
            "email": "alupoai_lenutza@yahoo.com"
          }
        ]
      },
      {
        "city": "POLOVRAGI",
        "schools": [
          {
            "name": "PETDAN PREST SRL",
            "address": "SAT.POLOVRAGI NR.212",
            "category": "B",
            "telephone": "0724516498",
            "email": "petredraganescu@yahoo.com"
          }
        ]
      },
      {
        "city": "NOVACI",
        "schools": [
          {
            "name": "DENBER SRL",
            "address": "STR.RINCA NR.79 ",
            "category": "B",
            "telephone": "0748407117",
            "email": "denber83@yahoo.com"
          }
        ]
      },
      {
        "city": "BUMBESTI JIU",
        "schools": [
          {
            "name": "COLEGIUL MIHAI VITEAZUL",
            "address": "STR.GHEORGHE DUMITRESCU ",
            "category": "B",
            "telephone": "0253463316",
            "email": "colegiul_mv@yahoo.com"
          }
        ]
      },
      {
        "city": "BAIA DE FIER",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC BAIA DE FIER",
            "address": "STR.PRINCIPALA NR.367",
            "category": "B",
            "telephone": "0253461328",
            "email": "anaionascu26@yahoo.com"
          }
        ]
      },
      {
        "city": "ROVINARI",
        "schools": [
          {
            "name": "DRIVE SCHOOL KARLA SRL",
            "address": "STR.PRIETENIEI NR.26",
            "category": "B",
            "telephone": "0766285370",
            "email": "nicualbu01@yahoo.com"
          }
        ]
      },
      {
        "city": "PESTISANI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC CONSTANTIN \nBRANCUSI",
            "address": "STR.PRINCIPALA NR.5",
            "category": "B",
            "telephone": "0253277113",
            "email": "costel_logascu@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "GL",
    "schools": [
      {
        "city": "GALATI",
        "schools": [
          {
            "name": "ANSORAJ SRL",
            "address": "STR.HENRI COANDA NR.8  BL J5 PARTER",
            "category": "B,C,D,CE",
            "telephone": "0236472799",
            "email": "scoalaaviatia@yahoo.com"
          },
          {
            "name": "BUDIRIS SRL",
            "address": "STR DOGARIEI NR 92",
            "category": "B",
            "telephone": "0756067386",
            "email": "office@salina-consulting.ro"
          },
          {
            "name": "AUTOGRAND SRL",
            "address": "STR.VASILE ALECSANDRI NR.37",
            "category": "B,C,D,CE",
            "telephone": "0744700246",
            "email": "florintenea@yahoo.com"
          },
          {
            "name": "EURO STYLE SRL",
            "address": "STR.DOMNEASCA NR.193",
            "category": "A,B,A1,AM",
            "telephone": "0236/461666",
            "email": "scoala_pilot@yahoo.com"
          },
          {
            "name": "REGATA SF SRL",
            "address": "CRIZANTEMELOR NR 13",
            "category": "B",
            "telephone": "0722208659",
            "email": "stefan_filote@yahoo.ro"
          },
          {
            "name": "TIMO DRIVE SRL",
            "address": "STR.CRIZANTEMELOR NR.49",
            "category": "B",
            "telephone": "0744584398",
            "email": "timanaprest@yahoo.com"
          },
          {
            "name": "AUTO FULGER 1 SRL",
            "address": "STR.BRASOV NR.85",
            "category": "A,B,A1,B1,AM,A2",
            "telephone": "0753883502",
            "email": "ginahanganu@yahoo.com"
          },
          {
            "name": "AUTOMAN DRIVE SRL",
            "address": "STR.LEONARD NAE NR.19, BL.C15,Sc.5, ",
            "category": "B",
            "telephone": "0740091061",
            "email": "Cordoneanu.Georgica@gmail.com"
          },
          {
            "name": "COLEGIUL TEHNIC TRAIAN VUIA",
            "address": "STR ANGHEL SALIGNY NR 114",
            "category": "B,C,CE",
            "telephone": "0236/316873",
            "email": "cttvgalati@yahoo.com"
          },
          {
            "name": "METALTEAM SRL",
            "address": "STR GEORGE COSBUC NR 257B",
            "category": "A,B,C,CE",
            "telephone": "0743151402",
            "email": "scoala.camino@yahoo.com"
          },
          {
            "name": "DINCOROM SRL",
            "address": "STR SATURN. NR 1 COMPLEX COM ",
            "category": "B,C,D,CE",
            "telephone": "0236/463220",
            "email": "cojodyn@yahoo.com"
          },
          {
            "name": "BEJAN CO GEORGIOMIO SRL",
            "address": "STR GEORGE COSBUC NR 292",
            "category": "B,C,D,CE",
            "telephone": "0236415874",
            "email": "scoala.adelante@yahoo.com"
          },
          {
            "name": "ASII VOLANULUI SRL",
            "address": "STR.ROMANA NR.132",
            "category": "B,C,D,CE",
            "telephone": "0236/435834",
            "email": "MERY_GDM@YAHOO.COM"
          },
          {
            "name": "NEREIDA SRL",
            "address": "STR CALUGARENI NR 1A",
            "category": "B",
            "telephone": "0745763866",
            "email": "cordoneanumarian66@yahoo.com"
          },
          {
            "name": "SC AVILINE SRL",
            "address": "STR.OLTULUI NR.17",
            "category": "A,B,BE,A1,AM,A2",
            "telephone": "",
            "email": "scoalaaviatia@yahoo.com"
          },
          {
            "name": "TIMO DRIVE LEO SRL",
            "address": "STR.CRIZANTEMELOR NR.49",
            "category": "B,C,D,CE",
            "telephone": "",
            "email": "TIMANAPREST@YAHOO.COM"
          },
          {
            "name": "DINCOROM STAR SRL",
            "address": "Str. SATURN NR. 1",
            "category": "B",
            "telephone": "0236.463220",
            "email": "cojodin@yahoo.com"
          },
          {
            "name": "LUCIANO H.L.A. SCHOOL",
            "address": "STR.CRISTOFOR COLUMB NR.41",
            "category": "B",
            "telephone": "0724242679",
            "email": "toni_cons@yahoo.com"
          },
          {
            "name": "CAMINO DRIVER SRL",
            "address": "STR.DEMOCRATIEI NR.10",
            "category": "A,B,C,CE",
            "telephone": "0743151402",
            "email": "caminodriver2016@yahoo.com"
          },
          {
            "name": "AXAJOBS LINE SRL-D",
            "address": "STR.BRAILEI NR.26 CORP C1 ET.3",
            "category": "B",
            "telephone": "0741010702",
            "email": "scoalaaviatia@yahoo.com"
          },
          {
            "name": "TRANSURB",
            "address": "B-DUL GEORGE COSBUC NR.259",
            "category": "B,D,Tv",
            "telephone": "0723161411",
            "email": "autobaza1cosbuc@gmail.com"
          },
          {
            "name": "DUAL AUTO SRL",
            "address": "STR OTELARILOR, NR14, M19, PARTER",
            "category": "B,BE",
            "telephone": "0745102444",
            "email": "felixviorel@yahoo.com"
          },
          {
            "name": "AUTOSAB L DRIVE SRL",
            "address": "STR.LEONARD NAE NR.21 CAMERA 1 ",
            "category": "A,B,C,D,BE,CE",
            "telephone": "0740410081",
            "email": "autosabdrive@yahoo.com"
          },
          {
            "name": "CHZ DRIVE ADELANTE SRL",
            "address": "STR.GEORGE COSBUC NR.292 CORP ",
            "category": "B,C,CE",
            "telephone": "",
            "email": "scoala.adelante@yahoo.com"
          },
          {
            "name": "CONDORLINE SRL",
            "address": "STR.OTELARILOR NR.14 PARTER BL.M19 ",
            "category": "B",
            "telephone": "0741010702",
            "email": "scoalaaviatia@yahoo.com"
          },
          {
            "name": "STATE LUCIAN DRIVE SRL",
            "address": "STR.PICTOR OCTAV BANCILA NR.29",
            "category": "B",
            "telephone": "0744693207",
            "email": "STATELUCIANDRIVE@GMAIL.COM"
          },
          {
            "name": "PFA PATRICHE LUCIAN",
            "address": "STR.DOMNEASCA NR.197",
            "category": "B",
            "telephone": "0745616822",
            "email": "patrichelucian@yahoo.com"
          },
          {
            "name": "PFA AGACHE ADRIAN",
            "address": "STR.DOMNEASCA NR.5 BL.P7 SC.1 ET.3 ",
            "category": "B",
            "telephone": "0745938992",
            "email": "ipsi_96@yahoo.com"
          },
          {
            "name": "PFA COJOCARU ANGELICA-ADRIAN",
            "address": "STR.FEROVIARILOR NR.15 BL.C8 AP.64",
            "category": "B",
            "telephone": "0740152541",
            "email": "cojocaruady77@gmail.com"
          },
          {
            "name": "PFA FOTACHE IONEL",
            "address": "BLD.DUNAREA NR.42A BL.BR1 AP.101",
            "category": "B",
            "telephone": "0724243679",
            "email": "instructor.fotache@yahoo.com"
          },
          {
            "name": "PFA DIMA GHEORGHE",
            "address": "STR.ING.ANGHEL SALIGNY NR.145 BL.7C ",
            "category": "B",
            "telephone": "0744777296",
            "email": "newmandimas@gmail.com"
          },
          {
            "name": "PFA COSTEA DANUT",
            "address": "BLD.SIDERUGISLOR NR.17 BL.C2 AP.7",
            "category": "B",
            "telephone": "0745825449",
            "email": "costeamadalin18@yahoo.ro"
          },
          {
            "name": "PFA PARLAPAN CATALIN",
            "address": "BLD.DUNAREA NR.66 BL.N7 SC.3 PARTER ",
            "category": "B",
            "telephone": "0743122045",
            "email": "catalin_parlapan@yahoo.com"
          },
          {
            "name": "PFA PETREA CATALIN",
            "address": "STR.STADIONULUI NR.4A BL.D19 AP.17",
            "category": "B",
            "telephone": "0741225174",
            "email": "catapetrea@yahoo.com"
          },
          {
            "name": "PFA LUPU DORIN-NICOLAE",
            "address": "STR.STRUNGARILOR NR.9 BL.U3 AP.21",
            "category": "B",
            "telephone": "0747750784",
            "email": "dorinlupu69@yahoo.com"
          },
          {
            "name": "PFA NEGRUS-COSTIN ADRIAN",
            "address": "STR.TECUCI NR.156 D BL.T4 SC.1 RT2 ",
            "category": "B",
            "telephone": "0744205406",
            "email": "alinanegrus@yahoo.com"
          },
          {
            "name": "PFA BALUTA NICU",
            "address": "STR.NUCULUI NR.3 BL.26 SC.1 ET.3 ",
            "category": "B",
            "telephone": "0743809460",
            "email": "nbaluta1@gmail.com"
          },
          {
            "name": "PFA MUNTEANU ALEN-DUMITRU",
            "address": "STR.MOVILEI NR.88 CORP C7 ",
            "category": "B",
            "telephone": "0745071073",
            "email": "dea17_elena@yahoo.com"
          },
          {
            "name": "PFA MACOVEI STEFAN-IONEL",
            "address": "STR. MOVILEI NR.88 CORP C7 ",
            "category": "B",
            "telephone": "0741393866",
            "email": "macoveiionel11@yahoo.com"
          },
          {
            "name": "PFA CERCEL BOGDAN",
            "address": "STR.ARCASILOR NR.99",
            "category": "B",
            "telephone": "0740411271",
            "email": "gina_cercel@yahoo.com"
          },
          {
            "name": "PFA SANDULEAC GHEORGHE",
            "address": "STR.MOVILEI NR.88 CORP C7 SALA2",
            "category": "B",
            "telephone": "0721706398",
            "email": "saduleac_gheorghe@yahoo.com"
          },
          {
            "name": "PFA FILIMON GHEORGHE",
            "address": "B-DUL GALATI NR.31 BL.N11 AP.23",
            "category": "B",
            "telephone": "0745468136",
            "email": "gaby_andreea15@yahoo.com"
          },
          {
            "name": "FULGER LEONARD DRIVE SRL",
            "address": "Str.Drumul Viilor, Nr.14, Trons.1, Micro ",
            "category": "C,CE",
            "telephone": "",
            "email": "ginahanganu@yahoo.com"
          },
          {
            "name": "FD DINCOROM TRANS",
            "address": "STR.REGIMENT 11 SIRET NR.2F-S3 BIROU ",
            "category": "B,C,CE",
            "telephone": "0236463220",
            "email": "cojodin@gmail.com"
          },
          {
            "name": "ORIZONT GEOGAB CAAG",
            "address": "Str.Basarabiei nr.142, Bl.N3A ,parter",
            "category": "B,C,CE",
            "telephone": "",
            "email": "gaby_andreea15@yahoo.com"
          },
          {
            "name": "P.F.A. GURAU I.GHEORGHE",
            "address": "GALATI,B-DUL SIDERURGISTILOR NR.36 ",
            "category": "B",
            "telephone": "0743135835",
            "email": "gaby_andreea15@yahoo.com"
          },
          {
            "name": "P.F.A. TOFAN IULIAN",
            "address": "",
            "category": "B",
            "telephone": "",
            "email": "gaby_andreea15@yahoo.com"
          },
          {
            "name": "P.F.A. ANDRONACHE VASILE ",
            "address": "STR.OLTULUI NR.47 BL.I AP.16",
            "category": "B",
            "telephone": "",
            "email": "gaby_andreea15@yahoo.com"
          },
          {
            "name": "FRATIA TOUR SRL",
            "address": "STR.BRAILEI NR.197 BL.A1 SC.1 PARTER",
            "category": "B,C,D,CE",
            "telephone": "0742294952",
            "email": "scoala.fratia@yahoo.com"
          },
          {
            "name": "AVITREE SRL",
            "address": "B-dul.Siderurgistilor nr.30 bl.SD4b ",
            "category": "B",
            "telephone": "0741010702",
            "email": "scoalaaviatia@yahoo.com"
          },
          {
            "name": "PFA GHIBERGIU STEFAN",
            "address": "STR.MOVILEI NR.88 CORP C7 mansarda, ",
            "category": "B",
            "telephone": "0744709921",
            "email": "ghibergiuraluca@yahoo.com"
          },
          {
            "name": "GLOBAL STARDRIVE 2019 SRL",
            "address": "STR.MOVILEI NR.88 CORP C8-C9 SALA 1",
            "category": "B,C,CE",
            "telephone": "0724243679",
            "email": "scoala.autoturismul@yahoo.ro"
          },
          {
            "name": "PFA TARALUNGA COSTEL",
            "address": "STR.OTELARILOR NR.17 BL.D11 AP.95",
            "category": "B",
            "telephone": "",
            "email": "costitaralunga@yahoo.com"
          },
          {
            "name": "GOLD AUTOMANEA SRL",
            "address": "BLD.MILCOV NR.40A-S6 CAM.2 ET.1",
            "category": "B",
            "telephone": "",
            "email": "maricela_cordoneanu@yahoo.com"
          },
          {
            "name": "P.F.A ZAHARIA TRAIAN",
            "address": "STR.PRUNARI,NR.10C,CAM2 ",
            "category": "B",
            "telephone": "0749706546",
            "email": "traianzaharia@yahoo.com"
          },
          {
            "name": "P.F.A IACOB PETRUS LIVIU",
            "address": "STR.PRUNARI,NR.10C,CAM2 ",
            "category": "B",
            "telephone": "0741434211",
            "email": "scoala.autoturismul@yahoo.ro"
          },
          {
            "name": "P.F.A SCUTARU A. MIHAITA",
            "address": "STR.PRUNARI,NR.10C,CAM2 ",
            "category": "B",
            "telephone": "0740087897",
            "email": "scutaru.mihaita@yahoo.ro"
          }
        ]
      },
      {
        "city": "TECUCI",
        "schools": [
          {
            "name": "SCATEC SRL",
            "address": "STR STEFAN CORODEANU NR 2",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0236/820470",
            "email": "valericalazar67@yahoo.com"
          },
          {
            "name": "DUNDEVI SRL",
            "address": "STR.MIHAIL KOGALNICEANU NR.57",
            "category": "B",
            "telephone": "0721650292",
            "email": "costelcadu@yahoo.com"
          },
          {
            "name": "GENSPOR SRL",
            "address": "STR PLUGULUI NR 39",
            "category": "B,C,CE",
            "telephone": "0236/818322",
            "email": "cristitamarin2013@yahoo.com"
          },
          {
            "name": "VALEX ADMIS SRL",
            "address": "STR GHEORGHE PETRESCU NR 14",
            "category": "B",
            "telephone": "0236811595",
            "email": "valex_bvl@yahoo.com"
          },
          {
            "name": "MIIA TOUR SRL",
            "address": "STR TRANSILVANIEI NR 7A ",
            "category": "A,B,C,D,BE,CE,A1,B1,AM,",
            "telephone": "0745116083",
            "email": "miiatour91@yahoo.com"
          },
          {
            "name": "GIPEMAR 2009 SRL",
            "address": "STR.ELENA DOAMNA NR.83",
            "category": "B",
            "telephone": "",
            "email": "relu.blanaru@yahoo.com"
          },
          {
            "name": "SCATEC WTD SRL",
            "address": "Str. 1Decembrie 1918 nr. 141",
            "category": "B",
            "telephone": "0236820480",
            "email": "diaconitamircea@yahoo.com"
          }
        ]
      },
      {
        "city": "SENDRENI",
        "schools": [
          {
            "name": "AVIDRIVE SRL",
            "address": "STR.PORTULUI NR.23 BL.SIRET3 SC.6 ",
            "category": "B",
            "telephone": "0741010702",
            "email": "scoalaaviatia@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "GR",
    "schools": [
      {
        "city": "GIURGIU",
        "schools": [
          {
            "name": "ARMONIA RUTIERA SRL",
            "address": "STR. PLEVNEI NR.5B",
            "category": "B,C,CE",
            "telephone": "216978",
            "email": "armonia_rutiera@yahoo.ro"
          },
          {
            "name": "AUTO REGAL SRL",
            "address": "STR. GARII, NR. 184E",
            "category": "A,B,C,CE,A1,B1,A2",
            "telephone": "0246226169",
            "email": "auto_regal2009@yahoo.com"
          },
          {
            "name": "AUTO TIK TRANS SRL",
            "address": "B-DUL IC BRATIANU, NR. 10-12",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0246217778",
            "email": "tick4_u@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC VICEAMIRAL \nIOAN BALANESCU GIURGIU",
            "address": "STR. UZINEI NR. 30 B",
            "category": "B",
            "telephone": "0246216198",
            "email": "grscnaval@yahoo.com"
          },
          {
            "name": "FICLEANU GHE GHEORGHE \nPERSOANA FIZICA AUTORIZATA",
            "address": "I.C. BRATIANU, NR. 10-12",
            "category": "B",
            "telephone": "",
            "email": "andrei.ficleanu@gmail.com"
          },
          {
            "name": "ALSO BLUE SRL-D",
            "address": "SOS. BUCURESTI, COMPLEX ANCORA, ",
            "category": "B,C,CE",
            "telephone": "0768445569",
            "email": "SCOALAALSOBLUE@GMAIL.COM"
          },
          {
            "name": "ACR AUTO TURISM SRL",
            "address": "STR. VASILE ALECSANDRI, BL. 4/300, ",
            "category": "B",
            "telephone": "0722714336",
            "email": "acr_gr@acr.ro"
          },
          {
            "name": "MECLEA RAZVAN-RADU PFA",
            "address": "B-DUL I.C. BRATIANU, NR. 10-12",
            "category": "B",
            "telephone": "0724921243",
            "email": "meclea_rrazvan@yahoo.com"
          },
          {
            "name": "ALSO BLUE BLACK SRL",
            "address": "Sos. Bucuresti, Com. Ancora, Spatiul Nr. ",
            "category": "B",
            "telephone": "0768445569",
            "email": "M.ALEXANDRU04@GMAIL.COM"
          }
        ]
      },
      {
        "city": "COMUNA ULMI",
        "schools": [
          {
            "name": "AUTO POPESCU SRL",
            "address": "SAT TRESTIENI",
            "category": "B",
            "telephone": "0745161450",
            "email": "autopopescu@yahoo.com"
          }
        ]
      },
      {
        "city": "MIHAILESTI",
        "schools": [
          {
            "name": "B&B GENERAL DRIVER AUTO SRL",
            "address": "CALEA BUCURESTI, NR. 219A",
            "category": "B",
            "telephone": "0723343872",
            "email": "bbauto_scoalasoferi@yahoo.com"
          }
        ]
      },
      {
        "city": "BOLINTIN VALE",
        "schools": [
          {
            "name": "AUTO SMART SRL",
            "address": "Str. Republicii, Nr. 25",
            "category": "A,B,A1,B1,A2",
            "telephone": "0722540331",
            "email": "autosmart2008@gmail.com"
          }
        ]
      },
      {
        "city": "JOITA",
        "schools": [
          {
            "name": "EL PROFESORE SRL",
            "address": "COM. JOITA NR.673",
            "category": "B",
            "telephone": "0246252044",
            "email": "scoalasoferi_elprofesore@yahoo.com"
          }
        ]
      },
      {
        "city": "VALEA DRAGULUI",
        "schools": [
          {
            "name": "MOTOTOLEA COM SERV SNC",
            "address": "SAT VALEA DRAGULUI",
            "category": "B",
            "telephone": "0246258832",
            "email": "iordan.dana@gmail.com"
          }
        ]
      },
      {
        "city": "MUN. GIURGIU",
        "schools": [
          {
            "name": "DESCULTU PETRE PERSOANA FIZICA \nAUTORIZATA",
            "address": "I.C. BRATIANU, NR. 10 12",
            "category": "B",
            "telephone": "",
            "email": "petre.descultu@gmail.com"
          }
        ]
      },
      {
        "city": "COLIBASI",
        "schools": [
          {
            "name": "WGB AUTOSCHOOL SRL",
            "address": "SAT COLIBASI, STR. SOCILOR, NR. 27",
            "category": "B",
            "telephone": "0734424682",
            "email": "larissa.tanase@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "HD",
    "schools": [
      {
        "city": "PETROSANI",
        "schools": [
          {
            "name": "AUTO MOTO DRIVE SCHOLL S.R.L.",
            "address": "STR. 22 DECEMBRIE, NR. 3C5",
            "category": "B",
            "telephone": "0722669742",
            "email": "automotodrive@yahoo.ro"
          },
          {
            "name": "AUTO PROFESIONAL DRIVE S.R.L.",
            "address": "STR. CARBUNELUI, NR 2",
            "category": "B,C,CE",
            "telephone": "0723695666",
            "email": "nataliapaveloni@yahoo.com"
          },
          {
            "name": "MADISON TRANS S.R.L.",
            "address": "STR. 1 DECEMBRIE 1918, NR 100, CASA ",
            "category": "B,C,D,BE,CE",
            "telephone": "0722821528",
            "email": "madisontrans@yahoo.com"
          },
          {
            "name": "SCOALA DE SOFERI CIOBANU S.R.L.",
            "address": "STR. 1 DECEMBRIE 1918, NR. 90",
            "category": "B,C,CE",
            "telephone": "0722735973",
            "email": "doru_cogeac@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DIMITRIE \nLEONIDA PETROSANI",
            "address": "STR. 22 DECEMBRIE, NR. 6",
            "category": "B",
            "telephone": "0254542282",
            "email": "ovidiu_colda@yahoo.com"
          },
          {
            "name": "AXEL SCHOOL DRIVING S.R.L.",
            "address": "STR. 1 DECEMBRIE 1918, BL. 57A, STR. 1 DECEMBRIE 1918, NR 100, ",
            "category": "B",
            "telephone": "0254570204",
            "email": "petcuflaviusalexandru@gmail.com"
          },
          {
            "name": "MDS INDEPENDENT SCHOOL S.R.L.",
            "address": "INCINTA CASEI DE CULTURA A \nSINDICATELOR",
            "category": "B",
            "telephone": "0721769002",
            "email": "madisontrans@yahoo.com"
          },
          {
            "name": "NETLOG COMPUTER S.R.L.",
            "address": "STR. INDEPENDENTEI, PT5, ZONA ",
            "category": "B",
            "telephone": "0726669060",
            "email": "contact@3daqua.ro"
          },
          {
            "name": "DOC-CIOBANU SORIN S.R.L.",
            "address": "Strada UNIVERSITATII, Nr. 20, Caminul ",
            "category": "B",
            "telephone": "0723922068",
            "email": "scoalaautodocsorinciobanu@yahoo.com"
          },
          {
            "name": "GSV AUTO PROFESIONAL EXPERT \nS.R.L.",
            "address": "Strada MIHAI VITEAZU, Nr. 3, CAM.18-19-\n20, Etaj 3",
            "category": "B",
            "telephone": "0763688009",
            "email": "sorin_grecea@yahoo.com"
          }
        ]
      },
      {
        "city": "HUNEDOARA",
        "schools": [
          {
            "name": "OVICOR AUTO COM S.R.L.",
            "address": "STR. RANDUNICII, NR. 6, Incinta Sc. ",
            "category": "B",
            "telephone": "0745401737",
            "email": "ovicorautocom@yahoo.com"
          },
          {
            "name": "DIDIRUT 14 S.R.L.",
            "address": "Strada VICTORIEI, Nr. 17, Bloc 2 scolar",
            "category": "A,B,C,D,CE,A1",
            "telephone": "0744510747",
            "email": "didirut14@yahoo.com"
          },
          {
            "name": "PEDRO UTIL S.R.L.",
            "address": "STR. LIBERTATII, NR. 27",
            "category": "B,C,CE",
            "telephone": "0254717701",
            "email": "monalisa13ro@yahoo.com"
          },
          {
            "name": "DAYANACRIS AUTOTRANS S.R.L.",
            "address": "STR. VICTORIEI, NR. 19",
            "category": "B",
            "telephone": "0721614296",
            "email": "cipri_i@yahoo.com"
          },
          {
            "name": "STEAUA CORVINILOR S.R.L.",
            "address": "Colegiului Tehnic Matei Corvin, bloc 4, \nsala 2",
            "category": "B",
            "telephone": "0745583423",
            "email": "steaua_corvinilor@yahoo.com"
          },
          {
            "name": "PROFESIONAL CURS AUTO S.R.L.",
            "address": "STR. DOINEI, NR. 13",
            "category": "B",
            "telephone": "0744636389",
            "email": "PROFESIONALCURSAUTO@YAHOO.COM"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"MATEI \nCORVIN\" HUNEDOARA",
            "address": "STR. VICTORIEI, NR. 17",
            "category": "B",
            "telephone": "0254711687",
            "email": "dan_mxv@yahoo.com"
          },
          {
            "name": "ACA AUTOSCHOOL SRL-D",
            "address": "Str. MIHAI VITEAZU, Nr. FN, Complex \nComercial Dunarea-Spatiul Nr.1",
            "category": "B",
            "telephone": "0737040727",
            "email": "acaautoschool@yahoo.com"
          },
          {
            "name": "P.F.A. HORVAT NICOLAE \nINSTRUCTOR",
            "address": "Strada 1DECEMBRIE1918, Nr. 2, Sala de \nCurs si Birou Arhiva",
            "category": "B",
            "telephone": "0744530622",
            "email": "pfanicolaehorvat@yahoo.com"
          },
          {
            "name": "LUCA BEST DRIVE S.R.L.",
            "address": "Strada VICTORIEI, Nr. 17, Scoala 4, Bloc 2",
            "category": "B",
            "telephone": "0763433707",
            "email": "mihaibudiul@yahoo.ro"
          },
          {
            "name": "P.F.A. IONESCU FLAVIAN SANDRINO",
            "address": "Str. CONSTANTIN BURSAN, Nr. 27",
            "category": "B",
            "telephone": "0740371041",
            "email": "sandrina_4@yahoo.com"
          }
        ]
      },
      {
        "city": "CALAN",
        "schools": [
          {
            "name": "MIRA & RICU AUTO COM S.R.L.",
            "address": "VALEA SINGEORGIULUI, NR 102",
            "category": "B",
            "telephone": "0762242448",
            "email": "mira.ricuautocomsrl@yahoo.com"
          },
          {
            "name": "P.F.A. SOPTEREAN MARIAN DRIVE ",
            "address": "SAT. STREISANGEORGIU, NR. 4",
            "category": "B,A1",
            "telephone": "0767871277",
            "email": "soptereanmarian@yahoo.com"
          },
          {
            "name": "DAVID DRIVE S.R.L.",
            "address": "Industrial Calan, birourile E201, E202, \nE206",
            "category": "B,C,CE",
            "telephone": "0766212168",
            "email": "scoala_david@yahoo.com"
          }
        ]
      },
      {
        "city": "DEVA",
        "schools": [
          {
            "name": "PILOTAJ S.R.L.",
            "address": "STR. 1 DECEMBRIE, NR. 19, ET. 1",
            "category": "B",
            "telephone": "0722712720",
            "email": "pilotaj.deva@yahoo.com"
          },
          {
            "name": "PHOENIX PLUS TIBI S.R.L.",
            "address": "B-dul. IULIU MANIU, Nr. 6",
            "category": "A,B,A1,AM,A2",
            "telephone": "0722435369",
            "email": "scoalaphoenix@yahoo.ro"
          },
          {
            "name": "ANCRIS AUTO MOTO S.R.L.",
            "address": "STR. 1 DECEMBRIE, NR. 19, ET. 2",
            "category": "B",
            "telephone": "0354411600",
            "email": "ancrisautomoto@yahoo.com"
          },
          {
            "name": "P.F.A. PLESA STEFAN FANICA \nINSTRUCTOR",
            "address": "B-DUL. 22 DECEMBRIE, NR. 116STR. VICTORIEI, NR. 17, In incinta ",
            "category": "B",
            "telephone": "0745393234",
            "email": "marcel.potor@yahoo.com"
          },
          {
            "name": "LP LINE EXPRESS S.R.L.",
            "address": "STR. MIHAI EMINESCU, NR. 1, AP. 3",
            "category": "B",
            "telephone": "0721369264",
            "email": "lupeapaulo@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC ENERGETIC \nDRAGOMIR HURMUZESCU",
            "address": "STR. TITU MAIORESCU NR 28",
            "category": "B",
            "telephone": "0254220240",
            "email": "dicuas@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \n\"TRANSILVANIA\" DEVA",
            "address": "STR. 22 DECEMBRIE, NR. 116",
            "category": "B",
            "telephone": "0254230739",
            "email": "steialuminita@yahoo.com"
          },
          {
            "name": "MC TOPAZ SPRINT S.R.L.",
            "address": "STRADA DEPOZITELOR, NR. 10",
            "category": "B",
            "telephone": "0723542930",
            "email": "blitztransportdeva@yahoo.com"
          },
          {
            "name": "P.F.A. LUPEA LARIN CLAUDIUS",
            "address": "B-DUL. 22 DECEMBRIE, NR. 116, COL. \nTEHN. \"TRANSILVANIA\" SALA 48",
            "category": "B",
            "telephone": "0722564097",
            "email": "lupeapaulo@yahoo.com"
          },
          {
            "name": "P.F.A. PREJBAN E. NICOLAE ADRIAN",
            "address": "Bulevardul 22 DECEMBRIE, Nr. 116, \nColegiul Tehnic \"TRANSILVANIA\" SALA 49",
            "category": "B",
            "telephone": "0744938902",
            "email": "prejban.adrian@yahoo.com"
          },
          {
            "name": "P.F.A. MORARU N. FLORINEL",
            "address": "Bulevardul 22 DECEMBRIE, Nr. 116, \nColegiul Tehnic \"TRANSILVANIA\" SALA 49",
            "category": "B",
            "telephone": "0722419378",
            "email": "roze_boanta@yahoo.com"
          },
          {
            "name": "P.F.A. MOLDOVAN GHEORGHE \nEUGEN",
            "address": "B-DUL. 22 DECEMBRIE, NR. 116, COL. \nTEHN. \"TRANSILVANIA\" SALA 48",
            "category": "B",
            "telephone": "0728171394",
            "email": "lupeapaulo@yahoo.com"
          },
          {
            "name": "P.F.A. GIT CRISTIAN EMIL",
            "address": "B-DUL. 22 DECEMBRIE, NR. 116, COL. \nTEHN. \"TRANSILVANIA\" SALA 49",
            "category": "B",
            "telephone": "0722980374",
            "email": "git.cristian@yahoo.com"
          },
          {
            "name": "ALTMAN EXPRESS S.R.L.",
            "address": "STR. DEPOZITELOR, NR. 10",
            "category": "B,C,D,CE",
            "telephone": "0740309389",
            "email": "lupeapaulo@yahoo.com"
          },
          {
            "name": "PHOENIX ALPIN S.R.L.",
            "address": "Bulevardul IULIU MANIU, Nr. 6",
            "category": "A,B,A1,A2",
            "telephone": "0722288150",
            "email": "PHOENIXALPIN@YAHOO.COM"
          }
        ]
      },
      {
        "city": "ORASTIE",
        "schools": [
          {
            "name": "NIC AUTOSCHOOL S.R.L.",
            "address": "STR. POPA SAPCA, NR. 31",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": " 0764542463",
            "email": "nic_autoschool@yahoo.com"
          },
          {
            "name": "P.F.A. TRUSCA ADRIAN ",
            "address": "STR. NICOLAE BALCESCU, NR. 20",
            "category": "B",
            "telephone": "0744997103",
            "email": "pfa_truscaadrian@yahoo.com"
          },
          {
            "name": "TEHNO AUTOP S.R.L.",
            "address": "STR. LUMINII, NR. 1",
            "category": "B,C,BE,CE",
            "telephone": "0722603764",
            "email": "dorianbaciu@yahoo.com"
          },
          {
            "name": "P.F.A. DONCA CORNELIU",
            "address": "Strada NICOLAE BALCESCU, Nr. 20",
            "category": "B",
            "telephone": "0722277206",
            "email": "cornel.donca@gmail.com"
          },
          {
            "name": "P.F.A. GIURGIU COSMIN DAN",
            "address": "Strada PETRU MAIOR, Nr. 13",
            "category": "B",
            "telephone": "0723327989",
            "email": "giurgiucd@yahoo.com"
          }
        ]
      },
      {
        "city": "CRISCIOR",
        "schools": [
          {
            "name": "RAZVAN & DANIEL S.R.L.",
            "address": "STR. BUNEI, NR. 2, Cladirea nr. 2 a \nLiceului Tehnologic Crisan-sala 34",
            "category": "B,BE",
            "telephone": "0729572319",
            "email": "mihailachescu@yahoo.com"
          }
        ]
      },
      {
        "city": "BRAD",
        "schools": [
          {
            "name": "P.F.A. BETEA TRAIAN ",
            "address": "STR. AVRAM IANCU, NR. 18",
            "category": "B",
            "telephone": "0747998139",
            "email": "interlogoana@yahoo.com"
          },
          {
            "name": "POLVEREA TRANS S.R.L.",
            "address": "STR. REPUBLICII, NR. 22, ET. 1",
            "category": "B,C,D,CE",
            "telephone": "0756566272",
            "email": "alinapolverea@yahoo.com"
          },
          {
            "name": "POLVEREA AUTO S.R.L.",
            "address": "Strada REPUBLICII, Nr. 22, Etaj II, Ap.22",
            "category": "B",
            "telephone": "0728613987",
            "email": "valy_polverea@yahoo.com"
          },
          {
            "name": "P.F.A. PIRVA SORIN CRISTIAN",
            "address": "STR. VANATORILOR, NR. 51",
            "category": "B",
            "telephone": "0762710710",
            "email": "cristipirva_pcc@yahoo.com"
          },
          {
            "name": "DAVID FULL ONE SRL",
            "address": "Str. DECEBAL, Nr. 72",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0742154297",
            "email": "aslauc@yahoo.com"
          },
          {
            "name": "P.F.A. JURCA HORIA",
            "address": "Strada VINATORILOR, Nr. 51",
            "category": "B",
            "telephone": "0726138364",
            "email": "horiajurcajhd@gmail.com"
          }
        ]
      },
      {
        "city": "HATEG",
        "schools": [
          {
            "name": "MIDIA DINAMIC S.R.L.",
            "address": "STR. UNIRII, NR. 1, in cadrul Scolii ",
            "category": "A,B,C,D,BE,CE,A2",
            "telephone": "0727348893",
            "email": "minuc_64@yahoo.com"
          }
        ]
      },
      {
        "city": "LUPENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC LUPENI",
            "address": "STR. TINERETULUI, NR 43",
            "category": "B",
            "telephone": "0254560779",
            "email": "mihaiasa.romeo@yahoo.com"
          },
          {
            "name": "AXEL AUTO DRIVE S.R.L.",
            "address": "Str. TINERETULUI, Nr. 43, CORP B",
            "category": "B,C,CE",
            "telephone": "0766818691",
            "email": "petcuflaviusalexandru@gmail.com"
          },
          {
            "name": "MARA AUTO DIDACTIC SRL",
            "address": "Strada REVOLUTIEI, Nr. 6",
            "category": "B",
            "telephone": "0722431794",
            "email": "artstoi@yahoo.com"
          }
        ]
      },
      {
        "city": "VULCAN",
        "schools": [
          {
            "name": "AXEL SCHOOL S.R.L.",
            "address": "STR. MORII, NR. 30",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0254570204",
            "email": "MIRINDA_2005_2006@YAHOO.COM"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"MIHAI \nVITEAZU\"",
            "address": "STR. NICOLAE TITULESCU, NR. 43",
            "category": "B",
            "telephone": "0254570563",
            "email": "dantomele@yahoo.com"
          }
        ]
      },
      {
        "city": "PETRILA",
        "schools": [
          {
            "name": "AUTO NOVA DRIVING COMPANY \nSRL",
            "address": "STR. 22 DECEMBRIE, CT1, PARTER-BIROU \nsi ET. 1",
            "category": "B",
            "telephone": "0722207857",
            "email": "adi_mustocea@yahoo.com"
          }
        ]
      },
      {
        "city": "VETEL",
        "schools": [
          {
            "name": "FUSCUS PREST SERV S.R.L.",
            "address": "Nr. 241Strada 1 DECEMBRIE, Nr. 40, Parc ",
            "category": "B",
            "telephone": "0722605093",
            "email": "pdana80@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "HR",
    "schools": [
      {
        "city": "ODORHEIU \nSECUIESC",
        "schools": [
          {
            "name": "DRIVE CENTER GAS TOURS SRL",
            "address": "STR. TARGULUI NR.5",
            "category": "B,C,D,CE",
            "telephone": "0266218410",
            "email": "drivecenter.gastours@yahoo.com"
          },
          {
            "name": "HUSZAR ARPAD PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR. WESSELENYI MIKLOS NR.18",
            "category": "B",
            "telephone": "0744701652",
            "email": "huszara64@gmail.com"
          },
          {
            "name": "SZAKSZI DRIVE SRL",
            "address": "STR.ALBINELOR NR.2",
            "category": "A,B,A1,AM,A2",
            "telephone": "0742137767",
            "email": "szakszidrive@hotmail.com"
          },
          {
            "name": "BALO V.JAN-EUGEN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR. FINTINEI NR.2",
            "category": "B",
            "telephone": "0744788197",
            "email": "jan.balo@yahoo.com"
          },
          {
            "name": "KFT HANITA SRL",
            "address": "STR. TOMPA LASZLO NR.12",
            "category": "A,B,A1,AM,A2",
            "telephone": "0746102861",
            "email": "kalmanhajnal@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  EOTVOS \nJOZSEF",
            "address": "STR.TOMPA LASZLO NR.12",
            "category": "B",
            "telephone": "0266218017",
            "email": "ikalman@freemail.hu"
          },
          {
            "name": "HEIPEL AUTO SRL",
            "address": "STR.1 DECEMBRIE 1918 NR.7",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0745649834",
            "email": "heipel.auto@yahoo.com"
          },
          {
            "name": "PILOTA GAS TOURS SRL",
            "address": "STR.TIRGULUI NR.5",
            "category": "B",
            "telephone": "0744593718",
            "email": "gastours95@gmail.com"
          },
          {
            "name": "BALI SAFE DRIVER SRL",
            "address": "SOS.TAMASI ARON NR.79",
            "category": "B,C,Tr,CE",
            "telephone": "0745234860",
            "email": "balidriver16@gmail.com"
          },
          {
            "name": "TURANI M&B ODORHEI SRL",
            "address": "STR.ORBAN BALAZS NR.20",
            "category": "A,B,A1,A2",
            "telephone": "0744385491",
            "email": "turanimisi@yahoo.com"
          },
          {
            "name": "PAL Z. ZOLTAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.BRESLELOR NR.8 AP.3",
            "category": "B",
            "telephone": "0752578608",
            "email": "zoltanpal84@gmail.com"
          },
          {
            "name": "LORINCZ TIBOR PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.VICTORIEI NR.28, AP.8",
            "category": "B",
            "telephone": "0744810247",
            "email": "wlktibi@gmail.com"
          }
        ]
      },
      {
        "city": "CRISTURU SECUIESC",
        "schools": [
          {
            "name": "TURANI M&B SRL",
            "address": "STR. KRIZA JANOS NR.27",
            "category": "B",
            "telephone": "0745649826",
            "email": "turanimisi@yahoo.com"
          },
          {
            "name": "CRISTUR VOLAN SRL",
            "address": "STR.HARGHITEI NR.14",
            "category": "B",
            "telephone": "0742133985",
            "email": "cristurvolan@freemail.hu"
          },
          {
            "name": "LICEUL TEHNOLOGIC ZEYK \nDOMOKOS",
            "address": "STR.HARGHITEI NR.14",
            "category": "B",
            "telephone": "0266242383",
            "email": "gs.zeykdomokos@eduhr.ro"
          },
          {
            "name": "ORBAN L. IANOS PERSOANA FIZICA \nAUTORIZATA",
            "address": "CART.KOSSITH LAJOS, BL.B15, ET.2, AP.6",
            "category": "B",
            "telephone": "0744101953",
            "email": "joeoktato@gmail.com"
          },
          {
            "name": "TOTH S. LAJOS PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.LIBERTATII NR.35",
            "category": "B",
            "telephone": "0744905417",
            "email": "tothlajos2@gmail.com"
          }
        ]
      },
      {
        "city": "GHEORGHENI",
        "schools": [
          {
            "name": "STEFI TOP SRL",
            "address": "STR. NICOLAE BALCESCU NR.42/A",
            "category": "A,B,C,D,BE,CE,A2",
            "telephone": "0744786499",
            "email": "csibi_levente@yahoo.com"
          },
          {
            "name": "BORSOS TRANS SRL",
            "address": "STR.CARPATI NR.6",
            "category": "B",
            "telephone": "0266351111",
            "email": "borsostrans1@gmail.com"
          },
          {
            "name": "ALFA VOLAN TRANS SRL",
            "address": "STR.MARTON ARON NR.20",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0266364003",
            "email": "sati6691@gmail.com"
          },
          {
            "name": "VOLANTRANS TOTAL SRL",
            "address": "STR.MARTON ARON NR.22",
            "category": "B,BE",
            "telephone": "0266364003",
            "email": "sati6691@gmail.com"
          }
        ]
      },
      {
        "city": "MIERCUREA CIUC",
        "schools": [
          {
            "name": "DEMANDIS SRL",
            "address": "STR. PIATA LIBERTATII NR.16",
            "category": "B",
            "telephone": "0745312947",
            "email": "demandis2000@yahoo.com"
          },
          {
            "name": "TOP AUTO TOUR SRL",
            "address": "STR. EROILOR NR.5A",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM,",
            "telephone": "0266311115",
            "email": "topautotour@yahoo.com"
          },
          {
            "name": "STOPAUTO-ORION SRL",
            "address": "STR.GAL SANDOR NR.16 BIROU NR.9 ",
            "category": "B",
            "telephone": "0740131886",
            "email": "orioncom0709@gmail.com"
          },
          {
            "name": "DELFIN IMPEX SRL",
            "address": "STR.VOROSMARTY MIHALY NR.21A",
            "category": "A,B,BE,AM",
            "telephone": "0266314533",
            "email": "bandi_jolan@yahoo.com"
          },
          {
            "name": "AUTOTOUR-BSS SRL",
            "address": "STR.GAL SANDOR NR.16/A BIROU NR.9",
            "category": "A,B,C,BE,CE",
            "telephone": "0742668118",
            "email": "autotourbss@yahoo.com"
          },
          {
            "name": "GERGELY E. ATTILA PERSOANA \nFIZICA AUTORIZATA",
            "address": "ALEEA NARCISELOR NR.7 SC.B AP.1",
            "category": "B",
            "telephone": "0744477012",
            "email": "zsofiaeross@freemail.hu"
          },
          {
            "name": "EROSS CSABA PERSOANA FIZICA \nAUTORIZATA",
            "address": "PTA.LIBERTATII NR.15 ET.9 AP.29",
            "category": "B",
            "telephone": "0722657972",
            "email": "zsofiaeross@freemail.hu"
          },
          {
            "name": "SZOCS ISTVAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR. BRADULUI NR.1 SC.B AP.14",
            "category": "B",
            "telephone": "0749327001",
            "email": "szocmar@freemail.hu"
          },
          {
            "name": "BAN AUTO TRAINING SRL",
            "address": "STR.TOPLITA NR.20-22 CLADIRE ",
            "category": "B",
            "telephone": "0744161630",
            "email": "banciugheorghe1@yahoo.com"
          },
          {
            "name": "TOP AUTO SRL",
            "address": "STR.EROILOR NR.5A",
            "category": "A,B,C,D,CE",
            "telephone": "0744617607",
            "email": "negrutop@yahoo.com"
          }
        ]
      },
      {
        "city": "TOPLITA",
        "schools": [
          {
            "name": "VALION TOP SRL",
            "address": "STR. STEFAN CEL MARE, NR.15",
            "category": "B",
            "telephone": "0724304149",
            "email": "valiontop@yahoo.com"
          },
          {
            "name": "ECOMAX SERV SRL",
            "address": "STR.STEJARULUI NR.25",
            "category": "A,B",
            "telephone": "0744401428",
            "email": "adydanmax@yahoo.com"
          },
          {
            "name": "ALFA VOLAN TRANS SRL",
            "address": "STR.SPORTIVILOR NR.5/G",
            "category": "B",
            "telephone": "0266364003",
            "email": "sati6691@gmail.com"
          }
        ]
      },
      {
        "city": "CORUND",
        "schools": [
          {
            "name": "REKA & DENES SRL",
            "address": "NR.410",
            "category": "B,BE",
            "telephone": "0744211026",
            "email": "denesfabian75@gmail.com"
          }
        ]
      },
      {
        "city": "DANESTI",
        "schools": [
          {
            "name": "VOLAN TRANS  SRL",
            "address": "STR. PRINCIPALA NR.784",
            "category": "B,Tr",
            "telephone": "0266327070",
            "email": "volantrans@clicknet.ro"
          }
        ]
      },
      {
        "city": "BRADESTI",
        "schools": [
          {
            "name": "GERGELY M. ZSOLT PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR. 311",
            "category": "B",
            "telephone": "0741033983",
            "email": "gergelyzsolt72@yahoo.com"
          }
        ]
      },
      {
        "city": "MORARENI",
        "schools": [
          {
            "name": "LAKATOS A ILDIKO PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.130",
            "category": "B",
            "telephone": "0743455622",
            "email": "lakatos_ildiko29@yahoo.com"
          }
        ]
      },
      {
        "city": "GOAGIU",
        "schools": [
          {
            "name": "FAZAKAS K. CSABA PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.87",
            "category": "B,BE",
            "telephone": "0741171695",
            "email": "csaba.fazakas72@yahoo.com"
          }
        ]
      },
      {
        "city": "BODOGAIA",
        "schools": [
          {
            "name": "SZOCS E BELA PERSOANA FIZICA \nAUTORIZATA",
            "address": "NR.193",
            "category": "B",
            "telephone": "0754993015",
            "email": "laszlo_ervin@yahoo.com"
          }
        ]
      },
      {
        "city": "SATU MARE",
        "schools": [
          {
            "name": "PALFI GASPAR-CSABA PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.567",
            "category": "B",
            "telephone": "0751126339",
            "email": "palfycsaba@yahoo.com"
          }
        ]
      },
      {
        "city": "CAPALNITA",
        "schools": [
          {
            "name": "ALBERT M. JOZSEF PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.88",
            "category": "B",
            "telephone": "0740681491",
            "email": "albertjozsef76@gmail.com"
          }
        ]
      },
      {
        "city": "PORUMBENII MICI",
        "schools": [
          {
            "name": "GYORGY-SZABO CSILLA PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.141",
            "category": "B",
            "telephone": "0742185254",
            "email": "heipel.auto@yahoo.com"
          }
        ]
      },
      {
        "city": "BARZAVA",
        "schools": [
          {
            "name": "DEMETER BALAZS PERSOANA \nFIZICA AUTORIZATA",
            "address": "NR.29A",
            "category": "B",
            "telephone": "0740381774",
            "email": "zsofiaeross@freemail.hu"
          }
        ]
      }
    ]
  },
  {
    "state": "IF",
    "schools": [
      {
        "city": "PANTELIMON",
        "schools": [
          {
            "name": "AUTO FAMILY TEAM SRL",
            "address": "STR.CORNELIU COPOSU  NR.60",
            "category": "B",
            "telephone": "0723152344",
            "email": "autofamilyteam@gmail.com"
          },
          {
            "name": "AUTOESCU SRL",
            "address": "STR. SF GHEORGHE, NR.155",
            "category": "B",
            "telephone": "0721124847",
            "email": "sc_autoescu_srl@yahoo.com"
          },
          {
            "name": "GARANTAT AUTO DRIVE SRL",
            "address": "STR. INTRAREA PRUNILOR, NR. 2, ",
            "category": "B",
            "telephone": "0765405600",
            "email": "mariusachim2006@yahoo.com"
          }
        ]
      },
      {
        "city": "JILAVA",
        "schools": [
          {
            "name": "ROBY PILOT SRL",
            "address": "STR. MIERLARI, NR. 13, CAM. 2",
            "category": "A,B,A1,A2",
            "telephone": "0723158202",
            "email": "roby_auto20@yahoo.com"
          }
        ]
      },
      {
        "city": "CIOROGARLA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC PAMFIL \nSEICARU",
            "address": "STR.SCOLII NR.15",
            "category": "B",
            "telephone": "0213159114",
            "email": "pamfilseicaru@yahoo.com"
          },
          {
            "name": "SIM ACTIV SRL",
            "address": "STR. FIERARI, NR. 9",
            "category": "A,B,C,CE",
            "telephone": "0722607150",
            "email": "scoalasoferi.simactiv@yahoo.com"
          }
        ]
      },
      {
        "city": "VOLUNTARI",
        "schools": [
          {
            "name": "AUTO MODIFAR S.R.L.",
            "address": "B-DUL VOLUNTARI, NR. 60A",
            "category": "B,C,CE,C1",
            "telephone": "0744530117",
            "email": "automodifarr@yahoo.com"
          },
          {
            "name": "CDI GRUP VEHICUL SRL",
            "address": "B-DUL VOLUNTARI, NR. 29A, PARTER",
            "category": "B,C,CE",
            "telephone": "",
            "email": ""
          }
        ]
      },
      {
        "city": "DOBROIESTI",
        "schools": [
          {
            "name": "ADI STAR IMPEX SRL",
            "address": "SOS.FUNDENI NR.83",
            "category": "B",
            "telephone": "0744343719",
            "email": "adistar_impex@yahoo.com"
          }
        ]
      },
      {
        "city": "MAGURELE",
        "schools": [
          {
            "name": "FIESTA BRAND SRL",
            "address": "STR. ATOMISTILOR,NR.64",
            "category": "B",
            "telephone": "0767318732",
            "email": "fiesta.brand@gmail.com"
          },
          {
            "name": "CD. LAUDRUP INVEST SRL",
            "address": "STR. BULGARULUI, NR. 8A",
            "category": "B",
            "telephone": "0721959950",
            "email": "dinudanielalexandru@yahoo.com"
          }
        ]
      },
      {
        "city": "DOBROESTI",
        "schools": [
          {
            "name": "DAS TRANSCOMEXIM CONSULT SRL",
            "address": "STR. CAPORAL ENE, NR.20, SAT FUNDENI",
            "category": "B",
            "telephone": "0722259157",
            "email": "scoaladas@yahoo.com"
          },
          {
            "name": "AUTO SOFT DRIVE SRL",
            "address": "SOS.FUNDENI,NR.73,ETAJ ",
            "category": "A,B,C,D,CE",
            "telephone": "0724070401",
            "email": "marius_softdrive@yahoo.com"
          }
        ]
      },
      {
        "city": "CORBEANCA",
        "schools": [
          {
            "name": "AUTO MIH DRIVE SRL",
            "address": "STR. INDEPENDENTEI, NR.42A,",
            "category": "B",
            "telephone": "0727157565",
            "email": "automihdrive@yahoo.com"
          }
        ]
      },
      {
        "city": "CHITILA",
        "schools": [
          {
            "name": "AUTO CRISTI OLTEANU SRL",
            "address": "STR. BANATULUI, NR. 7-9, LOT 1, ",
            "category": "B,BE",
            "telephone": "0727400780",
            "email": "autocristiolteanu@yahoo.com"
          }
        ]
      },
      {
        "city": "STEFANESTII DE JOS",
        "schools": [
          {
            "name": "AGRI EXPERT SRL",
            "address": "SOS.STEFANESTI,NR.11,CLADIREA ",
            "category": "B,Tr",
            "telephone": "0723506199",
            "email": "monica.emilia.soare@gmail.com"
          }
        ]
      },
      {
        "city": "DARASTI ILFOV",
        "schools": [
          {
            "name": "DENIS AUTO DAB SRL",
            "address": "STR. 1 DECEMBRIE 1918, NR. 163, ",
            "category": "B",
            "telephone": "0787244984",
            "email": "auto_dab2007@yahoo.com"
          },
          {
            "name": "P.F.A. LIXANDRU V. ION",
            "address": "STR. 1 DECEMBRIE 1918 NR.108",
            "category": "B",
            "telephone": "0722339565",
            "email": "balannu24@gmail.com"
          }
        ]
      },
      {
        "city": "BRANESTI",
        "schools": [
          {
            "name": "AUTO MENTOR SRL",
            "address": "STR.INTR.DOINA,NR.4,SAT ISLAZ",
            "category": "B",
            "telephone": "0722602621",
            "email": "florintataru_75@yahoo.com"
          }
        ]
      },
      {
        "city": "MOGOSOAIA",
        "schools": [
          {
            "name": "SAM-TRADI NEW SRL",
            "address": "SOS. BUCURESTI-TARGOVISTE, NR. 56, ",
            "category": "A,B,A1,AM,A2",
            "telephone": "0722337317",
            "email": "samtradi@yahoo.com"
          }
        ]
      },
      {
        "city": "DRAGOMIRESTI \nDEAL",
        "schools": [
          {
            "name": "SILA-MAR SRL",
            "address": "STR. REPUBLICII, NR. 47",
            "category": "B",
            "telephone": "0721365641",
            "email": "SCOALASILAMAR@GMAIL.COM"
          }
        ]
      },
      {
        "city": "BUFTEA",
        "schools": [
          {
            "name": "ALLSPEED POWERED BY TEO SRL",
            "address": "BULEVARDUL MIHAI EMINESCU, NR. 11, ",
            "category": "B",
            "telephone": "0733584111",
            "email": "office@scoaladesoferibuftea.ro"
          },
          {
            "name": "P.F.A. FIERARU F. ION ",
            "address": "STR. POENII, NR. 7",
            "category": "B",
            "telephone": "0727390925",
            "email": "fieraruion1961@gmail.com"
          }
        ]
      },
      {
        "city": "CHIAJNA",
        "schools": [
          {
            "name": "S.C. SCOALA DE SOFERI AUTO STYL \nS.R.L.",
            "address": "STR. LIBERTATII NR.24",
            "category": "B",
            "telephone": "0724253460",
            "email": "radutone@yahoo.com"
          },
          {
            "name": "IRI DRIVE SRL",
            "address": "STR. GEN.DAVID PRAPORGESCU NR.2A ",
            "category": "B,C,D,BE,CE",
            "telephone": "0728604651",
            "email": "IRIDRIVE@YAHOO.COM"
          },
          {
            "name": "EXCESIV DELTA DRIVE SRL",
            "address": "SAT DUDU, STR. APEDUCTULUI, NR. 58, \nSP. COM. NR. 5, CAM. 1 SI CAM. 2",
            "category": "B",
            "telephone": "0763559052",
            "email": "excesiv.delta.drive@gmail.com"
          }
        ]
      },
      {
        "city": "AFUMATI",
        "schools": [
          {
            "name": "AUTO SMART DRIVE SRL",
            "address": "SOS. STEFANESTI, NR. 140",
            "category": "B,BE",
            "telephone": "0720042230",
            "email": "vyvyana1981@yahoo.com"
          },
          {
            "name": "TITAUTO EVOLUTION SRL",
            "address": "STR. MIHAI BRAVU NR.1 LOC.AFUMATI ",
            "category": "B",
            "telephone": "0762182759",
            "email": "cristi12005@yahoo.com"
          },
          {
            "name": "AUTO V&L SMART DRIVE SRL",
            "address": "AGROINDAF AFUMATI SEDIUL FERMEI \nNR. 2)",
            "category": "B",
            "telephone": "0720042230",
            "email": "vyvyana1981@yahoo.com"
          }
        ]
      },
      {
        "city": "CLINCENI",
        "schools": [
          {
            "name": "P.F.A. DUMITRACHE MIREL ",
            "address": "SOS. CLINCENI, NR. 24",
            "category": "B",
            "telephone": "0744161887",
            "email": "mireldumi123@gmail.com"
          }
        ]
      },
      {
        "city": "BRAGADIRU",
        "schools": [
          {
            "name": "P.F.A. PRUNA COSTICA",
            "address": "SOS. CLINCENI, NR. 24",
            "category": "B",
            "telephone": "0744161887",
            "email": "costi.pruna@gmail.com"
          }
        ]
      },
      {
        "city": "CERNICA",
        "schools": [
          {
            "name": "DRIVE AUTO FOCUS SRL",
            "address": "PARCELELE 1265,1266,1267 SAT \nBALACEANCA",
            "category": "B",
            "telephone": "0767755810",
            "email": "contact.focusdrive@gmail.com"
          }
        ]
      },
      {
        "city": "POPESTI LEORDENI",
        "schools": [
          {
            "name": "AUTO MAV PERFECT SRL",
            "address": "SOSEAUA OLTENITEI, NR. 45SOS. STEFANESTI, NR. 140, (SC ",
            "category": "B",
            "telephone": "0724152986",
            "email": "paunmada30@gmail.com"
          },
          {
            "name": "AICHI CONECT SRL",
            "address": "STRADA OITUZ, NR.77, PARTER, SPATIUL ",
            "category": "B",
            "telephone": "0749693313",
            "email": "aichiconect@yahoo.com"
          }
        ]
      },
      {
        "city": "MOARA VLASIEI",
        "schools": [
          {
            "name": "ALTERNATIVE DRIVE SRL",
            "address": "STR. ZORILOR, NR. 13, CONSTRUCTIA C2, ",
            "category": "B",
            "telephone": "0761887251",
            "email": "cocokris76@yahoo.com"
          }
        ]
      },
      {
        "city": "DRAGOMIRESTI \nVALE",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC \"VINTILA \nBRATIANU\"",
            "address": "STR. MICSUNELELOR, NR. 165",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      }
    ]
  },
  {
    "state": "IL",
    "schools": [
      {
        "city": "URZICENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC URZICENI",
            "address": "STR.PETROLISTILOR NR.16",
            "category": "B,Tr",
            "telephone": "0243257551",
            "email": "teo_barbu57@yahoo.com"
          },
          {
            "name": "OPTIMAL AUTO GRUP S.R.L.",
            "address": "Str. Panduri, NR.57, Grup Scolar Sfanta ",
            "category": "B",
            "telephone": "0721488574",
            "email": "optimalautogrup2009@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC SFANTA \nECATERINA",
            "address": "STR.PANDURI NR.57",
            "category": "B",
            "telephone": "0727834842",
            "email": "gs_sf_ecaterina_urziceni@yahoo.com"
          },
          {
            "name": "AUTO SERV M&D S.R.L.",
            "address": "CALEA BUCURESTI, NR.3",
            "category": "B",
            "telephone": "0723718117",
            "email": "scoala.autoserv@gmail.com"
          },
          {
            "name": "ADRIA DAN AUTO SERV S.R.L.",
            "address": "CALEA ",
            "category": "B,C,D,CE",
            "telephone": "0730666355",
            "email": "agropan_oyl@yahoo.com"
          },
          {
            "name": "FINSCL S.R.L.-D.",
            "address": "STR.REGIEI, NR.15",
            "category": "B",
            "telephone": "0729110623",
            "email": "florin.ion1190@yahoo.ro"
          },
          {
            "name": "DAVID SERV AUTO SRL",
            "address": "STR.CALEA BUCURESTI, NR.103, ET.1",
            "category": "B,C,CE",
            "telephone": "0729166336",
            "email": "davidservauto@yahoo.com"
          },
          {
            "name": "ADRIASIM AUTO S.R.L",
            "address": "STR.PETROLISTILOR, NR.16",
            "category": "A,B,A2",
            "telephone": "0734381981",
            "email": "adriasim_scoala@yahoo.com"
          },
          {
            "name": "APOSTOL STAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.MIHAI VITEAZU, NR.53",
            "category": "B",
            "telephone": "0724719601",
            "email": "apostolstan123@gmail.com"
          },
          {
            "name": "STAN GHEORGHE GHEORGHE \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.MIHAI VITEAZU, NR.53",
            "category": "B",
            "telephone": "0729130376",
            "email": "gigi.stan6013@gmail.com"
          },
          {
            "name": "MARIN STELIAN-FLORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.MIHAI VITEAZU, NR.53",
            "category": "B",
            "telephone": "0765456699",
            "email": "4fouryou2@gmail.com"
          },
          {
            "name": "PETRACHE N. IONEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.MIHAI VITEAZU, NR.53",
            "category": "B",
            "telephone": "0765278900",
            "email": "ionelpetrache955@gmail.com"
          },
          {
            "name": "ABC CONDUCERE",
            "address": "STR.CALEA BUCURESTI,NR.3",
            "category": "B",
            "telephone": "0767458947",
            "email": "abc.conducere2018@yahoo.com"
          },
          {
            "name": "AUTO OST PROFESIONAL SRL",
            "address": "STR.GARII, NR.1",
            "category": "B",
            "telephone": "0724389201",
            "email": "oprea_st@yahoo.com"
          }
        ]
      },
      {
        "city": "FETESTI",
        "schools": [
          {
            "name": "MARID S.R.L.",
            "address": "STR.CALARASI, NR.606A",
            "category": "B,C,D,Tr,CE",
            "telephone": "0763131390",
            "email": "maridauto@gmail.com"
          },
          {
            "name": "TYC AUTOCOND S.R.L.",
            "address": "STR.DEPOULUI, NR.1, POST DISPECER, ",
            "category": "B",
            "telephone": "0722383287",
            "email": "tycautocond@yahoo.com"
          },
          {
            "name": "GEN AUTO PLUS SRL",
            "address": "STR.BANATENILOR, LOT 3, IN ",
            "category": "B",
            "telephone": "0733411303",
            "email": "genautoplus@yahoo.ro"
          },
          {
            "name": "11 MIA AUTOCOND SRL",
            "address": "STR.PROMENADEI, NR.4",
            "category": "B",
            "telephone": "0769276506",
            "email": "crina.span@yahoo.com"
          },
          {
            "name": "MILITARU N. FLORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CALARASI, NR.526",
            "category": "B",
            "telephone": "0787808289",
            "email": "florinm2011@yahoo.ro"
          },
          {
            "name": "POPA NICU PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.CALARASI, NR.526",
            "category": "B",
            "telephone": "0726736868",
            "email": "nicu_czl@yahoo.com"
          },
          {
            "name": "GEN AUTO PLUS SRL",
            "address": "STR.CALARASI, NR.526, Incinta ",
            "category": "C,CE",
            "telephone": "0733411303",
            "email": "genautoplus@yahoo.com"
          },
          {
            "name": "NEH AUTO SRL",
            "address": "STR.CALARASI, NR.606A",
            "category": "B",
            "telephone": "0731918020",
            "email": "maridauto@yahoo.com"
          }
        ]
      },
      {
        "city": "SLOBOZIA",
        "schools": [
          {
            "name": "3 PPP S.R.L.",
            "address": "SOS. AMARA, NR. 19",
            "category": "B,C,D,Tr,BE,CE",
            "telephone": "0722338722",
            "email": "office@3ppp-auto.ro"
          },
          {
            "name": "P.F.A. ENE IANCU IONEL ",
            "address": "STR. DOMNITA BALASA, NR.46",
            "category": "B",
            "telephone": "0728005159",
            "email": "pfaeneionel@gmail.com"
          },
          {
            "name": "P.F.A. DRAGHICI D. TUDOR",
            "address": "STR. DOMNITA BALASA, NR.46",
            "category": "B",
            "telephone": "0723722105",
            "email": "tudordraghici60@yahoo.com"
          },
          {
            "name": "P.F.A. MARCU FANEL-AURAS ",
            "address": "STR. DOMNITA BALASA, NR.46",
            "category": "B",
            "telephone": "0720005248",
            "email": "marcuauras@yahoo.com"
          },
          {
            "name": "VALMAR AUTOTRANS S.R.L.",
            "address": "STR. MATEI BASARAB, NR.31, ETAJ 1",
            "category": "B,C,D,CE",
            "telephone": "0755125899",
            "email": "valmarslobozia@yahoo.com"
          },
          {
            "name": "PFA URSAN M. PETRE",
            "address": "STR.DOMNITA BALASA, NR.46",
            "category": "B",
            "telephone": "0736925908",
            "email": "ursanpetre@yahoo.com"
          },
          {
            "name": "BAJAN GHEORGHE PERSOANA \nFIZICA AUTORIZAT",
            "address": "STR. MATEI BASARAB, NR.72",
            "category": "B",
            "telephone": "0724838491",
            "email": "aygyl8@yahoo.com"
          },
          {
            "name": "PETRESCU ION CATALIN PERSOANA \nFIZICA AUTORIZATA ",
            "address": "STR. MATEI BASARAB, NR.72",
            "category": "B",
            "telephone": "0768551160",
            "email": "aygyl8@yahoo.com"
          },
          {
            "name": "PANA GABRIEL COSMIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR. MATEI BASARAB, NR.72",
            "category": "B",
            "telephone": "0760295779",
            "email": "aygyl8@yahoo.com"
          },
          {
            "name": "PETRESCU DUMITRU DANIEL \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR. MATEI BASARAB, NR.72",
            "category": "A,B",
            "telephone": "0723633223",
            "email": "aygyl8@yahoo.com"
          },
          {
            "name": "TATU N. NICOLAE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.VIILOR, NR.90-92",
            "category": "B",
            "telephone": "0727802730",
            "email": "STAN_ADRIAN6@YAHOO.COM"
          },
          {
            "name": "DAN VICTOR PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.VIILOR, NR.90-92",
            "category": "B",
            "telephone": "0722547448",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "POPA ROMULUS FLORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.VIILOR, NR.90-92",
            "category": "B",
            "telephone": "0723094589",
            "email": "STAN_ADRIAN6@YAHOO.COM"
          },
          {
            "name": "VASILESCU C. LIVIU PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.VIILOR, NR.90-92",
            "category": "B",
            "telephone": "0730393877",
            "email": "STAN_ADRIAN6@YAHOO.COM"
          },
          {
            "name": "STAN PETRE ADRIAN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CUZA VODA, NR.1-5",
            "category": "B",
            "telephone": "0766887710",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "VOINESCU S. IONEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CUZA VODA, NR.1-5",
            "category": "B",
            "telephone": "0728837003",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "CIOARA NITA VASILE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CUZA VODA, NR.1-5",
            "category": "B",
            "telephone": "0724035615",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "ALECU COSTEL FLORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CUZA VODA, NR.1-5",
            "category": "B",
            "telephone": "0722659491",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "MAITA N. ION PERSOANA FIZICA \nAUTORIZATA",
            "address": "B-DUL CHIMIEI, NR.6, BIROUL NR.17, \nET.1",
            "category": "B",
            "telephone": "0721617769",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "STAN N. DANIEL PERSOANA FIZICA \nAUTORIZATA",
            "address": "B-DUL CHIMIEI, NR.6, BIROUL NR.17, \nET.1",
            "category": "B",
            "telephone": "0720068729",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "OANA C. ALEXANDRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "B-DUL CHIMIEI, NR.6, BIROUL NR.17, \nET.1",
            "category": "B",
            "telephone": "0722678941",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "PREDA D. AURELIAN PERSOANA \nFIZICA AUTORIZATA",
            "address": "B-DUL CHIMIEI, NR.6, BIROUL NR.17, \nET.1",
            "category": "B",
            "telephone": "0723221652",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "PANOPOL FLORIAN PERSOANA \nFIZICA AUTORIZATA",
            "address": "B-DUL MATEI BASARAB, BL.MB20, SC.F, \nET.P",
            "category": "B",
            "telephone": "0723697970",
            "email": "panopol_adrian@yahoo.com"
          },
          {
            "name": "TUDOR R. CONSTANTIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "B-DUL MATEI BASARAB, BL.MB.20, SC.F, \nET.P",
            "category": "B",
            "telephone": "0727851878",
            "email": "stan_adrian6@yahoo.com"
          },
          {
            "name": "IFTIME OACHIM PERSOANA FIZICA \nAUTORIZATA",
            "address": "B-DUL MATEI BASARAB, BL.MB20, SC.F, \nET.P",
            "category": "B",
            "telephone": "0722319220",
            "email": "iftime_oachim@yahoo.ro"
          },
          {
            "name": "MIHAI N.V. VASILE PERSOANA \nFIZICA AUTORIZATA",
            "address": "B-DUL MATEI BASARAB, BL.MB20, SC.F, \nET.P",
            "category": "B",
            "telephone": "0744854873",
            "email": "lica_scoala@yahoo.com"
          },
          {
            "name": "VASILE P. DUMITRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CRISAN, NR.9",
            "category": "B",
            "telephone": "0726209188",
            "email": "vasile.dumitru66@yahoo.com"
          },
          {
            "name": "CRETU A. VASILE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.CRISAN, NR.9",
            "category": "B",
            "telephone": "0723554077",
            "email": "cretuvsl@yahoo.com"
          },
          {
            "name": "IRIMIA NICOLAE TUDOR PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CRISAN, NR.9",
            "category": "B",
            "telephone": "0752163227",
            "email": "tudoririmia54@yahoo.com"
          },
          {
            "name": "SERBAN L. ION PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.CRISAN, NR.9",
            "category": "B",
            "telephone": "0735559949",
            "email": "instructor_auto_serban@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC MIHAI \nEMINESCU SLOBOZIA",
            "address": "STR LACULUI, NR.10",
            "category": "B",
            "telephone": "0243231542",
            "email": "delia_girl2009@yahoo.com"
          },
          {
            "name": "M&M DRIVERS SRL",
            "address": "SLOBOZIA, STR.SOS.BUCUCURESTI-",
            "category": "B",
            "telephone": "0736678727",
            "email": "scoalamandm@gmail.com"
          },
          {
            "name": "WEST AUTO COND S.R.L",
            "address": "B-DUL CHIMIEI, NR.13",
            "category": "B",
            "telephone": "0766772236",
            "email": "westautocond@yahoo.com"
          }
        ]
      },
      {
        "city": "TANDAREI",
        "schools": [
          {
            "name": "S.C. D.C.M. PRO - ELECTRONIC ",
            "address": "(PL) Str.Fetesti, Nr. 30, Loc. Tandarei, ",
            "category": "B",
            "telephone": "0723008887",
            "email": "cristidraghici2007@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC TANDAREI",
            "address": "STR.FETESTI,NR.3",
            "category": "B",
            "telephone": "0243273257",
            "email": "gs_ag_tandarei@yahoo.com"
          },
          {
            "name": "PETRACHE I. IONEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.MECANIZATORI, NR.13",
            "category": "B",
            "telephone": "0784279911",
            "email": "ionelpetrache326@yahoo.com"
          },
          {
            "name": "VASILE GH. DOBRE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.MECANIZATORI, NR.13",
            "category": "B",
            "telephone": "0728135702",
            "email": "prego_ogracom@yahoo.com"
          },
          {
            "name": "FG AUTOCURS SRL",
            "address": "STR.GARII, NR.86, TANDAREI, ",
            "category": "B",
            "telephone": "0766756160",
            "email": "fgautocurs@gmail.com"
          }
        ]
      },
      {
        "city": "FIERBINTI TARG",
        "schools": [
          {
            "name": "SYDONIA PROD S.R.L.",
            "address": "STR.CALEA BUCURESTI, NR.36",
            "category": "B,C,D,CE",
            "telephone": "0740030493",
            "email": "ghv10@yahoo.com"
          }
        ]
      },
      {
        "city": "FACAENI",
        "schools": [
          {
            "name": "IONITA ADRIAN-NICOLAE \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.CALARASI, NR.526",
            "category": "B",
            "telephone": "0760692567",
            "email": "anaionita36@yahoo.com"
          }
        ]
      },
      {
        "city": "MANASIA",
        "schools": [
          {
            "name": "DAVID SCHOOL SRL",
            "address": "STR.CALEA BUCURESTI, NR.103, ET.2, ",
            "category": "B,D",
            "telephone": "0729166336",
            "email": "dixi.dobdel@yahoo.ro"
          },
          {
            "name": "RARES SCHOOL SRL",
            "address": "B-DUL CHIMIEI, NR.2, SLOBOZIA, ",
            "category": "B",
            "telephone": "0729166336",
            "email": "davidservauto@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "IS",
    "schools": [
      {
        "city": "PASCANI",
        "schools": [
          {
            "name": "AUTO TOP SRL",
            "address": "STR.GARII NR.6",
            "category": "B",
            "telephone": "0723566231",
            "email": "auto_top@yahoo.com"
          },
          {
            "name": "DRIVE SAFE M.C.P. SRL",
            "address": "STR.CUZA VODA BL.D2 PARTER",
            "category": "A,B,C,D,BE,CE,AM",
            "telephone": "0232760910",
            "email": "DRIVE_SAFEMCP@YAHOO.COM"
          },
          {
            "name": "AMOSERV AUTO BEST SRL",
            "address": "STR.GARII NR.6 CLUB C.F.R. UNIREA",
            "category": "B,C,D,CE",
            "telephone": "0740291701",
            "email": "avadaneiionut8@yahoo.com"
          },
          {
            "name": "WEST LIMITED COMPANY SRL",
            "address": "STR.CUZA VODA BL.D12 PARTER",
            "category": "B,C,CE",
            "telephone": "0723408934",
            "email": "auto_lew@yahoo.com"
          },
          {
            "name": "MARVIO SRL",
            "address": "STR.MOLDOVEI NR.26, C1-STATIE ",
            "category": "B,C,CE",
            "telephone": "0726706378",
            "email": "office@marvio.ro"
          },
          {
            "name": "GIV COMPLEX SRL",
            "address": "STR.GARII NR.89 BL.C26 PARTER",
            "category": "B,C,CE",
            "telephone": "0232763986",
            "email": "givcomplex@yahoo.com"
          },
          {
            "name": "DOM-PRODTRANS SRL",
            "address": "STR.ABATOR NR.2",
            "category": "B,C,D,CE",
            "telephone": "0232/719294",
            "email": "dominte04@yahoo.com"
          },
          {
            "name": "AUTO AS SRL",
            "address": "STR.CEFERISTILOR NR.1 BL.29 PARTER",
            "category": "B",
            "telephone": "0722812467",
            "email": "scoalaautoas@gmail.com"
          },
          {
            "name": "AUTO DOL COMPANY SRL",
            "address": "STR. STADIONULUI NR.1 MANSARDA ",
            "category": "B",
            "telephone": "0766850778",
            "email": "dolhascanumihai@gmail.com"
          }
        ]
      },
      {
        "city": "IASI",
        "schools": [
          {
            "name": "FLORY TOURS SRL",
            "address": "STR.ARH.ION BERINDEI NR.31",
            "category": "B",
            "telephone": "0742522583",
            "email": "anton_florin47@yahoo.com"
          },
          {
            "name": "TOP DRIVING SCHOOL SRL",
            "address": "STR.CHISINAULUI NR.57",
            "category": "B",
            "telephone": "0232299921",
            "email": "em81204@yahoo.com"
          },
          {
            "name": "AUTO DRIVER SRL",
            "address": "STR.TREI FANTANI NR.2 BIS",
            "category": "B,C,CE",
            "telephone": "0232211245",
            "email": "mosumarius78@yahoo.com"
          },
          {
            "name": "VIOVAS SRL",
            "address": "CLADIREAPT 10 DIMITRIE CANTEMIR",
            "category": "A,B,C,D,CE,A1",
            "telephone": "0232234000",
            "email": "scviovas@yahoo.com"
          },
          {
            "name": "TIFACHE & COM SRL",
            "address": "STR.FUNDACUL OTELARIEI NR.10",
            "category": "B,C,CE",
            "telephone": "0744875661",
            "email": "ioan_tifache@yahoo.com"
          },
          {
            "name": "TITI BAS SRL",
            "address": "AL.NICOLINA NR.82 ET.1",
            "category": "A,B,C,CE,AM,A2",
            "telephone": "0752669226",
            "email": "titibas72@yahoo.com"
          },
          {
            "name": "AMIGO ART SRL",
            "address": "STR.SOCOLA NR.134",
            "category": "B,BE",
            "telephone": "0728878593",
            "email": "amigoartis@yahoo.ro"
          },
          {
            "name": "AUTO-ROBERT SRL",
            "address": "BD.DACIA NR.57 BL.DD6 PARTER ",
            "category": "B",
            "telephone": "0752780595",
            "email": "ramona_ramy84@yahoo.com"
          },
          {
            "name": "AUTONASTASE SRL",
            "address": "STR.CLOPOTARI NR.26B ",
            "category": "B,B1",
            "telephone": "0232203040",
            "email": "autonastase@yahoo.com"
          },
          {
            "name": "AUTO TRAINING EXPERT SRL",
            "address": "STR.SOCOLA NR.134 INCINTA CASA DE \nCULTURA A SINDICATELOR  ",
            "category": "B",
            "telephone": "0744585945",
            "email": "autotrainingexpert@yahoo.com"
          },
          {
            "name": "AUTO PRO MOTOR SRL",
            "address": "BD.PRIMAVERII NR.64-66",
            "category": "B",
            "telephone": "0740573627",
            "email": "autopromotor@yahoo.com"
          },
          {
            "name": "JUNIOR DRIVER SRL",
            "address": "STR.MOARA DE FOC NR.15A CORP C2/1 ",
            "category": "B",
            "telephone": "0747303668",
            "email": "gabilacatusu@yahoo.com"
          },
          {
            "name": "AUTO VETA MIXT SRL",
            "address": "STR.CALEA CHISINAULUI NR.17 CORP ",
            "category": "B",
            "telephone": "0725891816",
            "email": "autovetamixt@yahoo.com"
          },
          {
            "name": "TDV AUTO EXPERT SRL",
            "address": "BUL. SOCOLA NR.39",
            "category": "B",
            "telephone": "0743194771",
            "email": "info@tdvauto.ro"
          },
          {
            "name": "AUTO CRISS DRIVER SRL",
            "address": "SEDIU ADMINISTRATIV 1 BIROURI SCA.A \nET.2",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0745683874",
            "email": "autocrissiasi@yahoo.com"
          },
          {
            "name": "DALCOM AUTO ASE SRL",
            "address": "STR.IGNAT NR.7",
            "category": "B,C,CE",
            "telephone": "0747751499",
            "email": "dalcom_auto@yahoo.com"
          },
          {
            "name": "AUTO BYA STAR SRL",
            "address": "STR.NICOLINA NR.64 PARTER",
            "category": "B",
            "telephone": "0754436649",
            "email": "contact@autobyastar.ro"
          },
          {
            "name": "AUTO FANY ABC SRL",
            "address": "BD.PRIMAVERII NR.2 BL.AD-TIV P+8 ET.3 ",
            "category": "A,B,BE",
            "telephone": "00755336464",
            "email": "autofany@yahoo.com"
          },
          {
            "name": "AUTOZET PLUS SRL",
            "address": "STR.GARII NR.16 BL.L24 MEZANIN",
            "category": "B,C,D,CE",
            "telephone": "0749761191",
            "email": "alexandra.gimiga@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC GHEORGHE \nASACHI IASI",
            "address": "STR.SARARIEI NR.189",
            "category": "B",
            "telephone": "0232210242",
            "email": "fl_balaban@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC MIHAIL STURDZA",
            "address": "STR.MIHAIL STURDZA NR.2",
            "category": "B",
            "telephone": "0232233435",
            "email": "gsmsis_ro@yahoo.com"
          },
          {
            "name": "TARA T.V. SRL",
            "address": "STR.POITIERS NR.4 ET.1",
            "category": "B",
            "telephone": "0232233813",
            "email": "costeldiaconescu@yahoo.com"
          },
          {
            "name": "EMACOM SRL",
            "address": "STR.ETERNITATE NR.5 BL.306 MEZANIN",
            "category": "B,BE",
            "telephone": "0742202745",
            "email": "dadacuscatalina@yahoo.ro"
          },
          {
            "name": "AUTO POPA SRL",
            "address": "B-DUL NICOLAE IORGA NR.39 PARTER ",
            "category": "B",
            "telephone": "0232230368",
            "email": "ilielaurentiupopa@yahoo.com"
          },
          {
            "name": "AUTO COSTI SRL",
            "address": "STRADELA IARMAROC  NR.6",
            "category": "B",
            "telephone": "0740531498",
            "email": "aautocosti@yahoo.ro"
          },
          {
            "name": "REGIA AUTONOMA DE TRANSPORT \nPUBLIC IASI RA",
            "address": "STR.SILVESTRU NR.5",
            "category": "Tv",
            "telephone": "0232404707",
            "email": "siguranta_circulatiei@ratp-iasi.ro"
          },
          {
            "name": "BRAS SRL",
            "address": "STR.SILVESTRU NR.4",
            "category": "B",
            "telephone": "0232276321",
            "email": "cristi.axinia@bras.ro"
          },
          {
            "name": "SIGUR LA VOLAN SRL",
            "address": "P.L.STR.BUCIUM NR.3 CORP A ET.1 ",
            "category": "A,B,C,D,CE",
            "telephone": "0740034212",
            "email": "scoaladesoferisv@yahoo.com"
          },
          {
            "name": "AUTO ROBERT DRIVING SRL",
            "address": "STR. NECULAU NR.3 BL.588 PARTER ",
            "category": "B",
            "telephone": "0740250311",
            "email": "dafinutza_t@yahoo.com"
          },
          {
            "name": "AUTO IORDACHE SENIOR SRL - D",
            "address": "SOSEAUA ARCU NR.74 DEMISOL",
            "category": "A,B,C,D,CE,A2",
            "telephone": "0748419439",
            "email": "auto.iordache@yahoo.com"
          },
          {
            "name": "AUTO-SALT MOTOR SRL",
            "address": "STR. SILVESTRU NR.2 BL.L4-5 SI T1A ",
            "category": "B",
            "telephone": "0741071699",
            "email": "lili.negrii@yahoo.com"
          },
          {
            "name": "COL. AGR. SI DE IND. ALIMENTARA \nVASILE ADAMACHI",
            "address": "ALEEA MIHAIL SADOVEANU NR.41",
            "category": "B",
            "telephone": "0744621152",
            "email": "rotariuconstantin@yahoo.com"
          },
          {
            "name": "AUTOBLACK-SAV SRL",
            "address": "B-DUL SOCOLA NR.112 FOSTA STR. ",
            "category": "B",
            "telephone": "0758277673",
            "email": "savaisnelu@yahoo.com"
          },
          {
            "name": "SOFERUL VIP GRUP IASI SRL",
            "address": "STR. SF LAZAR NR50 DEMISOL ",
            "category": "B",
            "telephone": "0731366660",
            "email": "office@funnypcworld.ro"
          },
          {
            "name": "AUTO TODY FAMILY JUNIOR SRL",
            "address": "STR. SARARIE NR.189, INCINTA ",
            "category": "B",
            "telephone": "0740423865",
            "email": "tody.junior@yahoo.com"
          },
          {
            "name": "P.F.A. CIOANCA AURELIAN",
            "address": "CALEA CHISINAULUI NR.57 SALA3 ET.3 ",
            "category": "B",
            "telephone": "0740434249",
            "email": "max.cioanca@gmail.com"
          },
          {
            "name": "P.F.A. NENESTEAN RAUL-MARIAN",
            "address": "CALEA CHISINAULUI NR.57 SALA3 ET.3 ",
            "category": "B",
            "telephone": "0742141178",
            "email": "nenesteanraul@yahoo.com"
          },
          {
            "name": "P.F.A. SAFTA I. PETRU",
            "address": "CALEA CHISINAULUI NR.57 SALA3 ET.3 ",
            "category": "B",
            "telephone": "0740069559",
            "email": "saftapetru@yahoo.com"
          },
          {
            "name": "AUTO MARANA SRL",
            "address": "STR.VITEJILOR NR.7-8",
            "category": "B",
            "telephone": "0723945620",
            "email": "razlet.oana@yahoo.com"
          },
          {
            "name": "P.F.A. VIERU A. SORIN",
            "address": "ALEEA NICOLINA NR.2 BL.G8 ET.9 AP.57",
            "category": "B",
            "telephone": "",
            "email": ""
          },
          {
            "name": "AUTO BEST MEDIA SRL",
            "address": "STR. TABACULUI NR.30 HALA C9/2",
            "category": "B",
            "telephone": "0743087966",
            "email": "operatorulddd@yahoo.com"
          },
          {
            "name": "P.F.A. VIERU A. SORIN",
            "address": "ALEEA NICOLINA NR.2 BL.G8 ET.9 AP.57",
            "category": "B",
            "telephone": "0740062638",
            "email": "vierusorininstructorauto@gmail.com"
          },
          {
            "name": "P.F.A. PRALEA S. DANIEL-STEFAN",
            "address": "B-DUL PRIMAVERII NR.2 CLADIRE P+8 B-DUL POITIERS NR.48 PARTER , CU ",
            "category": "B",
            "telephone": "0784319171",
            "email": "stefan_dan71@yahoo.com"
          },
          {
            "name": "AUTO KARLY BBC SRL",
            "address": "INTRARE DIN STR. PLANTELOR , CARTIER \nFRUMOASA ",
            "category": "B",
            "telephone": "0754487993",
            "email": "kly_transport@yahoo.ro"
          },
          {
            "name": "S.C. REAL DRIVER S.R.L.",
            "address": "STR. PACURARI NR.45 ETAJ BIROUL 3",
            "category": "B,C,D,CE",
            "telephone": "0748123321",
            "email": "automobilistul@gmail.com"
          },
          {
            "name": "P.F.A. BURLACU I. IOAN-LIVIU",
            "address": "STR. STRAPUNGERE SILVESTRU NR.52 \nCAM.1SI2 PARTER BL.T3-4 SC.B AP.1",
            "category": "B",
            "telephone": "0744179541",
            "email": "liviu_71burlacu@yahoo.com"
          },
          {
            "name": "P.F.A. STRACIUC V. LAURENTIU",
            "address": "STR. STRAPUNGERE SILVESTRU NR.52 \nCAM.1SI2 PARTER BL.T3-4 SC.B AP.1",
            "category": "B",
            "telephone": "0745400091",
            "email": "liviu_71burlacu@yahoo.com"
          },
          {
            "name": "COMPANIA DE TRANSPORT PUBLIC \nS.A.",
            "address": "STR. SILVESTRU NR.5 C20 CAM.3 ET.2",
            "category": "D,Tv",
            "telephone": "0232267575",
            "email": "amelin_intermed@yahoo.com"
          },
          {
            "name": "AUTO BEBI BIF SRL",
            "address": "B-DUL NICOLAE IORGA STATIA CF \nNICOLINA-GARA INTERNATIONALA",
            "category": "B",
            "telephone": "0745155167",
            "email": "beby.bif74@gmail.com"
          },
          {
            "name": "AUTO CRISS TRANS SRL",
            "address": "BD.STEFAN CEL MARE SI SFANT NR.8 ",
            "category": "B,A1,A2",
            "telephone": "0742930888",
            "email": "autocrissiasi@gmail.com"
          },
          {
            "name": "P.F.A. COSTACHE P.MARIA",
            "address": "CALEA CHISINAULUI BL.57 SALA 2 ET.3",
            "category": "B",
            "telephone": "0745024441",
            "email": "em81204@yahoo.com"
          },
          {
            "name": "P.F.A. DOMINTE RADU-\nCONSTANTIN",
            "address": "BLD. NICOLAE IORGA NR.41 BL.P5 SC.A \nET.4 AP.14",
            "category": "B",
            "telephone": "0744283110",
            "email": "radudominte@yahoo.com"
          },
          {
            "name": "AUTO LYV IASI SRL",
            "address": "CALEA CHISINAULUI NR.27 CORP B \nCAM.18 SEDIU SC FIBRESIN SA ET.2",
            "category": "B",
            "telephone": "0744608192",
            "email": "autolyviasi@gmail.com"
          }
        ]
      },
      {
        "city": "PODU ILOAIEI",
        "schools": [
          {
            "name": "AUTO TODY-FAMILY TVL SRL",
            "address": "SOS.NATIONALA NR.114 PARTER INC. ",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0742970462",
            "email": "TODYFAMILY@YAHOO.COM"
          }
        ]
      },
      {
        "city": "LUNCA CETATUII",
        "schools": [
          {
            "name": "AUTO BUZATU SRL",
            "address": "STR.GARII NR.3",
            "category": "B",
            "telephone": "0232296317",
            "email": "autobuzatu@yahoo.com"
          }
        ]
      },
      {
        "city": "TARGU FRUMOS",
        "schools": [
          {
            "name": "IMOTOUR COMPANY",
            "address": "STR. PETRU RARES NR.76A ",
            "category": "B",
            "telephone": "0745803640",
            "email": "stoleru01@yahoo.com"
          }
        ]
      },
      {
        "city": "MADARJESTI",
        "schools": [
          {
            "name": "P.F.A. RADU GH. DAN",
            "address": "CALEA CHISINAULUI NR.57 SALA1 SI2 ",
            "category": "B",
            "telephone": "0740225092",
            "email": "carmencirsmari@gmail.com"
          }
        ]
      },
      {
        "city": "CHICEREA",
        "schools": [
          {
            "name": "P.F.A. CATRINESCU CONSTANTIN",
            "address": "STR.RELEU NR.81 PARTER COM.TOMESTI ",
            "category": "B",
            "telephone": "0744529270",
            "email": "catrinescu.vasilica@gmail.com"
          },
          {
            "name": "P.F.A. CIRSMARI GH. GHEORGHE-\nFLORENTIN",
            "address": "STR.RELEU NR.81 PARTER COM.TOMESTI",
            "category": "B",
            "telephone": "0745928852",
            "email": "cirsmarir@yahoo.com"
          },
          {
            "name": "P.F.A. CATRINESCU COSTICA-\nVASILICA",
            "address": "STR. RELEU NR.81 SAT CHICEREA COM. \nTOMESTI",
            "category": "B",
            "telephone": "0726746983",
            "email": "catrinescu.vasilica@gmail.com"
          }
        ]
      },
      {
        "city": "HOLBOCA",
        "schools": [
          {
            "name": "SCOALA PROFESIONALA HOLBOCA",
            "address": "STR. PRINCIPALA NR.36",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      }
    ]
  },
  {
    "state": "MH",
    "schools": [
      {
        "city": "DROBETA TURNU \nSEVERIN",
        "schools": [
          {
            "name": "AUTO FIRU SRL",
            "address": "STR. TRAIAN. NR. 277",
            "category": "A,B,C,D,BE,CE,A1,AM",
            "telephone": "0352401175",
            "email": "scoalaautofiru@yahoo.com"
          },
          {
            "name": "BOGA TRANS SRL",
            "address": "STR. CALOMFIRESCU, NR. 140",
            "category": "B",
            "telephone": "0740465773",
            "email": "boga_trans@yahoo.com"
          },
          {
            "name": "TITI TRANS AUTO SRL",
            "address": "STR. TRAIAN, NR. 156",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0744922438",
            "email": "scoala_tititrans@yahoo.com"
          },
          {
            "name": "BONDOC METIS SRL",
            "address": "STR.ADRIAN NR.214",
            "category": "B",
            "telephone": "0252225053",
            "email": "bondoc_metis@yahoo.com"
          },
          {
            "name": "LICEUL DE TRANSPORTURI AUTO",
            "address": "B_dul CAROL I, Nr. 5",
            "category": "B,C1,C1E",
            "telephone": "0252313149",
            "email": "cttauto@yahoo.com"
          },
          {
            "name": "SCHOOL AUTO GIVA SRL",
            "address": "STR.CANTEMIR NR.8",
            "category": "B",
            "telephone": "0252225034",
            "email": "danciu.autogiva@yahoo.com"
          },
          {
            "name": "PILOT 2000 SRL",
            "address": "STR.CALOMFIRESCU NR.54",
            "category": "A,B,BE,A1,AM,A2",
            "telephone": "0352401289",
            "email": "scoalapilot@yahoo.com"
          },
          {
            "name": " SPEEDWAY AUTO SERV SRL",
            "address": "DROBETA .TURNU SEVERIN, JUD. \nMEHEDINTI",
            "category": "B",
            "telephone": "0758658660",
            "email": "speedway.autoserv@gmail.com"
          },
          {
            "name": "P.F.A FIRESCU I. CONSTANTIN",
            "address": "STR. ADRIAN NR.159",
            "category": "B",
            "telephone": "0723603381",
            "email": "firescu_c@yahoo.com"
          },
          {
            "name": "P.F.A. POPESCU D. DUMITRU MIREL",
            "address": "STR. ADRIAN NR.159",
            "category": "B",
            "telephone": "0744591783",
            "email": "viorela_popescu@yahoo.com"
          },
          {
            "name": "GENIAL DRIVE SRL",
            "address": "STR.TRAIAN NR.216",
            "category": "B,C,CE",
            "telephone": "0741069206",
            "email": "genialauto@yahoo.com"
          },
          {
            "name": "AUTO FIRU FGH SRL",
            "address": "STR.ANGHEL SALIGNY NR.14A CAM.1-2 \nPARTER",
            "category": "A,B,C,D,BE,CE,A1,AM",
            "telephone": "0532401174",
            "email": "autofirufgh@gmail.com"
          },
          {
            "name": "PRODRIVE DROBETA SRL",
            "address": "STR.TRAIAN NR.207 LOC DROBETA \nTURNU SEVERIN ",
            "category": "B",
            "telephone": "0744437538",
            "email": "chivugb@yahoo.com"
          }
        ]
      },
      {
        "city": "ORSOVA",
        "schools": [
          {
            "name": "TRANS DORIN S.R.L.",
            "address": "STR. PORTILE DE FIER, NR. 3, ET. 3",
            "category": "B",
            "telephone": "0740273893",
            "email": "mlezivaiosif@gmail.com"
          }
        ]
      },
      {
        "city": "SIMIAN",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC TUDOR \nVLADIMIRESCU ",
            "address": "STR. GARII, NR. 1",
            "category": "B,Tr",
            "telephone": "0252338301",
            "email": "licvladimirescu@yahoo.com"
          }
        ]
      },
      {
        "city": "HALANGA",
        "schools": [
          {
            "name": "PILOT 2000 SRL",
            "address": "SAT HALINGA NR.18CHISINAU NR. 14, PARTER, LOC. ",
            "category": "B,C,D,CE",
            "telephone": "",
            "email": "scoalapilot@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "MM",
    "schools": [
      {
        "city": "BORSA",
        "schools": [
          {
            "name": "AUTO VIDMENA SRL",
            "address": "STR. 22 DECEMBRIE NR. 14/B",
            "category": "B",
            "telephone": "0262343433",
            "email": "vidmena_borsa@yahoo.com"
          },
          {
            "name": "AUTOMAX SRL",
            "address": "STR. LIBERTATII NR.76,INCINTA SCOLII ",
            "category": "B",
            "telephone": "0744169213",
            "email": "automax07@yahoo.com"
          },
          {
            "name": "AUTOLILI DRIVE SRL",
            "address": "STR.TINERETULUI NR.1",
            "category": "B,BE",
            "telephone": "0745368808",
            "email": "autolilimm@yahoo.com"
          },
          {
            "name": "FANTANA STANCHII SRL",
            "address": "STR.LIBERTATII NR.91",
            "category": "B,C,CE",
            "telephone": "0262343220",
            "email": "fantana.stanchii@yahoo.ro"
          }
        ]
      },
      {
        "city": "BAIA MARE",
        "schools": [
          {
            "name": "GINFLOR SRL",
            "address": "STR.GARII NR.33 ap.3",
            "category": "A,B,C,CE,A1",
            "telephone": "0740770207",
            "email": "scginflorsrl@yahoo.com"
          },
          {
            "name": "ALI & ADI SRL",
            "address": "STR. MONETARIEI NR.12",
            "category": "A,B",
            "telephone": "0742135263",
            "email": "alin_frisan@yahoo.com"
          },
          {
            "name": "AUTOPROMT SRL",
            "address": "STR.CULTURII NR.5 ET.3",
            "category": "B",
            "telephone": "036802847",
            "email": "autopromt@yahoo.com"
          },
          {
            "name": "AUTO ELEGANT SRL",
            "address": "STR. BUCOVINEI NR.9",
            "category": "B",
            "telephone": "0744933949",
            "email": "pop_teofil@yahoo.com"
          },
          {
            "name": "AUTO MOJE SRL",
            "address": "STR.REPUBLICII NR.33-35",
            "category": "A,B",
            "telephone": "0741201714",
            "email": "BOGDAN.MOJE@YAHOO.COM"
          },
          {
            "name": "FARES SRL",
            "address": "BLV. REPUBLICII NR.33,LICEUL CU ",
            "category": "B",
            "telephone": "0745037771",
            "email": "atymfi@gmail.com"
          },
          {
            "name": "COLEGIUL TEHNIC  ANGHEL ",
            "address": "STR.PROGRESULUI NR.43",
            "category": "B",
            "telephone": "0755469387",
            "email": "secretariat_ctsalignybm@yahoo.com"
          },
          {
            "name": "CARTEZIAN SRL",
            "address": "BV.UNIRII NR. 19",
            "category": "B",
            "telephone": "0722458500",
            "email": "svadrian@yahoo.com"
          },
          {
            "name": "SIROMEX COMPANY SRL",
            "address": "STR. BAII NR.3",
            "category": "B,B1",
            "telephone": "0720660323",
            "email": "scoalasiromex@gmail.com"
          },
          {
            "name": "UNA SRL",
            "address": "STR. PROGRESULUI NR.46",
            "category": "A,B",
            "telephone": "0262219870",
            "email": "deliamoldovan19@yahoo.com"
          },
          {
            "name": "AUTO-TICU SRL",
            "address": "STR.CRISULUI NR.8",
            "category": "A,B,C,BE,CE,D1,AM,A2",
            "telephone": "0262430764",
            "email": "scoalaticu@yahoo.com"
          },
          {
            "name": "PENTRU DUMNEAVOASTRA COM \nSRL",
            "address": "STR. 8 MARTIE NR.3",
            "category": "B,C,D,CE",
            "telephone": "07402965977",
            "email": "pentru.dumneavoastra@gmail.com"
          },
          {
            "name": "AA ECO EXIGENT SRL",
            "address": "STR. TRANSILVANIEI NR.8",
            "category": "B",
            "telephone": "0744539732",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "AUTODRIVER-SARA SRL",
            "address": "B-DUL BUCURESTI NR.55",
            "category": "B,C,CE",
            "telephone": "0740698653",
            "email": "erika_span@yahoo.com"
          },
          {
            "name": "AUTO RAL FORTE SRL",
            "address": "STR. 22 DECEMBRIE NR.18",
            "category": "B,C,CE",
            "telephone": "0744616750",
            "email": "autoralforte@gmail.com"
          },
          {
            "name": "AUTO SPEED ARDU SRL",
            "address": "STR.BUCOVINEI NR.9",
            "category": "B",
            "telephone": "0740120698",
            "email": "scoalasoferidorin@gmail.com"
          },
          {
            "name": "P.F.A. SCHVARCZ PETER ZOLTAN",
            "address": "STR.NICOLAE IORGA NR.6",
            "category": "B",
            "telephone": "0722873320",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. SCHVARCZ CRISTINA",
            "address": "STR.NICOLAE IORGA NR.6",
            "category": "B",
            "telephone": "0723451752",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A CRISAN GHE. FLORIN",
            "address": "STR.NICOLAE IORGA NR.6",
            "category": "B",
            "telephone": "074",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A Rat Stefan Instructor Auto",
            "address": "STR.NICOLAE IORGA NR.6",
            "category": "B",
            "telephone": "0747846641",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. DANCIU PETRU-VASILE",
            "address": "STR.FERESTRAULUI,NR.24A",
            "category": "B",
            "telephone": "0744529926",
            "email": "Ticadanciu75@gmail.com"
          },
          {
            "name": "P.F.A. DOROS MIHAI",
            "address": "STR.FERASTRAULUI,NR.24A",
            "category": "B",
            "telephone": "0745974914",
            "email": "sargiu.doros@yahoo.com"
          },
          {
            "name": "P.F.A. ISAC MIRCEA-RICARDO",
            "address": "STR.FERASTRAULUI,NR.24A",
            "category": "B",
            "telephone": "0744633860",
            "email": "deliamoldovan19@yahoo.com"
          },
          {
            "name": "P.F.A. HOBINCA IONEL",
            "address": "STR.FERASTRAULUI,NR.24A",
            "category": "B",
            "telephone": "",
            "email": "scoalasoferidorin@gmail.com"
          },
          {
            "name": "P.F.A. REMETAN TEODOR MIRCEA",
            "address": "STR.BAII NR.3",
            "category": "B",
            "telephone": "0744399867",
            "email": "stefaniaremetan@yahoo.com"
          },
          {
            "name": "P.F.A. MATE MARIUS",
            "address": "STR.BAII,NR.3",
            "category": "B",
            "telephone": "0740825518",
            "email": "marius_10mate@yahoo.com"
          },
          {
            "name": "P.F.A VELE I. IOAN-VALERIAN",
            "address": "STR.BAII NR.3",
            "category": "B",
            "telephone": "0748030456",
            "email": "valerian_vele@yahoo.com"
          },
          {
            "name": "P.F.A. MADARAS GHEORGHE",
            "address": "STR.BAII,NR.3",
            "category": "B",
            "telephone": "0743025243",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "ABCED PROF MIADI SRL",
            "address": "STR.VICTOR BABES NR.64",
            "category": "B,C,CE",
            "telephone": "0745662568",
            "email": "centrul.miadi@gmail.com"
          },
          {
            "name": "AUTO ECOLE MIS POP SRL",
            "address": "STR.GRANICERILOR NR.83 ET.PARTER",
            "category": "B,C,D,CE,B1",
            "telephone": "0742",
            "email": "mispop01@gmail.com"
          },
          {
            "name": "P.F.A. ANITAS IOAN",
            "address": "STR. PLAIULUI NR.20",
            "category": "B",
            "telephone": "0740562307",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. CHIS STELIAN OVIDIU",
            "address": "STR. TROMPETEI NR.1",
            "category": "B",
            "telephone": "0744989403",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "CRISAND INFINITY SRL",
            "address": "STR. 8 MARTIE NR.7 SALA C309A,309B",
            "category": "B,C,CE",
            "telephone": "0740534204",
            "email": "andreicadar82@gmail.com"
          }
        ]
      },
      {
        "city": "TIRGU LAPUS",
        "schools": [
          {
            "name": "TRANS ARIANA SRL",
            "address": "STR. MIHAIL EMINESCU NR.3-5",
            "category": "B",
            "telephone": "0745306915",
            "email": "dorina_gordan@yahoo.com."
          }
        ]
      },
      {
        "city": "SOMCUTA MARE",
        "schools": [
          {
            "name": "AUTO START VANCEA SRL",
            "address": "STR. SPITALULUI NR.1",
            "category": "B",
            "telephone": "0740069869",
            "email": "autostartvancea@yahoo.com"
          },
          {
            "name": "P.F.A. CERNESTEAN I. IOAN",
            "address": "STR.NICOLAE BALCESCU NR.66",
            "category": "B",
            "telephone": "0742953370",
            "email": "ncernestean73@yahoo.com"
          },
          {
            "name": "P.F.A CRISAN G.RADU-DAN",
            "address": "STR.NICOLAE BALCESCU NR.66",
            "category": "B",
            "telephone": "0741932064",
            "email": "radudancrisan@yahoo.com"
          },
          {
            "name": "P.F.A Cucuiet Marin Eugen",
            "address": "STR.NICOLAE BALCESCU NR.66",
            "category": "B",
            "telephone": "0744199989",
            "email": "cucuiet.mihai@gmail.com"
          },
          {
            "name": "P.F.A GROSAN VALER",
            "address": "STR.NICOLAE BALCESCU",
            "category": "B",
            "telephone": "0745953630",
            "email": "scoalasoferidorin@gmail.com"
          },
          {
            "name": "DRIVE4YOU SRL",
            "address": "STR. HORIA NR.4",
            "category": "B",
            "telephone": "0742586966",
            "email": "lupseionutwtf@gmail.com"
          }
        ]
      },
      {
        "city": "SIGHETU \nMARMATIEI",
        "schools": [
          {
            "name": "AUTO MONDO SRL",
            "address": "STR. GENERAL MOCIULSCHI NR. 17-19",
            "category": "B",
            "telephone": "0745078988",
            "email": "auto_mondo@yahoo.com"
          },
          {
            "name": "AUTOSTRATEGIC CO-OPERATION \nSRL",
            "address": "STR. POET ANDREI MURESAN NR.8",
            "category": "A,B,D,BE,A1,AM,A2",
            "telephone": "0262317930",
            "email": "autostrategic@yahoo.com"
          },
          {
            "name": "STRATEGIC IMPEX SRL",
            "address": "STR.C.D.GHEREA NR.60",
            "category": "B,C,D,CE",
            "telephone": "0262317930",
            "email": "strategicimpexsrl@yahoo.com"
          },
          {
            "name": "PROFESIONISTUL SERV SRL",
            "address": "STR NICOLAE TITULESCU NR.40",
            "category": "B,C,D,CE",
            "telephone": "0262316680",
            "email": "profesionistul_serv@yahoo.com"
          },
          {
            "name": "DUDU TRANS SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.11",
            "category": "B",
            "telephone": "0761629270",
            "email": "lihetion@yahoo.com"
          },
          {
            "name": "AUTO FRASIN SRL",
            "address": "STR. DRAGOS VODA NR.136",
            "category": "B",
            "telephone": "0754029906",
            "email": "autofrasindunca@yahoo.com"
          },
          {
            "name": "PILOTAJ EXPERT AUTO SRL",
            "address": "STR.PIATA 1 DECEMBRIE NR.4 SC. C \nAP.31B PARTER",
            "category": "B",
            "telephone": "0752862063",
            "email": "pilotajexpert@yahoo.com"
          },
          {
            "name": "INITIAL AUTO SRL",
            "address": "STR. PIATA 1 DECEMBRIE 1918 NR.23 \nET.1",
            "category": "A,B,C,D,CE",
            "telephone": "0761616272",
            "email": "initialauto1@gmail.com"
          },
          {
            "name": "PROFESIONISTUL INDEPENDENT \nSRL",
            "address": "STR. NICOLAE TITULESCU NR.40",
            "category": "B",
            "telephone": "0262316680",
            "email": "nicolae.prodaniuc@gmail.com"
          }
        ]
      },
      {
        "city": "VISEU DE SUS",
        "schools": [
          {
            "name": "AUTOLILI DRIVE SRL",
            "address": "STR.LIBERTATII NR.1",
            "category": "B",
            "telephone": "",
            "email": "autolilimm@yahoo.com"
          },
          {
            "name": "AUTO 3A SRL",
            "address": "STR. 22 DECEMBRIE 1918 NR.25",
            "category": "B",
            "telephone": "0744686971",
            "email": "scauto3a@yahoo.com"
          }
        ]
      },
      {
        "city": "BAIA SPRIE",
        "schools": [
          {
            "name": "COLEGIUL TEHNIC DE \nTRANSPORTURI AUTO BAIA SPRIE",
            "address": "ALEEA MINERILOR NR.1",
            "category": "B",
            "telephone": "0262262355",
            "email": "hoteaelvira@yahoo.com"
          },
          {
            "name": "P.F.A.TALPOS VASILE-ANTON ",
            "address": "STR. IGNISULUI NR.80",
            "category": "B",
            "telephone": "0744145702",
            "email": "dvtalpos@yahoo.com"
          },
          {
            "name": "P.F.A. VARGA L. MIRCEA",
            "address": "STR.IGNISULUI NR.80",
            "category": "B",
            "telephone": "0744358907",
            "email": "ralu_varga@yahoo.com"
          },
          {
            "name": "P.F.A. DANEA ALEXANDRU",
            "address": "STR.IGNISULUI NR.80",
            "category": "B",
            "telephone": "0722210379",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. GILCA ANDREI-STEFAN",
            "address": "STR.IGNISULUI NR.80",
            "category": "B",
            "telephone": "0728332000",
            "email": "mm25exi@yahoo.ro"
          },
          {
            "name": "AUTOSCHOOL MIADI SRL",
            "address": "ALEEA MINERILOR NR.1A,CORP B,",
            "category": "A,B,D,A2",
            "telephone": "0745662568",
            "email": "centrul.miadi@gmail.com"
          },
          {
            "name": "AUTO-TICU SCHOOL SRL",
            "address": "ALEEA MINERILOR NR.1A SALA 27 SI ",
            "category": "B,C,CE",
            "telephone": "0745258348",
            "email": "casadibm@yahoo.com"
          }
        ]
      },
      {
        "city": "RUSCOVA",
        "schools": [
          {
            "name": "PRENOTO COM SERV SRL",
            "address": "NR.130",
            "category": "B",
            "telephone": "0753955612",
            "email": "hantigvasile55@yahoo.com"
          }
        ]
      },
      {
        "city": "CAVNIC",
        "schools": [
          {
            "name": "COMP. EXPERT SRL",
            "address": "STR.INDEPENDENTEI NR.9/3",
            "category": "B",
            "telephone": "0744648554",
            "email": "COMP.EXPERT.PERTA@YAHOO.COM"
          }
        ]
      },
      {
        "city": "TARGU LAPUS",
        "schools": [
          {
            "name": "MIS POP SRL",
            "address": "STR. 1 MAI NR.82",
            "category": "A,B,C,BE,CE,A1,A2",
            "telephone": "0744552998",
            "email": " mispop01@yahoo.com"
          },
          {
            "name": "P.F.A.BOTH A.ATTILA",
            "address": "STR.1 MAI NR.82,LICEUL TEORETIC ",
            "category": "B",
            "telephone": "0740943904",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. POP V. DUMITRU",
            "address": "STR.1 MAI NR.82,LICEUL TEORETIC ",
            "category": "B",
            "telephone": "0753266524",
            "email": "scpopdumitru@gmail.com"
          },
          {
            "name": "P.F.A.VELEA IOAN-VASILE",
            "address": "STR.1 MAI NR.82,LICEUL TEORETIC ",
            "category": "B",
            "telephone": "",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. COPIL I. MIRCEA-IOAN",
            "address": "STR. 1 MAI NR.82,LICEUL TEORETIC ",
            "category": "B",
            "telephone": "",
            "email": "gicaexigent@gmail.com"
          }
        ]
      },
      {
        "city": "SEINI",
        "schools": [
          {
            "name": "ROM AUTO SABOU SRL",
            "address": "STR. BAII NR.28",
            "category": "B,C,BE,CE",
            "telephone": "0740276801",
            "email": "romautosabou@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC AGRICOL \n\"ALEXIU BERINDE\"",
            "address": "STR. VICTORIEI NR.2",
            "category": "B",
            "telephone": "0262491422",
            "email": "cmt_gabriela@yahoo.com"
          },
          {
            "name": "DOMI-AUTO DRIVE SRL",
            "address": "PIATA UNIRII NR.20",
            "category": "B",
            "telephone": "0745974914",
            "email": "sergiu.doros@yahoo.com"
          }
        ]
      },
      {
        "city": "MERISOR",
        "schools": [
          {
            "name": "P.F.A. ORBAN ATTILA TIBI",
            "address": "NR.76A",
            "category": "A,B,AM",
            "telephone": "0723450388",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. BARBOS ANDREI",
            "address": "NR.76A",
            "category": "A,B,A2",
            "telephone": "0744599405",
            "email": "barbos_andrei12@yahoo.com"
          },
          {
            "name": "P.F.A. FULOP GHEORGHE",
            "address": "ORAS TAUTII MAGHERAUS NR.76/A",
            "category": "B",
            "telephone": "0722974041",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A.POP ALEXANDRU ",
            "address": "ORAS TAUTII MAGHERAUS NR.76/A",
            "category": "A,B,A1,AM,A2",
            "telephone": "0721261174",
            "email": "palx1964@yahoo.com"
          }
        ]
      },
      {
        "city": "CULCEA",
        "schools": [
          {
            "name": "P.F.A.VESELU C. EMANOIL",
            "address": "NR.50",
            "category": "B",
            "telephone": "0745831293",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. AVRAM EUGEN",
            "address": "NR.50",
            "category": "B",
            "telephone": "",
            "email": "gicaexigent@gmail.com"
          },
          {
            "name": "P.F.A. FARAGO Z. ZOLTAN-EMERIC",
            "address": "NR.50",
            "category": "B",
            "telephone": "0751495203",
            "email": "centrul.miadi@gmail.com"
          }
        ]
      },
      {
        "city": "SIEU",
        "schools": [
          {
            "name": "SOFER EXPERT SRL",
            "address": "STR. PRINCIPALA NR.101",
            "category": "B",
            "telephone": "",
            "email": "pilotajexpert@yahoo.com"
          }
        ]
      },
      {
        "city": "COAS",
        "schools": [
          {
            "name": "P.F.A. CIRLIG CLAUDIA-ANDREA",
            "address": "NR.260",
            "category": "B",
            "telephone": "0744292305",
            "email": "claudia_cir@yahoo.com"
          },
          {
            "name": "P.F.A. POP GHEORGHE-ROMULUS",
            "address": "NR.260",
            "category": "B",
            "telephone": "",
            "email": "Cosmin.pop07@gmail.com"
          },
          {
            "name": "P.F.A. MOLDOVAN EMILIA-DANIELA",
            "address": "NR.260",
            "category": "B",
            "telephone": "",
            "email": "danielaemiliamoldovan@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "MS",
    "schools": [
      {
        "city": "SIGHISOARA",
        "schools": [
          {
            "name": "AUTOSIG S.R.L.",
            "address": "STR.TACHE IONESCU NR.18",
            "category": "B",
            "telephone": "0722849990",
            "email": "AUTOSIG.SIGHISOARA@YAHOO.COM"
          },
          {
            "name": "TOTALCAR SRL",
            "address": "STR. HORIA TECULESCU NR.45 AP.1",
            "category": "B",
            "telephone": "0",
            "email": "varvara_dan@yahoo.com"
          },
          {
            "name": "DE ANIMA SRL",
            "address": "STR.DUMBRAVEI NR.14 AP.16",
            "category": "B",
            "telephone": "0265772868",
            "email": "nataliabalan@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NR.1 \nSIGHISOARA",
            "address": "STR.TACHE IONESCU NR.18",
            "category": "B",
            "telephone": "0265772663",
            "email": "cdragan31@clicknet.ro"
          },
          {
            "name": "CAMBUS SA",
            "address": "STR.BARATILOR NR.8",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0265772660",
            "email": "sc_cambus_sa@yahoo.com"
          },
          {
            "name": "BRITANNIA AUTO CAR SRL",
            "address": "STR.VIILOR NR.4",
            "category": "B,C,CE",
            "telephone": "0740653565",
            "email": "remusboitos@gmail.com"
          },
          {
            "name": "FULICA MARIAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.NICOLAE TITULESCU NR.19",
            "category": "B",
            "telephone": "0746908959",
            "email": "goiamariusghita@live.com"
          },
          {
            "name": "DUMITRU GHEORGHE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.NICOLAE TITULESCU NR.19",
            "category": "B",
            "telephone": "0742288265",
            "email": "goiamariusghita@live.com"
          },
          {
            "name": "GOIA MARIUS GHITA PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.NICOLAE TITULESCU NR.19",
            "category": "B",
            "telephone": "0740642645",
            "email": "goiamariusghita@live.com"
          },
          {
            "name": "AVRAM C.CORNEL MIRCEA \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.NICOLAE TITULESCU NR.19",
            "category": "B",
            "telephone": "0744206191",
            "email": "goiamariusghita@live.com"
          }
        ]
      },
      {
        "city": "TIRGU MURES",
        "schools": [
          {
            "name": " DORU AUTOACTIV IMPEX SRL",
            "address": "STR.CALARASILOR NR.22",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0265212916",
            "email": "doruautoactiv@yahoo.com"
          },
          {
            "name": "AUTO LUCEAFARUL SRL",
            "address": "STR.GHEORGHE DOJA NR.77",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0740278245",
            "email": "auto_luceafarul@yahoo.com"
          },
          {
            "name": "AUTO NIC DRIVE SRL",
            "address": "PTA. ARMATEI NR.16",
            "category": "B,C,BE,CE",
            "telephone": "0744299232",
            "email": "autonicdrive@yahoo.com"
          },
          {
            "name": "PFA GEDE SZABOLCS ANDRAS",
            "address": "STR.RODNEI NR.1/1",
            "category": "B,BE",
            "telephone": "0745379621",
            "email": "instructor_gal@yahoo.com"
          },
          {
            "name": "IQ COMPANY SRL",
            "address": "STR.CALARASILOR NR.81",
            "category": "A,B,A1,A2",
            "telephone": "0740294297",
            "email": "office_IQ@yahoo.com"
          },
          {
            "name": "UNICUM OR SRL",
            "address": "P-TA TRANDAFIRILOR NR.61",
            "category": "A,B,A1,A2",
            "telephone": "0744822670",
            "email": "vargyas.zsolt@gmail.com"
          },
          {
            "name": "VAM DRIVING SCHOOL SRL",
            "address": "STR.CALARASILOR NR.90/1",
            "category": "A,B,C,D,CE,A2",
            "telephone": "0744162163",
            "email": "murzea_alin@yahoo.com"
          },
          {
            "name": "EUROFOCUS 2010 SRL",
            "address": "STR.UZINEI NR.4",
            "category": "B,BE",
            "telephone": "0265258116",
            "email": "info@eurofocus2010.eu"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"AUREL ",
            "address": "STR.MILCOVULUI  NR.1-5",
            "category": "B",
            "telephone": "0365/730964",
            "email": "aurelpersu@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"TRAIAN ",
            "address": "STR.GHEORGHE DOJA NR.102",
            "category": "B",
            "telephone": "0740026369",
            "email": "traianvuiams@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"GHEORGHE \nSINCAI\"",
            "address": "B-DUL 1848 NR.55 ",
            "category": "B",
            "telephone": "0365730962",
            "email": "serbp@yahoo.com"
          },
          {
            "name": "AUTO VOLAN SRL",
            "address": "STR. BODONI SANDOR, NR. 2",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0745582471",
            "email": "auto.volan@yahoo.com"
          },
          {
            "name": "HANCSERVICE IMPEX SRL",
            "address": "STR.ARANY JANOS NR.42 AP.1",
            "category": "A,B,C,CE",
            "telephone": "0741147407",
            "email": "scoalahancu@yahoo.com"
          },
          {
            "name": "MONDO TRANS SRL",
            "address": "STR.CALARASILOR NR.105",
            "category": "B,C,CE,A1,A2",
            "telephone": "0265250066",
            "email": "scmondotranssrl@yahoo.com"
          },
          {
            "name": "ROLIMAAN COM SRL",
            "address": "STR.IALOMITEI Nr.7",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0265140249",
            "email": "marcela.mrsn@yahoo.com"
          },
          {
            "name": "PFA TRAMBITAS IULIUS",
            "address": "STR.RODNEI NR.1/1",
            "category": "B",
            "telephone": "0745799151",
            "email": "instructor_gal@yahoo.com"
          },
          {
            "name": "SC SUPER DRIVER SRL-D",
            "address": "STR.TARGULUI  NR.6 AP.1",
            "category": "B",
            "telephone": "0744552114",
            "email": "superdrivermures@gmail.com"
          },
          {
            "name": "BEV TRANS & DRIVE SRL",
            "address": "STR.DAMBUL  PIETROS NR.25",
            "category": "B,C,CE",
            "telephone": "0744212923",
            "email": "scoalabev@yahoo.com"
          },
          {
            "name": "THOMAS HUX TRAVEL SRL",
            "address": "STR.BOLYAI NR.3 AP.8",
            "category": "B",
            "telephone": "0746053030",
            "email": "info@thomashux.ro"
          },
          {
            "name": "TRANSALDEA PAUL SRL",
            "address": "STR.CUZA VODA NR.89 CORP 8",
            "category": "B",
            "telephone": "0744377879",
            "email": "ramonamarianicoara@yahoo.com"
          },
          {
            "name": "SIMON G GAVRIL PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.CALARASILOR NR.94",
            "category": "B",
            "telephone": "0744208863",
            "email": "karat_paper@yahoo.ro"
          },
          {
            "name": "SUPER DRIVER EXPERT S.R.L.",
            "address": "STR.TARGULUI NR.18 ET.1",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0744552114",
            "email": "doru.vacaru83@gmail.com"
          },
          {
            "name": "P.F.A. BORDAS PETRU",
            "address": "STR.CALARASILOR NR.94",
            "category": "B",
            "telephone": "0740095361",
            "email": "karat_paper@yahoo.ro"
          },
          {
            "name": "P.F.A. CODARCEA V VIOREL IOAN ",
            "address": "STR.CALARASILOR NR.94",
            "category": "B",
            "telephone": "0745237598",
            "email": "codarceanelu@yahoo.com"
          },
          {
            "name": "P.F.A. OGLEJA IOAN ",
            "address": "STR.CALARASILOR NR.94",
            "category": "B",
            "telephone": "0742203892",
            "email": "karat_paper@yahoo.ro"
          },
          {
            "name": "P.F.A. CHERTES I. IULIAN",
            "address": "STR.CALARASILOR NR.105",
            "category": "B",
            "telephone": "0745427480",
            "email": "dalmaedit@yahoo.com"
          },
          {
            "name": "P.F.A. MOLDOVAN TE TEODOR",
            "address": "STR.CALARASILOR NR.105",
            "category": "B",
            "telephone": "0741332061",
            "email": "dalmaedit@yahoo.com"
          },
          {
            "name": "P.F.A. BARABAS DIONISIE VASILE",
            "address": "STR.CALARASILOR NR.105",
            "category": "B",
            "telephone": "0740374747",
            "email": "barabas.vasile@yahoo.com"
          },
          {
            "name": "P.F.A. VINITOR ISTVAN",
            "address": "STR.CALARASILOR NR.105",
            "category": "B",
            "telephone": "0746102039",
            "email": "vinitoristi@yahoo.com"
          },
          {
            "name": "P.F.A. OLTEAN SORIN IOAN",
            "address": "STR.TIRNAVEI NR.1A",
            "category": "B",
            "telephone": "0742976859",
            "email": "vanatoru1962@yahoo.com"
          },
          {
            "name": "P.F.A.BUDA AUREL",
            "address": "STR.CUZA VODA NR.79",
            "category": "B",
            "telephone": "0744161568",
            "email": "instructor_gal@yahoo.com"
          },
          {
            "name": "P.F.A.GURGHIAN SORIN",
            "address": "STR.CUZA VODA NR.22 CAMERELE 44-45",
            "category": "B",
            "telephone": "0740693679",
            "email": "instructor.auto@gmail.com"
          },
          {
            "name": "P.F.A. NEAG L. SORIN",
            "address": "PIATA TRANDAFIRILOR NR.4 AP.3",
            "category": "B",
            "telephone": "0744240495",
            "email": "sorinneag@yahoo.com"
          },
          {
            "name": "P.F.A. POPA PAVEL",
            "address": "STR.ARANY JANOS NR.52",
            "category": "B,BE",
            "telephone": "",
            "email": "popap@usa.com"
          },
          {
            "name": "P.F.A. FARKAS BF BELA",
            "address": "STR.RODNEI NR.1/1",
            "category": "B",
            "telephone": "0740196352",
            "email": "farkasbela2009@gmail.com"
          },
          {
            "name": "P.F.A. SERBAN GHEORGHE",
            "address": "PIATA TRANDAFIRILOR NR.4 AP.3",
            "category": "B",
            "telephone": "0744394150",
            "email": "vasiucristina1983@yahoo.com"
          },
          {
            "name": "P.F.A. MATCA A. ACHIM ",
            "address": "PIATA TRANDAFIRILOR NR.4 AP.III",
            "category": "B",
            "telephone": "0745708495",
            "email": "jcsiszar@yahoo.com"
          },
          {
            "name": "CIZMAS VASILE FLORIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.CUZA VODA NR.22 CAMERELE 44-45",
            "category": "B",
            "telephone": "0740161203",
            "email": "florin.cizmas@gmail.com"
          },
          {
            "name": "P.F.A. CHIOREAN PETRISOR FLORIN ",
            "address": "STR.CUZA VODA NR.22 CAMERELE 44-45",
            "category": "B",
            "telephone": "0748121448",
            "email": "instructor.auto@gmail.com"
          },
          {
            "name": "P.F.A. FARKAS ZOLTAN-ADRIAN",
            "address": "STR.CUZA VODA NR.22 CAMERELE 44-45",
            "category": "B",
            "telephone": "0741057241",
            "email": "instructor.auto@gmail.com"
          },
          {
            "name": "SANTEAN CONSTANTIN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.ARANY JANOS NR.52",
            "category": "A,B,A1,A2",
            "telephone": "0743551568",
            "email": "angela_santean@yahoo.com"
          },
          {
            "name": "EUROFOCUS DRIVE SRL",
            "address": "STR.UZINEI NR.4",
            "category": "B",
            "telephone": "0265258116",
            "email": "eurofocus.ms@gmail.com"
          },
          {
            "name": "P.F.A. KOVACS ISTVAN",
            "address": "STR.RODNEI NR.1/1",
            "category": "B",
            "telephone": "0744926279",
            "email": "instructor_gal@yahoo.com"
          }
        ]
      },
      {
        "city": "TARNAVENI",
        "schools": [
          {
            "name": "MIANA SRL",
            "address": "STR.TRANDAFIRILOR NR.10",
            "category": "B",
            "telephone": "0265446077",
            "email": "jcsiszar@yahoo.com"
          },
          {
            "name": "ABC AUTO SRL",
            "address": "STR.REPUBLICII NR.89",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0744814725",
            "email": "abc_auto@yahoo.com"
          },
          {
            "name": "AUTO SENS S.R.L.",
            "address": "STR. REPUBLICII NR.30",
            "category": "B",
            "telephone": "0744177805",
            "email": "mireladela04@yahoo.com"
          },
          {
            "name": "POPAUTOACTIV IMPEX SRL",
            "address": "STR.REPUBLICII NR.54, CORP I, ETAJ I",
            "category": "B,C,CE",
            "telephone": "0743135460",
            "email": "popautoactiv@gmail.com"
          },
          {
            "name": "SC AUTOCOS DRIVE SRL",
            "address": "STRADA LIVEZII NR.19",
            "category": "B",
            "telephone": "0745613298",
            "email": "autocosdrive@yahoo.com"
          }
        ]
      },
      {
        "city": "VIFOROASA",
        "schools": [
          {
            "name": "PFA GAL  SANDOR",
            "address": "VIFOROASA  NR.259",
            "category": "B",
            "telephone": "0744251408",
            "email": "instructor_gal@yahoo.com"
          },
          {
            "name": "PFA SZAKACS A. ALBERT",
            "address": "NR.259",
            "category": "B",
            "telephone": "0744681099",
            "email": "edith_szakacs@yahoo.com"
          }
        ]
      },
      {
        "city": "FANTANELE",
        "schools": [
          {
            "name": "PFA ADORJANI ZOLTAN",
            "address": "SAT VIFOROASA NR.259",
            "category": "B",
            "telephone": "0744479292",
            "email": "zoltanadorjani@yahoo.com"
          },
          {
            "name": "P.F.A. MILAS ION ADRIAN ",
            "address": "SAT VIFOROASA NR.259",
            "category": "B",
            "telephone": "0745507188",
            "email": "instructorgal@yahoo.com"
          }
        ]
      },
      {
        "city": "REGHIN",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC  IOAN BOJOR ",
            "address": "STR.RAULUI NR.16",
            "category": "B",
            "telephone": "0365410936",
            "email": "nicoleta.pintican@yahoo.com"
          },
          {
            "name": "SATURNIA IMPEX SRL",
            "address": "B-DUL LIBERTATII BL.6 AP.10",
            "category": "B",
            "telephone": "0740794802",
            "email": "saturniaimpex@yahoo.com"
          },
          {
            "name": "DORU AUTOACTIV IMPEX SRL",
            "address": "STR.PANDURILOR NR.113A",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0265212916",
            "email": "doruautoactiv@yahoo.com"
          },
          {
            "name": "PFA BOTAN PETRU",
            "address": "STR.RIULUI  NR.25",
            "category": "B",
            "telephone": "",
            "email": "dorin.baciu73@yahoo.com"
          },
          {
            "name": "PFA GRECU IG IOAN",
            "address": "STR.RIULUI NR.25",
            "category": "B",
            "telephone": "0744825119",
            "email": "grecu.ioan@yahoo.com"
          },
          {
            "name": "PFA BACIU DORIN",
            "address": "STR.RIULUI  NR.25",
            "category": "B",
            "telephone": "0744579511",
            "email": "dorin.baciu73@yahoo.com"
          },
          {
            "name": "PFA HURDUCACIU T PETRU",
            "address": "STR.RIULUI  NR.25",
            "category": "B",
            "telephone": "0755/273823",
            "email": "p.hurducaciu@yahoo.com"
          },
          {
            "name": "LUCEAFARUL REGHIN SRL",
            "address": "STR.PETRU MAIOR NR.14 SPATIU ",
            "category": "B,C,D,BE",
            "telephone": "0365455052",
            "email": "luceafarul_reghin@yahoo.com"
          },
          {
            "name": "GO & DRIVE PILOTAJ SRL",
            "address": "PIATA PETRU MAIOR NR.42",
            "category": "B,C,CE",
            "telephone": "0747835706",
            "email": "jeliprozi@yahoo.com"
          },
          {
            "name": "AUTO MAYKI DIAROM SRL",
            "address": "STR.PETRU MAIOR NR.52",
            "category": "B",
            "telephone": "0740201256",
            "email": "automaykidiarom@yahoo.com"
          },
          {
            "name": "P.F.A. POP G V DUMITRU ",
            "address": "STR.PETRU MAIOR NR.20 AP.7",
            "category": "B",
            "telephone": "0749210263",
            "email": "iosifmoldovan27@yahoo.com"
          },
          {
            "name": "P.F.A. MOLDOVAN L IOSIF ",
            "address": "STR.PETRU MAIOR NR.20 AP.7",
            "category": "B",
            "telephone": "0744537425",
            "email": "iosifmoldovan27@yahoo.com"
          },
          {
            "name": "P.F.A. COSTIN PETRU",
            "address": "STR.GHEORGHE DOJA NR.18 CORP 2-",
            "category": "B",
            "telephone": "0744437143",
            "email": "musdaniel@yahoo.com"
          },
          {
            "name": "P.F.A. BOROS ALEXANDRU",
            "address": "STR.GHEORGHE DOJA NR.18 CORP 2-",
            "category": "B",
            "telephone": "0744202879",
            "email": "musdaniel@yahoo.com"
          },
          {
            "name": "P.F.A. BOARIU IOAN LIVIU",
            "address": "STR.GHEORGHE DOJA NR.18 CORP 2-",
            "category": "B",
            "telephone": "0744869007",
            "email": "musdaniel@yahoo.com"
          },
          {
            "name": "P.F.A. RADU A IOAN",
            "address": "STR.GHEORGHE DOJA NR.18 CORP 2-",
            "category": "B",
            "telephone": "0745359649",
            "email": "musdaniel@yahoo.com"
          },
          {
            "name": "P.F.A. RUSU M. ALEXANDRU",
            "address": "PIATA PETRU MAIOR NR.20 AP.7",
            "category": "B",
            "telephone": "",
            "email": "musdaniel@yahoo.com"
          },
          {
            "name": "P.F.A. OARGA HOREA LUCIAN",
            "address": "PIATA PETRU MAIOR NR.42, ETAJ",
            "category": "B",
            "telephone": "0742353209",
            "email": "horeaoarga@yahoo.com"
          },
          {
            "name": "P.F.A. NISTOR I CLAUDIU VASILE",
            "address": "PIATA PETRU MAIOR NR.42, ETAJ",
            "category": "B",
            "telephone": "0740304399",
            "email": "clau.nis1981@gmail.com"
          },
          {
            "name": "P.F.A. ROMONTI V CALIN ",
            "address": "PIATA PETRU MAIOR NR.42, ETAJ",
            "category": "B",
            "telephone": "0747499214",
            "email": "romontiradu@yahoo.com"
          },
          {
            "name": "LUPESCU G NICOLAE PERSOANA \nFIZICA AUTORIZATA",
            "address": "REGHIN STR.SALCIILOR NR.3",
            "category": "B",
            "telephone": "0740794802",
            "email": "nicolaelupescu26@gmail.com"
          }
        ]
      },
      {
        "city": "SOVATA",
        "schools": [
          {
            "name": "HEGYI SRL",
            "address": "STR.TEILOR NR.26",
            "category": "B",
            "telephone": "0265577512",
            "email": "hegyi_reka@yahoo.com"
          }
        ]
      },
      {
        "city": "IERNUT",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC IERNUT",
            "address": "STR.MIHAI EMINESCU NR.9/A",
            "category": "B,Tr",
            "telephone": "0265471420",
            "email": "gs_iernut@yahoo.com"
          }
        ]
      },
      {
        "city": "MIERCUREA \nNIRAJULUI",
        "schools": [
          {
            "name": "AUTO SULI TIGER SRL",
            "address": "PIATA BOCSKAI ISTVAN NR.70 AP.1",
            "category": "B",
            "telephone": "0743674996",
            "email": "csiszer.erika@lorinfo.ro"
          }
        ]
      },
      {
        "city": "SANTANA DE \nMURES",
        "schools": [
          {
            "name": "P.F.A. CAMPIAN SERGIU-DUMITRU",
            "address": "STR.VOINICENILOR NR.687",
            "category": "B",
            "telephone": "",
            "email": "campiansergiu@yahoo.com"
          },
          {
            "name": "P.F.A. CIOCA V IOAN",
            "address": "STR.VOINICENILOR NR.687",
            "category": "B",
            "telephone": "0742902478",
            "email": "campiansergiu@yahoo.com"
          },
          {
            "name": "P.F.A. NATEA PETRU",
            "address": "STR.VOINICENILOR NR.687",
            "category": "B",
            "telephone": "0744162163",
            "email": "campiansergiu@yahoo.com"
          },
          {
            "name": "P.F.A. SUTA SIMION SORIN",
            "address": "STR. VOINICENILOR NR. 687",
            "category": "B",
            "telephone": "0744162163",
            "email": "campiansergiu@yahoo.com"
          }
        ]
      },
      {
        "city": "ALBESTI",
        "schools": [
          {
            "name": "P.F.A. BUZAS I GHEORGHE",
            "address": "STR.VALEA ALBESTIULUI NR.8",
            "category": "B",
            "telephone": "0730609447",
            "email": "danvarvara@gmail.com"
          },
          {
            "name": "P.F.A. KONCZ STEFAN",
            "address": "STR.VALEA ALBESTIULUI NR.8",
            "category": "B",
            "telephone": "0742944064",
            "email": "danvarvara@gmail.com"
          },
          {
            "name": "P.F.A. DAMIAN AUGUSTIN",
            "address": "STR.VALEA ALBESTIULUI NR.8",
            "category": "B",
            "telephone": "0265778162",
            "email": "danvarvara@gmail.com"
          },
          {
            "name": "P.F.A. MOLDOVAN I. MARIUS IOAN",
            "address": "STR.VALEA ALBESTIULUI NR.8",
            "category": "B",
            "telephone": "0757646253",
            "email": "danvarvara@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "NT",
    "schools": [
      {
        "city": "PIATRA NEAMT",
        "schools": [
          {
            "name": "OLDANCOM SRL",
            "address": "STR.BISTRITEI NR.5",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0233235523",
            "email": "ss_dacar@yahoo.com"
          },
          {
            "name": "POP TEAM SRL",
            "address": "STR.DIMITRIE LEONIDA,BL.D4,PARTER",
            "category": "B,C,D,CE",
            "telephone": "0740537585",
            "email": "pop_team05@yahoo.com"
          },
          {
            "name": "NERD KAR SRL",
            "address": "STR.MIHAIL SADOVEANU,NR.21",
            "category": "A,B,C,CE",
            "telephone": "0742043944",
            "email": "ioniuriinicorescu@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC FORESTIER",
            "address": "STR.STEFAN CEL MARE,NR.67",
            "category": "B,Tr",
            "telephone": "0233213680",
            "email": "simonna_olariu@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DE \nTRANSPORTURI",
            "address": "SOARELUI NR.1,",
            "category": "B",
            "telephone": "0233216163",
            "email": "romeo_taranu@yahoo.com"
          },
          {
            "name": "FESTINA LENTE SRL",
            "address": "STR.DURAULUI NR.11",
            "category": "B",
            "telephone": "0233233279",
            "email": "office@festina-lente.ro"
          },
          {
            "name": "PRIM DRIVER SRL",
            "address": "STR.CUEJDI NR.6",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "740942332",
            "email": "office@primdriver.ro"
          },
          {
            "name": "KYM STAR SRL",
            "address": "BV. DECEBAL NR.44   ",
            "category": "A,B,C,CE",
            "telephone": "0722302920",
            "email": "cristimoroikim@yahoo.com"
          },
          {
            "name": "DIALIV SERV SRL",
            "address": "BDUL.TRAIAN,NR.29,BL.A9 PARTER",
            "category": "A,B,C,D,BE,CE,A1,C1,C1E,",
            "telephone": "0722764586",
            "email": "sc_dialiv@yahoo.com"
          },
          {
            "name": "N&G COMPANY SRL",
            "address": "STR.MIHAI EMINESCU,NR.13",
            "category": "B,C,CE",
            "telephone": "0767366034",
            "email": "office@scoalaikarrus.ro"
          },
          {
            "name": "AUTO DIAMOND SRL",
            "address": "STR.MIHAIL SADOVEANU,NR.21",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0753959087",
            "email": "auto.diamond91@gmail.com"
          },
          {
            "name": "VLAD DRIVE SRL",
            "address": "BVD.REPUBLICII,NR.15,ET.1",
            "category": "B",
            "telephone": "0744602293",
            "email": "diana_jipa9@yahoo.com"
          },
          {
            "name": "SSA AUTO RUSU SRL",
            "address": "STR.ARCULUI NR.8",
            "category": "B,C,CE",
            "telephone": "0743649461",
            "email": "scoalaautorusu@gmail.com"
          },
          {
            "name": "P.F.A PURICESCU MIHAI",
            "address": "STRADA DURAULUI, NR.11, CAMERA 2, ",
            "category": "B",
            "telephone": "0744528280",
            "email": "office@festina-lente.ro"
          },
          {
            "name": "ALESCOS COMPANY SRL",
            "address": "B-DUL REPEBLICII , NR.15 ",
            "category": "B",
            "telephone": "0741342993",
            "email": "diana_jipa9@yahoo.com"
          },
          {
            "name": "P.F.A MARIAN C. VIOREL",
            "address": "STR PETRU MOVILA, NR 37,ET.P ",
            "category": "B",
            "telephone": "0745491112",
            "email": "marijan_vrl@yahoo.com"
          },
          {
            "name": "P.F.A. CIUBOTARIU M. CONSTANTIN",
            "address": "STRADA PETRU MOVILA, NR.37",
            "category": "B",
            "telephone": "0722204406",
            "email": "constantin.ciubotariu@yahoo.com"
          },
          {
            "name": "P.F.A TURA I. GHEORGHE",
            "address": "STRADA BISTRITEI , NR.12, BLOC F 19, ",
            "category": "B,BE",
            "telephone": "0745538546",
            "email": "gica_tzura@yahoo.com"
          },
          {
            "name": "DIALIV JUNIOR SRL",
            "address": "STR PRIVIGHETORII, NR 5, BL B3, ETAJ P , ",
            "category": "B,C,BE,CE",
            "telephone": "",
            "email": ""
          }
        ]
      },
      {
        "city": "ROMAN",
        "schools": [
          {
            "name": "TURBO DRIVE SRL",
            "address": "BVD.REPUBLICII NR.50, PARTER",
            "category": "B",
            "telephone": "0233742277",
            "email": "taxi_ttd@yahoo.com"
          },
          {
            "name": "AUTO BZC SRL",
            "address": "BDUL.ROMAN MUSAT,NR.53",
            "category": "B,C,CE",
            "telephone": "0233738822",
            "email": "auto_bzc@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC MIRON COSTIN ",
            "address": "MIHAI EMINESCU NR.5-7",
            "category": "B",
            "telephone": "0233744403",
            "email": "manuela_ciolan@yahoo.com"
          },
          {
            "name": "URSAROC SRL",
            "address": "BDUL.REPUBLICII,NR.65",
            "category": "B,C,CE",
            "telephone": "0758935739",
            "email": "ady_rusu87@yahoo.com"
          },
          {
            "name": "VANIREX COM SRL",
            "address": "STR.CUZA VODA,NR.17,INCINTA ",
            "category": "B",
            "telephone": "0233744227",
            "email": "secretariat@vanirex.ro"
          },
          {
            "name": "VLEDIS COM SRL",
            "address": "STR.SUCEDAVA NR.21",
            "category": "B,C,D,CE",
            "telephone": "0763816653",
            "email": "scvledis@yahoo.com"
          },
          {
            "name": "RELOVIDAN SRL",
            "address": "STR.ALEXANDRU CEL BUN NR.2",
            "category": "B,C,D,CE",
            "telephone": "0742491546",
            "email": "relovidan@yahoo.com"
          },
          {
            "name": "AUTO MARILYN SRL",
            "address": "STR.REPUBLICII NR.46",
            "category": "B",
            "telephone": "0766409550",
            "email": "ancamorosan92@yahoo.com"
          },
          {
            "name": "BEST RZV DRIVE SRL",
            "address": "STR ION IONESCU DE LA BRAD, NR 1",
            "category": "B,C,BE,CE",
            "telephone": "0745427730",
            "email": "razvan_jhony@yahoo.com"
          },
          {
            "name": "P.F.A. CRISTEA IONEL INSTRUCTOR \nAUTO",
            "address": "B-DUL REPUBLICII , BL.74, PARTER",
            "category": "B,BE",
            "telephone": "0760943951",
            "email": "ancaotilia2008@yahoo.com"
          },
          {
            "name": "P.F.A. MARILE P. GHEORGHE",
            "address": "Str Cuza Voda, Bl.13, Sc C, Et 3, Ap 36",
            "category": "B",
            "telephone": "0721748647",
            "email": "ancaotilia2008@yahoo.com"
          },
          {
            "name": "P.F.A. LUCA TEODOR",
            "address": "STR DOBROGEANU GHEREA, Bl 2, Sc A, ",
            "category": "B",
            "telephone": "",
            "email": "ancaotilia2008@yahoo.com"
          },
          {
            "name": "P.F.A. LUNGU A. IOAN",
            "address": "Str.Anton Pann, Bl.37, Ap.23",
            "category": "B",
            "telephone": "0742895810",
            "email": "ancaotilia2008@yahoo.com"
          },
          {
            "name": "P.F.A POSTOLACHE GABRIEL VIOREL",
            "address": "STR VERONICA MICLE, BL.6, SC B,ET.3, ",
            "category": "B",
            "telephone": "0745274252",
            "email": "gabi.postolache63@yahoo.ro"
          },
          {
            "name": "P.F.A JURAVLE CONSTANTIN",
            "address": "STR T.VLADIMIRESCU BL.11, SC.B, ET.4, ",
            "category": "B",
            "telephone": "0721079845",
            "email": "autospridon@yahoo.com"
          },
          {
            "name": "P.F.A GRUMEZESCU GHEORGHITA",
            "address": "STR.N.TITULESCU, BL.14, SC.E, ET.5, ",
            "category": "B",
            "telephone": "0767605201",
            "email": "autospridon@yahoo.com"
          },
          {
            "name": "P.F.A SPIRIDON GH.SILVIU \nGHEORGHE",
            "address": "G-ral Eremia Grigorescu, Nr.9",
            "category": "B",
            "telephone": "0746473809",
            "email": "autospridon@yahoo.com"
          },
          {
            "name": "UNGUREANU R. IULIAN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STRADA VASILE LUPU, BLOC 6 , AP 2",
            "category": "B",
            "telephone": "0745966460",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A PALADE LIVIU",
            "address": "STR GRIVITEI,NR 36",
            "category": "B",
            "telephone": "0723439870",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A IOSUB DANUT",
            "address": "SAT ROSIORI,COM DULCESTI,NR138",
            "category": "B",
            "telephone": "0761502502",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A BLANARIU  IOAN RELU",
            "address": "ALEEA TUDOR VLADIMIRESCU, BL.2, SC ",
            "category": "B",
            "telephone": "07233015033",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A ISCIUC VALENTIN",
            "address": "STRADA SUCEDAVA,BLOC 19,SC D,et 4, ",
            "category": "B",
            "telephone": "0720061976",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A CRISTEA C. VASILE",
            "address": "STRADA SMIRODOVA, BLOC 39, SCARA ",
            "category": "B",
            "telephone": "0765235740",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A BUDEANU LIVIU CONSTANTIN",
            "address": "SCUAR REPUBLICII, NR 4",
            "category": "B",
            "telephone": "0765318929",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "RELOVIDAN STAR SRL",
            "address": "STR ALEXANDRU CEL BUN, NR 2 ",
            "category": "B",
            "telephone": "0742491546",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A SMERIA STEFAN - ADRIAN",
            "address": "STR STEFAN CEL MARE, BL 10, SC C, ET 3, ",
            "category": "B",
            "telephone": "0766420543",
            "email": "iulianhanor@yahoo.com"
          },
          {
            "name": "P.F.A MANOLACHE C.LIVIU ",
            "address": "STR BUCEGI, BL 4, AP 35",
            "category": "B",
            "telephone": "0742221843",
            "email": "manolacheliviu13@yahoo.com"
          },
          {
            "name": "P.F.A GRIGORAS A. MIHAI",
            "address": "STR.C.A ROSETTI, NR 9A, AP.14 ",
            "category": "B",
            "telephone": "0755664933",
            "email": "iulianhanor@yahoo.com"
          }
        ]
      },
      {
        "city": "BICAZ",
        "schools": [
          {
            "name": "DELRUX TRANS SRL",
            "address": "STR.BARAJULUI NR.33 ",
            "category": "B",
            "telephone": "0233255003",
            "email": "delrux@yahoo.com"
          }
        ]
      },
      {
        "city": "TARGU NEAMT",
        "schools": [
          {
            "name": "ASSMENTOR SRL",
            "address": "STR.TUDOR ",
            "category": "B",
            "telephone": "0745373217",
            "email": "assmentorsrl@yahoo.com"
          },
          {
            "name": "SC ABC LENA CRIS SRL",
            "address": "STR. MARASESTI NR.18",
            "category": "B,C,D,CE",
            "telephone": "0749384490",
            "email": "lena.cris@yahoo.com"
          },
          {
            "name": "COMPLET DRIVER SRL",
            "address": "STR.CALISTRAT HOGAS,NR.2",
            "category": "B,C,CE",
            "telephone": "0745539553",
            "email": "complet_driver@yahoo.com"
          },
          {
            "name": "TRANS GIULIA TLX SRL",
            "address": "STR.MARASESTI,NR.28",
            "category": "B,C,D,CE",
            "telephone": "0744706524",
            "email": "transgiuliatlx@yahoo.ro"
          },
          {
            "name": "RETRO DRIVER SRL",
            "address": "STR.MIHAIL EMINESCU,NR.18",
            "category": "B",
            "telephone": "0745405949",
            "email": "bonteanucata@gmail.com"
          },
          {
            "name": "P.F.A. ROBAN G. ILIE",
            "address": "STR CALISTRAT HOGAS, NR.2",
            "category": "B",
            "telephone": "0741752456",
            "email": "robanilie@yahoo.com"
          },
          {
            "name": "P.F.A TIMISESCU OVIDIU",
            "address": "STRADA CALISTRAT HOGAS, NR.2",
            "category": "B",
            "telephone": "0749407485",
            "email": "ovidiutimisescu@yahoo.com"
          },
          {
            "name": "P.F.A CRACIUN E. CONSTANTIN",
            "address": "BDUL MIHAIL EMINESCU, BL M9 , SCB, ",
            "category": "B",
            "telephone": "0740188363",
            "email": "constantincraciun58@yahoo.com"
          }
        ]
      },
      {
        "city": "TG.NEAMT",
        "schools": [
          {
            "name": "COLEGIUL TEHNIC ION CREANGA",
            "address": "STR.MARASESTI NR.175",
            "category": "B,Tr",
            "telephone": "0233790574",
            "email": "ctictgneamt@yahoo.com"
          }
        ]
      },
      {
        "city": "TIRGU NEAMT",
        "schools": [
          {
            "name": "BOGDLENA COM SRL",
            "address": "STR. M.KOGALNICEANU NR. 5 ",
            "category": "B",
            "telephone": "0745577987",
            "email": "bogdlena@yahoo.com"
          }
        ]
      },
      {
        "city": "HORIA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC ION IONESCU \nDE LA BRAD",
            "address": "SAT HORIA,STR.ALEXANDRU CEL \nBUN,NR.115",
            "category": "B",
            "telephone": "0233725665",
            "email": "liceultehnologichoria@yahoo.com"
          }
        ]
      },
      {
        "city": "BORLESTI",
        "schools": [
          {
            "name": "JIPATIM SRL",
            "address": "BDUL REPUBLICII, NR 15",
            "category": "B",
            "telephone": "0752107535",
            "email": "diana_jipa9@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "OT",
    "schools": [
      {
        "city": "CORABIA",
        "schools": [
          {
            "name": "AUTO CRIS-LAR ANDRA SRL",
            "address": "STR. POPA SAPCA BL.23 PARTER ",
            "category": "B",
            "telephone": "0763074863",
            "email": "circiumarucristina@yahoo.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC DANUBIUS",
            "address": "STR.BUCURESTI NR.29",
            "category": "B",
            "telephone": "0249560744",
            "email": "voimarius@yahoo.com"
          },
          {
            "name": "AS-AUTO ADELINA",
            "address": "STR CARAIMAN NR 6",
            "category": "B",
            "telephone": "0767957008",
            "email": "asautoadelina@yahoo.com"
          },
          {
            "name": "AUTOSAVU S.R.L.",
            "address": "STR.POPA SAPCA NR.1 CAMERA 3",
            "category": "B",
            "telephone": "0744576015",
            "email": "scoalaautosavu@yahoo.com"
          },
          {
            "name": "BECHEANU COMP. SRL",
            "address": "FRATII GOLESTI NR 21 BIS",
            "category": "B",
            "telephone": "0766280748",
            "email": "mariacristinaj203@gmail.com"
          }
        ]
      },
      {
        "city": "CARACAL",
        "schools": [
          {
            "name": "CENTTAUR AUTOMOZAIC SRL",
            "address": "STR. CRAIOVEI NR.63 ANEXA C3 ",
            "category": "B",
            "telephone": "0747065385",
            "email": "stoparis@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  DIMITRIE \nPETRESCU",
            "address": "STR. V.ALECSANDRI NR.104",
            "category": "B,Tr",
            "telephone": "0249511302",
            "email": "colegiulagricolcaracal@yahoo.com"
          },
          {
            "name": "NINE TKL",
            "address": "STR.OLTENIEI. NR.10 ",
            "category": "B",
            "telephone": "0765219818",
            "email": "nelu_tkl@yahoo.com"
          },
          {
            "name": "AUTOMOBIL NITULESCU",
            "address": "STR. PIATA VICTORIEI NR 4",
            "category": "B",
            "telephone": "0745877962",
            "email": "automobilnitulescu@yahoo.com"
          },
          {
            "name": "AUTO STANCU AMA",
            "address": "STR. 1 DECEMBRIE 1918 NR.28 ",
            "category": "B,C,D,CE",
            "telephone": "0761646610",
            "email": "auto_stancu_ama@yahoo.com"
          },
          {
            "name": "DOBRE V.F. ADRIAN P.F.A.",
            "address": "STR. 1 DECEMBRIE 1918 NR.60, BL C15 ",
            "category": "B",
            "telephone": "0249",
            "email": "adriandobre5825@gmail.com"
          },
          {
            "name": "NIXIN NIK-RO SRL",
            "address": "STR. CALEA BUCURESTI NR 29A , ETAJ ",
            "category": "B",
            "telephone": "0769765765",
            "email": "nica_nicolae1@yahoo.com"
          },
          {
            "name": "AUTO COMBEI FLO SRL",
            "address": "STR. MIHAI EMINESCU NR. 18",
            "category": "B,C,CE",
            "telephone": "0767374147",
            "email": "autocombeiflo@gmail.com"
          },
          {
            "name": "AUTOSIA LEARN & DRIVE S.R.L.-D",
            "address": "STR. BICAZ NR.2",
            "category": "B",
            "telephone": "0771039434",
            "email": "autosiadrive@gmail.com"
          }
        ]
      },
      {
        "city": "SLATINA",
        "schools": [
          {
            "name": "AUTO-MARTI",
            "address": "STR.CRISAN NR 34",
            "category": "B,C,CE",
            "telephone": "0249437008",
            "email": "slatina.automarti@gmail.com"
          },
          {
            "name": "AUTO MOTO TRAFIC",
            "address": "STR.RECEA NR.1",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0249437940",
            "email": "automototraficsrl@yahoo.com"
          },
          {
            "name": "TRANSBUZ",
            "address": "STR.SILOZULUI NR.2",
            "category": "B,C,D,CE",
            "telephone": "0249432208",
            "email": "transbuz_slatina@yahoo.com"
          },
          {
            "name": "VIS SERGIU TRANS",
            "address": "P.L STR. CIREASOV NR 32 CORP C4 ",
            "category": "B,C,CE",
            "telephone": "0740541418",
            "email": "VISSERGIUTRANS@YMAIL.COM"
          },
          {
            "name": "AUTO MARIUS AMA SM",
            "address": "B-DUL A I CUZA BL 4C SC C PARTER",
            "category": "B",
            "telephone": "0249434020",
            "email": "auto_marius_ama@yahoo.com"
          },
          {
            "name": "MATEIAS",
            "address": "STR. ABATOR NR.1 ",
            "category": "B",
            "telephone": "0249413888",
            "email": "mateias4@yahoo.com"
          },
          {
            "name": "SIMILUC AUTODRIVE SRL",
            "address": "STR.BASARABILOR NR 97 ",
            "category": "B",
            "telephone": "0769498089",
            "email": "similucautodrive@yahoo.ro"
          },
          {
            "name": "MARINESCU GRUP SCOLAR AUTO \nSRL",
            "address": "STR.OLTULUI NR.29 ETAJ ",
            "category": "B",
            "telephone": "0746178154",
            "email": "marinescugrupscolarauto@gmail.com"
          },
          {
            "name": "EUROGAB A&G S.R.L.",
            "address": "STR. ALEEA MUNCII NR.2A ",
            "category": "A,B,C,BE,CE,A2",
            "telephone": "0766627221",
            "email": "eeurogab@yahoo.com"
          },
          {
            "name": "SCUDERIA AUTO CELIA",
            "address": "STR.CRISAN NR.36",
            "category": "B,C,CE",
            "telephone": "0729524820",
            "email": "scuderia.autocelia@gmail.com"
          },
          {
            "name": "VIDI CAR SCHOOL DRIVERS SRL",
            "address": "STR. PRIMAVERII NR 18 B",
            "category": "B",
            "telephone": "0771039434",
            "email": "vidicarschool@gmail.com"
          },
          {
            "name": "C.I. AUTO OLY STAR SCHOOL SRL-D",
            "address": "B-DUL NICOLAE TITULESCU BL 26 ",
            "category": "B",
            "telephone": "0766237538",
            "email": "olarucamelia91@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"PETRE S \nAURELIAN\"",
            "address": "STR.STREHARETI NR 150",
            "category": "B",
            "telephone": "0249432507",
            "email": "jeni_vasiloaica@yahoo.com"
          }
        ]
      },
      {
        "city": "POTCOAVA",
        "schools": [
          {
            "name": "PSIHODENT",
            "address": "STR.BIRCII ",
            "category": "B",
            "telephone": "0744629916",
            "email": "catanoiueugen@yahoo.com"
          }
        ]
      },
      {
        "city": "DRAGANESTI",
        "schools": [
          {
            "name": "ALIFCOM",
            "address": "STR. N TITULESCU NR 155",
            "category": "B",
            "telephone": "0721257720",
            "email": "alif_com68@yahoo.com"
          }
        ]
      },
      {
        "city": "FARCASELE",
        "schools": [
          {
            "name": "MIRSERVMAR",
            "address": "FARCASU DE JOS",
            "category": "B",
            "telephone": "0249",
            "email": "mariacristinaj203@gmail.com"
          }
        ]
      },
      {
        "city": "BOBICESTI",
        "schools": [
          {
            "name": "AUTO NINO VIT S. R. L.",
            "address": "STR.SARULUI NR.56  SAT MIRILA  COM ",
            "category": "B",
            "telephone": "0742131636",
            "email": "auto_ninovit@yahoo.com"
          }
        ]
      },
      {
        "city": "BALS",
        "schools": [
          {
            "name": "AUTOMOBIL STEFI-CIP",
            "address": "STR.N.BALCESCU NR.209 BL 4G SC.1 ",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0249452024",
            "email": "steficip@yahoo.com"
          },
          {
            "name": "GIANY EXPERT GNS SRL",
            "address": "STR. NICOLAE BALCESCU NR.7 COMPLEX \nCENTRU ET.1 SPATIUL NR 1",
            "category": "B",
            "telephone": "0744278735",
            "email": "gianygns2014@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NICOLAE \nBALCESCU",
            "address": "STR.NICOLAE BALCESCU NR.47",
            "category": "B",
            "telephone": "0249451064",
            "email": "strimbeanudaniel@yahoo.com"
          }
        ]
      },
      {
        "city": "SLATIOARA",
        "schools": [
          {
            "name": "SCOALA IULCIA MARICRIS SRL",
            "address": "SAT SLATIOARA T54 P1/1/4/1",
            "category": "B",
            "telephone": "0762900446",
            "email": "iulciamaricris@gmail.com"
          }
        ]
      },
      {
        "city": "PIATRA OLT",
        "schools": [
          {
            "name": "CASUTA TRANDAFIRILOR M&S-PO \nS.R.L.",
            "address": "SAT CRIVA DE SUS. ORAS PIATRA-OLT \nSTR.MARICESTI NR.29",
            "category": "B",
            "telephone": "0721956933",
            "email": "milica.costache@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "PH",
    "schools": [
      {
        "city": "PLOIESTI",
        "schools": [
          {
            "name": "VADRAUTO SRL",
            "address": "STR. POSTEI NR. 46",
            "category": "B,C,CE",
            "telephone": "0721505307",
            "email": "tiuganadriana@ymail.com"
          },
          {
            "name": "ZANUBA SRL",
            "address": "P-TA VICTORIEI NR.5 BL.B-EST Parter ",
            "category": "B",
            "telephone": "0722505191",
            "email": "casa_duca@yahoo.com"
          },
          {
            "name": "GARAL TRANS SRL",
            "address": "STR. NEAGOE BASARAB NR.25",
            "category": "B",
            "telephone": "0766234918",
            "email": "carmen.ganea@yahoo.com"
          },
          {
            "name": "VOINIK TOTAL SRL",
            "address": "STR. MARASESTI NR.259 BL.2  AP.18",
            "category": "B",
            "telephone": "0722286130",
            "email": "nikivoicu@yahoo.com"
          },
          {
            "name": "P.F.A. MALAESCU N. DANUT",
            "address": "STR. SOLD. EROU ARHIP NICOLAE NR.7  ",
            "category": "B",
            "telephone": "0765524513",
            "email": "frusinoiustelian@yahoo.com"
          },
          {
            "name": "PROSILGABIS SRL",
            "address": "STR. SPITALULUI, NR. 12A",
            "category": "B,C,D,CE",
            "telephone": "0722346364",
            "email": "prosilgabis@yahoo.com"
          },
          {
            "name": "VOLANUL EURO SRL",
            "address": "STR. ELENA DOAMNA NR.77A",
            "category": "B",
            "telephone": "0745048132",
            "email": "zgardan_anca@yahoo.com"
          },
          {
            "name": "D & T AUTO BEST SRL",
            "address": "STR. GEN. ION DRAGALINA NR. 4",
            "category": "B",
            "telephone": "0724365463",
            "email": "office@dtautobest.ro"
          },
          {
            "name": "C.C.C. ADMIS  A & D SRL",
            "address": "STR. COLTEI  NR.1",
            "category": "A,B,C,D,BE,CE,A1,B1,AM,",
            "telephone": "0758093500",
            "email": "ionela_alecu2000@yahoo.com"
          },
          {
            "name": "SCHOOL ADI AUTO S.R.L.",
            "address": "STR. NICOPOLE NR.44",
            "category": "B",
            "telephone": "0721140113",
            "email": "school.adiauto@yahoo.ro"
          },
          {
            "name": "P.F.A DIACONU V. EDUARD \nSEBASTIAN EDAUTO",
            "address": "STR. MARASESTI NR. 200",
            "category": "B",
            "telephone": "0720065212",
            "email": "eduarddiaconu@upcmail.ro"
          },
          {
            "name": "AUTOKLASS PUNCT RO SRL-D",
            "address": "STR. MIRCEA CEL BATRAN NR.130",
            "category": "B",
            "telephone": "0723881991",
            "email": "mihailescu.cristianmihai@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC DE \nTRANSPORTURI",
            "address": "STR. VALENI NR.144",
            "category": "B",
            "telephone": "0244543641",
            "email": "staniculae@yahoo.com"
          },
          {
            "name": "ALSIM AUTO BZM SRL.",
            "address": "STR. STEFAN CEL MARE NR. 6 CORP A ET. ",
            "category": "A,B,C,D,CE",
            "telephone": "0722249196",
            "email": "office@autobzm.ro"
          },
          {
            "name": "KRISKLASS PUNCTRO SRL",
            "address": "STR. LEBEDEI NR.10",
            "category": "A,B,C,CE,A1,A2",
            "telephone": "0723881991",
            "email": "mihailescu.cristianmihai@yahoo.com"
          },
          {
            "name": "VALTUD AUTOSERV SRL",
            "address": "STR. MIHAIL EMINESCU NR.8",
            "category": "A,B,C,CE,A2",
            "telephone": "0722781284",
            "email": "valtudautoserv@yahoo.com"
          },
          {
            "name": "SORISILV COM SRL",
            "address": "STR. ROMANA NR.120",
            "category": "B,C,D,CE",
            "telephone": "0722858283",
            "email": "scoalasorisilv@yahoo.com"
          },
          {
            "name": "GANIMAR COM SRL",
            "address": "STR. G-ral Ion Dragalina, NR.23,Cladire ",
            "category": "B",
            "telephone": "0748070977",
            "email": "marina.ilie72@yahoo.com"
          },
          {
            "name": "BON VOYAGE COM SRL",
            "address": "VASILE ALECSANDRI NR.2A",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0723324974",
            "email": "scoalabonvoyage@yahoo.com"
          },
          {
            "name": "SCORPION COM S.R.L.",
            "address": "STR VALENI,NR.141, Cladire C23-10213",
            "category": "B,C,D,CE",
            "telephone": "0244544418",
            "email": "catalina.luciana@yahoo.com"
          },
          {
            "name": "ACR AUTO TURISM SRL",
            "address": "SOS. VESTULUI NR.9B ",
            "category": "B",
            "telephone": "0745382748",
            "email": "acr_ph@acr.ro"
          },
          {
            "name": "FIRST KLG CONCEPT AUTO SRL",
            "address": "STR. SONDELOR NR.2 CORP C1 CAM. 2-3",
            "category": "B",
            "telephone": "0722385920",
            "email": "matiana_ctin@yahoo.com"
          },
          {
            "name": "IRIAN AUTOCAR SERV SRL",
            "address": "STR. ROMANA NR.78",
            "category": "A,B,C,CE",
            "telephone": "0722858283",
            "email": "irianautocar@yahoo.com"
          },
          {
            "name": "ADRYJAN INTERSPEED SRL",
            "address": "STR. POMPELOR NR. 2",
            "category": "B,C,D,CE",
            "telephone": "0724576363",
            "email": "scoalaadryjan@yahoo.com"
          },
          {
            "name": "P.F.A. NEDELCU D. GHEORGHE",
            "address": "STR. SONDELOR NR.62C AP.12",
            "category": "B",
            "telephone": "0728141048",
            "email": "george.nedelcu1980@yahoo.com"
          },
          {
            "name": "P.F.A. IONESCU GHE. EUGEN ",
            "address": "SOS. VESTULUI NR.1, Bl.52, Sc.A, Et.6, ",
            "category": "B",
            "telephone": "0723860777",
            "email": "wartalogul@yahoo.com"
          },
          {
            "name": "P.F.A. RADULESCU GH. DINU",
            "address": "ALEEA PRISLOP, NR.2, BL.5 SCARA B, ",
            "category": "B",
            "telephone": "",
            "email": ""
          },
          {
            "name": "P.F.A. POPA I. LAURENTIU",
            "address": "STR. BANATULUI NR. 2 BL. 36D SC. A ",
            "category": "B",
            "telephone": "0722851834",
            "email": "popalaurentiu_ro@yahoo.com"
          },
          {
            "name": "P.F.A. POPA TIBERIU-ALEXANDRU",
            "address": "STR. BANATULUI NR. 2 BL. 36D SC. A ",
            "category": "B",
            "telephone": "0722134634",
            "email": "tiberiu.popa.ad@gmail.com"
          },
          {
            "name": "P.F.A. VULPE P.V. DUMITRU EUGEN",
            "address": "ALEEA ARNAUTI, NR.3 BL.56, SC.B, ET.1, ",
            "category": "B",
            "telephone": "0722990540",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. Cretu St. Gheorghe \n`Instructor Auto`",
            "address": "STR. BAHLUIULUI NR.16 BL.162 AP.113",
            "category": "B",
            "telephone": "0724339536",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. BADEA N. NICOLAE \n\"INSTRUCTOR AUTO\"",
            "address": "STR. BAHLUIULUI NR.14 BL.150 SC.A \nAP.29",
            "category": "B",
            "telephone": "0765242016",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. RADULESCU GH. DINU",
            "address": "ALEEA PRISLOP, NR.2, BL.5, SCARA B, ",
            "category": "B",
            "telephone": "0726448865",
            "email": "dinuradulescu59@yahoo.com"
          },
          {
            "name": "P.F.A. TAPU N. NICU",
            "address": "STR. VIORELELOR NR.2",
            "category": "B",
            "telephone": "",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. VOICILAS MARIAN",
            "address": "STR. DR. TOMA IONESCU NR. 27 CAM. ",
            "category": "B",
            "telephone": "0723375576",
            "email": "marian_voicilas@yahoo.com"
          },
          {
            "name": "P.F.A. SIMA GHE. ALEXANDRU \nGHEORGHE",
            "address": "STR. DR. TOMA IONESCU NR. 27 CAM. \n11",
            "category": "B",
            "telephone": "0740046294",
            "email": "simaalexandru67@yahoo.com"
          },
          {
            "name": "P.F.A. PETRE C. GHEORGHE \nINSTRUCTOR AUTO",
            "address": "STR. DR. TOMA IONESCU NR. 27 CAM. \n11",
            "category": "B",
            "telephone": "0726337580",
            "email": "valtudautoserv@yahoo.com"
          },
          {
            "name": "P.F.A. MICLEA I. ADRIAN CRISTINEL",
            "address": "STR. DR. TOMA IONESCU NR. 27 CAM. ",
            "category": "B",
            "telephone": "0723322526",
            "email": "adi.miclea1969@gmail.com"
          },
          {
            "name": "P.F.A. GHEORGHE CIPRIAN-IOAN ",
            "address": "STR. CAMELIEI, NR.9B,BL.59, ET.5, AP.23",
            "category": "B",
            "telephone": "0763630898",
            "email": "ciprian_ghexghe@yahoo.com"
          },
          {
            "name": "P.F.A. PERTEA MARIAN-ALBERT",
            "address": "ALE. SILISTEI NR.3 BL.158A SC.B AP.25",
            "category": "B",
            "telephone": "0723296829",
            "email": "marianploiesti@yahoo.com"
          },
          {
            "name": "P.F.A. ANDRONE C. DACIAN OVIDIU",
            "address": "str. Marasesti nr.254",
            "category": "B",
            "telephone": "0722781375",
            "email": "ceraselaflorea@yahoo.com"
          },
          {
            "name": "P.F.A. ANGHEL GH. CRISTINEL \nCONSTANTIN",
            "address": "STR. SOLD. EROU VASILE \nCONSTANTINOIU NR.10",
            "category": "B",
            "telephone": "0722367473",
            "email": "ceraselaflorea@yahoo.com"
          },
          {
            "name": "P.F.A. IRIMIA ST. ION",
            "address": "PT. MIHAI VITEAZU NR. 3 BL. 10G2 AP. ",
            "category": "B",
            "telephone": "0722358905",
            "email": "ceraselaflorea@yahoo.com"
          },
          {
            "name": "P.F.A. SANDA GH. IANCU VALENTIN",
            "address": "STR. BUSTENARI NR.38",
            "category": "B",
            "telephone": "0722891556",
            "email": "pascutoirodica@gmail.com"
          },
          {
            "name": "SORISILV ROMSERV SRL",
            "address": "STR. ROMANA NR.120",
            "category": "B,C,CE",
            "telephone": "0744532226",
            "email": "scoalasorisilv@yahoo.com"
          },
          {
            "name": "P.F.A. TRIFAN I. GHEORGHE",
            "address": "STR. MIHAI BRAVU  NR.179 BL.3B  SC.A  ",
            "category": "B",
            "telephone": "0723321673",
            "email": "ceraselaflorea@yahoo.com"
          },
          {
            "name": "SARA AUTO SCHOOL SRL",
            "address": "STR. IZVOARE NR. 129",
            "category": "B",
            "telephone": "0720190589",
            "email": "saraautoschool@yahoo.com"
          },
          {
            "name": "AUTO CASA DUCA SRL",
            "address": "STR. PIATA VICTORIEI NR. 5 BL. B-EST",
            "category": "B",
            "telephone": "",
            "email": "casa_duca@yahoo.com"
          },
          {
            "name": "P.F.A. CORALIU I. CONSTANTIN \nCARO-AUTO",
            "address": "STR. FRASINET NR. 8 BL. 5M SC. A ET.3 \nAP. 14",
            "category": "B",
            "telephone": "0723676843",
            "email": "coraliu.constantin@gmail.com"
          },
          {
            "name": "P.F.A. MIHALCESCU CRISTIAN -\nAUTOSERV",
            "address": "BDUL. BUCURESTI NR. 2D BL. 15 SC. D \nAP. 31",
            "category": "B",
            "telephone": "0723282655",
            "email": "cristianmihalcescu@yahoo.com"
          },
          {
            "name": "P.F.A MATEI ST. FILI",
            "address": "STR. MIHAI BRAVU BL. 38 SC. A AP. 10",
            "category": "B",
            "telephone": "",
            "email": "nashul60@yahoo.com"
          },
          {
            "name": "KRISKLASS DRIVER SRL",
            "address": "STR. ROSIORI NR. 22 ETAJ P",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0723881991",
            "email": "mihailescu.cristianmihai@yahoo.com"
          },
          {
            "name": "LAUR SRL",
            "address": "STR. TORCATORI NR. 3",
            "category": "B",
            "telephone": "0244590592",
            "email": "tatarus_liviu@yahoo.com"
          },
          {
            "name": "JAN AUTOSERV SRL",
            "address": "STR. DR. TOMA IONESCU NR. 27 ETAJ 1",
            "category": "B",
            "telephone": "0724576363",
            "email": "georgiana31cst@gmail.com"
          },
          {
            "name": "CRISTIAN HAPC CO SRL",
            "address": "STR. DR. TOMA IONESCU NR.27 ET.2 \nCAMIN FETE LICEUL UCECOM",
            "category": "B",
            "telephone": "0732534264",
            "email": "rcris21r@yahoo.com"
          },
          {
            "name": "ALSIG BZM SRL",
            "address": "STR. TABACI NR. 3",
            "category": "A,B,C,D,CE",
            "telephone": "0722249196",
            "email": "officealsigbzm@gmail.com"
          },
          {
            "name": "P.F.A. NICA N. NATASA RAMONA",
            "address": "Str. Dr. Toma IONESCU, Nr.27, Etj.II, ",
            "category": "B",
            "telephone": "0723529116",
            "email": "ioananatallia@yahoo.com"
          }
        ]
      },
      {
        "city": "CAMPINA",
        "schools": [
          {
            "name": "AUTOPROF SRL",
            "address": "STR. GRIVITEI NR.39",
            "category": "A,B,AM",
            "telephone": "0762210556",
            "email": "autoprof41@yahoo.com"
          },
          {
            "name": "HIP PEP AUTO SRL",
            "address": "STR. BOBALNA NR. 39",
            "category": "B,C,CE",
            "telephone": "0737158798",
            "email": "schristescu@yahoo.com"
          },
          {
            "name": "SCOALA DE AGENTI DE POLITIE \nVASILE LASCAR",
            "address": "BDUL.CAROL I NR.145",
            "category": "B",
            "telephone": "0723216467",
            "email": "mateioctavian@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC MECANIC \nMUNICIPIUL CAMPINA",
            "address": "STR. ECATERINA TEODOROIU NR.34",
            "category": "B,C1",
            "telephone": "0244373269",
            "email": "vandrone@yahoo.com"
          },
          {
            "name": "MANLEX AUTOSERV PARTS SRL",
            "address": "STR. GRIVITEI NR. 82",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0723455577",
            "email": "manlexauto@yahoo.com"
          },
          {
            "name": "P.F.A. BOCANU I. LIVIU VIOREL",
            "address": "STR. DEALULUI NR.73",
            "category": "B",
            "telephone": "0744494749",
            "email": "manuelacamelia.bocanu@yahoo.com"
          },
          {
            "name": "P.F.A. CHRISTESCU M.I. SORIN \nMARIAN",
            "address": "STR. BEIUS NR.7 BL.4 SC.A AP.6",
            "category": "B",
            "telephone": "0722787712",
            "email": "schristescu@yahoo.com"
          },
          {
            "name": "VADRAUTO SRL.",
            "address": "STR. SIMION BARNUTIU NR. 120",
            "category": "A,B,C,D,BE,CE,A1,AM",
            "telephone": "0729335577",
            "email": "tiuganadriana@ymail.com"
          },
          {
            "name": "P.F.A. POPESCU V. SORIN \"SERV \nAUTO\"",
            "address": "STR. T. VLADIMIRESCU NR.28 BL.C4 SC.A \nAP.40",
            "category": "B",
            "telephone": "0724656771",
            "email": "popescuautoscoala@yahoo.com"
          },
          {
            "name": "P.F.A. CONSTANTIN N. DORIN \"CON-\nDOR AUTO\"",
            "address": "ALEEA POIENITEI NR.1",
            "category": "B",
            "telephone": "0724528618",
            "email": "scoalamanlexauto@yahoo.com"
          },
          {
            "name": "DUMI FAST AUTOMOBILE SRL",
            "address": "B-DUL. CULTURII NR. 7",
            "category": "B",
            "telephone": "0723641207",
            "email": "eliza_lucia@yahoo.com"
          },
          {
            "name": "P.F.A. GHITA GH. MARIAN ",
            "address": "Str. Schelelor nr.3 Bl. A6 Ap.5",
            "category": "B",
            "telephone": "0722222428",
            "email": "alecsu_al3x@yahoo.com"
          },
          {
            "name": "P.F.A. PRODAN DAN CONSTANTIN",
            "address": "STR. ORIZONTULUI NR.9 BL. B42 SC.A ",
            "category": "B",
            "telephone": "0727718876",
            "email": "scoalamanlexauto@yahoo.com"
          },
          {
            "name": "PAVEL VICTOR SERVICII P.F.A.",
            "address": "STR. CALEA DOFTANEI NR. 1 BL. 17 HSC. ",
            "category": "B",
            "telephone": "",
            "email": "pashka.manase@gmail.com"
          },
          {
            "name": "VADRAUTO DRIVE TOP SRL",
            "address": "STR. I. H RADULESCU NR. 9A ",
            "category": "B,C,D,BE,CE",
            "telephone": "0736560115",
            "email": "officevadrauto@yahoo.com"
          }
        ]
      },
      {
        "city": "COM. MAGURENI",
        "schools": [
          {
            "name": "OVINIAL SRL",
            "address": "STR. TEI NR.301 A",
            "category": "B",
            "telephone": "0723540971",
            "email": "alexandruovidiu524@yahoo.com"
          }
        ]
      },
      {
        "city": "Ploiesti",
        "schools": [
          {
            "name": "P.F.A.FRUSINOIU D. STELIAN",
            "address": "Str. Theodor Aman Nr. 49 Bl.28C Sc.A ",
            "category": "B",
            "telephone": "0722761433",
            "email": "frusinoiustelian@yahoo.com"
          }
        ]
      },
      {
        "city": "MIZIL",
        "schools": [
          {
            "name": "P.F.A. LUPSAN ST. ION  ",
            "address": "STR. CUZA VODA NR.3 BL.31 SC.A ET.1 ",
            "category": "B",
            "telephone": "0723875326",
            "email": "frusinoiustelian@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC TASE \nDUMITRESCU",
            "address": "STR. MIHAI BRAVU NR.116",
            "category": "B,C,Tr,CE",
            "telephone": "0244250143",
            "email": "stan_t_maria@yahoo.com"
          },
          {
            "name": "SEMAFORUL TRANS SRL",
            "address": "STR. TEPES VODA NR. 122A ",
            "category": "B",
            "telephone": "0727614089",
            "email": "ioni_81@yahoo.com"
          },
          {
            "name": "P.F.A.GRIGORESCU A. GEORGE",
            "address": "STR. GEORGE RANETTI NR. 94",
            "category": "B",
            "telephone": "0723139638",
            "email": "ceraselaflorea@yahoo.com"
          }
        ]
      },
      {
        "city": "CORNU",
        "schools": [
          {
            "name": "KISSCOP AUTO SRL",
            "address": "SAT CORNU DE JOS  STR. PRIMAVERII ",
            "category": "B",
            "telephone": "0244367349",
            "email": "angela.chiscop@yahoo.com"
          }
        ]
      },
      {
        "city": "COMARNIC",
        "schools": [
          {
            "name": "STEPI B&C DRIVE S.R.L.",
            "address": "STR. VALEA CANDEI NR.5 ET.P",
            "category": "B",
            "telephone": "0722433841",
            "email": "iuliasteriu@yahoo.com"
          }
        ]
      },
      {
        "city": "PLOPENI",
        "schools": [
          {
            "name": "OVI BONNE ROUTE SRL",
            "address": "STR.REPUBLICII NR.1 Pavilion ",
            "category": "B",
            "telephone": "0723324974",
            "email": "ovidiuvrabioiu@yahoo.com"
          },
          {
            "name": "BON VOYAGE OVI S.R.L.",
            "address": "B-DUL REPUBLICII NR.1 Incinta Fabrica 1 ",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0729900188",
            "email": "scoalabonvoyage@yahoo.com"
          }
        ]
      },
      {
        "city": "COM. BARCANESTI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC AGRICOL \nBARCANESTI",
            "address": "SAT BARCANESTI NR.368A",
            "category": "B,Tr",
            "telephone": "0244277050",
            "email": "gsabarc@clicknet.ro"
          }
        ]
      },
      {
        "city": "MANECIU",
        "schools": [
          {
            "name": "AUTOPREST RED LARIX SRL",
            "address": "SAT GHEABA NR.253",
            "category": "B",
            "telephone": "0721262708",
            "email": "www.redlarix@yahoo.com"
          }
        ]
      },
      {
        "city": "BOLDESTI SCAENI",
        "schools": [
          {
            "name": "SCOALA DE SUBOFITERI DE \nPOMPIERI PAVEL ZAGANESCU  ",
            "address": "STR. POLIGONULUI, NR. 2",
            "category": "B,C",
            "telephone": "0244211202",
            "email": "gabi_tod@yahoo.com"
          },
          {
            "name": "P.F.A. PANTILIMONESCU ADRIAN",
            "address": "STR. FAGULUI NR.25 BL.6B SC.B AP.16",
            "category": "B",
            "telephone": "0729266260",
            "email": "adryanpanti@yahoo.com"
          }
        ]
      },
      {
        "city": "SINAIA",
        "schools": [
          {
            "name": "NELTRANS SRL",
            "address": "STR. STANJENEILOR NR.16 ",
            "category": "B",
            "telephone": "0244313940",
            "email": "mihail.gagenel@yahoo.com"
          },
          {
            "name": "P.F.A. COLTOIU MARIUS",
            "address": "STR. STANJENEILOR NR.8 BL.1B AP.8",
            "category": "B",
            "telephone": "0724927815",
            "email": "scoalamanlexauto@yahoo.com"
          }
        ]
      },
      {
        "city": "VALENII DE MUNTE",
        "schools": [
          {
            "name": "C.C.C. ADMIS A&D SRL",
            "address": "B-DUL NICOLAE IORGA , NR.93",
            "category": "B",
            "telephone": "0722273449",
            "email": "officevaleni@cccadmis.ro"
          },
          {
            "name": "P.F.A. VLADAN C. IOAN ADRIAN",
            "address": "STR.NICOLAE IORGA NR. 93",
            "category": "B",
            "telephone": "0768481821",
            "email": "cocozece@yahoo.com"
          },
          {
            "name": "P.F.A. Dumitrascu D. Gheorghe \n`Instructor Auto`",
            "address": "STR. MIHAIL KOGALNICEANU NR.19",
            "category": "B",
            "telephone": "0726926132",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. BIRZILOIU GELU-MUGUREL",
            "address": "BLD. NICOLAE IORGA NR.93",
            "category": "B",
            "telephone": "0762976191",
            "email": "mugurelbirziloiu@yahoo.com"
          }
        ]
      },
      {
        "city": "VALEA \nCALUGAREASCA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC \"GHEORGHE \nIONESCU-SISESTI\"",
            "address": "STR. SAT VALEA POPII NR.179",
            "category": "B",
            "telephone": "0244235809",
            "email": "gsagricol@gmail.com"
          },
          {
            "name": "P.F.A. PAUN D. IULIAN",
            "address": "STR. EFORIE, BL.4, AP.1",
            "category": "B",
            "telephone": "",
            "email": "Valibusuioc11@yahoo.com"
          },
          {
            "name": "P.F.A. RADUCEA N. ION",
            "address": "STR. EFORIEI, BL.35, SC.B, AP.23",
            "category": "B",
            "telephone": "",
            "email": "ionraducea@yahoo.com"
          }
        ]
      },
      {
        "city": "PUCHENII MARI",
        "schools": [
          {
            "name": "P.F.A. DINU GH. CRISTIAN",
            "address": "SAT MIROSLAVESTI NR.374",
            "category": "B",
            "telephone": "0744546321",
            "email": "loredana.dinu@yahoo.com"
          },
          {
            "name": "P.F.A. PAUN GH. TUDOREL \nINSTRUCTOR AUTO",
            "address": "SAT. PIETROSANI NR. 757",
            "category": "B",
            "telephone": "0722288338",
            "email": "ceraselaflorea@yahoo.com"
          }
        ]
      },
      {
        "city": "BALTESTI",
        "schools": [
          {
            "name": "P.F.A. MISCORICI I. MARIUS",
            "address": "SAT BALTESTI NR.353",
            "category": "B",
            "telephone": "0730953220",
            "email": "aalinalucia@yahoo.com"
          }
        ]
      },
      {
        "city": "BLEJOI",
        "schools": [
          {
            "name": "P.F.A. IONESCU ROBERT-FLORIN",
            "address": "Sat Tantareni, nr.72C",
            "category": "B",
            "telephone": "",
            "email": "wartalogul@yahoo.com"
          }
        ]
      },
      {
        "city": "ARICESTII \nRAHTIVANI",
        "schools": [
          {
            "name": "P.F.A. STEFAN N. ION ``INSTRUCTOR \nAUTO``",
            "address": "SAT ARICESTII RAHTIVANI NR.193",
            "category": "B",
            "telephone": "0728954428",
            "email": "ion.stefan59@yahoo.com"
          }
        ]
      },
      {
        "city": "BREBU",
        "schools": [
          {
            "name": "P.F.A. BARBULESCU IRINEL",
            "address": "SAT. BREBU MANASTIREI NR. 475",
            "category": "B",
            "telephone": "073696702",
            "email": "irinel_barbulescu@yahoo.com"
          }
        ]
      },
      {
        "city": "BARCANESTI",
        "schools": [
          {
            "name": "P.F.A. MOHOREANU I. ION",
            "address": "SAT ROMANESTI, NR.62",
            "category": "B",
            "telephone": "0724429386",
            "email": "ciprian_ghexghe@yahoo.com"
          }
        ]
      },
      {
        "city": "BERCENI",
        "schools": [
          {
            "name": "P.F.A. PANA A. CONSTANTIN \nINSTRUCTOR AUTO",
            "address": "SAT CORLATESTI, NR.119",
            "category": "B",
            "telephone": "0724169139",
            "email": "sophy_flory85@yahoo.com"
          },
          {
            "name": "P.F.A FLOREA JAN",
            "address": "SAT. MOARA NOUA STR. PRINCIPALA ",
            "category": "B",
            "telephone": "0722912928",
            "email": "jan.florea@yahoo.com"
          }
        ]
      },
      {
        "city": "GORGOTA",
        "schools": [
          {
            "name": "AUTO VAL VLT SRL",
            "address": "SAT POIENARII APOSTOLI NR.13",
            "category": "B",
            "telephone": "0722781284",
            "email": "autovalvlt@yahoo.com"
          }
        ]
      },
      {
        "city": "BAICOI",
        "schools": [
          {
            "name": "DAVMAR AUTO S.R.L.",
            "address": "STR. INDEPENDENTEI, NR.313",
            "category": "B",
            "telephone": "0727404636",
            "email": "davmarsrl@yahoo.com"
          }
        ]
      },
      {
        "city": "URLATI",
        "schools": [
          {
            "name": "P.F.A. DRAGHIA IONUT CIPRIAN",
            "address": "STR. 1 MAI NR.165",
            "category": "B",
            "telephone": "0723245600",
            "email": "ionut_draghia@yahoo.com"
          }
        ]
      },
      {
        "city": "COLCEAG",
        "schools": [
          {
            "name": "P.F.A. DUMITRU NICOLAE-",
            "address": "SAT. INOTESTI NR. 449",
            "category": "B",
            "telephone": "0731530105",
            "email": "ceraselaflorea@yahoo.com"
          }
        ]
      },
      {
        "city": "GORNET",
        "schools": [
          {
            "name": "AUTO FIRST DRIVE SRL-D",
            "address": "SAT GORNET STR. PAUNESTI NR.400",
            "category": "B",
            "telephone": "0735113633",
            "email": "autodrivefirst@gmail.com"
          }
        ]
      },
      {
        "city": "MAGURELE",
        "schools": [
          {
            "name": "P.F.A. CIOMAG N. MARIAN",
            "address": "SAT. MAGURELE NR. 442",
            "category": "B",
            "telephone": "0733726252",
            "email": "ceraselaflorea@yahoo.com"
          }
        ]
      },
      {
        "city": "CEPTURA",
        "schools": [
          {
            "name": "P.F.A. GHINOIU D. MARIAN DANUT",
            "address": "SAT. SOIMESTI NR. 150",
            "category": "B",
            "telephone": "0724624163",
            "email": "cathalina_ana@yahoo.com"
          }
        ]
      },
      {
        "city": "BUCOV",
        "schools": [
          {
            "name": "P.F.A. ALDOIU GHE. COSTEL SORIN",
            "address": "SAT PLEASA NR.562",
            "category": "B",
            "telephone": "0745136400",
            "email": "luminita_aldoiu@yahoo.com"
          }
        ]
      },
      {
        "city": "TINOSU",
        "schools": [
          {
            "name": "P.F.A. IONITA I. DUMITRU\"MITICA\"",
            "address": "SAT. PISCULESTI NR. 196",
            "category": "B",
            "telephone": "0731867462",
            "email": "ceraselaflorea@yahoo.com"
          }
        ]
      },
      {
        "city": "POIANA CAMPINA",
        "schools": [
          {
            "name": "P.F.A. BANICA D. DUMITRU DANUT",
            "address": "STR. BISERICII BL.1 SC.B AP.11",
            "category": "B",
            "telephone": "0723686777",
            "email": "scoalamanlexauto@yahoo.com"
          }
        ]
      },
      {
        "city": "VARBILAU",
        "schools": [
          {
            "name": "P.F.A. MOICEANU ST. ADRIAN",
            "address": "SAT LIVADEA NR.124",
            "category": "B",
            "telephone": "",
            "email": "RAZVAN.MOICEANU@YAHOO.COM"
          }
        ]
      },
      {
        "city": "BREAZA",
        "schools": [
          {
            "name": "P.F.A. SERBANOIU GH. GHEORGHE",
            "address": "STR. VICTORIEI NR.1A BL.N1 SC.A AP.4",
            "category": "B",
            "telephone": "0722937597",
            "email": "scoalamanlexauto@yahoo.com"
          }
        ]
      },
      {
        "city": "VALEA DOFTANEI",
        "schools": [
          {
            "name": "P.F.A. TOCITU GHE. GHEORGHE",
            "address": "SAT TESILA STR. SCHIORILOR NR.702",
            "category": "B",
            "telephone": "0723057972",
            "email": "schristescu@yahoo.com"
          }
        ]
      },
      {
        "city": "HABUD",
        "schools": [
          {
            "name": "BION FLAVAL SRL",
            "address": "SAT. SIRNA NR.11",
            "category": "B,C,D,BE,CE",
            "telephone": "0723203412",
            "email": "Irianautocar@yahoo.com"
          }
        ]
      },
      {
        "city": "MAGURENI",
        "schools": [
          {
            "name": "P.F.A. TIFIGIU T. VASILE ",
            "address": "SAT COCORASTII CAPLII NR.255A",
            "category": "B",
            "telephone": "0726291450",
            "email": "vtifigiu@gmail.com"
          }
        ]
      },
      {
        "city": "BUSTENI",
        "schools": [
          {
            "name": "MICLEA ROCOSMIC DRIVE SRL-D",
            "address": "BDUL LIBERTATII NR. 129",
            "category": "B",
            "telephone": "0745605868",
            "email": "rocosmicbusteni@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "SB",
    "schools": [
      {
        "city": "SIBIU",
        "schools": [
          {
            "name": "MED-AUTO",
            "address": "STR.CRISULUI NR.1",
            "category": "A,B,BE,A1,AM,A2",
            "telephone": "0745126400",
            "email": "medauto@yahoo.com"
          },
          {
            "name": "POP GHEORGHE PFA",
            "address": "STR.D.D. ROSCA NR.16",
            "category": "B",
            "telephone": "0721798891",
            "email": "george.pop15@yahoo.com"
          },
          {
            "name": "CAZACU CARMENCITA SRL",
            "address": "STR.POET DIMITRIE ANGHEL NR.4",
            "category": "B",
            "telephone": "0269520021",
            "email": "scoala.carmencita@yahoo.com"
          },
          {
            "name": "TARCEA IOAN SNC",
            "address": "STR.ZAHARIA BOIU NR.2",
            "category": "B",
            "telephone": "0269445430",
            "email": "tarcearodica@yahoo.com"
          },
          {
            "name": "SIB SERVICE SRL",
            "address": "STR.NICOLAE TECLU NR.8A",
            "category": "B",
            "telephone": "0269216090",
            "email": "office@sibservice.ro"
          },
          {
            "name": "COLEGIUL AGRICOL D.P.BARCIANU",
            "address": "STR.BANATULUI NR.2",
            "category": "B,Tr",
            "telephone": "0269211368",
            "email": "barcianu@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \nINDEPENDENTA",
            "address": "STR.GLADIOLELOR NR.2",
            "category": "B",
            "telephone": "0269221806",
            "email": "independenta_77@yahoo.com"
          },
          {
            "name": "COMBI DRIVER SRL",
            "address": "STR. GUSTERITEI  NR.16 ET.2",
            "category": "A,B,C,CE,A1,AM,A2",
            "telephone": "0722226689",
            "email": "scoaladesofericombi@gmail.com"
          },
          {
            "name": "DACIMEX SRL",
            "address": "STR. TIGLARILOR NR.20",
            "category": "B",
            "telephone": "0269228542",
            "email": "dacimex@yahoo.com"
          },
          {
            "name": "PRIMA TRANS SRL",
            "address": "CALEA DUMBRAVII NR.95",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0269250444",
            "email": "baltadorh@yahoo.com"
          },
          {
            "name": "AMSO",
            "address": "STR.BADEA CARTAN NR.1",
            "category": "A,B,C,D,BE,CE,A1,B1,C1,\nD1,C1E,AM,A2",
            "telephone": "0269/242666",
            "email": "amso_valentino@yahoo.com"
          },
          {
            "name": "BIG COMPANY SRL",
            "address": "STR.GHETARIEI NR.1",
            "category": "B,C,CE",
            "telephone": "0269431957",
            "email": "bgsibiu@gmail.com"
          },
          {
            "name": "COMBI PROMO SRL",
            "address": "STR CALEA GUSTERITEI NR.16 PARTER",
            "category": "B",
            "telephone": "0745751355",
            "email": "jurjusorin@yahoo.com"
          },
          {
            "name": "UNITATEA MILITARA 01512 SIBIU",
            "address": "STR.REVOLUTIEI NR.3-5",
            "category": "B,C",
            "telephone": "0723255498",
            "email": "virca_ioan@yahoo.com"
          },
          {
            "name": "TRANS GRUP AGAPIA SRL",
            "address": "STR.RAULUI ,NR.33 CORP CLADIRE \nADMINISTRATIV,CAMERA 49,ETAJ 2",
            "category": "A,B,C,BE,CE,B1",
            "telephone": "0725722730",
            "email": "trans.agapia@yahoo.com"
          },
          {
            "name": "MARGARIT ION INSTRUCTOR AUTO \nPFA",
            "address": "STR.DD ROSCA NR16",
            "category": "B",
            "telephone": "0740144197",
            "email": "sebi.margarit@gmail.com"
          },
          {
            "name": "BIBU VASILE-FLORIN PFA",
            "address": "STR.DD ROSCA NR.16",
            "category": "B",
            "telephone": "0751119306",
            "email": "emabibu@gmail.com"
          },
          {
            "name": "DOMNARIU DUMITRU PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.1",
            "category": "B",
            "telephone": "0741062888",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "MACARIE ION-DANIEL PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.1 ",
            "category": "B",
            "telephone": "0740468808",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "OPRISIU LAURENTIU PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.1",
            "category": "B",
            "telephone": "0727880979",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "BORJOI IOAN EUGEN PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.1",
            "category": "B,BE",
            "telephone": "0745763015",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "IONITA SORIN PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.2",
            "category": "B",
            "telephone": "0740265942",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "POPA MARCEL DIONISIE PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.2",
            "category": "B",
            "telephone": "0744591981",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "VASIU VIOREL PFA",
            "address": "STR.ZAHARIA BOIU NR.20A CAM.2",
            "category": "B",
            "telephone": "0743396708",
            "email": "borjoi_eugen@yahoo.com"
          },
          {
            "name": "BALTES MIHAI PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.FORJORILOR NR.3A",
            "category": "B",
            "telephone": "0757603753",
            "email": "scoaladesoferi.ovidiufloca@yahoo.com"
          },
          {
            "name": "FLOCA OLIVIU OVIDIU PERSOANA \nFOZICA AUTORIZATA",
            "address": "STR.FORJORILOR  NR.3A",
            "category": "B",
            "telephone": "0744604532",
            "email": "scoaladesoferi.ovidiufloca@yahoo.com"
          },
          {
            "name": "SAS A.ALEXANDRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.2 CORP A",
            "category": "B",
            "telephone": "0745668410",
            "email": "scoalasoferisas@gmail.com"
          },
          {
            "name": "PUSTEA ILIE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.2 CORP A",
            "category": "B",
            "telephone": "0749625244",
            "email": "nelutu.furdui@yahoo.com"
          },
          {
            "name": "TOADER I.M.ILIE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.2 CORP A",
            "category": "B",
            "telephone": "0740847960",
            "email": "toader.iliuta@yahoo.com"
          },
          {
            "name": "COSOFRET D.M.DUMITRU \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.FORJORILOR NR.3A",
            "category": "B",
            "telephone": "0745020589",
            "email": "scoaladesoferi.ovidiufloca@yahoo.com"
          },
          {
            "name": "POPESCU MARIUS DANIEL \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.D.D.ROSCA NR.16",
            "category": "B",
            "telephone": "0723255498",
            "email": "totosymbol@yahoo.com"
          },
          {
            "name": "A & R DRIVING SERVICE SRL",
            "address": "STR.RAULUI NR.33 CORP CLADIRE \nADMINISTRATIV ,BIROU 36-37,ET.II",
            "category": "B",
            "telephone": "0749893771",
            "email": "alex_dordea2000@yahoo.com"
          },
          {
            "name": "BULEA GH.IOAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.20A,CAMERA 2",
            "category": "B",
            "telephone": "",
            "email": "prudent.drive@yahoo.com"
          },
          {
            "name": "RUCAREAN MARIUS-ALIN \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.2 CORP A",
            "category": "B",
            "telephone": "0751974787",
            "email": "Alinrucarean@gmail.com"
          },
          {
            "name": "VONICA SORIN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.ZAHARIA BOIU NR.2 CORP A",
            "category": "B",
            "telephone": "0741345571",
            "email": "Alinrucarean@gmail.com"
          },
          {
            "name": "EVO DRIVE SCHOOL SRL",
            "address": "Str.Barsei ,Demisol,Bloc 8",
            "category": "B",
            "telephone": "0770656757",
            "email": "evodriveschool@gmail.com"
          },
          {
            "name": "ELIT DRIVE SRL",
            "address": "STR.HIPODROMULUI NR.29,ET.2",
            "category": "B",
            "telephone": "0728208208",
            "email": "office@scoalaelit.ro"
          },
          {
            "name": "AUTO TARCEA SRL",
            "address": "STR.ZAHARIA BOIU ,NR.2,CORP B",
            "category": "B,C,CE",
            "telephone": "0766699695",
            "email": "tarcearodica@yahoo.com"
          },
          {
            "name": "PIRVU G.GHEORGHE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.FORJORILOR NR.3A",
            "category": "B",
            "telephone": "0721600577",
            "email": "scoaladesoferi.ovidiufloca@yahoo.com"
          }
        ]
      },
      {
        "city": "MEDIAS",
        "schools": [
          {
            "name": "PERFORMER SRL",
            "address": "STR.LUCIAN BLAGA NR.6B AP.2",
            "category": "A,B,C,D,CE,A1,AM,A2",
            "telephone": "0740331033",
            "email": "office@scoalaperformer.ro"
          },
          {
            "name": "DRIVER LMA SRL",
            "address": "STR.MIHAI EMINESCU NR.5",
            "category": "B",
            "telephone": "0269844744:",
            "email": "driver_lma@yahoo.com"
          },
          {
            "name": "ADMIS DRIVER SRL",
            "address": "STR.ANDREI SAGUNA NR.9 ",
            "category": "B",
            "telephone": "0269833010",
            "email": "admis.driver@yahoo.com"
          },
          {
            "name": "EURO VOLAN SRL",
            "address": "STR..LUCIAN BLAGA NR.6 AP.3",
            "category": "B,C,Tr,BE,CE",
            "telephone": "0269837744",
            "email": "eurovolan@yahoo.com"
          },
          {
            "name": "AUTOPREST SRL",
            "address": "STR.AVRAM IANCU NR.13.AP.CORP NR.2",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0269831471",
            "email": "autoprestmedias@yahoo.com"
          },
          {
            "name": "TOBIMAR SANCAR SRL",
            "address": "STR.LUCIAN BLAGA NR.2 AP.1",
            "category": "B,C",
            "telephone": "0771250329",
            "email": "tobimar75@yahoo.com"
          },
          {
            "name": "PROFESIONAL AUTO INFINIT SRL-D",
            "address": "STR.DR.VICTOR BABES NR.17",
            "category": "B",
            "telephone": "0721849801",
            "email": "cretu.nicu@yahoo.com"
          },
          {
            "name": "GAVRIL S. VASILE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.LUCIAN BLAGA NR.6B,AP.2",
            "category": "B",
            "telephone": "0743995151",
            "email": "scoalaperformer@yahoo.com"
          },
          {
            "name": "HATEGAN MIRCEA PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.LUCIAN BLAGA NR.6B,AP.2",
            "category": "B",
            "telephone": "0722878666",
            "email": "hategan.mircea@yahoo.com"
          },
          {
            "name": "OGNEAN IOAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.LUCIAN BLAGA NR.6B,AP.2",
            "category": "B",
            "telephone": "0740456385",
            "email": "scoalaperformer@yahoo.com"
          },
          {
            "name": "CSIFO LEVENTE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.LUCIAN BLAGA ,NR.6B,AP.2",
            "category": "B",
            "telephone": "0771250329",
            "email": "csifolevente@yahoo.com"
          },
          {
            "name": "BAJCSI IOSIF CSOBO PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR. 1 DECEMBRIE NR.14",
            "category": "B",
            "telephone": "0743921141",
            "email": "IOJIBAJCSI@YAHOO.COM"
          },
          {
            "name": "FANTANA I.IOAB PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR. 1 DECEMBRIE NR.14",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      },
      {
        "city": "RACOVITA",
        "schools": [
          {
            "name": "HAMCIUC OCTAVIAN PFA",
            "address": "NR.468",
            "category": "B",
            "telephone": "0741034462",
            "email": "ghamciuc@yahoo.com"
          }
        ]
      },
      {
        "city": "MEDIAS ",
        "schools": [
          {
            "name": "XPERT DRIVER SRL",
            "address": "STR.MIHAIL KOGALNICEANU NR.41 ",
            "category": "B",
            "telephone": "0727373742",
            "email": "xpert_driver@yahoo.com"
          }
        ]
      },
      {
        "city": "TARNAVA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC STANESCU \nVALERIAN",
            "address": "STR.MARULUI NR.2",
            "category": "B,C1",
            "telephone": "0269858145",
            "email": "danaghira@yahoo.com"
          }
        ]
      },
      {
        "city": "AVRIG",
        "schools": [
          {
            "name": "AUTO-CIS COM SRL",
            "address": "STR.BISERICII NR.2",
            "category": "B",
            "telephone": "0269523201",
            "email": "cis_email@yahoo.com"
          },
          {
            "name": "IURO DRIVE SRL",
            "address": "STR.OLTULUI NR.2",
            "category": "B",
            "telephone": "0745505747",
            "email": "iurodrive@yahoo.com"
          }
        ]
      },
      {
        "city": "AGNITA",
        "schools": [
          {
            "name": "AUTOPREST SRL",
            "address": "STR SCOLII NR,1",
            "category": "B",
            "telephone": "0269831471",
            "email": "autoprestmedias@yahoo.com"
          },
          {
            "name": "BRAGA BTY CAR SRL-D",
            "address": "STR. 1 DECEMBRIE NR.13",
            "category": "B",
            "telephone": "0729968162",
            "email": "bytzy_4_u@yahoo.com"
          }
        ]
      },
      {
        "city": "TALMACIU",
        "schools": [
          {
            "name": "TERTECI ALEXANDRU-RARES PFA ",
            "address": "STR.GHEORGHE LAZAR NR.74",
            "category": "B",
            "telephone": "0740841712",
            "email": "corsa_alex@yahoo.com"
          },
          {
            "name": "POPELCA GHEORGHE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.LAZAR GHEORGHE NR.74",
            "category": "B",
            "telephone": "0746910920",
            "email": "gegeume@yahoo.com"
          },
          {
            "name": "MANUIL OVIDIU IOAN PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.LAZAR GHEORGHE NR.74",
            "category": "B",
            "telephone": "742225155",
            "email": "ovidiumanuil@gmail.com"
          }
        ]
      },
      {
        "city": "DUMBRAVENI",
        "schools": [
          {
            "name": "LICEUL TIMOTEI CIPARIU \nDUMBRAVENI",
            "address": "PIATA TIMOTEI CIPARIU NR.9",
            "category": "B",
            "telephone": "0269865315",
            "email": "grdumbraveni@yahoo.com"
          },
          {
            "name": "DUDA IOAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "str.Closca nr.48",
            "category": "B",
            "telephone": "0741154299",
            "email": "duda.ioan@yahoo.com"
          }
        ]
      },
      {
        "city": "SALISTE",
        "schools": [
          {
            "name": "GROZAV ILIE PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.IOAN LUPAS,NR.14",
            "category": "B",
            "telephone": "0743575209",
            "email": "ioanademian91@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "SJ",
    "schools": [
      {
        "city": "ZALAU",
        "schools": [
          {
            "name": "IONICA TRANS SRL",
            "address": "STR POET ANDREI MURESANU NR 42",
            "category": "B,C,CE",
            "telephone": "0755652994",
            "email": "ionicatrans@yahoo.com"
          },
          {
            "name": "DRIVER TEST SRL",
            "address": "STR ANDREI MURESANU NR 74/A",
            "category": "A,B,C,CE,A1",
            "telephone": "0741170990",
            "email": "ludovicjako@yahoo.com"
          },
          {
            "name": "AUTO-PALAS SRL",
            "address": "STR.LUPULUI NR.28",
            "category": "B,C,CE",
            "telephone": "0749410633",
            "email": "autopalas@yahoo.com"
          },
          {
            "name": "NOVA & LAURA SRL",
            "address": "STR B P HASDEU NR 19 PARTER",
            "category": "B",
            "telephone": "0745753167",
            "email": "autonovazalau@yahoo.com"
          },
          {
            "name": "ABCDE DRIVE INSTRUCTOR SRL",
            "address": "STR MACIESULUI NR 2",
            "category": "A,B,C,D,BE,CE",
            "telephone": "0260617767",
            "email": "scoalaautofaur@yahoo.com"
          },
          {
            "name": "AUTO NOVA SRL",
            "address": "STR CORNELIU COPOSU NR 129",
            "category": "A,B,C,D,Tr,BE,CE,A1,AM,",
            "telephone": "0260632904",
            "email": "autonovazalau@yahoo.com"
          },
          {
            "name": "AUTO IONY SRL",
            "address": "STR GARII NR 7/A",
            "category": "B",
            "telephone": "0740878078",
            "email": "ionica_danila.muresan@yahoo.ro"
          },
          {
            "name": "EURO BXB SRL",
            "address": "STR POROLISSUM NR 9",
            "category": "B,C,D,CE",
            "telephone": "0744828270",
            "email": "EUROBXB@YAHOO.COM"
          },
          {
            "name": "WALEX-W.M.X S.R.L.",
            "address": "STR. NICOLAE TITULESCU NR 4",
            "category": "B,C,D,BE,CE",
            "telephone": "0744545475",
            "email": "walexdoki@gmail.com"
          },
          {
            "name": "FAUR PROD COM SRL",
            "address": "B DUL MIHAI VITEAZU NR 58 A BIROUL 1 \nNR SI BIROUL NR 2 ETAJ 2",
            "category": "B",
            "telephone": "0745241542",
            "email": "scoalaautofaur@yahoo.com"
          },
          {
            "name": "PFA DRAGOS D DUMITRU",
            "address": "STR SARMAS NR 86",
            "category": "B",
            "telephone": "0740464660",
            "email": "petkes.karoly@yahoo.com"
          },
          {
            "name": "PFA SOLYOM CSABA FERENC",
            "address": "STR OLARILOR NR 36",
            "category": "B",
            "telephone": "0740189836",
            "email": "norbitrans1@gmail.com"
          },
          {
            "name": "PFA DEMJEN G CSABA",
            "address": "STR PICTOR IOAN SIMA NR 19 BL P44 SC ",
            "category": "B",
            "telephone": "0748178047",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA MOLDOVAN A MARIUS MIHAI",
            "address": "STR B P HASDEU NR 24 BL H 24 SC A AP ",
            "category": "B",
            "telephone": "0753023600",
            "email": "mariusmoldovan71@yahoo.com"
          },
          {
            "name": "PFA GHIMAN AUGUSTIN DAN",
            "address": "STR IOAN NICHITA NR 22 BL F15 SC A AP ",
            "category": "B",
            "telephone": "0740610543",
            "email": "dang_71gust@yahoo.com"
          },
          {
            "name": "PFA TARBA IOAN",
            "address": "BDUL MIHAI VITEAZUL NR 100/a PARTER ",
            "category": "B",
            "telephone": "0740918985",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA BOB ADRIAN IOAN",
            "address": "BDUL MIHAI VITEAZU NR 100/a PARTER ",
            "category": "B",
            "telephone": "0745604226",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA DULL I IOZSEF LASZLO",
            "address": "BDUL MIHAI VITEAZUL NR 100/a PARTER",
            "category": "B",
            "telephone": "0744540142",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA BALAGEL I EMIL",
            "address": "BDUL MIHAI VITEAZUL NR 100/a PARTER",
            "category": "B",
            "telephone": "0744113183",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA BABANAS V ALEXANDRU",
            "address": "STR DUMBRAVA NR 51 BL D23 SC A AP 6",
            "category": "B",
            "telephone": "0744850854",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA TERHES GHEORGHE",
            "address": "STR DELENI NR 1B",
            "category": "B",
            "telephone": "0745096033",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA IANCHIS I NELIAN",
            "address": "STR GEORGE COSBUC NR 6",
            "category": "B",
            "telephone": "0740282830",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA SABOU MARIANA",
            "address": "STR POROLISSUM NR 9",
            "category": "B",
            "telephone": "0741064096",
            "email": "mariana.sabou72@gmail.com"
          },
          {
            "name": "AUTO DRIVER ARBOCHIDE SRL",
            "address": "STR FABRICII NR 10",
            "category": "B",
            "telephone": "0741131987",
            "email": "arbochide@gmail.com"
          },
          {
            "name": "PFA STOICA D IOAN",
            "address": "STR CORNELIU COPOSU NR 129 CAMERA ",
            "category": "B",
            "telephone": "0748070156",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA MOLDOVAN EZECHIL-ISAI",
            "address": "STR LT COL PRETORIAN NR 22/A CAM 1 ",
            "category": "B",
            "telephone": "0744760744",
            "email": "isaimoldovan@yahoo.com"
          }
        ]
      },
      {
        "city": "SIMLEUL SILVANIEI",
        "schools": [
          {
            "name": "EDU-TEO SRL",
            "address": "STR.S.BARNUTIU NR.11",
            "category": "B",
            "telephone": "0741091253",
            "email": "teo19662001@yahoo.com"
          }
        ]
      },
      {
        "city": "CRASNA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC CSEREY-GOGA",
            "address": "STR.PIATA EROILOR NR 14",
            "category": "B",
            "telephone": "0260636062",
            "email": "grupscindcrasna@yahoo.com"
          },
          {
            "name": "PFA PETKES LAURENTIU",
            "address": "NR 475",
            "category": "B",
            "telephone": "0735502537",
            "email": "petkes.karoly@yahoo.com"
          },
          {
            "name": "PFA PESTEAN ADRIAN VICTOR",
            "address": "NR 37",
            "category": "B,BE",
            "telephone": "0744523189",
            "email": "petkes.karoly@yahoo.com"
          }
        ]
      },
      {
        "city": "JIBOU",
        "schools": [
          {
            "name": "CONFECTII JIBOU SA",
            "address": "STR STADIONULUI NR 15",
            "category": "B",
            "telephone": "0728001548",
            "email": "confectiijibou2006@yahoo.com"
          },
          {
            "name": "PFA POCOL VASILE IOAN",
            "address": "STR SOMESULUI NR 1",
            "category": "B",
            "telephone": "0741150506",
            "email": "simona.pocol24@gmail.com"
          },
          {
            "name": "PFA FRIDMAN DANIEL",
            "address": "STR HOREA NR 16",
            "category": "B",
            "telephone": "0744672420",
            "email": "prosteanlaura@yahoo.com"
          },
          {
            "name": "PFA ANTON F VASILE",
            "address": "STR 1 MAI NR 17",
            "category": "B",
            "telephone": "0744104005",
            "email": "anton.vasile54@yahoo.com"
          }
        ]
      },
      {
        "city": "SIMLEU SILVANIEI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC IOAN OSSIAN",
            "address": "STR.S.BARNUTIU NR.11",
            "category": "B",
            "telephone": "0260678350",
            "email": "zoliorosz@yahoo.com"
          },
          {
            "name": "PFA CSOBANKAI LASZLO",
            "address": "STR SIMION BARNUTIU NR 11 CLADIREA ",
            "category": "B",
            "telephone": "0745514621",
            "email": "pocolateodor@yahoo.com"
          },
          {
            "name": "PFA COZMA VASILE IOAN",
            "address": "STR SIMION BARNUTIU NR 11 CLADIREA ",
            "category": "B",
            "telephone": "0744482445",
            "email": "pocolateodor@yahoo.com"
          },
          {
            "name": "PFA SANDOR F MARIUS",
            "address": "STR SIMION BARNUTIU NR 11 CLADIREA ",
            "category": "B",
            "telephone": "0740098315",
            "email": "pocolateodor@yahoo.com"
          },
          {
            "name": "PFA OROSZ ZOLTAN",
            "address": "STR GH LAZAR BL L11 AP 11 SIMLEU ",
            "category": "B",
            "telephone": "0745233633",
            "email": "pocolateodor@yahoo.com"
          },
          {
            "name": "PFA POP I A NICOLAE",
            "address": "STR TRANDAFIRILOR NR 29",
            "category": "B",
            "telephone": "0740883795",
            "email": "pocolateodor@yahoo.com"
          },
          {
            "name": "PFA KOMUVES J C IOAN",
            "address": "STR SIMION BARNUTIU NR 11 CLADIRE ",
            "category": "B",
            "telephone": "0745320229",
            "email": "pocolateodor@yahoo.com"
          }
        ]
      },
      {
        "city": "NADIS",
        "schools": [
          {
            "name": "AUTO TEO CHIS SRL D",
            "address": "NR 35 CORP B INTRARE B",
            "category": "B",
            "telephone": "0745544975",
            "email": "greticachis@yahoo.com"
          }
        ]
      },
      {
        "city": "SARMASAG",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC NR.1 \nSARMASAG",
            "address": "STR PRIMAVERII NR 15",
            "category": "B",
            "telephone": "0740395816",
            "email": "sca.litesa@yahoo.com"
          }
        ]
      },
      {
        "city": "VALCAU DE JOS",
        "schools": [
          {
            "name": "PFA SIRCA NICOLAE DANIEL",
            "address": "NR 198",
            "category": "B",
            "telephone": "0767088779",
            "email": "pocolateodor@yahoo.com"
          }
        ]
      },
      {
        "city": "CARASTELEC",
        "schools": [
          {
            "name": "PFA SZABO GABRIEL",
            "address": "CARASTELEC NR 471",
            "category": "B",
            "telephone": "0768702247",
            "email": "pocolateodor@yahoo.com"
          }
        ]
      },
      {
        "city": "IP",
        "schools": [
          {
            "name": "PFA FAZAKAS G ZOLTAN",
            "address": "IP NR 25",
            "category": "B",
            "telephone": "0745590856",
            "email": "pocolateodor@yahoo.com"
          }
        ]
      },
      {
        "city": "MARIN",
        "schools": [
          {
            "name": "PFA MAXIM I AUREL",
            "address": "NR 39",
            "category": "B",
            "telephone": "0740412429",
            "email": "aurel.maxxim@gmail.com"
          }
        ]
      },
      {
        "city": "CRISENI",
        "schools": [
          {
            "name": "PFA POP FELICIA ELVIRA",
            "address": "NR 152",
            "category": "B",
            "telephone": "0744676860",
            "email": "pop_felicia_1972@yahoo.com"
          }
        ]
      },
      {
        "city": "CRISTOLTEL",
        "schools": [
          {
            "name": "PFA BREBAN D LIDIA",
            "address": "STR LT COL PRETORIAN NR 22A CAM 1 \nET 2 LOC ZALAU JUD SALAJ",
            "category": "B",
            "telephone": "0769624745",
            "email": "brebanlidia@yahoo.com"
          }
        ]
      },
      {
        "city": "SAG",
        "schools": [
          {
            "name": "PFA TUNSU CONSTANTIN DOREL",
            "address": "SAT SAG NR 27",
            "category": "B",
            "telephone": "0743313987",
            "email": "tunsuconstantin_70@yahoo.com"
          }
        ]
      },
      {
        "city": "PLOPIS",
        "schools": [
          {
            "name": "PFA CRISAN P GAVRIL",
            "address": "NR 139 A",
            "category": "B",
            "telephone": "0765906680",
            "email": "prosteanlaura@yahoo.com"
          }
        ]
      },
      {
        "city": "SIG",
        "schools": [
          {
            "name": "SCOALA PROFESIONALA SIG",
            "address": "NR 160",
            "category": "B",
            "telephone": "0787679545",
            "email": "tunsuconstantin_70@yahoo.com"
          }
        ]
      },
      {
        "city": "SURDUC",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC NR 1 SURDUC ",
            "address": "STR PRINCIPALA NR 180",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      }
    ]
  },
  {
    "state": "SM",
    "schools": [
      {
        "city": "SATU MARE",
        "schools": [
          {
            "name": "APROPO SRL",
            "address": "STR.CRISAN NR.1",
            "category": "B",
            "telephone": "0746235871",
            "email": "apropo.sm@gmail.com"
          },
          {
            "name": "AGO ANA IOANA SRL",
            "address": "STR.HENRI COANDA NR.17",
            "category": "B,C,D,CE,AM",
            "telephone": "0742065610",
            "email": "giurgigheorghe@yahoo.com"
          },
          {
            "name": "FIRST DRIVE SRL",
            "address": "STR.EROII REVOLUTIEI NR.5",
            "category": "B",
            "telephone": "0740164159",
            "email": "first.drive@yahoo.com"
          },
          {
            "name": "REFLEX SRL",
            "address": "STR.RODNEI NR.46",
            "category": "B,A2",
            "telephone": "0744546397",
            "email": "office@scoalareflex.ro"
          },
          {
            "name": "SIMEDA SRL",
            "address": "STR.DIMBOVITEI NR.1",
            "category": "A,B,A1,AM,A2",
            "telephone": "0740453212",
            "email": "leontinadumitrana@yahoo.com"
          },
          {
            "name": "NORD STAR SRL",
            "address": "STR.AVRAM IANCU NR.28",
            "category": "A,B,AM,A2",
            "telephone": "0722732147",
            "email": "nordstarsrl@yahoo.com"
          },
          {
            "name": "EXPEDIENT SRL",
            "address": "STR.CALEA ODOREULUI NR.31",
            "category": "B",
            "telephone": "0741145520",
            "email": "expedientsm@gmail.com"
          },
          {
            "name": "GARANT 2006 SRL",
            "address": "VASILE LUCACIU NR.52",
            "category": "A,B,C,CE,A1",
            "telephone": "0755134952",
            "email": "garant2006@yahoo.com"
          },
          {
            "name": "SAMI SRL",
            "address": "STR.MARA NR.55",
            "category": "A,B,C,D,CE,A1",
            "telephone": "0744629247",
            "email": "chisflorin@yahoo.com"
          },
          {
            "name": "AUTO REFLEX SRL",
            "address": "Piata 25 Octombrie,Mag.87/2A,Bl.14",
            "category": "A,B,C,D,CE,A1,AM",
            "telephone": "0744378063",
            "email": "mocan_corina@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"UNIO-TRAIAN \nVUIA\"",
            "address": "BDUL CLOSCA NR.72A",
            "category": "B",
            "telephone": "721030",
            "email": "traianvuiasm@yahoo.com"
          },
          {
            "name": "ABC AUTO START SRL",
            "address": "STR.ION SLAVICI NR.27",
            "category": "B",
            "telephone": "0746040291",
            "email": "adi_ulmeanu@yahoo.com"
          },
          {
            "name": "P.F.A. NAGY G ATTILA ",
            "address": "STR.TRAIAN VUIA,BL.C15,AP.10",
            "category": "B",
            "telephone": "0745773632",
            "email": "nagyattilacom@gmail.com"
          },
          {
            "name": "P.F.A.COZMA RADU LUCIAN",
            "address": "STR.BRASOV,NR.29,BL.UF,AP.1",
            "category": "B",
            "telephone": "0744391747",
            "email": "cozmaradulucian@yahoo.com"
          },
          {
            "name": "P.F.A.PINTEA MIRCEA AUREL",
            "address": "STR.RINDUNELELOR NR.10,AP.1",
            "category": "B",
            "telephone": "0740660483",
            "email": "m.pintea61@yahoo.com"
          },
          {
            "name": "P.F.A.OLAH ROSCA VASILE",
            "address": "STR.CRINULUI NR.13",
            "category": "B",
            "telephone": "0722925660",
            "email": "olahlaszlo51@yahoo.com"
          },
          {
            "name": "P.F.A.PALCAU MILIAN",
            "address": "STR.BELSUGULUI,NR.22,AP7.",
            "category": "B",
            "telephone": "0744391704",
            "email": "palcau.milian@gmail.com"
          },
          {
            "name": "P.F.A.POP FRANCISC",
            "address": "STR.VASILE LUPU,BL.UE6,AP.8",
            "category": "B",
            "telephone": "0744856728",
            "email": "pop.francisc@yahoo.com"
          },
          {
            "name": "AR AUTO REFLEX SRL",
            "address": "Piata 25 Octombrie MAG ",
            "category": "B",
            "telephone": "0740856317",
            "email": "ar.autoreflex@gmail.com"
          },
          {
            "name": "PFA PUSCAS VASILE",
            "address": "STR.CURTUIUS I,NR.39",
            "category": "B",
            "telephone": "0742946002",
            "email": "vasilepuscas260360@yahoo.com"
          },
          {
            "name": "PFA SALAJAN SEBASTIAN IOAN",
            "address": "STR.BOBOCULUI,BL.UK,AP.3",
            "category": "B",
            "telephone": "0740177982",
            "email": "SEBI33SM@YAHOO.COM"
          },
          {
            "name": "CRISTIVOLUC SRL",
            "address": "STR.RETEZATULUI,NR.21",
            "category": "B,BE",
            "telephone": "0751122113",
            "email": "ovilucut@yahoo.com"
          },
          {
            "name": "AUTO NORD INTERMEDIERI SRL",
            "address": "STR.PETOFI SANDOR NR.34",
            "category": "B",
            "telephone": "0740149140",
            "email": "AUTONORDINTERMEDIERI@YAHOO.COM"
          },
          {
            "name": "POPA VASILE SCOALA DE SOFERI \nP.F.A.",
            "address": "STR.BASARABIA NR.21",
            "category": "B",
            "telephone": "0744848194",
            "email": "pimpi2009@yahoo.com"
          }
        ]
      },
      {
        "city": "CAREI",
        "schools": [
          {
            "name": "MARC COM SRL",
            "address": "STR.IGNISULUI NR.20 ",
            "category": "B",
            "telephone": "0745852033",
            "email": "carambamarcel@yahoo.com"
          },
          {
            "name": "BEST CAR SRL",
            "address": "STR.GRIGORE ALEXANDRESCU NR.9",
            "category": "A,B,BE,A1,A2",
            "telephone": "864010",
            "email": "monikafabian@yahoo.com"
          },
          {
            "name": "LGH AUTO SRL",
            "address": "STR.GHEORHE LAZAR NR.21",
            "category": "B",
            "telephone": "0744920664",
            "email": "lgh_auto@yahoo.com"
          },
          {
            "name": "P.F.A.SFIRA GHEORGHE",
            "address": "STR.ALBINELOR NR.51",
            "category": "B",
            "telephone": "0745045688",
            "email": "sfira_gheorghe@yahoo.com"
          },
          {
            "name": "P.F.A. MOROTI VASILE",
            "address": "STR.N.IORGA NR.48",
            "category": "B",
            "telephone": "0740513299",
            "email": "morotivasile@yahoo.com"
          }
        ]
      },
      {
        "city": "VAMA",
        "schools": [
          {
            "name": "IONATAN TEODOR SRL",
            "address": "NR.1059",
            "category": "B,BE",
            "telephone": "0740794370",
            "email": "ionatanzelea@yahoo.com"
          }
        ]
      },
      {
        "city": "NEGRESTI OAS",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC IONITA \nG.ANDRON",
            "address": "STR.LUNA NR.7-8 ",
            "category": "B,C,CE",
            "telephone": "0261854880",
            "email": "fratila.viorel@yahoo.com"
          },
          {
            "name": "BIG AUTO STAR SRL",
            "address": "STR.VICTORIEI NR.108 ",
            "category": "B",
            "telephone": "0758978595",
            "email": "tavybig23@yahoo.com"
          }
        ]
      },
      {
        "city": "MARTINESTI",
        "schools": [
          {
            "name": "AUTOSAM SM SA",
            "address": "STR.TRACTORISTILOR NR.337",
            "category": "B,Tr",
            "telephone": "0744541116",
            "email": "autosamsm@yahoo.com"
          }
        ]
      },
      {
        "city": "BABASESTI",
        "schools": [
          {
            "name": "P.F.A.LUNG P NICOLAE",
            "address": "NR.90",
            "category": "B",
            "telephone": "0756032212",
            "email": "niculung@yahoo.com"
          }
        ]
      },
      {
        "city": "CAPLENI",
        "schools": [
          {
            "name": "P.F.A.TANKO GYORGY BELA",
            "address": "NR.309A",
            "category": "B",
            "telephone": "0744364466",
            "email": "tankogyorgy6064@yahoo.ro"
          }
        ]
      },
      {
        "city": "LUCACENI",
        "schools": [
          {
            "name": "CIOCOTISAN VASILE RADU PFA",
            "address": "NR.78",
            "category": "B",
            "telephone": "",
            "email": ""
          }
        ]
      }
    ]
  },
  {
    "state": "SV",
    "schools": [
      {
        "city": "SUCEAVA",
        "schools": [
          {
            "name": "DELTACAR SRL",
            "address": "STR.MIHAI VITEAZU NR.27,CAM. 3-4",
            "category": "B,C,CE",
            "telephone": "0744544575",
            "email": "lory_lory_88@yahoo.com"
          },
          {
            "name": "TRANS JOHAN LANG SRL",
            "address": "STR.UNIRII NR.80 BL.59A",
            "category": "B,C,CE",
            "telephone": "0751428041",
            "email": "sc.trans.johan.long.srl@gmail.com"
          },
          {
            "name": "DANSAMI S.R.L.",
            "address": "STR.VISINILOR NR.3A",
            "category": "B,C,CE",
            "telephone": "0230523304",
            "email": "dansami33@yahoo.com"
          },
          {
            "name": "TET-GIM SRL",
            "address": "STR.MIHAI EMINESCU NR.5",
            "category": "B,C,D,BE,CE",
            "telephone": "0751222555",
            "email": "TET_GIM@YAHOO.COM"
          },
          {
            "name": "AUTO MACOVEI S.R.L.",
            "address": "STR.GHEORGHE DOJA NR.165H",
            "category": "B,C,CE",
            "telephone": "0745821791",
            "email": "automacovei@yahoo.com"
          },
          {
            "name": "AUTO STEFAN SRL",
            "address": "STR.AVRAM IANCU NR.1",
            "category": "B,C,CE",
            "telephone": "0751096456",
            "email": "autostefansv@gmail.com"
          },
          {
            "name": "AUTO-DIANA S.R.L.",
            "address": "STR.GRIGORE ALEXANDRU GHICA NR.88",
            "category": "A,B,A1,AM,A2",
            "telephone": "0740287323",
            "email": "autodiana@ymail.com"
          },
          {
            "name": "PLUS EUROPA SRL",
            "address": "STR. AMURGULUI NR.2C",
            "category": "A,B,C,D,BE,CE",
            "telephone": "0741064341",
            "email": "florinvblaga@yahoo.com"
          },
          {
            "name": "SIGUR OIL UNIVERSAL SRL-\nP.L.SUCEAVA",
            "address": "STR.TUDOR STEFANELI F.N.",
            "category": "A,B,C,D,Tr,BE,CE,A1,B1,A\nM",
            "telephone": "0740034212",
            "email": "scoaladesoferisv@yahoo.com"
          },
          {
            "name": "HAPPY DRIVERS SRL",
            "address": "STR.ANTON HOLBAN NR.4",
            "category": "B,C,CE",
            "telephone": "0726782525",
            "email": "ionut_balaneasa@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC PETRU MUSAT",
            "address": "CALEA UNIRII NR.15",
            "category": "B",
            "telephone": "0230524728",
            "email": "ursuleandumitru@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC ALEXANDRU \nIOAN CUZA",
            "address": "CALEA UNIRII NR.49",
            "category": "B",
            "telephone": "0230516752",
            "email": "bichelmihai@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC DE INDUSTRIE \nALIMENTARA",
            "address": "STR.DIMITRIE CANTEMIR NR.5",
            "category": "B",
            "telephone": "0230522304",
            "email": "catalinatanasa@yahoo.com"
          },
          {
            "name": "OVASAL TRANS-ABC S.R.L.",
            "address": "STR.MARASESTI NR.48 ",
            "category": "B,C,D,CE",
            "telephone": "0744618801",
            "email": "office@ovasal.ro"
          },
          {
            "name": "DISCRET COM SRL",
            "address": "STR.MARASESTI NR.48 BL.48/6",
            "category": "B",
            "telephone": "0230520003",
            "email": "ovadiuc_vali@yahoo.com"
          },
          {
            "name": "SIGUR LA VOLAN SRL",
            "address": "STR.MIRAUTI NR.3 ET.1",
            "category": "A,B,C,D,DE,CE,C1,A2",
            "telephone": "0740034212",
            "email": "scoaladesoferisv@yahoo.com"
          },
          {
            "name": "EXPERT DRIVE SRL",
            "address": "STR.UNIVERSITATII NR.29 ET.2 ",
            "category": "B,C,CE",
            "telephone": "0753172727",
            "email": "Expertdrive2008@yahoo.com"
          },
          {
            "name": "AUTO-SILANDEMA SRL",
            "address": "STR.NICOLAE IORGA ",
            "category": "B",
            "telephone": "0722768262",
            "email": "scoaladesoferiauto_silandema@yahoo.com"
          },
          {
            "name": "GYM-AUTO SRL",
            "address": "STR. STEFAN CEL MARE NR. 20A, CASA ",
            "category": "B",
            "telephone": "0741978787",
            "email": "gymautosv@gmail.com"
          },
          {
            "name": "DELTACAR BEST SRL",
            "address": "STR. MIHAI VITEAZU NR.27, SALA ",
            "category": "B",
            "telephone": "0744544575",
            "email": "cristiscutelnicu@yahoo.com"
          },
          {
            "name": "OVASAL SRL",
            "address": "STR.MARASESTI NR.48B PARTER ",
            "category": "B",
            "telephone": "0755048049",
            "email": "lyla992002@yahoo.com"
          },
          {
            "name": "AUTOSILVIA SRL",
            "address": "STR.GARII NR.1",
            "category": "B",
            "telephone": "0752752276",
            "email": "allina_26@yahoo.com"
          },
          {
            "name": "AUTO MOTO BOGDAN SRL",
            "address": "STR. TRAIAN VUIA NR.13,ET.1",
            "category": "A,B,C,BE,CE,A1,A2",
            "telephone": "0742077229",
            "email": "auto_moto_bogdan@yahoo.com"
          },
          {
            "name": "DELTACAR NORD SRL",
            "address": "STR.TRAIAN VUIA NR.4 ET.1",
            "category": "B",
            "telephone": "0744544575",
            "email": "lory_lory_88@yahoo.com"
          },
          {
            "name": "TASIA-AUTO RUTIER SRL",
            "address": "STR. GHEORGHE DOJA NR.123B",
            "category": "B",
            "telephone": "0740570901",
            "email": "daniel_blahovici@yahoo.com"
          },
          {
            "name": "PUSCASU FLORIN P.F.A.",
            "address": "STR.UNIRII NR.14A",
            "category": "B",
            "telephone": "0747024987",
            "email": "florin_fish68@yahoo.com"
          },
          {
            "name": "AUTO UNGUREAN SRL",
            "address": "STR.I.G.SBIERA NR.25 B DEMISOL",
            "category": "B",
            "telephone": "0758849222",
            "email": "dianarusu89@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC SAMUIL ",
            "address": "STR. SAMOIL ISOPESCU NR.19",
            "category": "B",
            "telephone": "0749833116",
            "email": "smiley_89_smiley@yahoo.com"
          },
          {
            "name": "AUTO PLUS EUROPA SRL",
            "address": "STR. AMURGULUI NR.2C",
            "category": "B",
            "telephone": "0741064341",
            "email": "florinvblaga@yahoo.com"
          },
          {
            "name": "LAVRIC DANUT P.F.A.",
            "address": "CALEA UNIRII NR.24.A BIROU 4",
            "category": "B",
            "telephone": "0744555832",
            "email": "lavricdanpfa@gmail.com"
          },
          {
            "name": "OVADIUC VASILE P.F.A.",
            "address": "CALEA UNIRII NR.24.A BIROU 4",
            "category": "B",
            "telephone": "0745500405",
            "email": "scoalaovadiuc@gmail.com"
          },
          {
            "name": "CANDIL DRIVE SCHOOL SRL",
            "address": "STR. ANA IPATESCU NR.3 A SPATIUL ",
            "category": "B",
            "telephone": "0736171550",
            "email": "costelignatescu@yahoo.co.uk"
          }
        ]
      },
      {
        "city": "SIRET",
        "schools": [
          {
            "name": "MARNICDAL SRL",
            "address": "STR.ANTON PAUL NR.2",
            "category": "B,C,CE",
            "telephone": "0744129551",
            "email": "ionuk_no1@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC LATCU VODA",
            "address": "STR.LATCU VODA NR.15A",
            "category": "B",
            "telephone": "0230281357",
            "email": "latcuvoda@yahoo.com"
          },
          {
            "name": "SOLENIS COM SRL",
            "address": "STR.PETRU MUSAT NR.1 C",
            "category": "B",
            "telephone": "0744534552",
            "email": "solenis.auto@yahoo.com"
          },
          {
            "name": "AUTO MITZA SIRET SRL",
            "address": "STR. TUDOR VLADIMIRESCU NR.26",
            "category": "B",
            "telephone": "0745987134",
            "email": "bogdanclaudiubuza@gmail.com"
          }
        ]
      },
      {
        "city": "CAJVANA",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC  STEFAN CEL \nMARE CAJVANA",
            "address": "STR.PRINCIPALA ",
            "category": "B,C",
            "telephone": "0230539090",
            "email": "dragosh_ungurean@yahoo.com"
          },
          {
            "name": "AUTO H.G.A. SRL",
            "address": "CAJVANA NR.1762",
            "category": "B,C,CE",
            "telephone": "0740922818",
            "email": "gavrilhariuc@yahoo.com"
          },
          {
            "name": "PROEXPERT AUTO SRL",
            "address": "CAJVANA NR.896",
            "category": "B,C,C1",
            "telephone": "0753172727",
            "email": "proexpertauto@yahoo.com"
          }
        ]
      },
      {
        "city": "RADAUTI",
        "schools": [
          {
            "name": "AUTO GRIGOROVICI SRL",
            "address": "STR. STEFAN CEL MARE NR.27",
            "category": "B",
            "telephone": "0744276791",
            "email": "grigorovici_cezar@yahoo.com"
          },
          {
            "name": "P.F.A.-CACIUR L. GHEORGHE ",
            "address": "STR. MIHAI VITEAZU BL.19 SC.B ET.3 ",
            "category": "B",
            "telephone": "0745630646",
            "email": "gheorghe.caciur@yahoo.ro"
          },
          {
            "name": "GIA CONSULTANT SRL",
            "address": "PIATA UNIRII NR.54 ET.1",
            "category": "B",
            "telephone": "0743309098",
            "email": "gianinabarba@yahoo.com"
          },
          {
            "name": "KRISS-DIA AUTO SRL",
            "address": "STR.STEFAN CEL MARE NR.21,ET.2",
            "category": "B,C,BE,CE",
            "telephone": "0746246246",
            "email": "cazac.cristian@yahoo.com"
          },
          {
            "name": "COLEGIUL TEHNIC RADAUTI",
            "address": "STR.TUDOR VLADIMIRESCU NR.4",
            "category": "B",
            "telephone": "0743511279",
            "email": "nicucovali@yahoo.com"
          },
          {
            "name": "AUTO PRECOV SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.4",
            "category": "B",
            "telephone": "0743591279",
            "email": "tiberiu_instr@yahoo.com"
          },
          {
            "name": "HOLDEX IMPEX SRL",
            "address": "STR.PICTOR GRIGORESCU NR.3",
            "category": "B,C,D,CE",
            "telephone": "0230562827",
            "email": "holdex.impex@yahoo.com"
          },
          {
            "name": "RUT COM SERV SRL",
            "address": "STR.STEFAN CEL MARE NR.5",
            "category": "B,C,CE",
            "telephone": "0740034794",
            "email": "rutcomserv@gmail.com"
          },
          {
            "name": "ROM-COSMETIC DESIGN GABRIELA \nSRL",
            "address": "STR.PICTOR GRIGORESCU NR.3",
            "category": "B,C,CE",
            "telephone": "0744528282",
            "email": "rcd.gabriela@yahoo.com"
          },
          {
            "name": "SEBIPET-AUTO SRL",
            "address": "STR.BOGDAN VODA NR.12",
            "category": "B,C,D,CE",
            "telephone": "0744858595",
            "email": "sebipet_auto@yahoo.com"
          },
          {
            "name": "AUTO ANAPRE SRL",
            "address": "STR.TUDOR VLADIMIRESCU NR.4, ",
            "category": "A,B,BE,A2",
            "telephone": "",
            "email": "tiberiu_instr@yahoo.com"
          },
          {
            "name": "YONCHIM DRIVE SRL",
            "address": "PIATA GAROAFELOR NR.1 ET.2",
            "category": "B",
            "telephone": "0745904610",
            "email": "ICHIM26IOAN@YAHOO.COM"
          },
          {
            "name": "AUTO SICRIS SRL",
            "address": "STR.PUTNEI NR.103",
            "category": "B,C",
            "telephone": "0740470397",
            "email": "popescu_cristina12@yahoo.com"
          },
          {
            "name": "IRIMESCU GH.TRIFAN-P.F.A.",
            "address": "STR.VOLOVATULUI NR.14 SPATIUL ",
            "category": "B",
            "telephone": "0740389834",
            "email": "irimescu.trifan@gmail.com"
          },
          {
            "name": "GHINGHILOSCHI G.IOAN-P.F.A.",
            "address": "STR.VOLOVATULUI NR.14 SPATIUL ",
            "category": "B",
            "telephone": "0744624532",
            "email": "autogrigorean@yahoo.com"
          },
          {
            "name": "LUTIA G.DORIN P.F.A.",
            "address": "STR.VOLOVATULUI NR.14 SPATIUL ",
            "category": "B",
            "telephone": "0753023470",
            "email": "lutiad@yahoo.com"
          },
          {
            "name": "COSTIUC GH.M.NECULAI-P.F.A.",
            "address": "STR.VOLOVATULUI NR.14 SPATIUL ",
            "category": "B",
            "telephone": "0745522489",
            "email": "marcel_wwn@yahoo.com"
          },
          {
            "name": "AUTO TEOLUC SRL",
            "address": "STR. GRANICERULUI NR.12",
            "category": "B,C,CE,AM",
            "telephone": "0742356753",
            "email": "bogdanclaudiubuza@gmail.com"
          }
        ]
      },
      {
        "city": "FALTICENI",
        "schools": [
          {
            "name": "AUTOSPARK SRL",
            "address": "STR.IOAN DRAGOSLAV NR.19A CAM.2",
            "category": "B",
            "telephone": "0743024334",
            "email": "barascuion49@yahoo.ro"
          },
          {
            "name": "CONECT RALBO SRL",
            "address": "STR.MIHAI EMINESCU NR.7",
            "category": "B,C,BE,CE",
            "telephone": "0741167180",
            "email": "florinbolohan2007@yahoo.com"
          },
          {
            "name": "XORAUTO SRL",
            "address": "STR.13 DECEMBRIE NR.15",
            "category": "B",
            "telephone": "0740078186",
            "email": "xor_auto1@yahoo.com"
          },
          {
            "name": "SCOALA MILITARA DE SUBOFITERI \nDE JANDARMI",
            "address": "STR. ARMATEI NR.1-3",
            "category": "B",
            "telephone": "0230541660",
            "email": "office@jandarmeriafalticeni.ro"
          },
          {
            "name": "COLEGIUL TEHNIC MIHAI BACESCU",
            "address": "STR.NICOLAE BELDICEANU NR.10",
            "category": "B",
            "telephone": "0230543333",
            "email": "grigorebogdan70@yahoo.com"
          },
          {
            "name": "COLEGIUL VASILE LOVINESCU",
            "address": "STR.MAIOR IOAN NR.10",
            "category": "B,Tr",
            "telephone": "0230541215",
            "email": "florin_4469@yahoo.com"
          },
          {
            "name": "PERLA FALTICENILOR MARKET SRL",
            "address": "STR.UNIRII NR.3",
            "category": "B",
            "telephone": "0744582869",
            "email": "perlafalticenilor@yahoo.com"
          },
          {
            "name": "DALI TEX SRL",
            "address": "STR.TARANCUTEI NR.19 CORP C581/2",
            "category": "B",
            "telephone": "0722415219",
            "email": "ddalitex@yahoo.com"
          },
          {
            "name": "AMATOR SERV SRL",
            "address": "STR.13 DECEMBRIE NR.15",
            "category": "A,B,C,D,DE,BE,CE,A1,D1,\nD1E,AM,A2",
            "telephone": "0740078186",
            "email": "auto_xor@yahoo.com"
          },
          {
            "name": "STIRBU C. CONSTANTIN-CLAUDIU-\nP.FA.",
            "address": "STR.N.BALCESCU BL.2A SC.A AP.9",
            "category": "B",
            "telephone": "0745643056",
            "email": "pfa_stirbuc@yahoo.com"
          },
          {
            "name": "BOERESCU M. MIREL-P.F.A.",
            "address": "STR.REPUBLICII NR.52",
            "category": "B",
            "telephone": "0744650090",
            "email": "mirel_boerescu@yahoo.com"
          },
          {
            "name": "CHERAN GH. ION-P.F.A.",
            "address": "STR.2 GRANICERI BL.4 SC.B AP.15",
            "category": "B",
            "telephone": "0751659772",
            "email": "pfa_stirbuc@yahoo.com"
          },
          {
            "name": "BOERESCU M.MIREL PFA",
            "address": "STR.13 DECEMBRIE NR.21",
            "category": "B",
            "telephone": "0744650090",
            "email": "bogdanclaudiubuza@gmail.com"
          },
          {
            "name": "CORA VASILICA PFA",
            "address": "STR.TARANCUTEI NR.19 SPATIU C599",
            "category": "B,BE",
            "telephone": "0740245376",
            "email": "ionut.cora21@yahoo.com"
          },
          {
            "name": "CORA S. DUMITRU P.F.A.",
            "address": "STR. TARANCUTEI NR.19",
            "category": "B",
            "telephone": "0745059134",
            "email": "miticora@yahoo.com"
          },
          {
            "name": "GRIGORE N. BOGDAN P.F.A.",
            "address": "STR. TARANCUTEI NR.19",
            "category": "B",
            "telephone": "0762437853",
            "email": "grigorebogdan70@yahoo.com"
          },
          {
            "name": "BALTARIU M. CATALIN",
            "address": "STR. 13 DECEMBRIE NR.21",
            "category": "B",
            "telephone": "0740873424",
            "email": "magdabaltariu@yahoo.com"
          },
          {
            "name": "PINTEA V. GAVRIL",
            "address": "STR. 13 DECEMBRIE NR.21",
            "category": "B",
            "telephone": "0744325529",
            "email": "pinteagavril@yahoo.com"
          },
          {
            "name": "CIUPERCOVICI C.DAN-CRISTIAN \nP.F.A.",
            "address": "STR.13 DECEMBRIE NR.21",
            "category": "B",
            "telephone": "",
            "email": "ciupercovicid@yahoo.com"
          },
          {
            "name": "SSP DRIVE SRL",
            "address": "BLD. 2 GRANICERI NR.76BIS",
            "category": "B",
            "telephone": "0748159764",
            "email": "auto.ssp@yahoo.com"
          }
        ]
      },
      {
        "city": "HORODNIC DE SUS",
        "schools": [
          {
            "name": "AUTOGRIGOREAN SRL",
            "address": "Com. Horodnic de Sus nr.765",
            "category": "B,C,CE",
            "telephone": "0740998807",
            "email": "autogrigorean@yahoo.com"
          }
        ]
      },
      {
        "city": "MARGINEA",
        "schools": [
          {
            "name": "AUTO ROTAR SRL",
            "address": "MARGINEA NR.3064",
            "category": "B",
            "telephone": "0741736587",
            "email": "b_marcel45@yahoo.com"
          }
        ]
      },
      {
        "city": "MILISAUTI",
        "schools": [
          {
            "name": "MULTI-PERMIS SRL",
            "address": "MILISAUTI NR.6 ET.1",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0744784251",
            "email": "multipermis@yahoo.com"
          }
        ]
      },
      {
        "city": "CAMPULUNG \nMOLDOVENESC",
        "schools": [
          {
            "name": "COLEGIUL SILVIC BUCOVINA",
            "address": "CALEA BUCOVINEI NR.56",
            "category": "B",
            "telephone": "0230314093",
            "email": "atomeivaleriumihai@yahoo.com"
          },
          {
            "name": "ANDA BELLA SERV SRL",
            "address": "STR.GRIVITEI NR.8A",
            "category": "B,C,CE",
            "telephone": "0722164338",
            "email": "knejec@yahoo.com"
          },
          {
            "name": "TITIN SERVICE SRL",
            "address": "STR. MOLIDULUI NR.8",
            "category": "A,B,C,CE",
            "telephone": "0744519415",
            "email": "COSMIN.GIOSAN@RESTAURANTBUCOVINA.\nRO"
          },
          {
            "name": "STASCOND SRL",
            "address": "STR.GARII NR.7C",
            "category": "B,C,CE",
            "telephone": "0723825331",
            "email": "cristina_stas2005@yahoo.com"
          },
          {
            "name": "MONAMIR TRANSAUTO",
            "address": "STR. GHEORGHE DOJA NR.2",
            "category": "B,C,D,CE",
            "telephone": "0723499847",
            "email": "scoaladesoferiservus@yahoo.com"
          },
          {
            "name": "EMILUMI",
            "address": "STR. BUNESTI NR.6C",
            "category": "B,C,CE",
            "telephone": "0723499847",
            "email": "scoaladesoferiservus@yahoo.com"
          }
        ]
      },
      {
        "city": "GURA HUMORULUI",
        "schools": [
          {
            "name": "COLEGIUL ALEXANDRU CEL BUN",
            "address": "B-DUL BUCOVINEI NR.41",
            "category": "B",
            "telephone": "0230235098",
            "email": "cipirianvizdoaga@yahoo.com"
          },
          {
            "name": "RUSSOCOND SRL",
            "address": "STR.SFINTUL MIHAIL NR.4",
            "category": "B",
            "telephone": "0744234299",
            "email": "russocond@yahoo.com"
          },
          {
            "name": "GH DRIVERS CLUB SRL",
            "address": "STR. AVRAM IANCU NR.11 AP.1",
            "category": "B",
            "telephone": "0732162896",
            "email": "adibotezat1977@yahoo.com"
          }
        ]
      },
      {
        "city": "VATRA DORNEI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC VASILE DEAC",
            "address": "STR.VASILE LITU NR.3",
            "category": "B",
            "telephone": "0230371436",
            "email": "grupscolarvasiledeac@yahoo.com"
          },
          {
            "name": "WOLFVAL SRL",
            "address": "STR.PODU VERDE NR.4,PARTER",
            "category": "A,B,C,CE,A1",
            "telephone": "0747070668",
            "email": "valilupu61@yahoo.com"
          },
          {
            "name": "GYMY COND SRL",
            "address": "STR.VASILE LITU NR.6",
            "category": "B,C,BE,CE",
            "telephone": "0742094400",
            "email": "gymycond@yahoo.com"
          },
          {
            "name": "ATRACTIV TRANSAUTO SRL",
            "address": "STR.GARII NR.3",
            "category": "B,C,CE",
            "telephone": "0723499847",
            "email": "scoaladesoferiservus@yahoo.com"
          },
          {
            "name": "P.F.A. OACHES V. VIOREL",
            "address": "STR. LUMEA NOUA NR.5,BL. ",
            "category": "B",
            "telephone": "0740311559",
            "email": "vioreloaches@yahoo.com"
          },
          {
            "name": "BRINZEI GH.CRISTIAN-VASILE P.F.A.",
            "address": "STR.UNIRII NR.14A PARTER",
            "category": "B",
            "telephone": "0722209974",
            "email": "kristianvd2002@yahoo.com"
          },
          {
            "name": "SCHEUL N.NISTOR P.F.A.",
            "address": "STR.UNIRII NR.14A PARTER",
            "category": "B",
            "telephone": "0745131154",
            "email": "kristianvd2002@yahoo.com"
          },
          {
            "name": "MYG AUTO COND SRL",
            "address": "STR. VASILE LITU NR.6 CAM. 1 si 2",
            "category": "B",
            "telephone": "0742094400",
            "email": "mygdorna@yahoo.com"
          }
        ]
      },
      {
        "city": "DORNA \nCANDRENILOR",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC DORNA \nCANDRENILOR",
            "address": "STR.PRINCIPALA NR.88",
            "category": "B,C,CE,C1",
            "telephone": "0230575171",
            "email": "florin_fish68@yahoo.com"
          }
        ]
      },
      {
        "city": "FRATAUTII NOI",
        "schools": [
          {
            "name": "FRASTRA AUTO SRL",
            "address": "STR.PIATA UNIRII NR.29 BL.31,RADAUTI",
            "category": "B",
            "telephone": "0744556874",
            "email": "sebipet_auto@yahoo.com"
          }
        ]
      },
      {
        "city": "VICOVU DE SUS",
        "schools": [
          {
            "name": "AUTOMANDICI SRL",
            "address": "CALEA CERNAUTI NR.291",
            "category": "B",
            "telephone": "0757560138",
            "email": "automandici.petru@gmail.com"
          },
          {
            "name": "AUTONEL SRL",
            "address": "CALEA CERNAUTI NR.291",
            "category": "B",
            "telephone": "0745575442",
            "email": "lily_cozma@yahoo.com"
          },
          {
            "name": "AUTOSTAR DRIVE MANAGEMENT \nSRL",
            "address": "CALEA CERNAUTI NR.354",
            "category": "B",
            "telephone": "0748635617",
            "email": "alistarcosti@yahoo.com"
          }
        ]
      },
      {
        "city": "VOLOVAT",
        "schools": [
          {
            "name": "AUTO CZR SRL",
            "address": "VOLOVAT NR.1331",
            "category": "B",
            "telephone": "0745812022",
            "email": "tincutza74@yahoo.com"
          }
        ]
      },
      {
        "city": "FRASIN",
        "schools": [
          {
            "name": "DRIVE-DAR AUTO SRL",
            "address": "STR. 1 MAI BL.G4 SC.A AP.3 GURA ",
            "category": "B",
            "telephone": "0741769412",
            "email": "drivedarauto@gmail.com"
          }
        ]
      },
      {
        "city": "DOLHASCA",
        "schools": [
          {
            "name": "BICHEL C.MIHAI-GABRIEL P.F.A.",
            "address": "DOLHASCA NR.328",
            "category": "B",
            "telephone": "0723519592",
            "email": "bichelmihai@yahoo.com"
          }
        ]
      },
      {
        "city": "BROSTENI",
        "schools": [
          {
            "name": "MYG AUTOCOND SRL",
            "address": "STR. NICOLAE NANU NR.10 COMPLEX ",
            "category": "B",
            "telephone": "0742094400",
            "email": "mygautocond@yahoo.com"
          }
        ]
      },
      {
        "city": "UDESTI",
        "schools": [
          {
            "name": "P.F.A. STEFURIUC IOAN",
            "address": "UDESTI NR.254",
            "category": "B",
            "telephone": "0745935030",
            "email": "ionutz_njk@yahoo.ro"
          }
        ]
      }
    ]
  },
  {
    "state": "TL",
    "schools": [
      {
        "city": "MACIN",
        "schools": [
          {
            "name": "AUTO PLUS SRL",
            "address": "STR.1 DECEMBRIE NR.33",
            "category": "A,B,C,D,BE,CE,A1,D1,AM,",
            "telephone": "571026",
            "email": "sc_autoplus_srl@yahoo.com"
          },
          {
            "name": "SAPHIR SRL",
            "address": "STR.ALEXANDRU LAPUSNEANU NR.43",
            "category": "B,C,CE",
            "telephone": "0745849258",
            "email": "SAPHIR1991@YAHOO.COM"
          },
          {
            "name": "SC SABILUC AUTO SRL PUNCT DE \nLUCRU MACIN",
            "address": "MACIN, STR. 1 DECEMBRIE, BL. 22",
            "category": "B",
            "telephone": "0740410081",
            "email": "sabiluc.auto@yahoo.com"
          }
        ]
      },
      {
        "city": "TULCEA",
        "schools": [
          {
            "name": "VALMAR AUTOTRANS SRL",
            "address": "STR.LIBERTATII NR.27 ET.P",
            "category": "B,C,D,CE",
            "telephone": "0744696964",
            "email": "valmarautotrans@yahoo.com"
          },
          {
            "name": "LISSA SRL",
            "address": "STR.MIRCEA VODA NR.44",
            "category": "B",
            "telephone": "0745757378",
            "email": "SC.LISSA@YAHOO.COM"
          },
          {
            "name": "CRIZANTEMA SRL",
            "address": "STR.BOIERESCU NR.4",
            "category": "B",
            "telephone": "525946",
            "email": "crizantemasc@yahoo.com"
          },
          {
            "name": "JITARU IMPEX SRL",
            "address": "STR.SPITALULUI, NR. 46",
            "category": "B,C,CE",
            "telephone": "0749790970",
            "email": "cornel.jitaru@yahoo.com"
          },
          {
            "name": "PAP AUTO COMPANY SRL",
            "address": "STR.VITICULTURII NR.2H",
            "category": "B,C,BE,CE",
            "telephone": "0744908559",
            "email": "corimad_srl@yahoo.com"
          },
          {
            "name": "ATRACTIV AUTOBEST SRL",
            "address": "STR. PIATA INDEPENDENTEI NR.1 ARIPA ",
            "category": "B",
            "telephone": "0745566915",
            "email": "atractivautobest@yahoo.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC ANGHEL \nSALIGNY TULCEA ",
            "address": "STR.VIITORULUI NR.22",
            "category": "B",
            "telephone": "0240534238",
            "email": "colanghelsaligny@yahoo.ro"
          },
          {
            "name": "AS SRL",
            "address": "STR.LIBERTATII NR.63",
            "category": "B",
            "telephone": "506506",
            "email": "taxi_as_tulcea@yahoo.com"
          },
          {
            "name": "AUTOTUR SRL",
            "address": "STR.LIBERTATII , NR. 87",
            "category": "A,B,C,D,BE,CE,AM,A2",
            "telephone": "515217",
            "email": "office@autotur.ro"
          },
          {
            "name": "INTERCRIS SRL(RAZBOIENI)",
            "address": "STR.RAZBOIENI NR.14 BIS.",
            "category": "B,C,CE",
            "telephone": "0755880056",
            "email": "intercrissrl@yahoo.com"
          },
          {
            "name": "AGROTRANSPORT TULCEA SA",
            "address": "STR.BUNA VESTIRE NR.1",
            "category": "B,C,D,Tr,CE",
            "telephone": "0240517937",
            "email": "melaniaduna@yahoo.com"
          },
          {
            "name": "AMBROZIA SRL",
            "address": "STR.CARPATI NR.6",
            "category": "A,B,C,CE,AM",
            "telephone": "518527",
            "email": "office_ambrozia@yahoo.com"
          },
          {
            "name": "SABILUC AUTO SRL",
            "address": "STR.CONSTRUCTORILOR, NR. 6 BIS",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0757396591",
            "email": "sabiluc.auto@yahoo.com"
          },
          {
            "name": "C&N CO SRL",
            "address": "STR.GARII NR.4 BL.2 PARTER",
            "category": "B",
            "telephone": "514011",
            "email": "cnc_srl@yahoo.com"
          },
          {
            "name": "LARIE N GHEORGHE PFA",
            "address": "STR. Viticulturii,NR.8",
            "category": "B",
            "telephone": "0744972241",
            "email": "mihaela.mihai15@gmail.com"
          },
          {
            "name": "PFA BITOANA NICOLAE SORIN",
            "address": "STR.VITICULTURII,NR.8",
            "category": "B",
            "telephone": "0745297581",
            "email": "mihaela.mihai15@gmail.com"
          },
          {
            "name": "PFA LUNGU LIVIU SERVICII",
            "address": "STR. VITICULTURII , NR. 8",
            "category": "B",
            "telephone": "0745841158",
            "email": "mihaela.mihai15@gmail.com"
          },
          {
            "name": "PFA CANDIU P. FLORIN",
            "address": "STR. VITICULTURII, NR. 8",
            "category": "B",
            "telephone": "",
            "email": "mihaela.mihai15@gmail.com"
          },
          {
            "name": "SC CDC ACTUAL DRIVE SRL",
            "address": "STR. VICTORIEI,NR. 62",
            "category": "B",
            "telephone": "0745043105",
            "email": "dumitrucdc@yahoo.com"
          },
          {
            "name": "VALMAR AUTOTRANS SRL PL \nTULCEA",
            "address": "STR. ISACCEI NR.73 PARTER-CAM.1 SI \nET.1 -CAM. 13",
            "category": "B",
            "telephone": "0744696964",
            "email": "valmarautotrans@yahoo.com"
          },
          {
            "name": "S.C. LISARUN DRIVE S.R.L.",
            "address": "STR. MIRCEA VODA NR.44 ",
            "category": "B",
            "telephone": "0745757378",
            "email": "sc.lissa@yahoo.com"
          }
        ]
      },
      {
        "city": "BABADAG",
        "schools": [
          {
            "name": "AUTO VIVA SRL",
            "address": "STR.AUREL VLAICU NR.4",
            "category": "B",
            "telephone": "0745173720",
            "email": "SC_AUTO_VIVA_SRL@YAHOO.RO"
          },
          {
            "name": "AUTOTUR SRL PL BABADAG",
            "address": "STR.PAVEL GHEORGHE NR.8 ET.1",
            "category": "B",
            "telephone": "",
            "email": "office@autotur.ro"
          }
        ]
      },
      {
        "city": "SARICHIOI",
        "schools": [
          {
            "name": "AUTOGRIG SRL",
            "address": "Nr.145 C,Cam.1",
            "category": "B",
            "telephone": "0740852969",
            "email": "grig_red@yahoo.com"
          }
        ]
      },
      {
        "city": "ISACCEA",
        "schools": [
          {
            "name": "SC DRAIVAUTO SRL",
            "address": "STR. MIRCEA VODA, NR. 10,BL.85,SC. E, ",
            "category": "B",
            "telephone": "0723554468",
            "email": "draivauto2014@yahoo.ro"
          }
        ]
      },
      {
        "city": "MURIGHIOL",
        "schools": [
          {
            "name": "SC PAP AUTO COMPANY SRL",
            "address": "STR. FERICIRII ,NR. 25 ",
            "category": "B",
            "telephone": "0744908559",
            "email": "scautopapcompany@yahoo.com"
          }
        ]
      },
      {
        "city": "TOPOLOG",
        "schools": [
          {
            "name": "SC TOPMAN AUTO SRL",
            "address": "STR. CALEA BUCURESTI, NR. 21",
            "category": "B",
            "telephone": "0744497603",
            "email": "manoliul@yahoo.com"
          }
        ]
      },
      {
        "city": "VALEA TEILOR",
        "schools": [
          {
            "name": "SC IMPERIAL AUTO TULCEA SRL",
            "address": "STR.MIHAI VITEAZU,NR.3,",
            "category": "B",
            "telephone": "0751924859",
            "email": "imperialautotl@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "TM",
    "schools": [
      {
        "city": "LUGOJ",
        "schools": [
          {
            "name": "MIADIGVA SRL",
            "address": "STR. FAGARAS, NR.2",
            "category": "B",
            "telephone": "0256355805",
            "email": "mihaela13_86@yahoo.com"
          },
          {
            "name": "TUDO 2002",
            "address": "STR. BUCEGI, NR. 21",
            "category": "B,C,Tr,CE",
            "telephone": "0256355862",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "ANDRAS COMPANY",
            "address": "STR. 20 DECEMBRIE 1989, NR.3BIS",
            "category": "B,BE",
            "telephone": "0722413232",
            "email": "andrasco@rdstm.ro"
          },
          {
            "name": "SENAH SRL",
            "address": "STR. VICTOR VLAD DELAMARINA, NR.39",
            "category": "B,C,CE",
            "telephone": "0721900071",
            "email": "scoala.senah@gmail.com"
          },
          {
            "name": "AUTO NICU 2006 SRL",
            "address": "STR. NICOLAE BALCESCU NR.5 AP.2",
            "category": "B",
            "telephone": "0256/352936",
            "email": "sc.autonicu_srl@yahoo.com"
          },
          {
            "name": "AUTOYANNIS LUGOJ SRL",
            "address": "STR.OLARI, NR.9",
            "category": "B,C,BE,CE",
            "telephone": "0765857872",
            "email": "adelina_razvan2008@yahoo.com"
          },
          {
            "name": "LUGOJ DRIVE SRL",
            "address": "STR. ROSADA VALENTIN, NR.3",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0721900071",
            "email": "lugojdrive@gmail.com"
          },
          {
            "name": "FAGET DRIVE SRL",
            "address": "STR.20 DECEMBRIE 1989, NR.15, AP.5",
            "category": "B",
            "telephone": "0721900071",
            "email": "faget.drive@gmail.com"
          },
          {
            "name": "SORIN DRIVE SRL-D",
            "address": "STR.ROSADA VALENTIN, NR.19",
            "category": "B",
            "telephone": "0723260179",
            "email": "sorindrivesrl@yahoo.com"
          },
          {
            "name": "P.F.A. CRACIUN IOAN",
            "address": "STR. NICOLAE BALCESCU, NR.1",
            "category": "B",
            "telephone": "0723260149",
            "email": "sorincraciun_pfa@yahoo.com"
          },
          {
            "name": "P.F.A. ZARIONI VASILICA",
            "address": "STR. NICOLAE BALCESCU, NR.1",
            "category": "B",
            "telephone": "0722882080",
            "email": "zarionimaria@yahoo.com"
          },
          {
            "name": "PFA CAPRARIU CRISTIAN",
            "address": "STR. VICTOR VLAD DELAMARINA, NR.39",
            "category": "B",
            "telephone": "0722836018",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "PFA CAPRARIU NICOLAE",
            "address": "STR. VICTOR VLAD DELAMARINA, NR.39",
            "category": "B",
            "telephone": "0722702648",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "PFA DRAGOS NICOLAE IOAN",
            "address": "STR. VICTOR VLAD DELAMARINA, NR.39",
            "category": "B",
            "telephone": "0722470128",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "PFA LAZAR VALERICA IONEL",
            "address": "STR. VICTOR VLAD DELAMARINA, NR.39",
            "category": "B",
            "telephone": "0722206387",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "BOCEANU-SZABO SORIN ALIN PFA",
            "address": "STR. NICOLAE BALCESCU, NR.1",
            "category": "B",
            "telephone": "0723986367",
            "email": "andreescu_carmen76@yahoo.com"
          },
          {
            "name": "STOIA DORIN CRISTIAN PFA",
            "address": "STR. NICOLAE BALCESCU, NR. 1",
            "category": "B",
            "telephone": "0766723958",
            "email": "dorin.stoia.pfa@gmail.com"
          }
        ]
      },
      {
        "city": "TIMISOARA",
        "schools": [
          {
            "name": "TUDO 2002 SRL",
            "address": "STR.PESTALOZZI NR.18 ET.I AP.2",
            "category": "B,C,CE",
            "telephone": "256192538",
            "email": "nicu_petrovici@yahoo.com"
          },
          {
            "name": "CONS-AUTO TOTAL SRL",
            "address": "BUL. LIVIU REBREANU,NR.4 ",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0256203508",
            "email": "cons.auto@yahoo.com"
          },
          {
            "name": "ZARA 2005 SRL",
            "address": "STR. INDEPENDENTEI NR.2, AP.10",
            "category": "B,A1,AM",
            "telephone": "0256/274824",
            "email": "zara@scoalazara.com"
          },
          {
            "name": "FAN TAXI SRL",
            "address": "CALEA BUZIASULUI, NR.104, CAMERA 1",
            "category": "B",
            "telephone": "0722254342",
            "email": "fantaxi@ymail.com"
          },
          {
            "name": "PROFAM ALEX AUTO  SRL",
            "address": "STR.ARIES.NR.19,CASA ",
            "category": "B",
            "telephone": "0722823620",
            "email": "profamauto@yahoo.com"
          },
          {
            "name": "EURO DRIVER COMPANY",
            "address": "STR. ARIADNA, NR. 43",
            "category": "B",
            "telephone": "0723355075",
            "email": "eurodrivertm@gmail.com"
          },
          {
            "name": "ARIS AUTO SRL",
            "address": "STR. LIEGE, NR.26",
            "category": "B",
            "telephone": "0721670563",
            "email": "proteasa_razvan@yahoo.com"
          },
          {
            "name": "SOCIETATEA DE TRANSPORT PUBLIC \nTIMISOARA SA",
            "address": "STR. RENASTERII, NR.28",
            "category": "B,Tv,Tb",
            "telephone": "0256433240",
            "email": "cristina.stana@ratt.ro"
          },
          {
            "name": "MIKO VIP",
            "address": "CALEA BOGDANESTILOR, NR.32B",
            "category": "B",
            "telephone": "0742151004",
            "email": "autovip2008@gmail.com"
          },
          {
            "name": "AUTO VALI & ADI COMPANY SRL",
            "address": "STR. IRIS, NR. 25.",
            "category": "A,B,C,D,Tr,BE,CE",
            "telephone": "0256272668",
            "email": "scoala@autovali.ro"
          },
          {
            "name": "AUTO VALI&ADI COM II ",
            "address": "STR. IRIS, NR. 25,AP.1.",
            "category": "B",
            "telephone": "0256272668",
            "email": "scoala@autovali.ro"
          },
          {
            "name": "ISAC & YSA SRL ",
            "address": "STR. ION SLAVICI,NR.71,CAM.1",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0762257728",
            "email": "otialessia@yahoo.com"
          },
          {
            "name": "USAMVB \"REGELE MIHAI I AL \nROMANIEI\" TIMISOARA",
            "address": "CALEA ARADULUI, NR. 119",
            "category": "B,C",
            "telephone": "0726397474",
            "email": "parvan.mircea@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \nTRANSPORTURI AUTO ",
            "address": "STR.ARDEALULUI NR.1",
            "category": "B",
            "telephone": "221672",
            "email": "pavelescus@yahoo.com"
          },
          {
            "name": "KREBS",
            "address": "CAL. SAGULUI, NR.22.",
            "category": "B",
            "telephone": "0744622508",
            "email": "scoalakrebs@gmail.com"
          },
          {
            "name": "LUCKY DRIVER SRL",
            "address": "STR.CLOSCA NR.2A MANSARDA",
            "category": "A,B",
            "telephone": "0766236771",
            "email": "nistormarius55@yahoo.com"
          },
          {
            "name": "AUTO NEA GELU SRL",
            "address": "B-DUL TAKE IONESCU, NR.51, SPATIUL 3-",
            "category": "B",
            "telephone": "0745523106",
            "email": "auto.neagelu@gmail.com"
          },
          {
            "name": "AUTOBLITZ LAND SRL",
            "address": "STR. ALBINELOR, NR. 70B",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0723542920",
            "email": "office@autoblitz.ro"
          },
          {
            "name": "AUTO TOMAS ALFA SRL",
            "address": "STR.GHEORGHE LAZAR, NR.17, ET.1",
            "category": "B,C,BE,CE",
            "telephone": "0721901737",
            "email": "contact@scoalaalfa.ro"
          },
          {
            "name": "TDP AUTO CRISTI SRL",
            "address": "STR.LIEGE, NR.18",
            "category": "B,C,BE,CE,B1",
            "telephone": "0730556454",
            "email": "officetdp.cristi@gmail.com"
          },
          {
            "name": "AUTO NYK TOTAL SRL",
            "address": "STR.AMURGULUI NR.1",
            "category": "B",
            "telephone": "0721825749",
            "email": "autonyktotal@gmail.com"
          },
          {
            "name": "PAUL AUTO MOTO SRL",
            "address": "STR. CETATEA ALBA, NR.7",
            "category": "A,B,A1,A2",
            "telephone": "0745092481",
            "email": "bolog_oana_maria@yahoo.com"
          },
          {
            "name": "JUADI SRL",
            "address": "B-DUL TAKE IONESCU, NR.51, ETAJ, ",
            "category": "B",
            "telephone": "0256491902",
            "email": "office@autoblitz.ro"
          },
          {
            "name": "TC AUTO TVI SRL",
            "address": "STR.MACILOR, NR.1, BIROUL 6",
            "category": "B",
            "telephone": "0724298242",
            "email": "tavi.lungu@yahoo.com"
          },
          {
            "name": "BT TIN-TIN SRL",
            "address": "STR. ION ROATA, NR.26, CAMERA 1",
            "category": "B,BE",
            "telephone": "0725032399",
            "email": "bt.tin.tin01@gmail.com"
          },
          {
            "name": "M.A.L. ROAD DRIVERS SRL",
            "address": "CALEA ARADULUI, NR. 41-43, PARTER",
            "category": "B",
            "telephone": "0761642777",
            "email": "m.a.l.roaddrivers@gmail.com"
          },
          {
            "name": "TIM SMART DRIVE SRL",
            "address": "STR.OVIDIU COTRUS NR.24A",
            "category": "B",
            "telephone": "0726397640",
            "email": "officeizodasa@yahoo.com"
          }
        ]
      },
      {
        "city": "JIMBOLIA",
        "schools": [
          {
            "name": "MARC",
            "address": "STR.TUDOR VLADIMIRESCU NR.96",
            "category": "B",
            "telephone": "0766677293",
            "email": "scoalamarcnou@yahoo.com"
          },
          {
            "name": "AUTO SDL SRL",
            "address": "STR. REPUBLICII, NR. 35.",
            "category": "B",
            "telephone": "0744846744",
            "email": "autosdl@gmail.com"
          }
        ]
      },
      {
        "city": "SANNICOLAU MARE",
        "schools": [
          {
            "name": "AUTO TODEA INTERNATIONAL",
            "address": "STR.ANDREI SAGUNA, NR.43",
            "category": "B",
            "telephone": "0256372340",
            "email": "scoala@autodelta.ro"
          },
          {
            "name": "DARIO DRIVE",
            "address": "STR.PIATA 30 DECEMBRIE, NR.7, CAM.2",
            "category": "B",
            "telephone": "0763353818",
            "email": "lakylucian@yahoo.com"
          },
          {
            "name": "AUTO NATANAEL SRL",
            "address": "STR. CALEA LUI TRAIAN, NR.2",
            "category": "B",
            "telephone": "0722671309",
            "email": "auto_natanael@yahoo.com"
          },
          {
            "name": "AUTO DELTA - P.L. SINNICOLAU \nMARE",
            "address": "STR. ANDREI SAGUNA, NR.43",
            "category": "A,B,C,Tr,CE",
            "telephone": "0256/372340",
            "email": "autodelta35@yahoo.com"
          }
        ]
      },
      {
        "city": "DETA",
        "schools": [
          {
            "name": "EDY 2000 SRL",
            "address": "STR. MIHAI VITEAZU, NR. 3.",
            "category": "B",
            "telephone": "0722989831",
            "email": "scoaladesoferi_edy2000@yahoo.com"
          }
        ]
      },
      {
        "city": "LOVRIN",
        "schools": [
          {
            "name": "TDP AUTO CRISTI SRL",
            "address": "NR.FN",
            "category": "A,B,A1,B1,A2",
            "telephone": "0730556454",
            "email": "officetdp.cristi@gmail.com"
          }
        ]
      },
      {
        "city": "CHISODA",
        "schools": [
          {
            "name": "RAVITEX SRL",
            "address": "CALEA SAGULUI, NR.221, DN 59 KM 7, ",
            "category": "B,C,D,CE",
            "telephone": "0722608222",
            "email": "scoaladesoferi@ravitex.ro"
          }
        ]
      },
      {
        "city": "DUMBRAVITA",
        "schools": [
          {
            "name": "DUNCA EXPEDITII SRL",
            "address": "DJ 691, KM 7+142",
            "category": "B,C,CE",
            "telephone": "0752770777",
            "email": "scoaladesoferi@duncaexpeditii.ro"
          }
        ]
      },
      {
        "city": "PERIAM",
        "schools": [
          {
            "name": "TOP DRIVE PROFESSIONAL SRL",
            "address": "STR. MAGNOLIEI, NR.19, CORP CLADIRE ",
            "category": "B",
            "telephone": "0762209632",
            "email": "officetdpprof@gmail.com"
          }
        ]
      },
      {
        "city": "BUZIAS",
        "schools": [
          {
            "name": "BORAN TIN TIN PLUS SRL",
            "address": "STR.BISERICII, NR.18, SPATIU ",
            "category": "B",
            "telephone": "0725032399",
            "email": "scoalatintin@outlook.com"
          }
        ]
      },
      {
        "city": "RECAS",
        "schools": [
          {
            "name": "SIMA VICTOR ANTON PERSOANA \nFIZICA AUTORIZATA",
            "address": "SAT IZVIN, NR.301",
            "category": "B",
            "telephone": "0722380540",
            "email": "anton_sima@yahoo.com"
          }
        ]
      },
      {
        "city": "FAGET",
        "schools": [
          {
            "name": "FAGET DRIVE SRL",
            "address": "ALEEA STADIONULUI, NR.1",
            "category": "B",
            "telephone": "0721900071",
            "email": "drivefaget@gmail.com"
          }
        ]
      },
      {
        "city": "MOSNITA NOUA",
        "schools": [
          {
            "name": "PFA ISCRULESCU CATALIN",
            "address": "NR.328",
            "category": "B",
            "telephone": "0721331779",
            "email": "isc_cata@yahoo.com"
          }
        ]
      },
      {
        "city": "JAMU MARE",
        "schools": [
          {
            "name": "WIN AUTO EXCLUSIV SRL-D",
            "address": "NR.105/a",
            "category": "B",
            "telephone": "0770141633",
            "email": "winautoexclusiv@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "TR",
    "schools": [
      {
        "city": "TURNU MAGURELE",
        "schools": [
          {
            "name": "DORELEN COM SRL",
            "address": "STR. G-RAL PRAPORGESCU , NR. 25A",
            "category": "B",
            "telephone": "0247414172",
            "email": "DORELEN2000@YAHOO.COM"
          },
          {
            "name": "ALYSRAM S.R.L.",
            "address": "STR. CPT. STANCULESCU , NR. 21",
            "category": "B",
            "telephone": "0740210608",
            "email": "alin_stanescu2006@yahoo.com"
          },
          {
            "name": "AUTO TRANS GROUP S.R.L.",
            "address": "B-DUL INDEPENDENTEI , NR. 638 ( ",
            "category": "B",
            "telephone": "0745511610",
            "email": "autotransgroup@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC SF. \nHARALAMBIE",
            "address": "STR.OITUZ , NR.11",
            "category": "B",
            "telephone": "0247416354",
            "email": "gsharalambie@yahoo.com"
          },
          {
            "name": "AUTO ALYSRAM S.R.L.",
            "address": "STRADA LUNCII NR.10 BIS",
            "category": "B",
            "telephone": "0762711010",
            "email": "ramonaelena_stanescu@yahoo.com"
          }
        ]
      },
      {
        "city": "ALEXANDRIA",
        "schools": [
          {
            "name": "PROFALEX  S.R.L.",
            "address": "SOS. TURNU MAGURELE , NR. 1",
            "category": "B",
            "telephone": "0247/421066",
            "email": "profalexsrl@yahoo.ro"
          },
          {
            "name": "AUTO SAVE  SRL",
            "address": "STR. 1 DECEMBRIE , NR. 91 ",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0766202424",
            "email": "auto_save_grup@yahoo.com"
          },
          {
            "name": "MALDAEANU SRL",
            "address": "STR. LIBERTATII , NR. 50",
            "category": "A,B,C,D,CE,AM",
            "telephone": "421162",
            "email": "sc_maldaeanu_srl@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NR. 1",
            "address": "SOS.TURNU MAGURELE NR.1-3",
            "category": "B",
            "telephone": "0247312989",
            "email": "gruia_marin@yahoo.com"
          },
          {
            "name": "ANDREEA TRANS SRL",
            "address": "STR. CUZA VODA , NR. 113",
            "category": "A,B,C,CE",
            "telephone": "0744349456",
            "email": "scoalasoferiandreea@yahoo.com"
          },
          {
            "name": "ARXY ROXVAL SRL",
            "address": "STR. VIILOR , NR. 13",
            "category": "B",
            "telephone": "0768272162",
            "email": "valentinpetrescu_arxy@yahoo.com"
          },
          {
            "name": "AUTO SAM S.R.L.",
            "address": "STR. LIBERTATII , BL. A6 , ET. 1",
            "category": "B",
            "telephone": "0760838922",
            "email": "autosam_alexandria@yahoo.ro"
          },
          {
            "name": "ANDRIVE SRL",
            "address": "STR. LIBERTATII , NR. 111",
            "category": "B",
            "telephone": "0761334492",
            "email": "andriveauto@gmail.com"
          },
          {
            "name": "LITTLE CAR AUTO SRL",
            "address": "STR. NICOLAE BALCESCU , NR. 109",
            "category": "B",
            "telephone": "0762261566",
            "email": "miculuciandaniel80@yahoo.com"
          },
          {
            "name": "AUTOADYCRISS S.R.L.",
            "address": "STR.NEGRU VODA NR.98",
            "category": "B,C,D,CE",
            "telephone": "0724506128",
            "email": "autoadycriss@gmail.com"
          },
          {
            "name": "CALYPSO SCHOOL SRL",
            "address": "STR. MIHAIL KOGALNICEANU , NR. 123",
            "category": "B,C,CE",
            "telephone": "0762261566",
            "email": "neagudomnel@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC NICOLAE \nBALCESCU",
            "address": "STR.LIBERTATII NR.69",
            "category": "B",
            "telephone": "0247312758",
            "email": "curealg@yahoo.com"
          },
          {
            "name": "AUTO ALEX GROUP S.R.L.",
            "address": "NANOV,Sat Nanov, Str.Dunarii,Nr.30",
            "category": "B,C,CE",
            "telephone": "0767790444",
            "email": "iulica.petre@yahoo.com"
          },
          {
            "name": "ALEXTOP DRIVE S.R.L.",
            "address": "Sos. Turnu Magurele,Nr.1,Etaj II",
            "category": "B",
            "telephone": "0767366804",
            "email": "cpirlan@yahoo.com"
          }
        ]
      },
      {
        "city": "VIDELE",
        "schools": [
          {
            "name": "AUTO SAM S.R.L.",
            "address": "STR. FLORILOR , NR. 32",
            "category": "A,B,C,D,CE,A1",
            "telephone": "0727127706",
            "email": "autosamvidele@gmail.com"
          }
        ]
      },
      {
        "city": "ROSIORI DE VEDE",
        "schools": [
          {
            "name": "FANE TRANS S.R.L.",
            "address": "STR,. DUNARII , NR. 111C",
            "category": "B",
            "telephone": "0721885466",
            "email": "fanetranssrl@yahoo.com"
          },
          {
            "name": "MIAMA COM SRL",
            "address": "STR.DUNARII , BL. K1 , SC. C , PARTER",
            "category": "B",
            "telephone": "0247466550",
            "email": "mitran.marin@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC  EMIL \nRACOVITA ",
            "address": "STR. RENASTERII , NR. 21",
            "category": "B",
            "telephone": "0247/460055",
            "email": "gigel_ungureanu@yahoo.com"
          },
          {
            "name": "AUTO FANE DRIVE SRL",
            "address": "STR. MARASESTI , NR. 71",
            "category": "B",
            "telephone": "0766415544",
            "email": "stefania_isar@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC ANGHEL \nSALIGNY",
            "address": "STR. MIHAIL KOGALNICEANU , NR. 52",
            "category": "B",
            "telephone": "0247466690",
            "email": "marius_2320032003@yahoo.com"
          },
          {
            "name": "CAMARAD MIHIGH AUTO S.R.L.",
            "address": "Str.MARASESTI, Nr.9-11,etaj 2, camerele ",
            "category": "B",
            "telephone": "0766593650",
            "email": "mihai.purcarea69@gmail.com"
          }
        ]
      },
      {
        "city": "PIATRA",
        "schools": [
          {
            "name": "AUTO ELI TRANS SRL",
            "address": "PIATRA",
            "category": "B,C,BE,CE",
            "telephone": "0766724136",
            "email": "autoelitrans@gmail.com"
          }
        ]
      },
      {
        "city": "NANOV",
        "schools": [
          {
            "name": "AUTO SOARE SMS SRL",
            "address": "STR. DUNARII , NR. 214",
            "category": "B",
            "telephone": "0763969919",
            "email": "smsmario89@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "VL",
    "schools": [
      {
        "city": "DRAGASANI",
        "schools": [
          {
            "name": "S.M.SUBOFITERI DE JANDARMI \nGRIGORE ALEXANDRU GHICA",
            "address": "STR.I.C.BRATIANU NR.25",
            "category": "B",
            "telephone": "0250810552",
            "email": "scoaladragasani@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC BRATIANU",
            "address": "STR.MIHAIL KOGALNICEANU NR.1",
            "category": "B",
            "telephone": "0250810260",
            "email": "i.maruntelu@yahoo.com"
          },
          {
            "name": "RADCRIS-AUTO SRL",
            "address": "SAT OVESELU COM. MACIUCA",
            "category": "B,C,CE",
            "telephone": "0745827954",
            "email": "rad_cris_auto@yahoo.com"
          },
          {
            "name": "FANDRIVER SRL",
            "address": "STR. REGELE CAROL NR.24",
            "category": "B",
            "telephone": "0250810610",
            "email": "fandriver09@yahoo.com"
          },
          {
            "name": "LIDER PILOTAJ SRL",
            "address": "STR.DOBROGEANU GHEREA BL.P ",
            "category": "B",
            "telephone": "0767429262",
            "email": "luciabuliteanu@yahoo.com"
          },
          {
            "name": "COSTI AUTOCLICK SRL",
            "address": "STR SCOLII NR 1",
            "category": "B",
            "telephone": "0768668666",
            "email": "costyely20@yahoo.com"
          },
          {
            "name": "STREET QUEENS SRL",
            "address": "STR. MIHAIL KOGALNICEANU NR.1 ET.1",
            "category": "B,C,CE",
            "telephone": "0765056413",
            "email": "lazarescurenato@yahoo.com"
          }
        ]
      },
      {
        "city": "RAMNICU VALCEA",
        "schools": [
          {
            "name": "BONCAR SRL",
            "address": "STR.TOPOLOG NR.5 ",
            "category": "B",
            "telephone": "0745320102",
            "email": "BONCARSRL@YAHOO.COM"
          },
          {
            "name": "AUTO NIK-DAN SRL",
            "address": "STR. CALEA LUI TRAIAN NR.45 BL.1 ",
            "category": "A,B,C,D,CE,A2",
            "telephone": "0723415975",
            "email": "dumbravescunorocel@yahoo.com"
          },
          {
            "name": "AUTO STAR SRL",
            "address": "STR.LUCIAN BLAGA NR.2 BL.A53 PARTER",
            "category": "B",
            "telephone": "0741046235",
            "email": "mariusinstructor@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC HENRI \nCOANDA",
            "address": "STR.HENRI COANDA NR.23",
            "category": "B",
            "telephone": "0350405975",
            "email": "sandamarius18@yahoo.com"
          },
          {
            "name": "COLEGIUL DE SILVICULTURA SI \nPROTECTIA MEDIULUI",
            "address": "STR.CALEA LUI TRAIAN NR.195-197",
            "category": "B,Tr",
            "telephone": "0250749521",
            "email": "forestier@iplus.ro"
          },
          {
            "name": "LICEUL TEHNOLOGIC GENERAL \nMAGHERU",
            "address": "STR.CALEA LUI TRAIAN NR.43",
            "category": "B",
            "telephone": "0350401627",
            "email": "generalmagheru@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC CAPITAN \nNICOLAE PLESOIANU",
            "address": "STR.MORILOR NR.4A",
            "category": "B",
            "telephone": "0250732853",
            "email": "stroe_ion@yahoo.com"
          },
          {
            "name": "AUTO POPTEO SRL",
            "address": "STR.RAURENI NR.162",
            "category": "B",
            "telephone": "0765553872",
            "email": "autopopteo@yahoo.com"
          },
          {
            "name": "AUTOPILOTAJ SA",
            "address": "STR.CALEA LUI TRAIAN NR.92",
            "category": "A,B,A1,A2",
            "telephone": "0250711452",
            "email": "autopilotaj92@yahoo.com"
          },
          {
            "name": "CAMA SRL",
            "address": "STR.PATRASCU VODA NR.4",
            "category": "B,C,D,BE,CE",
            "telephone": "0745689046",
            "email": "mirelcalciu@yahoo.com"
          },
          {
            "name": "LEX EDO SRL",
            "address": "BV.TINERETULUI NR.12 BL.A10 PARTER",
            "category": "B,C,D,BE,CE",
            "telephone": "0744664712",
            "email": "lexedo2004@yahoo.com"
          },
          {
            "name": "DOMA IMPORT EXPORT SRL",
            "address": "STR.FERDINAND NR.62",
            "category": "A,B,C,BE,CE,A1,AM",
            "telephone": "0744252525",
            "email": "domaimportexport2007@yahoo.com"
          },
          {
            "name": "FLOREXIM INTERNATIONAL SRL",
            "address": "STR.MIHAI EMINESCU NR.14 ",
            "category": "B",
            "telephone": "0250740000",
            "email": "florexim@yahoo.com"
          },
          {
            "name": "STREET KINGS SRL",
            "address": "STR.CALEA LUI TRAIAN NR.160 BL.21 ",
            "category": "B",
            "telephone": "0751797821",
            "email": "alina_madona25@yahoo.com"
          },
          {
            "name": "ANDONA SRL",
            "address": "STR.MORILOR NR.1 ",
            "category": "B",
            "telephone": "0744502818",
            "email": "mateidannicolae@yahoo.com"
          },
          {
            "name": "EOD START SRL",
            "address": "CALEA LUI TRAIAN NR.171 INCAPEREA ",
            "category": "B",
            "telephone": "0740036367",
            "email": "enachea@ymail.com"
          },
          {
            "name": "CLAUMIR RENT SRL",
            "address": "STR. RAURENI NR.49B",
            "category": "A,B,D,A1,B1,A2",
            "telephone": "0742241944",
            "email": "mirelcalciu@yahoo.com"
          },
          {
            "name": "YPI IUL DRIVER SRL",
            "address": "CALEA LUI TRAIAN NR.1 CAMIN NEF. ",
            "category": "B,C,CE",
            "telephone": "0761605961",
            "email": "lacatushu_daniel@yahoo.ro"
          },
          {
            "name": "CORSA TRUCK SRL",
            "address": "CALEA LUI TRAIAN NR.45 CAMIN ",
            "category": "B,C,CE",
            "telephone": "0741046235",
            "email": "mariusinstructor@yahoo.com"
          },
          {
            "name": "ADEMINA AUTO SRL",
            "address": "STR.GIB MIHAESCU NR.8 CORP BIROURI ",
            "category": "B",
            "telephone": "0766653801",
            "email": "istratescu.andrei@yahoo.com"
          },
          {
            "name": "TRANSVAL AUTOTEST SRL",
            "address": "STR. CALEA LUI TRAIAN NR.80 BL.S13 ",
            "category": "B",
            "telephone": "0763/737614",
            "email": "transval.autotest@yahoo.com"
          },
          {
            "name": "EMDORIA AUTO SRL-D",
            "address": "STR.GIB MIHAESCU NR.8 CORP ",
            "category": "B,C,CE",
            "telephone": "0746656697",
            "email": "emdoria22@gmail.com"
          },
          {
            "name": "ABC SOFER SRL",
            "address": "BDL. TINERETULUI NR.1B CASA ",
            "category": "B",
            "telephone": "0764466588",
            "email": "meianudaniel@yahoo.com"
          },
          {
            "name": "LORENTZ CONSULTING SRL",
            "address": "STR STRAUBING, NR.167",
            "category": "B",
            "telephone": "0350913",
            "email": "lary1973@mail.com"
          },
          {
            "name": "ALEXCRIN DRIVE SRL",
            "address": "STR. REPUBLICII NR.35 AP.1",
            "category": "B,C",
            "telephone": "0722575204",
            "email": "alexcrin1973@yahoo.com"
          }
        ]
      },
      {
        "city": "BUJORENI",
        "schools": [
          {
            "name": "SEDNA TRANS SRL",
            "address": "SAT OLTENI NR.153",
            "category": "B,C,CE",
            "telephone": "0722315710",
            "email": "sedna.trans@ymail.com"
          },
          {
            "name": "ROMFRANCE SRL",
            "address": "STRADA BUJORENI NR 111B",
            "category": "B",
            "telephone": "0350806988",
            "email": "p.petcu.rfr@gmail.com"
          }
        ]
      },
      {
        "city": "MALDARESTI",
        "schools": [
          {
            "name": "ZEPO CRIS SRL",
            "address": "MALDARESTI NR.79",
            "category": "B",
            "telephone": "0740775472",
            "email": "ciocirlan.zepocris@yahoo.com"
          }
        ]
      },
      {
        "city": "BAILE GOVORA",
        "schools": [
          {
            "name": "AUTORAF SRL",
            "address": "STR.PIETII NR.23",
            "category": "B",
            "telephone": "0723079131",
            "email": "firenzze29@yahoo.com"
          }
        ]
      },
      {
        "city": "BERBESTI",
        "schools": [
          {
            "name": "COSMOCHIM SRL",
            "address": "STR.PRINCIPALA NR.59",
            "category": "B",
            "telephone": "0740628679",
            "email": "cosmochim@yahoo.com"
          }
        ]
      },
      {
        "city": "VLADESTI",
        "schools": [
          {
            "name": "ALEX CRIN SRL",
            "address": "SAT VLADESTI NR.142",
            "category": "A,B,C,D,CE,A1,A2",
            "telephone": "0765389349",
            "email": "alexcrin1973@yahoo.com"
          }
        ]
      },
      {
        "city": "HOREZU",
        "schools": [
          {
            "name": "COTOFANA SRL",
            "address": "STR.ALEXANDRU IOAN CUZA NR.41",
            "category": "B,C,D",
            "telephone": "0744619453",
            "email": "cotofanahorezu@hotmail.com"
          },
          {
            "name": "TRANSLAR SRL",
            "address": "STR.MIRCEA CEL BATRAN BL.O1 PARTER",
            "category": "B,C,D,BE,CE",
            "telephone": "0250860099",
            "email": "translar10@yahoo.com"
          },
          {
            "name": "LYONMAR TRANS SRL",
            "address": "STR.UNIRII NR.12 ET.1",
            "category": "B",
            "telephone": "0745672980",
            "email": "lyonmar@yahoo.com"
          },
          {
            "name": "TRANS UDVESCU SRL",
            "address": "STR. TUDOR VLADIMIRESCU, NR.80",
            "category": "B",
            "telephone": "0788881980",
            "email": "udvesculiviu@yahoo.com"
          }
        ]
      },
      {
        "city": "ROESTI",
        "schools": [
          {
            "name": "LUCKY SCHOOL SRL",
            "address": "SAT CUENI NR.147",
            "category": "B",
            "telephone": "0763635498",
            "email": "bemilnicolae@yahoo.com"
          }
        ]
      },
      {
        "city": "BARBATESTI",
        "schools": [
          {
            "name": "TRANSVAL AUTOTEST SRL",
            "address": "SAT BODESTI",
            "category": "B,C,CE",
            "telephone": "0763737614",
            "email": "transval.autotest@yahoo.com"
          }
        ]
      },
      {
        "city": "NICOLAE BALCESCU",
        "schools": [
          {
            "name": "AUTO-DAVID DRIVER SRL",
            "address": "SAT ROTARESTI NR.151",
            "category": "B",
            "telephone": "0745019070",
            "email": "bogdan.cirlea@yahoo.com"
          }
        ]
      }
    ]
  },
  {
    "state": "VN",
    "schools": [
      {
        "city": "FOCSANI",
        "schools": [
          {
            "name": "TRIAUTO TAZMOTO SRL",
            "address": "STR.MARE A UNIRII,NR.39B",
            "category": "B",
            "telephone": "0768258242",
            "email": "toddyca@yahoo.com"
          },
          {
            "name": "PRO CASA SRL",
            "address": "STR.MARE A UNIRII, NR.52 A",
            "category": "B",
            "telephone": "0726740287",
            "email": "tomadorel17@yahoo.com"
          },
          {
            "name": "AUTO MAD 2005 SRL",
            "address": "STR.CARABUS NR.11",
            "category": "B",
            "telephone": "0744780402",
            "email": "mihaescu.eugen@yahoo.com"
          },
          {
            "name": "S.C. SCORPION TEST 2006 S.R.L.",
            "address": "STR.ANA IPATESCU NR.17",
            "category": "A,B,C,D,CE,AM",
            "telephone": "0237226551",
            "email": "scoala.auto@scorpion.ro"
          },
          {
            "name": "AGAFITEI SERV SRL",
            "address": "STR.DORNEI,NR.8,AP.1",
            "category": "B",
            "telephone": "0745938120",
            "email": "office@scoaladesoferiagafitei.ro"
          },
          {
            "name": "AUTO SCHUMACHER SRL",
            "address": "STR.VRANCEI,NR.10B",
            "category": "A,B,AM",
            "telephone": "0740060889",
            "email": "autoschumacher@yahoo.com"
          },
          {
            "name": "AUTO GEGEA SRL",
            "address": "STR.MIHAIL KOGALNICEANU NR.19A",
            "category": "B",
            "telephone": "0737496705",
            "email": "autogegea@gmail.com"
          },
          {
            "name": "Colegiul Tehnic Edmond Nicolau  ",
            "address": "STR.1 DECEMBRIE 1918 NR.10",
            "category": "B",
            "telephone": "0744524377",
            "email": "edmond.nicolau@yahoo.com"
          },
          {
            "name": "Colegiul Tehnic Auto TRAIAN VUIA",
            "address": "STR. COTESTI,NR.52",
            "category": "B,C,CE,C1",
            "telephone": "0237236713",
            "email": "ctatvuiafocsani@yahoo.com"
          },
          {
            "name": "Colegiul Tehnic  Valeriu D. Cotea ",
            "address": "STR. CUZA VODA,NR.46",
            "category": "B",
            "telephone": "0237225859",
            "email": "colegiul_cotea@yahoo.com"
          },
          {
            "name": "DIMA PREST SRL",
            "address": "STR.ANGHEL SALIGNY NR.35B",
            "category": "B",
            "telephone": "0724860561",
            "email": "mihaela.dediu24@yahoo.com"
          },
          {
            "name": "NENCIU COM SRL",
            "address": "STR.TATULESCU NR.10-12",
            "category": "B,C,D,CE,AM",
            "telephone": "0744602214",
            "email": "scoalanenciu@yahoo.com"
          },
          {
            "name": "MELINTEANU PREST SRL",
            "address": "STR. MOLDOVA NR.13",
            "category": "B,C,D,BE,CE",
            "telephone": "0762221988",
            "email": "melinteanuiulian@yahoo.com"
          },
          {
            "name": "AUTOMIRCEA DRIVING SRL-D",
            "address": "B-DUL DIMITRIE CANTEMIR,NR.4",
            "category": "B",
            "telephone": "0763641878",
            "email": "bazar_mircea@yahoo.com"
          },
          {
            "name": "ACR AUTO TURISM SRL",
            "address": "B-DUL UNIRII,NR.28,BL.28,SC.3,PARTER",
            "category": "B",
            "telephone": "0745849168",
            "email": "acr_vn@acr.ro"
          },
          {
            "name": "MELIS TEST SRL",
            "address": "STR.REPUBLICII,NR.9",
            "category": "B,C,CE",
            "telephone": "0722390753",
            "email": "diana.melinteanu@yahoo.com"
          },
          {
            "name": "NAN VALERIU PERSOANA FIZICA \nAUTORIZATA",
            "address": "BV.DIMITRIE CANTEMIR NR.4",
            "category": "B",
            "telephone": "0744957731",
            "email": "NANVALERIU2017@GMAIL.COM"
          },
          {
            "name": "MOCANU D.V.DUMITRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "BD.DIMITRIE CANTEMIR,NR.4",
            "category": "B",
            "telephone": "0745915906",
            "email": "mocanudumitru@gmail.com"
          },
          {
            "name": "ZAMFIR LUCIAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "BD.DIMITRIE CANTEMIR,NR.4",
            "category": "B",
            "telephone": "0743032126",
            "email": "lucianzamfir18@gmail.com"
          },
          {
            "name": "GEAMBASU SILVIU PERSOANA \nFIZICA AUTORIZATA",
            "address": "BD.DIMITRIE CANTEMIR,NR.4",
            "category": "B",
            "telephone": "0766105699",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "CHIRIAC A. COSTICA PFA",
            "address": "B-DUL DIMITRIE CANTEMIR NR. 4",
            "category": "B",
            "telephone": "0745204457",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "SARZEA SORINEL PERSOANA FIZICA \nAUTORIZATA",
            "address": "BV.DIMITRIE CANTEMIR NR.4",
            "category": "B",
            "telephone": "0722758672",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "CODRESCU COSTEL PERSOANA \nFIZICA AUTORIZATA",
            "address": "BV.DIMITRIE CANTEMIR NR.4",
            "category": "B",
            "telephone": "0744963211",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "CROITORU DANUT PERSOANA \nFIZICA AUTORIZATA",
            "address": "BV.DIMITRIE CANTEMIR NR.4",
            "category": "B",
            "telephone": "0723077421",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "SERBAN V.GABRIEL LAURENTIU PFA",
            "address": "B-DUL DIMITRIE CANTEMIR NR. 4",
            "category": "B",
            "telephone": "0745535694",
            "email": "geamby2004@yahoo.com"
          },
          {
            "name": "NENCIU PREST SRL",
            "address": "STR.SLT.GH.TATULESCU,NR.10-12",
            "category": "B",
            "telephone": "0744201768",
            "email": "nenciu_stefan@yahoo.com"
          },
          {
            "name": "AUTO MELI CORNEL SRL",
            "address": "STR.REPUBLICII,NR.9,T39,P.2501,NR.Cad",
            "category": "B,C,CE",
            "telephone": "0722390753",
            "email": "diana.melinteanu@yahoo.com"
          },
          {
            "name": "MALIVCOM SRL",
            "address": "STR.CUZA VODA, NR.59",
            "category": "A,B,A1,A2",
            "telephone": "0769833848",
            "email": "scoalamoisa@gmail.com"
          },
          {
            "name": "MINOIU IULIAN-VALENTIN \nPERSOANA FIZICA AUTORIZATA",
            "address": "BV.DIMITRIE CANTEMIR NR.4",
            "category": "B",
            "telephone": "0771172153",
            "email": "iulian.vn@gmail.com"
          },
          {
            "name": "MALIVCOM SRL",
            "address": "STR.REVOLUTIEI NR.3A",
            "category": "B,C,CE",
            "telephone": "0769833848",
            "email": "scoalamoisa@gmail.com"
          }
        ]
      },
      {
        "city": "ADJUD",
        "schools": [
          {
            "name": "COLEGIUL TEHNIC  GHEORGHE ",
            "address": "BV. REPUBLICII,NR.107",
            "category": "B",
            "telephone": "0744477896",
            "email": "gsaadjud@yahoo.com"
          },
          {
            "name": "OK DRIVER SRL",
            "address": "STR.VASILE ALECSANDRI NR.FN",
            "category": "B",
            "telephone": "0744247643",
            "email": "ok.driver@yahoo.com"
          },
          {
            "name": "MELISDRIVE SRL",
            "address": "STR.ALEXANDRU IOAN CUZA NR.62",
            "category": "B",
            "telephone": "0721221988",
            "email": "melinteanuiulian@yahoo.com"
          },
          {
            "name": "AUTO MUNTEANU PREST SRL",
            "address": "Str.Vasile ",
            "category": "B",
            "telephone": "0755457783",
            "email": "catalin.drive@yahoo.com"
          }
        ]
      },
      {
        "city": "PANCIU",
        "schools": [
          {
            "name": "MELIS TEST SRL",
            "address": "STR.NICOLAE TITULESCU NR.67",
            "category": "B",
            "telephone": "0722390753",
            "email": "diana.melinteanu@yahoo.com"
          },
          {
            "name": "MELINTEANU PREST SRL",
            "address": "NICOLAE TITULESCU NR.64",
            "category": "B",
            "telephone": "0762221988",
            "email": "melinteanuiulia@yahoo.com"
          }
        ]
      },
      {
        "city": "CAMPINEANCA",
        "schools": [
          {
            "name": "NEGRU TRANS SRL",
            "address": "STR.DOCTOR CARNABEL,NR.2",
            "category": "B,C,D,Tr,DE,BE,CE",
            "telephone": "0766469226",
            "email": "transfair.focsani@gmail.com"
          }
        ]
      }
    ]
  },
  {
    "state": "VS",
    "schools": [
      {
        "city": "VASLUI",
        "schools": [
          {
            "name": "S.C. EURO ROUTE S.R.L.",
            "address": "STR. STEFAN CEL MARE NR.115",
            "category": "B",
            "telephone": "0745010451",
            "email": "sceuroroute@gmail.com"
          },
          {
            "name": "AUTOICHIM SRL",
            "address": "STR.STEFAN CEL MARE NR.153",
            "category": "B,C,D,CE",
            "telephone": "0740342171",
            "email": "ichim.maria67@yahoo.com"
          },
          {
            "name": "IRAL DRIVING S.R.L.",
            "address": "STR.PETRU RARES NR.7",
            "category": "B,C,D,CE",
            "telephone": "0751741587",
            "email": "ogrinjioana@yahoo.com"
          },
          {
            "name": "UNIPREV AUTO SRL",
            "address": "STR.DOBROGEANU GHEREA NR.38",
            "category": "B,C,CE",
            "telephone": "0744340101",
            "email": "uniprevauto@gmail.com"
          },
          {
            "name": "AUTOSEND SRL",
            "address": "STR.SPIRU HARET NR.4 sediu admin.-",
            "category": "A,B,C,D,CE",
            "telephone": "0757450166",
            "email": "autosendvaslui@yahoo.ro"
          },
          {
            "name": "LICEUL STEFAN PROCOPIU",
            "address": "STR.CASTANILOR NR.3",
            "category": "B",
            "telephone": "0235318122",
            "email": "edy_popa2002@yahoo.com"
          },
          {
            "name": "PRIORITY SRL",
            "address": "STR. DONICI NR.15",
            "category": "B,C,CE",
            "telephone": "0744766926",
            "email": "sorin_bolea@yahoo.com"
          },
          {
            "name": "AUTOMOMENT SRL",
            "address": "STR.MIHAI VITEAZU NR.275 BL.275 SC.C ",
            "category": "B",
            "telephone": "0740089665",
            "email": "geta.males@gmail.com"
          },
          {
            "name": "AEO-EMI SRL",
            "address": "STR.C.D.GHEREA  NR.26",
            "category": "A,B,C,D,BE,CE,A1,A2",
            "telephone": "0744148466",
            "email": "ogrinjeugen@gmail.com"
          },
          {
            "name": "AUTOICHIM DOI SRL",
            "address": "STR.STEFAN CEL MARE NR.153 ET.2",
            "category": "B,C,CE",
            "telephone": "0740342171",
            "email": "ichim.maria67@yahoo.com"
          },
          {
            "name": "OCHIANA JANITA LUCICA \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.ING.BADEA ROMEO NR.1",
            "category": "B",
            "telephone": "0745669098",
            "email": "jeni_ochiana@yahoo.com"
          },
          {
            "name": "ANDRUSCA NECULAI PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.HULUBAT NR.12",
            "category": "B",
            "telephone": "0744851250",
            "email": "geta.males@yahoo.com"
          },
          {
            "name": "PROFIRE NELU PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.TRAIAN Piata Agroalimentara-Hala \nC1",
            "category": "B",
            "telephone": "0722638261",
            "email": "geta.males@gmail.com"
          },
          {
            "name": "PFA ARAMESCU IULIAN",
            "address": "STR.STEFAN CEL MARE, NR.91 BL.T4, ",
            "category": "B",
            "telephone": "0747270250",
            "email": "marius_n2004@yahoo.com"
          },
          {
            "name": "PFA MANEA LIVIU NECULAI",
            "address": "STR.STEFAN CEL MARE, NR.91, BL.T4, ",
            "category": "B",
            "telephone": "0740291008",
            "email": "marius_n2004@yahoo.com"
          },
          {
            "name": "PFA CIOBANU A. DUMITRU",
            "address": "STR.STEFAN CEL MARE, NR.91, BL.T4, ",
            "category": "B",
            "telephone": "0742244303",
            "email": "marius_n2004@yahoo.com"
          },
          {
            "name": "PFA AMARANDEI GELU",
            "address": "STR.STEFAN CEL MARE, NR.91, BL.T4, ",
            "category": "B",
            "telephone": "0749074428",
            "email": "marius_n2004@yahoo.com"
          },
          {
            "name": "PFA BOJIAN EUGEN CORNELIU",
            "address": "STR.MIHAI VITEAZU, BL.275, SC.C, ",
            "category": "B",
            "telephone": "0721640276",
            "email": "geta.males@gmail.com"
          },
          {
            "name": "PFA ABABEI ALEXANDRU IOAN",
            "address": "STR.MIHAI VITEAZU, BL.275, SC.C, ",
            "category": "B",
            "telephone": "0745922239",
            "email": "geta.males@gmail.com"
          },
          {
            "name": "PFA BADARAU IULIANA",
            "address": "STR.ING.BADEA ROMEO, NR.1A,CAMERA STR.MARESAL C-TIN PREZAN, NR.11-",
            "category": "B",
            "telephone": "0744479065",
            "email": "geta.males@gmail.com"
          },
          {
            "name": "PFA OCNEANU ALIN IONUT",
            "address": "13,CORP ADMINISTRATIV, BIROU NR.S8, \nET.2",
            "category": "B",
            "telephone": "0743180802",
            "email": "ocneanualin2977@gmaill.com"
          },
          {
            "name": "AYO UTILITY SRL-D",
            "address": "STR.CANTONULUI NR.51",
            "category": "B",
            "telephone": "0751396730",
            "email": "a_aionoaiei92@yahoo.com"
          },
          {
            "name": "PASAT COSTACHE PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.M-SAL CONSTANTIN PREZAN NR.11-\n13 Biroul S8 ET.2",
            "category": "B",
            "telephone": "0788062287",
            "email": "ocneanualin2977@gmail.com"
          },
          {
            "name": "VODUT DUMITRU PERSOANA \nFIZICA AUTORIZATA",
            "address": "STR.M-SAL CONSTANTIN PREZANNR.11-\n13 Biroul S8 ET.2",
            "category": "B",
            "telephone": "0746761584",
            "email": "ocneanualin2977@gmail.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"ION MINCU\"",
            "address": "STR. CASTANILOR NR.6",
            "category": "B",
            "telephone": "0733019375",
            "email": "gologan_florin@yahoo.com"
          },
          {
            "name": "P.F.A. CUCOS DANIEL",
            "address": "STR.CONSTANTIN BRANCOVEANU NR.2E ",
            "category": "B",
            "telephone": "0744429364",
            "email": "geta.males@gmail.com"
          }
        ]
      },
      {
        "city": "BARLAD",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC PETRU RARES",
            "address": "STR. PETRU RARES NR.41",
            "category": "B",
            "telephone": "0235422778",
            "email": "ltpraresbarlad@yahoo.com"
          },
          {
            "name": "AUTO DRIVE SRL",
            "address": "B-DUL.REPUBLICII NR.254",
            "category": "B",
            "telephone": "0744607823",
            "email": "zmircea68@gmail.com"
          },
          {
            "name": "AUTOMOBILE RAY S.R.L.",
            "address": "STR.STROE BELLOIESCU NR.43",
            "category": "B",
            "telephone": "0335414123",
            "email": "nelutzu26@yahoo.com"
          },
          {
            "name": "GRUPMANY-PLUS SRL",
            "address": "BLD.REPUBLICII NR.217 Complex UMPS ",
            "category": "B",
            "telephone": "0745368379",
            "email": "grupmanyplus@yahoo.com"
          },
          {
            "name": "MACDRIVE SRL",
            "address": "STR.GHEORGHE DOJA NR.4",
            "category": "B",
            "telephone": "0740972047",
            "email": "alina75mac1@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC \"ALEXANDRU \nIOAN CUZA\"   ",
            "address": "B-DUL REPUBLICII NR.318",
            "category": "B",
            "telephone": "0235413323",
            "email": "gsiaic@yahoo.com"
          },
          {
            "name": "RAY 11- SRL",
            "address": "STR.STROE BELOESCU NR.43",
            "category": "A,B,C,D,Tr,CE,A1,A2",
            "telephone": "0745397252",
            "email": "scoaladesoferiray11@yahoo.com"
          },
          {
            "name": "AUTOCONTACT SRL",
            "address": "STR.REPUBLICII NR.123 ET.1",
            "category": "A,B,C,D,BE,CE,A1,AM,A2",
            "telephone": "0235419108",
            "email": "autocontact2002@yahoo.com"
          },
          {
            "name": "JONTRANS SRL",
            "address": "BLD.REPUBLICII NR.217 Complex UMPS \ncorp B cam.14-16-17 ET.2",
            "category": "B,C,CE",
            "telephone": "0745303981",
            "email": "jontrans@yahoo.com"
          },
          {
            "name": "PFA NASTASE MIHAI-MARCEL",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0745698820",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA IOAN T. COSTICA",
            "address": "STR.1 DECEMBRIE, NR.2 , SALA CURSURI ",
            "category": "B",
            "telephone": "0744109405",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA STANCIU C.A. IONEL",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0767498950",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA NITU S. OCTAVIAN",
            "address": " STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0753608786",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA PLESU V.GHEORGHE",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0722727597",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA TANASE GH. VIOREL",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0749342498",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA POPA GHE. GHEORGHE EUGEN",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0745226266",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA HRISCU ADRIAN",
            "address": "STR.1 DECEMBRIE, NR.2, SALA DE ",
            "category": "B",
            "telephone": "0745842868",
            "email": "hriscuadrian786@yahoo.com"
          },
          {
            "name": "PFA BALAN MIHAI",
            "address": "STR.1 DECEMBRIE, NR.2, SALA DE ",
            "category": "B",
            "telephone": "0745102265",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA LUPU V.LIVIU",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0742051941",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA ARHIP CEZAR",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0741759829",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "PFA PADURARU V.MIHAI",
            "address": "STR.1 DECEMBRIE, NR.2, SALA CURSURI ",
            "category": "B",
            "telephone": "0741021968",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "VIITORUL SOFER SRL",
            "address": "STR.1DECEMBRIE NR.2 ET.1",
            "category": "B,C,CE",
            "telephone": "0742180631",
            "email": "viitorulsofer1@yahoo.com"
          },
          {
            "name": "P.F.A. PACURA GELU",
            "address": "ALE.PARC NR.29 BL.D5 SC.A ET.2 AP.10",
            "category": "B",
            "telephone": "0744115107",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "P.F.A. ADAMECZ DAN",
            "address": "STR.VARARIEI NR.51 BL.G6 SC.B AP.30",
            "category": "B",
            "telephone": "0740087931",
            "email": "soferuldemaine@yahoo.ro"
          },
          {
            "name": "P.F.A. GOLOGAN-CALMUC MARIUS",
            "address": "STR.VARARIEI NR.2 BL.D2 SC.4 ET.2 ",
            "category": "B",
            "telephone": "0762660085",
            "email": "soferuldemaine@yahoo.ro"
          }
        ]
      },
      {
        "city": "HUSI",
        "schools": [
          {
            "name": "COLEGIUL AGRICOL DIMITRIE \nCANTEMIR",
            "address": "STR.MIHAIL KOGALNICEANU NR.17",
            "category": "B,Tr",
            "telephone": "0235481048",
            "email": "pasager37@yahoo.com"
          },
          {
            "name": "AUTO BHN SRL",
            "address": "STR.STEFAN CEL MARE NR.119(Corp ",
            "category": "B,C,D,BE,CE",
            "telephone": "0761939888",
            "email": "autobhnhusi@yahoo.com"
          },
          {
            "name": "LICEUL TEHNOLOGIC IOAN ",
            "address": "STR. CPT.NICOLAE CISMAN NR.18",
            "category": "B",
            "telephone": "0235480991",
            "email": "19195540"
          },
          {
            "name": "TITAN AUTO SRL",
            "address": "STR.STEFAN CEL MARE NR.97",
            "category": "B",
            "telephone": "0765210266",
            "email": "titan.auto2015@yahoo.com"
          },
          {
            "name": "VERAMIR AUTO SRL",
            "address": "STR.M.RALEA NR.32",
            "category": "B,C,D,CE",
            "telephone": "0235470756",
            "email": "andreea_mihaela_c@yahoo.com"
          },
          {
            "name": "HOLTITEO SRL",
            "address": "STR.1DECEMBRIE NR.47 BL.Recon Parter ",
            "category": "B",
            "telephone": "0765337132",
            "email": "holtiprod@yahoo.com"
          },
          {
            "name": "VIO BHN SPORT SRL",
            "address": "STR.FLORILOR  BL.22 SC.I",
            "category": "B",
            "telephone": "0761939888",
            "email": "bahnariu.ioana@yahoo.com"
          },
          {
            "name": "ALE&GEO@AUTOBEST SRL",
            "address": "Fundatura VIILOR NR.10",
            "category": "B",
            "telephone": "0766234517",
            "email": "vali_jojo_mir@yahoo.com"
          },
          {
            "name": "DOMINOU AUTO SRL",
            "address": "B-DUL 1 MAI NR.82",
            "category": "B",
            "telephone": "0766589634",
            "email": "ivona_grig_23@yahoo.com"
          }
        ]
      },
      {
        "city": "NEGRESTI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC \"NICOLAE \nIORGA\"    ",
            "address": "STR.1 DECEMBRIE NR.3",
            "category": "B,Tr",
            "telephone": "0235457582",
            "email": "stanpusa@yahoo.com"
          },
          {
            "name": "BUTNARU BOGDAN-TRAIAN \nPERSOANA FIZICA AUTORIZATA",
            "address": "STR.CASA APELOR NR.24",
            "category": "B",
            "telephone": "0742381220",
            "email": "bogdantraianuk@gmail.com"
          },
          {
            "name": "BUTNARU IOAN PERSOANA FIZICA \nAUTORIZATA",
            "address": "STR.CASA APELOR NR.24",
            "category": "B",
            "telephone": "0745465857",
            "email": "bogdantraianuk@gmail.com"
          }
        ]
      },
      {
        "city": "SULETEA",
        "schools": [
          {
            "name": "DRIVE TESTE AUTO COMAN SRL",
            "address": "VASLUI STR.MARESAL CONSTANTIN \nPREZAN NR.11-13 Biroul P12",
            "category": "B",
            "telephone": "0756296811",
            "email": "cristinelcoman37@yahoo.ro"
          }
        ]
      },
      {
        "city": "ZORLENI",
        "schools": [
          {
            "name": "LICEUL TEHNOLOGIC ''MARCEL \nGUGUIANU\" ",
            "address": "COM.ZORLENI",
            "category": "B",
            "telephone": "0235424919",
            "email": "vasilicadarie@yahoo.com"
          }
        ]
      },
      {
        "city": "GRIVITA",
        "schools": [
          {
            "name": "PFA STEFANESCU C. VIOREL",
            "address": "COM.GRIVITA NR.590 ",
            "category": "B",
            "telephone": "0744241704",
            "email": "viorel.stefanescu@yahoo.com"
          }
        ]
      },
      {
        "city": "CODAESTI",
        "schools": [
          {
            "name": "PIC FOOD SRL",
            "address": "STR.PRINCIPALA ,LICEUL STEFAN CEL ",
            "category": "B",
            "telephone": "0741106788",
            "email": "Ionelcatalinpamcu91@yahoo.com"
          }
        ]
      }
    ]
  }
]