import React, { useState, useEffect } from 'react';
import './App.css';
import { withRouter, Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import Routes from './Routes';
import { Navbar, Nav } from 'react-bootstrap';

const App = (props) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);


  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/");
  }

  return (
    !isAuthenticating &&
    <>
      <Navbar fluid collapseOnSelect className="mb-5 header-color">
        <Navbar.Brand><Link to="/">viraj.ro</Link></Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {
              isAuthenticated
                ? <>
                  <a className="mx-3" href="/driving-school-admin">Profil</a>
                  <a onClick={handleLogout}>Logout</a>
                </>
                : <Nav.Link href="/sign-in">Logare Admin Scoala</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </>
  );
}

export default withRouter(App);
