import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel
} from "react-bootstrap";
import CustomError from '../common/CustomError';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isConfirming, setIsConfirming] = useState('');
  const [isSendingCode, setIsSendingCode] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const showErrorModal = (message) => {
    setShowError(true);
    setErrorMessage(message);
  }

  const validateCodeForm = () => {
    return email.length > 0;
  }

  const isPasswordValid = () => {
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
    return regex.test(password)
  }

  const validateResetForm = () => {
    return (
      code.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  const handleSendCodeClick = async event => {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (e) {
      showErrorModal("Am intampinat o eroare in timpul trimiterii. Te rugam sa incerci dinnou");
      setIsSendingCode(false);
    }
  };

  const handleConfirmClick = async event => {
    event.preventDefault();

    if (!isPasswordValid()) {
      showErrorModal("Parola trebuie sa contina cel putin 8 caractere, si trebuie sa includa cel putin un numar, un caracter special, un caracter cu litera mare si unul cu litera mica");
      return;
    }

    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(
        email,
        code,
        password
      );
      setConfirmed(true);
    } catch (e) {
      if (e.message && e.message === 'Invalid code provided, please request a code again.') {
        showErrorModal("Codul este incorrect. Te rugam sa incerci dinnou.");
      } else {
        showErrorModal("Am intampinat o eroare in timpul verificarii. Te rugam sa incerci dinnou");
      }

      setIsConfirming(false);
    }
  };

  const renderRequestCodeForm = () => {
    return (
      <form onSubmit={handleSendCodeClick}>
        <FormGroup bsSize="large" controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <button disabled={isSendingCode || !validateCodeForm()} className="btn btn-primary" type="submit" >Trimite Cod
        {isSendingCode && <div className="spinner-margin" role="status"></div>}
        </button>
      </form>
    );
  }

  const renderConfirmationForm = () => {
    return (
      <form onSubmit={handleConfirmClick}>
        <FormGroup bsSize="large" controlId="code">
          <FormLabel>Cod Confirmare</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <Form.Text className="text-muted">Vei primi un cod de confirmare imediat pe adresa {email}. Te rugam sa verifici si folderul spam.</Form.Text>
        </FormGroup>
        <hr />
        <FormGroup bsSize="large" controlId="password">
          <FormLabel>Parola Noua</FormLabel>
          <FormControl
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </FormGroup>
        <FormGroup bsSize="large" controlId="confirmPassword">
          <FormLabel>Confirma Parola</FormLabel>
          <FormControl
            type="password"
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </FormGroup>
        <button disabled={isConfirming || !validateResetForm()} className="btn btn-primary" type="submit" >Confirma
          {isConfirming && <div class="spinner-margin" role="status"></div>}
        </button>
      </form>
    );
  }

  const renderSuccessMessage = () => {
    return (
      <div className="success">
        <p>Parola a fost resetata cu success.</p>
        <p>
          <Link to="/sign-in">
            Click aici pentru a te loga
          </Link>
        </p>
      </div>
    );
  }

  const hideError = () => setShowError(false);

  return (
    <div className="container text-center">
      <h1 className="font-weight-normal mb-5">Resetare Parola</h1>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-4">
          <CustomError show={showError} message={errorMessage} onHide={hideError} />
          {
            !codeSent
              ? renderRequestCodeForm()
              : !confirmed
                ? renderConfirmationForm()
                : renderSuccessMessage()
          }
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;