import React, { useState } from 'react';
import { Row, Col, Form, Container, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import CustomError from './common/CustomError';

const AccountVerification = (props) => {

  const { username } = props.match.params;
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleResendClick = async () => {
    try {
      setIsResendingCode(true);
      await Auth.resendSignUp(username);
    } catch (err) {
      showErrorModal('Am intampinat o eroare in retrimiterii codului de verificare. Te rugam sa incerci dinnou.');
    } finally {
      setIsResendingCode(false);
    }
  }

  const showErrorModal = (message) => {
    setShowError(true);
    setErrorMessage(message);
  }

  const handleVerifyClick = async () => {
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(username, verificationCode);
      props.history.push("/sign-in");
    } catch (e) {
      showErrorModal('Am intampinat o eroare in timpul verificarii codului. Te rugam sa incerci dinnou.');
    } finally {
      setIsLoading(false);
    }
  }

  const isFormValid = () => verificationCode.length > 0

  const hideError = () => setShowError(false);

  return (
    <Container>
      <CustomError show={showError} message={errorMessage} onHide={hideError} />
      <div className="row justify-content-center text-center mb-4">
        <div className="col-lg-10">
          <h1 className="font-weight-normal">Verificare cont</h1>
          <p className="font-weight-light">Vei primi in curant un email cu codul de verificare (verifica si folderul spam!). Te rugam sa il introduci mai jos</p>
        </div>
      </div>
      <Row className="justify-content-center text-center">
        <Col sm={8} md={6} lg={4} >
          <Form>
            <Form.Group>
              <Form.Control onChange={e => setVerificationCode(e.target.value)} />
            </Form.Group>
            <Button disabled={isLoading || !isFormValid()} onClick={handleVerifyClick} variant="primary" >
              Verifica
              {isLoading && <div class="spinner-border spinner-border-sm mx-2" role="status"></div>}
            </Button>
          </Form>

          <div className="my-5">
            <p className="font-weight-light">Daca nu ai primit un email cu codul in 5 minute, te rugam sa dai click pe Retrimite Cod</p>
            <Button onClick={handleResendClick} variant="secondary" >Retrimite Cod
            {isResendingCode && <div class="spinner-border spinner-border-sm mx-2" role="status"></div>}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AccountVerification;